<app-search-navigation></app-search-navigation>

<ng-container *ngIf="itemWasFound && !loadingIncident">
    <div *ngIf="showEmailNotificationModal">
        <app-themed-dialog (closeModal)="closeEmailNotificationModal()" dialogTitle="Notify Contacts" data-testid="notify-contacts-modal">
            <div class="contact-instructions" top>
                To include more addresses, type or paste values separated by a space, comma, or semicolon.
            </div>
            <div class="resend-email-modal" content>
                <app-textarea
                    name="additional-recipients"
                    label="Contacts:"
                    [(value)]="editableEmailDetails.newRecipient"
                    [verticalStyle]="true"
                    [disableResize]="true"
                    placeholder="Add More..."
                    [hideBorder]="true"
                    (keydown)="onContactChange($event)">
                    <div class="recipient-pill-container">
                        <app-email-contact-pill
                            *ngFor="let recipient of editableEmailRecipients"
                            [email]="recipient.email"
                            [canRemove]="recipient.canRemove"
                            [checked]="recipient.include"
                            [invalidEmail]="recipient.invalidEmail"
                            (toggleRecipient)="toggleRecipient($event)"
                            (removeRecipient)="removeRecipient($event)"></app-email-contact-pill>
                    </div>
                </app-textarea>
                <div class="divider"></div>
                <app-textarea
                    name="subject"
                    label="Subject:"
                    [value]="emailDetails.subject"
                    [disabled]="true"
                    [verticalStyle]="true"
                    [disableResize]="true"></app-textarea>
                <div class="divider"></div>
                <app-textarea
                    name="message-body"
                    label="Message Body:"
                    [(value)]="editableEmailDetails.messageBody"
                    [verticalStyle]="true"
                    [disableResize]="true"></app-textarea>
                <div class="attachments">
                    <app-checkbox
                        *ngFor="let attachment of emailDetails.attachments"
                        label="Attach {{ attachment }}"
                        checked="true"
                        disabled="true"></app-checkbox>
                </div>
            </div>
            <ng-container buttons>
                <gaggle-button label="Send" buttonStyle="primary" (buttonClick)="resendEmail()" class="resend-button"></gaggle-button>
            </ng-container>
        </app-themed-dialog>
    </div>
    <div class="action-bar">
        <div>
            <app-menu-button
                label="Unblock"
                icon="unblock"
                (buttonClick)="unblockIncident()"
                [disabled]="isUnblockButtonDisabled"
                [showSpinner]="isUnblockingIncident"></app-menu-button>
            <app-menu-button
                data-testid="contact-communications-button"
                *ngIf="warningTypeShortened !== 'VIOL'"
                label="Communications"
                icon="contact-person"
                (buttonClick)="showIncidentContactCommunications()"></app-menu-button>
        </div>
        <div>
            <app-menu-button label="View Work Item" icon="category" (buttonClick)="viewWorkItem()"></app-menu-button>
            <app-menu-button
                label="Reopen"
                icon="reload"
                (buttonClick)="reopenIncident()"
                [disabled]="isReopenButtonDisabled"></app-menu-button>
        </div>
    </div>
    <div class="incident-details">
        <div class="detail-row">
            <gaggle-icon
                [iconName]="warningIcon"
                iconState="active"
                [color]="textColor"
                iconSize="1em"
                title="warning-level-icon"></gaggle-icon>
            <p class="bold warning-level">{{ warningTypeShortened }}:</p>
            <p class="date">
                <span class="bold">{{ incidentDate.dayAndMonth }}</span>
                , {{ incidentDate.year }} at
                <span class="bold">{{ incidentDate.time }}</span>
            </p>
        </div>
        <div class="detail-row">
            <p class="student-name bold">{{ incidentDetails.userFullName }}</p>
        </div>
        <div class="detail-row">
            <div class="district">
                <span class="bold">District:</span>
                <span>{{ incidentDetails.districtName }}</span>
            </div>
            <div class="school-group">
                <span class="bold">School Group:</span>
                <span>{{ incidentDetails.groupName }}</span>
            </div>
        </div>
    </div>
    <div class="incident-summary-container">
        <div class="incident-summary">
            <div class="summary-row">
                <p>Incident Type:</p>
                <p>{{ warningType }}</p>
            </div>
            <div class="summary-row">
                <p>Incident ID:</p>
                <p>{{ incidentDetails.incidentId }}</p>
            </div>
            <div class="summary-row">
                <p>Item ID:</p>
                <p>{{ incidentDetails.workItemId }}</p>
            </div>
            <div class="summary-row" *ngIf="itemDetails.ncmecId">
                <p>NCMEC ID:</p>
                <p>{{ itemDetails.ncmecId }}</p>
            </div>
            <div class="summary-row">
                <p>Acknowledged By:</p>
                <div class="acknowledgement-info" *ngIf="incidentDetails.acknowledgmentDetails?.date; else noAck">
                    <p data-testid="acknowledgement-info">
                        {{ incidentDetails.acknowledgmentDetails?.fullName }} -
                        {{ incidentDetails.acknowledgmentDetails?.date | date: "medium" }}
                    </p>
                    <gaggle-pill
                        class="portal"
                        data-testid="ack-source-pill"
                        [textColor]="textColor"
                        *ngIf="incidentDetails.acknowledgmentDetails?.source">
                        <p>{{ incidentDetails.acknowledgmentDetails?.source }}</p>
                    </gaggle-pill>
                </div>
                <ng-template #noAck>
                    <p data-testid="acknowledgement-info">NOT ACKNOWLEDGED</p>
                </ng-template>
            </div>
            <div class="summary-row">
                <p>Reopened:</p>
                <p data-testid="reopened">{{ hasMultipleCompleteEvents }}</p>
            </div>
            <div class="summary-row">
                <p>GAFT Incident:</p>
                <p data-testid="gaft">{{ incidentDetails.gaft }}</p>
            </div>
            <div class="summary-row">
                <p>Internal Notes:</p>
                <p data-testid="internal-notes">{{ incidentDetails.internalNotes || "--" }}</p>
            </div>

            <div class="divider"></div>
            <div class="summary-row">
                <p>User:</p>
                <p>{{ incidentDetails.userFullName }}</p>
            </div>
            <div class="summary-row">
                <p>Username:</p>
                <p>{{ incidentDetails.userName }}</p>
            </div>
            <div class="summary-row">
                <p>District:</p>
                <p>{{ incidentDetails.districtName }}</p>
            </div>
            <div class="summary-row">
                <p>School Group:</p>
                <p>{{ incidentDetails.groupName }}</p>
            </div>
            <div class="summary-row">
                <p>District Notes:</p>
                <p>{{ itemDetails.districtNotes || "--" }}</p>
            </div>

            <div class="divider"></div>
            <div class="summary-row">
                <p>Content Type:</p>
                <p>{{ entityType }}</p>
            </div>
            <div class="summary-row">
                <p>Content Category:</p>
                <p>{{ actionReasons }}</p>
            </div>
            <div class="summary-row">
                <p>Content Availability:</p>
                <p>{{ contentAvailability }}</p>
            </div>
            <div class="summary-row">
                <p>Reason:</p>
                <p>{{ incidentDetails.blockedReasons }}</p>
                <!-- TODO: should this reference the itemDetails blockReasons for some parsing purposes? -->
            </div>

            <div class="divider"></div>
            <div class="summary-row">
                <p>Event Log:</p>
                <div>
                    <div class="event-row" *ngFor="let event of events">
                        <p>{{ event.label }}:</p>
                        <p class="date">
                            <ng-container *ngIf="event.date.message; else eventDate">
                                <span class="dark">{{ event.date.message }}</span>
                            </ng-container>
                            <ng-template #eventDate>
                                <span class="dark">{{ event.date.dayAndMonth }}</span>
                                , {{ event.date.year }} at
                                <span class="dark">{{ event.date.time }}</span>
                                <span *ngIf="event.workerName">
                                    by
                                    <span class="dark">{{ event.workerName }}</span>
                                </span>
                            </ng-template>
                        </p>
                    </div>
                </div>
            </div>

            <div class="divider"></div>
            <div class="summary-row" *ngIf="originalMessageDetails">
                <p>Original Message:</p>
                <div>
                    <div class="event-row">
                        <p>From:</p>
                        <p>{{ originalMessageDetails.sender }}</p>
                    </div>
                    <div class="event-row">
                        <p>To:</p>
                        <div>
                            <p *ngFor="let recipient of originalMessageDetails.recipients">
                                {{ recipient }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="email-preview" *ngIf="emailContents">
            <div class="detail-row">
                <p class="email-sender">
                    <span class="bold">Gaggle Safety</span>
                    <{{ emailDetails.from }}>
                </p>
                <p class="date">
                    <span class="bold">{{ incidentDate.dayAndMonth }}</span>
                    , {{ incidentDate.year }} at
                    <span class="bold">{{ incidentDate.time }}</span>
                </p>
            </div>
            <div class="detail-row" *ngIf="emailDetails.subject">
                <p class="email-subject bold">{{ emailDetails.subject }}</p>
            </div>
            <div class="recipient-list">
                <span class="bold label">To:</span>
                {{ emailDetails.to }}
            </div>
            <div class="recipient-list" *ngIf="emailDetails.cc">
                <span class="bold label">CC:</span>
                {{ emailDetails.cc }}
            </div>
            <div class="email">
                <iframe src="about:blank" [srcdoc]="emailContents | safeHtml" [id]="EMAIL_PREVIEW_ID" (load)="resizeToEmail()"></iframe>
            </div>
        </div>

        <div class="email-preview" *ngIf="!emailContents">
            <div class="not-found">No email was found associated with this incident</div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!itemWasFound && !loadingIncident">
    <div class="not-found">
        <p>
            No incident found under incident id
            <code>{{ incidentId }}</code>
        </p>
        <gaggle-button *ngIf="itemDetails" label="View Work Item" (buttonClick)="viewWorkItem()" buttonStyle="secondary"></gaggle-button>
    </div>
</ng-container>
