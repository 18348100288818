import { Injectable } from "@angular/core";
import { IntrospectionFragmentMatcher, NormalizedCacheObject } from "apollo-cache-inmemory";
import { ConfigService } from "src/app/shared/services/config.service";
import { StateService } from "src/app/shared/services/state.service";
import { AuthService } from "src/app/shared/services/auth.service";
import AWSAppSyncClient from "aws-appsync";
import { LoggingLevel } from "src/app/models/client-logging.model";
import logEvent from "src/app/graphql/mutations/logEvent";
import { SplitService } from "@splitsoftware/splitio-angular";
import { FeatureFlagName, FeatureFlagValue } from "src/app/models/feature-flags.model";

const UI_SESSION_ID_HEADER_KEY = "X-Gaggle-UI-Session-ID";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: "INTERFACE",
          name: "WorkItem",
          possibleTypes: [
            { name: "ChatWorkItem" },
            { name: "GenericWorkItem" },
            { name: "ThirdPartyWorkItem" },
            { name: "SpeakupWorkItem" },
            { name: "WebFilterWorkItem" },
          ],
        },
      ],
    },
  },
});

@Injectable({
  providedIn: "root",
})
export class AppsyncClientService {
  private callTracker = {};
  private workItemRetryCount = 0;
  private client: Promise<AWSAppSyncClient<NormalizedCacheObject>>;

  constructor(
    private configService: ConfigService,
    private state: StateService,
    private authService: AuthService,
    private splitService: SplitService,
  ) {
    this.createClient();
  }

  async getClient(): Promise<AWSAppSyncClient<NormalizedCacheObject>> {
    return this.client;
  }

  private getApiKeyClient(): Promise<AWSAppSyncClient<NormalizedCacheObject>> {
    return new AWSAppSyncClient({
      url: this.configService.getConfig().baseApiUrl,
      region: "us-west-2",
      auth: {
        type: "API_KEY",
        apiKey: "invalidApiKey",
      },
      disableOffline: true,
    }).hydrated();
  }

  private async createClient(): Promise<AWSAppSyncClient<NormalizedCacheObject>> {
    const config = this.configService.getConfig();
    const urlOverride = this.splitService.getTreatment(FeatureFlagName.APPSYNC_URL_OVERRIDE, {
      username: this.authService.getUsername(),
    });

    let baseApiUrl = config.baseApiUrl;
    if (urlOverride !== FeatureFlagValue.NOT_SET && urlOverride !== FeatureFlagValue.OFF) {
      baseApiUrl = `https://${urlOverride}.appsync-api.us-west-2.amazonaws.com/graphql`;
    }

    this.state.gewiBaseUrl = config.gewiBaseUrl;
    this.workItemRetryCount = config.nextItemRetryCount;
    this.client = new AWSAppSyncClient({
      url: baseApiUrl,
      region: "us-west-2",
      auth: {
        type: "AMAZON_COGNITO_USER_POOLS",
        jwtToken: () => this.authService.getAuthToken(),
      },
      disableOffline: true,
      cacheOptions: {
        fragmentMatcher,
      },
    }).hydrated();

    return this.client;
  }

  public async query<T>(
    query: any,
    variables: any = {},
    { useApiKeyClient = false, returnError = false, retryCount = this.workItemRetryCount, retryingCall = false } = {},
  ): Promise<T | null> {
    const queryName = query.definitions[0].name.value;

    if (!retryingCall) {
      this.callTracker[queryName] = 0;
    }

    let client: AWSAppSyncClient<NormalizedCacheObject>;
    if (useApiKeyClient) {
      client = await this.getApiKeyClient();
    } else {
      client = await this.getClient();
    }

    const queryVariables = {
      "@@skipRetry": true,
      ...variables,
    };

    try {
      const response = await client.query<T>({
        query,
        variables: queryVariables,
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        context: {
          headers: {
            [UI_SESSION_ID_HEADER_KEY]: this.state.uiSessionId,
            "ngsw-bypass": "",
          },
        },
      });

      if (response.errors) {
        if (this.noPSAPDataFound(response) || this.noExternalMetadataFound(response)) {
          return null;
        }

        this.logClientEvent(LoggingLevel.WARNING, `Error received from Graphql during query ${queryName}`, response);
        return null;
      }

      this.callTracker[queryName] = 0;
      return response.data;
    } catch (error) {
      if (error.networkError?.statusCode === 401 && !retryingCall) {
        await this.authService.refreshSession();
        return await this.query<T>(query, variables, { useApiKeyClient, returnError, retryingCall: true });
      } else {
        if (returnError) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return { error };
        } else {
          if (this.callTracker[queryName] < retryCount) {
            this.callTracker[queryName] += 1;
            return await this.query<T>(query, variables, { useApiKeyClient, returnError, retryingCall: true });
          } else {
            this.logClientEvent(LoggingLevel.ERROR, `Could not complete query ${queryName}`, error);
            return null;
          }
        }
      }
    }
  }

  public async mutate<T>(
    mutation: any,
    variables: any = {},
    { retryingCall = false, retryCount = this.workItemRetryCount, enableRetry = false } = {},
  ): Promise<T | null> {
    const client = await this.getClient();
    const mutationName = mutation.definitions[0].name.value;

    if (!retryingCall) {
      this.callTracker[mutationName] = 0;
    }

    const mutationVariables = {
      "@@skipRetry": true,
      ...variables,
    };

    try {
      const response = await client.mutate<T>({
        mutation,
        variables: mutationVariables,
        fetchPolicy: "no-cache",
        context: {
          headers: {
            [UI_SESSION_ID_HEADER_KEY]: this.state.uiSessionId,
            "ngsw-bypass": "",
          },
        },
      });

      if (response.errors) {
        this.logClientEvent(LoggingLevel.WARNING, `Error received from Graphql during mutation ${mutationName}`, response);
      }

      this.callTracker[mutationName] = 0;
      return response.data;
    } catch (error) {
      if (error.networkError?.statusCode === 401 && !retryingCall) {
        await this.authService.refreshSession();
        return await this.mutate<T>(mutation, variables, { retryingCall: true });
      } else {
        if (enableRetry && this.callTracker[mutationName] < retryCount) {
          this.callTracker[mutationName] += 1;
          return await this.mutate<T>(mutation, variables, { retryingCall: true });
        } else {
          this.logClientEvent(LoggingLevel.ERROR, `Could not complete mutation ${mutationName}`, error);
          return null;
        }
      }
    }
  }

  // onSubscriptionResponse is a function that performs logic based on alerts pushed out by the given subscription
  public async subscribe<T>(subscription: any, variables: any = {}, onSubscriptionResponse: any): Promise<T | null> {
    const client = await this.getClient();
    const subscriptionName = subscription.definitions[0].name.value;

    try {
      const observable = client.subscribe({
        query: subscription,
        variables,
      });

      observable.subscribe({
        next: onSubscriptionResponse,
        complete: console.log,
        error: (error) => this.logClientEvent(LoggingLevel.ERROR, `Could not complete subscription ${subscriptionName}`, error),
      });

      return null;
    } catch (error) {
      this.logClientEvent(LoggingLevel.ERROR, `Could not complete subscription ${subscriptionName}`, error);
      return null;
    }
  }

  public logClientEvent(level: LoggingLevel, message: string, eventData: any): void {
    const data = eventData !== null ? eventData : <any>{};
    data.workerUsername = this.authService.signedIn() ? this.authService.getUsername() : "unauthenticated user";
    this.getClient().then((client) => {
      client.mutate({
        // don't use the mutate helper function to avoid finding yourself in an error loop
        mutation: logEvent,
        variables: {
          level: level,
          source: "L2UI",
          message: message,
          data: JSON.stringify(data),
        },
        fetchPolicy: "no-cache",
      });
    });
    switch (level) {
      case LoggingLevel.ERROR:
        console.error(message, eventData);
        break;
      case LoggingLevel.WARNING:
        console.warn(message, eventData);
        break;
      case LoggingLevel.INFO:
        console.info(message, eventData);
        break;
      case LoggingLevel.DEBUG:
        console.debug(message, eventData);
    }
  }

  private tokenHasExpired(error): boolean {
    const statusCode = error?.networkError?.statusCode;
    return statusCode === 401 && error.networkError?.result?.errors?.some((error) => error.message.includes("Token has expired."));
  }

  private noPSAPDataFound(response): boolean {
    return (
      !response.data?.getPSAPInfoForStudent && response.errors.some((error) => error.message.includes("No SIS data returned for user"))
    );
  }

  private noExternalMetadataFound(response): boolean {
    return !response.data?.getExternalWorkItemMetadata && response.errors.some((error) => error.errorType === "Not Found");
  }
}
