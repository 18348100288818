import gql from "graphql-tag";

export default gql`
  query getQueues {
    queueStatistics {
      queues {
        level1Count
        level2Count
        highPriorityCount
        type
      }
    }
  }
`;
