<div
    class="contact"
    [ngClass]="{ disabled: isDisabled }">
    <div
        class="name"
        [ngClass]="{ 'unknown-name': !contact.name.length }">
        {{ contact.name || "Unknown" }}
    </div>
    <div>
        <div class="title-container">
            <div class="title">
                {{ jobTitle }}
                <gaggle-pill
                    class="after-hours-pill"
                    data-testid="after-hours-pill"
                    icon="after-hours"
                    color="#e6e6ff"
                    iconColor="#665cff"
                    *ngIf="contact.afterHours"></gaggle-pill>
            </div>
            <div
                *ngIf="!contact.afterHours"
                class="availability-toggle">
                <gaggle-toggle
                    (status)="onToggleDisabled()"
                    [isToggleOn]="!isDisabled"
                    name="{{ contact.name }}"></gaggle-toggle>
            </div>
        </div>
        <div class="text-phone-row">
            <div class="phone-number">
                <span class="phone-type">Mobile:</span>
                {{ formattedPhoneNumber }}
            </div>
            <gaggle-button
                icon="copy"
                label="Copy"
                (click)="onCopyClick()"
                [disabled]="isDisabled"
                [backgroundColor]="copyButtonBackground"
                [textColor]="textButtonColor"
                [iconColor]="textButtonColor">
            </gaggle-button>
        </div>
    </div>
</div>
