import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "src/app/shared/services/auth.service";
import { ThemeService } from "src/app/shared/services/theme.service";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { Router, ActivatedRoute, NavigationEnd, Route } from "@angular/router";
import { StateService } from "src/app/shared/services/state.service";
import { FeatureFlagName, FeatureFlagValue } from "src/app/models/feature-flags.model";
import { RoutingLabels } from "src/app/models/routing.model";
import { filter } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { map } from "rxjs/operators";
import { SwUpdate } from "@angular/service-worker";
import { UiAnalyticsService } from "@shared/services/ui-analytics.service";
import { WorkItemVisibilityService } from "@shared/services/work-item-visibility.service";
import { EmergencyContactService } from "@shared/services/emergency-contact.service";

interface Tab {
  label: RoutingLabels;
  route: string;

  shouldDisplay(): boolean;
}

@UntilDestroy()
@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit {
  @Input() activeLink: RoutingLabels;

  public showRightDrawer: boolean;
  public routes: { url: string; data: any; route: Route }[] = [];

  navigationTabs: Tab[] = [
    {
      label: RoutingLabels.REVIEW,
      route: "/",
      shouldDisplay: (): boolean => true,
    },
    {
      label: RoutingLabels.SEARCH,
      route: "/search",
      shouldDisplay: (): boolean => true,
    },
    {
      label: RoutingLabels.ADMIN,
      route: "/admin",
      shouldDisplay: (): boolean => this.auditIsEnabled,
    },
  ];
  showUserMenu = false;
  newApplicationAvailable = false;

  constructor(
    private authService: AuthService,
    private themeService: ThemeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private stateService: StateService,
    private appSyncService: AppSyncService,
    private swUpdate: SwUpdate,
    private uiAnalyticsService: UiAnalyticsService,
    private workItemVisibilityService: WorkItemVisibilityService,
    private emergencyContactService: EmergencyContactService,
  ) {}

  ngOnInit(): void {
    this.stateService.newApplicationAvailable$.subscribe((val) => {
      if (val) {
        this.newApplicationAvailable = true;
      }
    });

    this.stateService.showRightDrawer$.subscribe((val) => {
      this.showRightDrawer = val;
    });

    // Subscribe to router events to update the active link when the route changes
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd), // Only NavigationEnd events
        map(() => this.activatedRoute), // Get the current activated route
        map((route) => {
          while (route.firstChild) {
            // Get the deepest child route, if there are multiple router outlets
            route = route.firstChild;
          }
          return route;
        }),
      )
      .subscribe((route) => this.setActiveLink(route));

    // Upon initial load, grab the currently activated route and find the deepest child route / component
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
      this.setActiveLink(route);
    }
  }

  /**
   * Sets the active link based on the current route. This is configured in the routing module by
   * specifying a label in the data object of the route.
   *
   * @param route
   */
  setActiveLink(route: ActivatedRoute): void {
    this.activeLink = route.snapshot.data.label || this.activeLink;
  }

  get auditIsEnabled(): boolean {
    return this.stateService.getFeatureFlag(FeatureFlagName.L2_AUDIT) === FeatureFlagValue.ON;
  }

  getLatestApplication(): void {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }

  onLogOut(): void {
    this.showUserMenu = false;
    this.appSyncService.logout();
  }

  onToggleUserMenu(): void {
    this.showUserMenu = !this.showUserMenu;
  }

  get validGaggleUser(): boolean {
    return this.authService.validGaggleUser();
  }

  get username(): string {
    return this.authService.getUsername();
  }

  toggleTheme(): void {
    this.themeService.toggleTheme();
  }

  navigateToHome(): void {
    window.open("https://apps.gaggle.net", "_blank");
  }

  get iconColor(): string {
    return this.themeService.getVariable("--nav-link-color");
  }

  onClickTab(tab: Tab): void {
    if (this.showRightDrawer) {
      return;
    }
    this.uiAnalyticsService.leaveQueue();
    this.workItemVisibilityService.stopHeartbeat(15);
    this.emergencyContactService.reset();
    this.stateService.exitReviewMode();
    this.router.navigate([tab.route]);
  }
}
