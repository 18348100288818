import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ThemeService } from "src/app/shared/services/theme.service";

@Component({
  selector: "app-email-contact-pill",
  templateUrl: "./email-contact-pill.component.html",
  styleUrls: ["./email-contact-pill.component.scss"],
})
export class EmailContactPillComponent {
  @Input() email: string;
  @Input() canRemove = false;
  @Input() invalidEmail = false;
  @Input() checked;

  @Output() toggleRecipient = new EventEmitter();
  @Output() removeRecipient = new EventEmitter();

  constructor(private theme: ThemeService) {}

  get pillColor(): string {
    if (this.invalidEmail) {
      return "rgb(245,237,238)";
    }
    return this.checked ? "RGBA(103, 92, 255, 0.16)" : "rgba(0, 0, 0, 0.08)";
  }

  get invalidEmailCheckboxColor(): string {
    return this.theme.getVariable("--red-color");
  }

  recipientClick(): void {
    this.toggleRecipient.emit(this.email);
  }

  removeContact(): void {
    this.removeRecipient.emit(this.email);
  }
}
