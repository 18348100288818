import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { StateService } from "src/app/shared/services/state.service";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { ThemeService } from "src/app/shared/services/theme.service";
import { EMAIL_REGEX, formattedDateObject } from "src/app/shared/utils/format";
import { ACTION_REASON_MAP, EventType, WARNING_DISPLAY_MAP, WARNING_TYPE_MAP } from "src/app/models/incident.model";
import { InternalWorkItemStatus, WorkItemEventMap } from "src/app/models/work-item-incident.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { ActionReason, EventPayload, MessageMetadata, workItemToDisplayTypeMap, WorkItemType } from "src/app/models/work-item.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { getWarningTypeIcon } from "src/app/shared/utils/helpers";
import { EmergencyContactService } from "src/app/shared/services/emergency-contact.service";
import { MatDialog } from "@angular/material/dialog";
import { IncidentContactCommunicationsDialogComponent } from "src/app/components/incident-summary/incident-contact-communications-dialog/incident-contact-communications-dialog.component";
import { Incident, WarningEventType } from "src/generated/graphql";
import { contentCategories } from "src/app/models/content-category.model";
import { Observable, Subject, takeUntil } from "rxjs";
import { Select } from "@ngxs/store";
import { SearchRecordNavigationInfo, SearchSelectors } from "@shared/state/search/search.selectors";

interface EmailDetails {
  from: string;
  subject: string;
  messageBody: string;
  allRecipients: string[];
  to: string;
  cc: string;
  attachments: string[];
}

interface EditableEmailDetails {
  messageBody: string;
  recipients: any[];
  newRecipient: string;
}

@Component({
  selector: "app-incident-summary",
  templateUrl: "./incident-summary.component.html",
  styleUrls: ["./incident-summary.component.scss"],
})
export class IncidentSummaryComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  EMAIL_PREVIEW_ID = "email-preview";
  incidentId: string;
  loadingIncident = false;
  isResendingEmail = false;
  isUnblockingIncident = false;
  incidentDetails: Incident;
  itemDetails;
  internalStatus: InternalWorkItemStatus;

  incidentWasReopened = false;

  emailContents = null;
  emailDetails: EmailDetails;
  editableEmailDetails: EditableEmailDetails;
  originalMessageDetails = null;
  incidentDate = null;
  warningIcon: string;
  warningType: string;
  warningTypeShortened: string;
  entityType: string;
  actionReasons: string;
  contentAvailability: string;
  events = [];

  showEmailNotificationModal = false;
  isAfterHours = false;
  hasMultipleCompleteEvents = false;

  textColor: string;

  @Select(SearchSelectors.searchRecordNavigationInfo) searchRecordNavigationInfo$: Observable<SearchRecordNavigationInfo>;

  constructor(
    private activeRoute: ActivatedRoute,
    private state: StateService,
    private appSync: AppSyncService,
    private theme: ThemeService,
    private auth: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private location: Location,
    private matDialog: MatDialog,
    private emergencyContactService: EmergencyContactService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.activeRoute.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe(({ params }) => {
      this.incidentId = params["incidentId"];
      if (this.incidentId) {
        this.appSync.getWorkItemAndIncidentByGsmId(this.incidentId);
      }
    });

    this.activeRoute.data.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.incidentWasReopened = !!data.reopenedIncident;
      } else {
        this.incidentWasReopened = false;
      }
    });

    this.state.showFullPageLoadingSpinner$.pipe(takeUntil(this.unsubscribe$)).subscribe((showingSpinner) => {
      this.loadingIncident = showingSpinner;
      setTimeout(() => this.changeDetectorRef.detectChanges());
    });

    this.state.currentIncidentDetails$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.incidentDetails = data;
        this.emergencyContactService.selectedCategories = contentCategories
          .filter((category) => {
            return this.incidentDetails.actionReasons.includes(category.actionReason as any);
          })
          .map((category) => {
            category.warningType = this.incidentDetails.warningType as unknown as WarningEventType;
            return category;
          });

        if (this.incidentDetails.incidentEmail) {
          this.emailContents = this.incidentDetails.incidentEmail.bodyHtml;

          this.emailDetails = {
            from: this.incidentDetails.incidentEmail.from,
            subject: this.incidentDetails.incidentEmail.subject,
            messageBody: this.incidentDetails.notes, // TODO: change this reference to correct thing?
            allRecipients: [...this.incidentDetails.incidentEmail.to, ...this.incidentDetails.incidentEmail.cc].filter((v) => v),
            to: this.incidentDetails.incidentEmail.to.join(", "),
            cc: this.incidentDetails.incidentEmail.cc?.join(", ") || null,
            attachments: this.incidentDetails.attachments
              ?.map((attachment) => (attachment.include ? attachment.name : null))
              .filter((v) => v),
          };
        } else {
          this.emailContents = null;
          this.emailDetails = null;
        }

        this.warningTypeShortened = WARNING_DISPLAY_MAP[this.incidentDetails.warningType];
        this.warningType = `${WARNING_TYPE_MAP[this.incidentDetails.warningType]} (${this.warningTypeShortened})`;
        this.warningIcon = getWarningTypeIcon(this.incidentDetails.warningType);
        this.entityType = workItemToDisplayTypeMap[this.incidentDetails.entityType];
        this.actionReasons = this.incidentDetails.actionReasons.map((ar) => ACTION_REASON_MAP[ar]).join(", ");
        this.resetEditableEmailDetails();
      }
    });

    this.state.currentItemDetails$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.itemDetails = data;

        const hasQuarantineEvent = this.itemDetails.events.some(
          (event) => event.event === EventType.QUARANTINE || event.event === EventType.QUARANTINE_ONLY,
        );
        this.contentAvailability = hasQuarantineEvent ? "Blocked" : "Normal Availability";
        this.incidentDate = formattedDateObject(parseInt(this.incidentDetails.processedDate));
        this.events = this.createEvents();
        this.location.replaceState(`/incidents/${this.incidentId}`);
      }
    });

    this.state.currentInternalWorkItemStatus$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.internalStatus = data;
    });

    this.state.messageMetadata$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.originalMessageDetails = this.createMessageDetails(data);
    });

    this.state.itemIsInAfterHoursWindow$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.isAfterHours = data;
    });

    this.theme.themeMode$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.textColor = this.theme.getVariable("--text-primary-color");
    });
  }

  get hasReopenPermission(): boolean {
    return this.auth.hasReopenPermission();
  }

  get itemWasFound(): boolean {
    return !!this.incidentDetails && !!this.itemDetails;
  }

  get editableEmailRecipients(): any[] {
    return this.editableEmailDetails.recipients;
  }

  get isUnblockButtonDisabled(): boolean {
    return !this.hasReopenPermission || this.isUnblockingIncident;
  }

  get isNotifyContactsButtonDisabled(): boolean {
    return true;
    // return !this.hasReopenPermission || this.isResendingEmail;
  }

  get isReopenButtonDisabled(): boolean {
    return !this.hasReopenPermission || this.incidentWasReopened || !this.finalEventIsCompleted || !this.incidentIsFullyCompleted;
  }

  get finalEventIsCompleted(): boolean {
    return this.itemDetails.events[this.itemDetails.events.length - 1].event === "complete";
  }

  get incidentIsFullyCompleted(): boolean {
    if (this.internalStatus) {
      return this.internalStatus.state === "final" && this.internalStatus.locked === false;
    }

    return false;
  }

  showIncidentContactCommunications(): void {
    this.matDialog.open(IncidentContactCommunicationsDialogComponent, {
      width: "600px",
      data: {
        incidentContacts: this.incidentDetails?.workerCommunications?.contacts,
        incidentDetails: this.incidentDetails,
      },
    });
  }

  openEmailNotificationModal(): void {
    if (this.isNotifyContactsButtonDisabled) {
      return;
    }
    this.showEmailNotificationModal = true;
  }

  reopenIncident(): void {
    if (this.isReopenButtonDisabled) {
      return;
    }

    this.router.navigateByUrl(`/incidents/${this.itemDetails.id}/reopen`);
  }

  viewWorkItem(): void {
    this.router.navigateByUrl(`/work-items/${this.itemDetails.id}`);
  }

  closeEmailNotificationModal(): void {
    this.showEmailNotificationModal = false;
    this.resetEditableEmailDetails();
  }

  private resetEditableEmailDetails(): void {
    if (this.emailDetails) {
      this.editableEmailDetails = {
        messageBody: this.emailDetails.messageBody,
        recipients: this.emailDetails.allRecipients.map((recipient) => ({
          include: false,
          email: recipient,
          canRemove: false,
        })),
        newRecipient: "",
      };
    }
  }

  toggleRecipient(selectedEmail: string): void {
    const indexOfMatchingRecipient = this.editableEmailDetails.recipients.indexOf(
      this.editableEmailDetails.recipients.find((recipient) => recipient.email === selectedEmail),
    );
    this.editableEmailDetails.recipients[indexOfMatchingRecipient].include =
      !this.editableEmailDetails.recipients[indexOfMatchingRecipient].include;
  }

  removeRecipient(selectedEmail: string): void {
    const indexOfMatchingRecipient = this.editableEmailDetails.recipients.indexOf(
      this.editableEmailDetails.recipients.find((recipient) => recipient.email === selectedEmail),
    );

    this.editableEmailDetails.recipients = [
      ...this.editableEmailDetails.recipients.slice(0, indexOfMatchingRecipient),
      ...this.editableEmailDetails.recipients.slice(indexOfMatchingRecipient + 1),
    ];
  }

  onContactChange(event: any): void {
    const emailInput = event.target.value.trim();
    if (event.code === "Enter" || event.code === "Space" || event.code === "Comma" || event.code === "Semicolon") {
      event.preventDefault();
      if (!emailInput.length) {
        return;
      }
      const matchingRecipient = this.editableEmailDetails.recipients.find((recipient) => recipient.email === emailInput);
      if (matchingRecipient) {
        this.toggleRecipient(matchingRecipient.email);
        this.editableEmailDetails.newRecipient = "";
        return;
      }

      const separatorRegex = new RegExp(/[\,\;\s]\s?/, "gi");

      const textSplitOnSeparator = emailInput.split(separatorRegex);

      textSplitOnSeparator.forEach((recipient) => {
        const validEmail = recipient.match(EMAIL_REGEX);

        if (validEmail) {
          this.editableEmailDetails.recipients.push({
            include: true,
            email: validEmail[0],
            canRemove: true,
          });
        } else {
          this.editableEmailDetails.recipients.push({
            include: true,
            email: recipient,
            canRemove: true,
            invalidEmail: true,
          });
        }
      });

      this.editableEmailDetails.newRecipient = "";
      return;
    }

    if (event.code === "Backspace" && !emailInput.length) {
      const lastRecipientIsRemovable = this.editableEmailDetails.recipients[this.editableEmailDetails.recipients.length - 1].canRemove;
      if (lastRecipientIsRemovable) {
        this.editableEmailDetails.recipients = [...this.editableEmailDetails.recipients.slice(0, -1)];
      }
      return;
    }
  }

  async unblockIncident(): Promise<void> {
    if (this.isUnblockButtonDisabled) {
      return;
    }

    this.isUnblockingIncident = true;
    const payload: EventPayload = {
      userId: this.itemDetails.userId,
      groupId: this.itemDetails.groupId,
      districtId: this.itemDetails.districtId,
      customerId: this.itemDetails.customerId,
      entityId: this.itemDetails.entityId,
      entityType: this.itemDetails.workItemEntityType,
      userEmail: null,
      remoteEntityId: null,
      actionReasons: [ActionReason.FALSE_POSITIVE],
    };

    await this.appSync.resolveItemInBackground(EventType.UNBLOCK, payload, true);

    this.contentAvailability = "Normal Availability";
    this.snackBar.open("Content unblocked.", "Okay", {
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    });

    this.isUnblockingIncident = false;
  }

  async resendEmail(): Promise<void> {
    this.isResendingEmail = true;
    const shouldReceiveEmail = this.editableEmailDetails.recipients.filter((recipient) => recipient.include);
    const onlyValidEmails = shouldReceiveEmail.filter((recipient) => !recipient.invalidEmail);

    const intendedRecipients = onlyValidEmails.map((recipient) => ({
      email: recipient.email,
    }));
    const messageBody = this.editableEmailDetails.messageBody;
    this.closeEmailNotificationModal();

    await this.appSync.resendIncidentEmail(this.incidentDetails.incidentId, messageBody, intendedRecipients);

    this.snackBar.open("Incident email sent.", "Okay", {
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    });
    this.isResendingEmail = false;
  }

  private createEvents(): any[] {
    const occurred = {
      label: WorkItemEventMap.occurred,
      workerId: null,
      workerName: null,
      date: formattedDateObject(this.itemDetails.occurrenceDate),
    };
    const created = {
      label: WorkItemEventMap.created,
      workerId: null,
      workerName: null,
      date: formattedDateObject(this.itemDetails.created),
    };
    const processingEvents = this.itemDetails.events.map((event) => ({
      label: WorkItemEventMap[event.event],
      workerId: event.workerId,
      workerName: event.workerName,
      date: formattedDateObject(event.date),
    }));
    this.hasMultipleCompleteEvents = this.itemDetails.events.filter((event) => event.event === "complete").length > 1;
    if (!this.incidentWasReopened) {
      return [occurred, created, ...processingEvents];
    } else {
      this.hasMultipleCompleteEvents = true; // set to true since this was just reopened
      return [
        occurred,
        created,
        ...processingEvents,
        {
          label: WorkItemEventMap.reopened,
          workerId: null,
          workerName: null,
          date: {
            message: "Just now",
          },
        },
      ];
    }
  }

  private createMessageDetails(messageMetadata: MessageMetadata | null): any {
    if (messageMetadata && this.itemDetails) {
      if (this.itemDetails.workItemEntityType === WorkItemType.MESSAGE) {
        return {
          recipients: messageMetadata.recipients.split(" "),
          sender: messageMetadata.sender,
        };
      }
    } else {
      return null;
    }
  }

  resizeToEmail(): void {
    try {
      /* eslint-disable @typescript-eslint/ban-ts-comment */

      document.getElementById(this.EMAIL_PREVIEW_ID).style.height = `${
        // @ts-ignore
        document.getElementById(this.EMAIL_PREVIEW_ID).contentWindow.document.documentElement.scrollHeight + 1
      }px`;
      document.getElementById(this.EMAIL_PREVIEW_ID).style.width = `${
        // @ts-ignore
        document.getElementById(this.EMAIL_PREVIEW_ID).contentWindow.document.documentElement.scrollWidth + 24
      }px`;
      /* eslint-enable @typescript-eslint/ban-ts-comment */
    } catch (error) {
      return;
    }
  }
}
