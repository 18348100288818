import { AuthOptions, createAuthLink } from "aws-appsync-auth-link";
import { AUTH_TYPE } from "aws-appsync-auth-link/lib/auth-link";
import { ApolloLink, Operation } from "@apollo/client/core";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { AuthService } from "src/app/shared/services/auth.service";
import { HttpLink } from "apollo-angular/http";
import { isSubscriptionOperation } from "src/app/features/appsync/utils";

/**
 * Build out a hybrid link that will handle both subscriptions and queries/mutations by creating
 * two separate links and splitting the operation based on the operation type.
 */
export const createHybridLink = (baseApiUrl: string, authService: AuthService, httpLink: HttpLink): ApolloLink => {
  // Build a link to be used for queries and mutations
  const uriWithOperationName = (operation: Operation) => baseApiUrl + "?operationName=" + operation.operationName;
  const httpLinkHandler = httpLink.create({ uri: uriWithOperationName });

  // Build the link to be used for subscriptions
  const authOptions: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: () => authService.getAuthToken(),
  };

  const wsAuthConfig = {
    url: baseApiUrl,
    region: "us-west-2",
    auth: authOptions,
  };

  const keepAliveTimeoutMs = 120000;

  // prettier-ignore
  const wsLinkHandler = ApolloLink.from([
    createAuthLink(wsAuthConfig),
    createSubscriptionHandshakeLink({ ...wsAuthConfig, keepAliveTimeoutMs }, httpLinkHandler)
  ]);

  return ApolloLink.split(isSubscriptionOperation, wsLinkHandler, httpLinkHandler);
};
