import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Incident } from "src/app/models/incident.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ThemeMode, ThemeService } from "src/app/shared/services/theme.service";
import { StateService } from "src/app/shared/services/state.service";
import { StudentInfo } from "src/app/models/work-item.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  CreateSalesforceCaseV2MutationVariables,
  GsmAppsyncApi,
  IncidentProcessingAcknowledgment,
  IncidentProcessingCommunication,
  IncidentProcessingData,
  IncidentProcessingDataType,
  IncidentProcessingEmergencyContact,
  SalesforceCaseStatus,
} from "src/generated/graphql";
import { Store } from "@ngxs/store";
import moment from "moment";
import { ConvertCasePipe } from "@shared/pipes/convert-case.pipe";

@UntilDestroy()
@Component({
  selector: "app-create-salesforce-case",
  templateUrl: "./create-salesforce-case.component.html",
  styleUrls: ["./create-salesforce-case.component.scss"],
})
export class CreateSalesforceCaseComponent implements OnInit, OnChanges {
  private stateService = inject(StateService);
  private themeService = inject(ThemeService);
  private formBuilder = inject(FormBuilder);
  private gsmAppsyncApi = inject(GsmAppsyncApi);
  private convertCasePipe = inject(ConvertCasePipe);
  private store = inject(Store);

  @Input() incident: Incident;
  @Input() expand: boolean;
  @Output() enableNextSection = new EventEmitter();

  private studentInfo: StudentInfo;
  private studentTimezone: string;

  public salesforceForm: FormGroup;
  public caseSubmitted = false;
  public useDarkMode: boolean;
  public buttonBackgroundColor: string;
  public buttonTextColor: string;
  public buttonLabel = "Submit Case Details";
  public caseUrl: string;
  public errorMessage: string;
  public incidentCommunications: string;
  public noContactsMade = false;

  ngOnInit(): void {
    // Build the reactive form object for use in the template
    this.buildForm();

    // Grab the student info for the subject / description
    this.stateService.currentItemDetails$.pipe(untilDestroyed(this)).subscribe((itemDetails) => {
      if (!itemDetails) {
        return;
      }
      this.studentInfo = itemDetails.studentInfo;
      this.studentTimezone = itemDetails.studentTimezone;
    });

    this.stateService.selectedCategories$.pipe(untilDestroyed(this)).subscribe((selectedCategories) => {
      if (!selectedCategories || selectedCategories.length === 0) {
        return;
      }
      const categories = selectedCategories.map((c) => c.title).join(" / ");
      const defaultSubject = `PSS - ${this.studentInfo.firstName} ${this.studentInfo.lastName} - ${categories}`;
      this.salesforceForm.get("subject").setValue(defaultSubject);
    });

    // Grab the theme and update relevant variables on change
    this.themeService.themeMode$.pipe(untilDestroyed(this)).subscribe((theme) => {
      this.useDarkMode = theme === ThemeMode.DARK;
      this.buttonBackgroundColor = this.themeService.getVariable("--checkbox");
      this.buttonTextColor = this.themeService.getVariable("--nav-link-color");
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.expand?.currentValue) {
      this.getIncidentCommunications();
    }
  }

  getIncidentCommunications(): void {
    this.incidentCommunications = "Fetching incident Communications...";
    this.gsmAppsyncApi
      .getIncidentProcessingData({ incidentId: this.incident.id }, { context: { bypassSpinner: true } })
      .subscribe(async (result) => {
        if (!result.data?.getIncidentProcessingData) {
          this.incidentCommunications = "Unable to fetch incident communications";
          return;
        }

        await this.processIncidentCommunications(result.data.getIncidentProcessingData as IncidentProcessingData[]);
      });
  }

  private async processIncidentCommunications(records: IncidentProcessingData[]): Promise<void> {
    const availabilityRecords = await this.extractEmergencyContacts(records);
    const communicationAndAckRecords = await this.communicationAndAckRecords(records);

    if (communicationAndAckRecords.length === 0 || availabilityRecords.length === 0) {
      this.incidentCommunications = "No Incident Communications Found";
    } else {
      this.incidentCommunications = communicationAndAckRecords
        .map((record) => {
          // Lookup the contact based on the availability records
          const contact = availabilityRecords.find((c) => c.ecId === record.ecId);

          // Format the time and communication type for display
          const timeString = moment(record.dateTime).format("hh:mm:ss a");

          // Failsafe for when we can't find the contact
          let ecName = "Unknown Contact";
          if (contact) {
            ecName = contact.ecName;
          }

          // Return the formatted string, adding the acknowledgment source if it's an acknowledgment record
          if (record.dataType === IncidentProcessingDataType.Acknowledgment) {
            const ackSource = this.convertCasePipe.transform(record.source, "SNAKE_CASE", "TITLE_CASE");
            return `${timeString} - Acknowledged (${ackSource}) - ${ecName}`;
          } else {
            const commType = this.convertCasePipe.transform(record.communicationType, "SNAKE_CASE", "TITLE_CASE");
            return `${timeString} - ${commType} - ${ecName}`;
          }
        })
        // And join them all together with newlines to make it look pretty
        // in the textarea field we're using to display it
        .join("\n");
    }
  }

  private async extractEmergencyContacts(records: IncidentProcessingData[]): Promise<IncidentProcessingEmergencyContact[]> {
    return records.filter((record) => record.dataType === IncidentProcessingDataType.Availability) as IncidentProcessingEmergencyContact[];
  }

  private async communicationAndAckRecords(
    records: IncidentProcessingData[],
  ): Promise<IncidentProcessingCommunication[] | IncidentProcessingAcknowledgment[]> {
    const filteredRecords = records.filter(
      (record) =>
        record.dataType === IncidentProcessingDataType.Communications || record.dataType === IncidentProcessingDataType.Acknowledgment,
    ) as IncidentProcessingCommunication[] | IncidentProcessingAcknowledgment[];

    return filteredRecords.sort((a, b) => (a.dateTime <= b.dateTime ? -1 : 0));
  }

  private buildForm(): void {
    this.salesforceForm = this.formBuilder.group({
      subject: ["", Validators.required],
      description: [""],
    });
  }

  submitCase(): void {
    this.caseSubmitted = true;
    this.buttonLabel = "Submitting Case";
    const formData = this.salesforceForm.value;

    if (this.noContactsMade) {
      formData.description = "NO CONTACTS WERE DIRECTLY NOTIFIED\n\n" + formData.description;
    }

    const salesforceCaseData: CreateSalesforceCaseV2MutationVariables = {
      ...formData,
      status: SalesforceCaseStatus.ClosedResolved,
      incidentId: this.store.selectSnapshot((state) => state.incident.incidentId),
      timezone: this.studentTimezone,
    };

    this.gsmAppsyncApi.createSalesforceCaseV2(salesforceCaseData).subscribe({
      next: (result) => {
        if (result.data?.createSalesforceCaseV2?.caseUrl) {
          this.buttonLabel = "Successfully Created Case";
          this.caseUrl = result.data.createSalesforceCaseV2.caseUrl;
        } else {
          this.errorMessage = "Please try again, or create the case manually.";
          this.buttonLabel = "ERROR Creating Case";
          this.caseSubmitted = false;
        }
        this.enableNextSection.emit();
      },
      error: () => {
        this.errorMessage = "Please try again, or create the case manually.";
        this.buttonLabel = "ERROR Creating Case";
        this.caseSubmitted = false;
        this.enableNextSection.emit();
      },
    });
  }
}
