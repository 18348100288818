import gql from "graphql-tag";

export default gql`
  mutation newEvent(
    $workItemId: ID!
    $event: String!
    $finalOverride: Boolean!
    $bundleDigest: String
    $unbundleType: String
    $payload: EventPayloadInput!
  ) {
    newEvent(
      request: {
        workItemId: $workItemId
        event: $event
        finalOverride: $finalOverride
        bundleDigest: $bundleDigest
        unbundleType: $unbundleType
        payload: $payload
      }
    ) {
      success
      gsmId
    }
  }
`;
