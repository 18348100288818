<gaggle-checkbox
    [label]="label"
    [name]="name"
    (toggleCheckbox)="onToggle($event)"
    [checked]="checked"
    [size]="size"
    [checkboxColor]="checkboxColor"
    [labelColor]="labelColor"
    [disabled]="disabled"
    [checkmarkStyle]="checkmarkStyle"></gaggle-checkbox>
