<div class="review-container">
    <app-queue-left-sidebar
        [ngClass]="{ open: showLeftDrawer }"
        [showRightDrawer]="showRightDrawer"
        [showLeftDrawer]="showLeftDrawer"
        (exitCurrentQueue)="onClickExit()"
        [hideQueues]="isReopenIncident || isOpenWorkItem"></app-queue-left-sidebar>

    <div class="item-viewer-container">
        <app-item-viewer-action-bar
            [showActionBar]="!showRightDrawer"
            (showRightDrawer)="onShowRightDrawer($event)"
            (showNcmecDrawer)="onShowNcmecDrawer($event)"
            [isReopenIncident]="isReopenIncident"
            [isOpenWorkItem]="isOpenWorkItem"></app-item-viewer-action-bar>

        <ng-container *ngIf="itemDetails; else selectAQueue">
            <app-item-viewer [isReopenIncident]="isReopenIncident" [isOpenWorkItem]="isOpenWorkItem"></app-item-viewer>
            <footer *ngIf="itemDetails && !showRightDrawer && !isReopenIncident && !isOpenWorkItem" class="footer">
                <div class="exit" (click)="onClickExit()" data-cy="exit-queue-button">
                    <gaggle-icon iconName="exit-queue" iconSize="L"></gaggle-icon>
                    <span class="text">Exit Queue</span>
                </div>
                <div class="skip" (click)="onClickSkip()" data-cy="skip-item-button">Skip</div>
            </footer>
        </ng-container>
        <ng-template #selectAQueue>
            <div class="empty-item-viewer-container" *ngIf="!isReopenIncident && !isOpenWorkItem">
                <div class="text">Select a populated work queue.</div>
            </div>
        </ng-template>
    </div>

    <app-incident-response-right-drawer
        *ngIf="showRightDrawer"
        [ngClass]="{ open: showRightDrawer }"
        [ncmecOnly]="ncmecOnlyDrawer"
        [ncmecEvent]="ncmecEvent"
        [isReopenIncident]="isReopenIncident"
        [isOpenWorkItem]="isOpenWorkItem"></app-incident-response-right-drawer>
</div>
