import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "forceBlankTarget" })
export class ForceBlankTargetPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(value, "text/html");
    const anchors: HTMLCollectionOf<HTMLAnchorElement> = parsedHtml.getElementsByTagName("a");

    Array.from(anchors).forEach((a) => {
      a.removeAttribute("target");
      a.setAttribute("target", "_blank"); // on links that already have this attribute this will do nothing
    });

    return parsedHtml.body.innerHTML;
  }
}
