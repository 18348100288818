<div class="submit-incident-container">
    <div
        *ngIf="expand"
        class="expandable-content">
        <div class="sub-header">Violation will be recorded, but emails will not be sent</div>

        <div class="email-container">
            <app-email-content
                [incident]="incident"
                submitLabel="Submit Incident"
                (enableNextSection)="enableNextIncidentSection()"
                [isReopenIncident]="isReopenIncident"
                [isOpenWorkItem]="isOpenWorkItem">
            </app-email-content>
        </div>
    </div>
</div>
