<div class="tooltip-container">
    <gaggle-button
        [label]="label"
        [icon]="icon"
        [iconSize]="iconSize"
        buttonStyle="{{ buttonType }} full-width large"
        [disabled]="disabled"
        [backgroundColor]="buttonColor"
        (buttonClick)="onClick($event)"
        [textColor]="buttonTextColor"></gaggle-button>
    <div
        *ngIf="showTooltip"
        class="tooltip">
        {{ tooltip }}
    </div>
    <gaggle-icon
        *ngIf="hasDropdown"
        data-testid="dropdown-chevron"
        iconName="chevron"
        iconState="down"
        class="dropdown-icon"
        iconSize="2em"
        [color]="chevronColor"></gaggle-icon>
</div>
