<div class="incident-response-container">
    <div class="header-container">
        <div class="header">
            <div class="header-text">
                Create Incident
                <ng-container *ngIf="incidentId$ | async as incidentId">
                    <div class="sub-text" (click)="onCopyIncidentId(incidentId)">
                        {{ incidentId }}
                    </div>
                </ng-container>
            </div>
            <button class="cancel-button" mat-button [disabled]="isCancelButtonDisabled" (click)="onClickCancel()">Cancel</button>
        </div>
    </div>

    <app-section-navigation
        [currentSection]="currentSection"
        [enabledSections]="enabledSections"
        [enableContentNext]="shouldEnableNextForContentCategorySection"
        [enableEmailNext]="shouldEnableNextForEmailEmergencyContactsSection"
        [enableTextNext]="shouldEnableNextForTextCategorySection"
        (setCurrentSection)="updateCurrentSection($event)"
        [incident]="newIncident"
        [ncmecOnly]="ncmecOnly"
        [ncmecEvent]="ncmecEvent"
        [isReopenIncident]="isReopenIncident"
        [isOpenWorkItem]="isOpenWorkItem">
        <div>
            <app-content-category
                [incident]="newIncident"
                [expand]="currentSection.name === sectionTitles.ContentCategory"
                [isReopenIncident]="isReopenIncident"></app-content-category>

            <div *ngIf="!userMustEscalatePSS">
                <app-submit-incident
                    [incident]="newIncident"
                    [expand]="currentSection.name === sectionTitles.SubmitIncident"
                    (enableNextSection)="enableNextInEmailSection()"
                    [isReopenIncident]="isReopenIncident"
                    [isOpenWorkItem]="isOpenWorkItem"></app-submit-incident>
                <app-email-emergency-contacts
                    [incident]="newIncident"
                    [contacts]="emailContacts"
                    [expand]="currentSection.name === sectionTitles.EmailEmergencyContacts"
                    [gsmEmailSetting]="gsmEmailSetting"
                    [groupAdministrator]="groupAdministrator"
                    (enableNextSection)="enableNextInEmailSection()"
                    [isReopenIncident]="isReopenIncident"
                    [isOpenWorkItem]="isOpenWorkItem"></app-email-emergency-contacts>
                <app-text-emergency-contacts
                    [itemDetails]="itemDetails"
                    [contacts]="textContacts"
                    (enableNextSection)="enableNextInTextSection()"
                    [expand]="currentSection.name === sectionTitles.TextEmergencyContacts"></app-text-emergency-contacts>
                <app-call-emergency-contacts
                    [contacts]="callContacts"
                    [expand]="currentSection.name === sectionTitles.CallEmergencyContacts"></app-call-emergency-contacts>
                <app-create-salesforce-case
                    [incident]="newIncident"
                    [expand]="currentSection.name === sectionTitles.CreateSalesforceCase"
                    (enableNextSection)="enableNextInSalesforceCaseSection()"></app-create-salesforce-case>
                <app-ncmec
                    [incident]="newIncident"
                    [expand]="currentSection.name === sectionTitles.NCMEC"
                    (submitNcmecReport)="this.ncmecReportSent = true"></app-ncmec>
            </div>
        </div>
    </app-section-navigation>
</div>
