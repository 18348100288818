<gaggle-dialog
    (closeModal)="closeThisModal()"
    [dialogTitle]="dialogTitle"
    [dialogBackgroundColor]="overrideBackgroundColor"
    [dialogCloseButtonColor]="overrideSecondaryButtonColor">
    <ng-content
        select="[top]"
        top></ng-content>
    <ng-content
        select="[content]"
        content></ng-content>
    <ng-content
        select="[buttons]"
        buttons></ng-content>
</gaggle-dialog>
