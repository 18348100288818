<gaggle-pill [color]="pillColor">
    <span class="pill-guts">
        <app-checkbox
            *ngIf="!invalidEmail"
            [label]="email"
            [name]="email"
            size="M"
            [checked]="checked"
            (toggleCheckbox)="recipientClick()"></app-checkbox>
        <gaggle-checkbox
            *ngIf="invalidEmail"
            [label]="email"
            [name]="email"
            size="M"
            [checked]="checked"
            checkmarkStyle="minus"
            [checkboxColor]="invalidEmailCheckboxColor"
            (toggleCheckbox)="recipientClick()"></gaggle-checkbox>
        <button
            *ngIf="canRemove"
            class="remove-button"
            (click)="removeContact()"
            data-testid="remove-contact">
            <gaggle-icon
                iconName="dismiss"
                iconSize="M"></gaggle-icon>
        </button>
    </span>
</gaggle-pill>
