<header class="iw-header" data-testid="header">
    <nav class="iw-nav">
        <div class="left">
            <div class="logo-desktop" (click)="navigateToHome()">
                <img src="./assets/gaggle-logo.png" alt="Gaggle Icon" />
            </div>

            <div class="vertical-separator"></div>

            <div class="tabs" *ngIf="validGaggleUser">
                <div class="tab-container" *ngFor="let tab of navigationTabs">
                    <div
                        class="tab"
                        *ngIf="tab.shouldDisplay()"
                        [ngClass]="{ selected: activeLink === tab.label, disabled: showRightDrawer }"
                        [matTooltip]="showRightDrawer ? 'Navigation disabled while incident is in progress.' : ''"
                        (click)="onClickTab(tab)">
                        {{ tab.label }}
                    </div>
                </div>
            </div>
        </div>

        <div class="right">
            <button
                mat-icon-button
                data-testid="new-application-button"
                class="new-application-button"
                *ngIf="newApplicationAvailable"
                (click)="getLatestApplication()"
                color="warn"
                matTooltip="Get the latest version of L2UI">
                <mat-icon>auto_mode</mat-icon>
            </button>
            <div (mouseenter)="onToggleUserMenu()" (mouseleave)="onToggleUserMenu()">
                <button [mat-menu-trigger-for]="userOptionsMenu" class="user-menu-button" data-cy="username">
                    <gaggle-icon iconName="user_logged_in" iconSize="L" [color]="iconColor"></gaggle-icon>
                    <span class="username">{{ username }}</span>
                    <gaggle-icon iconName="menu" iconSize="XL"></gaggle-icon>
                </button>
                <mat-menu #userOptionsMenu="matMenu" xPosition="before" class="user-menu-options">
                    <button mat-menu-item (click)="navigateToHome()">
                        <mat-icon>home</mat-icon>
                        Home
                    </button>
                    <button mat-menu-item (click)="toggleTheme()">
                        <mat-icon>invert_colors</mat-icon>
                        Dark Mode
                    </button>
                    <button mat-menu-item (click)="onLogOut()">
                        <mat-icon>logout</mat-icon>
                        Log Out
                    </button>
                </mat-menu>
            </div>
        </div>
    </nav>
</header>
