import { Injectable } from "@angular/core";
import {
  WorkItemEntityType,
  WarningEventType,
  EmergencyContactWithAvailability,
  EmergencyContactAvailability,
  AcknowledgmentSource,
  GetEmergencyContactsV2GQL,
  EmergencyContactsWithAvailabilityResponse,
} from "src/generated/graphql";
import { BehaviorSubject } from "rxjs";
import { ContentCategory } from "src/app/models/content-category.model";
import { ActionReason } from "src/app/models/work-item.model";
import { StateService } from "src/app/shared/services/state.service";

export enum EmergencyContactEmailResult {
  SENT = "SENT",
  NONE = "NONE",
}

export enum EmergencyContactTextResult {
  SENT = "SENT",
  SCHEDULED = "SCHEDULED",
  REPLIED = "REPLIED",
  NONE = "NONE",
}

export enum EmergencyContactCallResult {
  ATTEMPTED = "ATTEMPTED",
  ANSWERED = "ANSWERED",
  NONE = "NONE",
}

export interface EmergencyContactExtended extends EmergencyContactWithAvailability {
  communications?: {
    acknowledgment?: AcknowledgmentSource;
    email?: EmergencyContactEmailResult;
    text?: EmergencyContactTextResult;
    call?: EmergencyContactCallResult;
  };
  availability?: EmergencyContactAvailability;
  selected?: boolean;
  smsStatus?: {
    status: string;
    statusDate: Date;
  };
}

export interface FilteredEmergencyContacts {
  all: EmergencyContactExtended[];
  email?: EmergencyContactExtended[];
  sms?: EmergencyContactExtended[];
  call?: EmergencyContactExtended[];
}

@Injectable({
  providedIn: "root",
})
export class EmergencyContactService {
  private emergencyContacts: EmergencyContactExtended[];
  private warningType: WarningEventType;
  private isAfterHours: boolean;

  private _selectedCategories: ContentCategory[] = [];
  public get selectedCategories(): ContentCategory[] {
    return this._selectedCategories;
  }
  public set selectedCategories(value: ContentCategory[]) {
    this._selectedCategories = value;
    this.updateFilteredContacts();
  }

  private _filteredContacts$ = new BehaviorSubject<FilteredEmergencyContacts>(null);
  public filteredContacts$ = this._filteredContacts$.asObservable();
  public get filteredContacts(): FilteredEmergencyContacts {
    return this._filteredContacts$.value;
  }

  constructor(private getEmergencyContactsV2GQL: GetEmergencyContactsV2GQL, private stateService: StateService) {}

  private getUniqueContacts(contacts: EmergencyContactExtended[], key: string): EmergencyContactExtended[] {
    return [...new Map(contacts.map((c) => [c[key], c])).values()];
  }

  private async setHighestWarningType(): Promise<void> {
    let warningType = WarningEventType.Violation;
    this.selectedCategories.forEach((c) => {
      if (c.warningType === WarningEventType.QuestionableContent && warningType === WarningEventType.Violation) {
        warningType = c.warningType;
      } else if (c.warningType === WarningEventType.PossibleStudentSituation) {
        warningType = c.warningType;
      }
    });

    this.warningType = warningType;
  }

  private async setContactAvailability(): Promise<void> {
    if (this.emergencyContacts?.length > 0) {
      this.emergencyContacts.map((contact) => {
        switch (this.warningType) {
          case WarningEventType.PossibleStudentSituation:
            contact.availability = contact.pss;
            break;
          case WarningEventType.QuestionableContent:
            contact.availability = contact.qcon;
            break;
          default:
            contact.availability = null;
        }
      });
    }
  }

  contactIsSubscribed(contact: EmergencyContactExtended, actionReasons: ActionReason[]): boolean {
    /*
    If the contact doesn't have availability for the current warning type, filter it out,
    Otherwise, continue to evaluate the categories for the current warning type and filter
    out contacts that have no subscriptions for the selected categories
    */
    if (!contact.availability) {
      return false;
    }

    return actionReasons.some((category) =>
      // TODO: Remove Hacky hack to get around mismatched types until we can get away from local models
      contact.availability.categories.find((subscribed) => (subscribed as unknown as ActionReason) === category),
    );
  }

  private filterByWarningTypeAndCategory(): EmergencyContactExtended[] {
    if (this.selectedCategories.length === 0) {
      return [];
    }

    /*
     * Get an array of action reasons for the selected categories that match the warning type
     * This ensures that if someone subscribes for a category in PSS, but not for QCON (or vice versa),
     * we'll still get the correct contacts for the warning type
     */
    const actionReasonsForWarningType = this.selectedCategories
      .filter((c) => c.warningType === this.warningType)
      .map((c) => c.actionReason)
      .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), []);

    let filteredContacts = this.emergencyContacts.filter((contact) => {
      return this.contactIsSubscribed(contact, actionReasonsForWarningType);
    });

    // If this is a PSS and we don't have any matching contacts, add all district contacts by default
    if (filteredContacts.length === 0 && this.warningType === WarningEventType.PossibleStudentSituation) {
      filteredContacts = this.emergencyContacts.filter((c) => c.districtContact);
    }

    return filteredContacts;
  }

  private filterEmailContacts(contacts: EmergencyContactExtended[]): EmergencyContactExtended[] {
    /*
    Due to email delay windows, we need to handle both email and digest contacts here so that they can
    all be handed over to the email service as part of the newEvent call.
     */
    const emailContacts = contacts.filter((c) => c.availability.email && c.email);
    const digestContacts = contacts.filter((c) => c.availability.digest && c.email);
    const uniqueEmailContacts = this.getUniqueContacts(emailContacts, "email");
    const uniqueDigestContacts = this.getUniqueContacts(digestContacts, "email");

    return [...uniqueEmailContacts, ...uniqueDigestContacts];
  }

  private filterSmsContacts(contacts: EmergencyContactExtended[]): EmergencyContactExtended[] {
    if (this.warningType !== WarningEventType.PossibleStudentSituation) {
      return [];
    }

    return contacts.filter((c) => c.availability.text && c.unformattedMobilePhone);
  }

  private filterCallContacts(contacts: EmergencyContactExtended[]): EmergencyContactExtended[] {
    if (this.warningType !== WarningEventType.PossibleStudentSituation) {
      return [];
    }

    /*
    Because we want to be able to allow the worker to display unavailable contacts, we don't filter out
    contacts that are not available for phone for the current warning type, but rather filter out those
    contacts that are listed as emailOnly contacts. This allows us to display all contacts, if the worker
    is unable to reach the available contacts.
     */
    const contactsWithPhone = contacts.filter((c) => c.unformattedOfficePhone || c.unformattedMobilePhone || c.unformattedHomePhone);
    const available = contactsWithPhone.filter((c) => c.availability.phone);
    const unavailable = contactsWithPhone.filter((c) => !c.availability.phone && !c.emailOnly);
    const sortedAvailable = this.sortContactsByPriority(available);
    const sortedUnavailable = this.sortContactsByPriority(unavailable);

    return [...sortedAvailable, ...sortedUnavailable];
  }

  private sortContactsByPriority(contacts: EmergencyContactExtended[]): EmergencyContactExtended[] {
    /*
    In order to sort the contacts, we need to sort them into groups:
      If GAFT, dispatch contacts are handled differently than other contacts
        1. Dispatch contacts always go first (group then school) if they are in the list at this point
        2. Group contacts go next, sorted by priority
        3. District contacts go last, sorted by priority
      If not GAFT, dispatch contacts are handled like any other contact, so we'll combine the dispatch
      contacts with the non-dispatch contacts and sort by priority. This will put dispatch contacts
      first if they have the same priority as another contact.
        1. Group contacts go first, sorted by priority
        2. District contacts go last, sorted by priority
     */

    const dispatchSchoolContacts = contacts.filter((c) => c.dispatch && !c.districtContact).sort((a, b) => a.priority - b.priority);
    const dispatchDistrictContacts = contacts.filter((c) => c.dispatch && c.districtContact).sort((a, b) => a.priority - b.priority);
    let districtContacts = contacts.filter((c) => !c.dispatch && c.districtContact).sort((a, b) => a.priority - b.priority);
    let schoolContacts = contacts.filter((c) => !c.dispatch && !c.districtContact).sort((a, b) => a.priority - b.priority);

    if (this.isAfterHours) {
      return [...dispatchSchoolContacts, ...dispatchDistrictContacts, ...schoolContacts, ...districtContacts];
    } else {
      districtContacts = [...dispatchDistrictContacts, ...districtContacts].sort((a, b) => a.priority - b.priority);
      schoolContacts = [...dispatchSchoolContacts, ...schoolContacts].sort((a, b) => a.priority - b.priority);
      return [...schoolContacts, ...districtContacts];
    }
  }

  setContactsFromWorkItem = (ecAvailability: EmergencyContactsWithAvailabilityResponse): void =>
    this.processEmergencyContactInformation(ecAvailability);

  fetchContacts(
    districtId: string,
    groupId: string,
    workItemType: WorkItemEntityType,
    targetDateTime: string = new Date().toISOString(),
  ): void {
    this.getEmergencyContactsV2GQL
      .fetch({
        districtId: districtId,
        groupId: groupId,
        workItemType: workItemType,
        targetDateTime: targetDateTime,
      })
      .subscribe((result) => {
        this.processEmergencyContactInformation(result.data.getEmergencyContactsV2);
      });
  }

  private processEmergencyContactInformation(ecAvailability: EmergencyContactsWithAvailabilityResponse): void {
    this.emergencyContacts = ecAvailability.contacts;
    if (!this.emergencyContacts) {
      return;
    }

    this.emergencyContacts.map(
      (contact) =>
        (contact.communications = {
          email: EmergencyContactEmailResult.NONE,
          text: EmergencyContactTextResult.NONE,
          call: EmergencyContactCallResult.NONE,
        }),
    );

    this.isAfterHours = ecAvailability.isAfterHoursPSS && ecAvailability.hasGaftSubscription;
    this.stateService.itemIsInAfterHoursWindow = this.isAfterHours;

    // And update the filtered contacts based on what we have right now
    this.updateFilteredContacts();
  }

  updateFilteredContacts(): void {
    /*
    This method is called whenever the warning type or selected categories change. It filters the
    contacts based on the current warning type and selected categories and then updates the
    filteredContacts$ observable by filtering the contacts by communication type.
     */

    // prettier-ignore
    Promise.all([
      this.setHighestWarningType(),
      this.setContactAvailability()]).then(() => {

        if (this.emergencyContacts?.length > 0) {
          const filteredContacts = this.filterByWarningTypeAndCategory();

          this._filteredContacts$.next({
            all: this.emergencyContacts,
            email: this.filterEmailContacts(filteredContacts),
            sms: this.filterSmsContacts(filteredContacts),
            call: this.filterCallContacts(filteredContacts),
          });
        } else {
          this._filteredContacts$.next({
            all: [],
            email: [],
            sms: [],
            call: [],
          });
        }
    });
  }

  reset(): void {
    this.warningType = null;
    this.emergencyContacts = [];
    this.isAfterHours = false;
    this._selectedCategories = [];
    this._filteredContacts$.next(null);
  }
}
