import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ThemeService } from "src/app/shared/services/theme.service";

@Component({
  selector: "app-themed-dialog",
  templateUrl: "./themed-dialog.component.html",
  styleUrls: ["./themed-dialog.component.scss"],
})
export class ThemedDialogComponent {
  @Input() dialogTitle: string;
  @Output() closeModal = new EventEmitter();

  constructor(private theme: ThemeService) {}

  get overrideBackgroundColor(): string {
    return this.theme.getVariable("--dialog-background-color");
  }

  get overrideSecondaryButtonColor(): string {
    return this.theme.getVariable("--button-secondary-color");
  }

  closeThisModal(): void {
    this.closeModal.emit();
  }
}
