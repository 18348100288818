import { Component } from "@angular/core";
import { PSAPAddress } from "src/app/models/sis.model";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { StateService } from "src/app/shared/services/state.service";
import { FeatureFlagName, FeatureFlagValue } from "src/app/models/feature-flags.model";
import { ThemeService } from "src/app/shared/services/theme.service";

@Component({
  selector: "app-psap",
  templateUrl: "./psap.component.html",
  styleUrls: ["./psap.component.scss"],
})
export class PsapComponent {
  loadingPSAP = false;
  studentContact = null;
  psapContacts = [];
  psapWasFetched = false;

  constructor(private appsync: AppSyncService, private state: StateService, private theme: ThemeService) {}

  get isPSAPEnabled(): boolean {
    return this.state.getFeatureFlag(FeatureFlagName.PSAP) === FeatureFlagValue.ON;
  }

  get isPSAPLinkEnabled(): boolean {
    return this.state.getFeatureFlag(FeatureFlagName.PSAP_LINK) === FeatureFlagValue.ON;
  }

  get PSAPButtonIsDisabled(): boolean {
    return this.loadingPSAP || this.psapWasFetched;
  }

  get buttonBackgroundColor(): string {
    return this.theme.getVariable("--button-secondary-color");
  }

  get buttonTextColor(): string {
    return this.theme.getVariable("--text-primary-color");
  }

  generateAddressString(address: PSAPAddress): string {
    return `${address.address} ${address.city}, ${address.state} ${address.postalCode}`;
  }

  async fetchPSAPInfo(): Promise<void> {
    this.loadingPSAP = true;
    const contacts = await this.appsync.getPSAPForStudent(this.state.currentItemDetails.userId);

    if (contacts.length > 0) {
      const studentContact = contacts.find((contact) => !contact.relationship);
      const psapContacts = contacts.filter((contact) => contact.relationship);

      if (studentContact) {
        this.studentContact = {
          name: studentContact.address.name,
          phone: studentContact.address.phone,
          addresses: studentContact.psaps.map((psap) => ({
            address: this.generateAddressString(studentContact.address),
            url: psap.mapUrl,
          })),
        };
      } else {
        this.studentContact = null;
      }

      this.psapContacts = psapContacts.map((contact) => ({
        relationship: contact.relationship,
        name: contact.address.name,
        phone: contact.address.phone,
        addresses: contact.psaps.map((psap) => ({
          address: this.generateAddressString(contact.address),
          url: psap.mapUrl,
        })),
      }));
    } else {
      this.studentContact = null;
      this.psapContacts = [];
    }

    this.psapWasFetched = true;
    this.loadingPSAP = false;
  }
}
