<div
    class="ec-sms-grid"
    [ngClass]="{ disabled: isDisabled }">
    <div class="checkbox left-col">
        <gaggle-checkbox
            [checked]="checked"
            [disabled]="disabled"
            [darkMode]="useDarkMode"></gaggle-checkbox>
    </div>
    <div class="name right-col">
        {{ contact.name || "Unknown" }}
        <gaggle-pill
            *ngIf="contact.afterHours"
            icon="after-hours"></gaggle-pill>
    </div>

    <div
        class="ack-col"
        *ngIf="showAck() || smsStatus">
        <gaggle-tooltip *ngIf="smsStatus">
            <span class="sms-status">{{ smsStatus.status | titlecase }}</span>
            <ng-container tooltip>{{ smsStatus.statusDate | date: "short" }}</ng-container>
        </gaggle-tooltip>
        <gaggle-pill
            *ngIf="showAck()"
            class="resolved"
            icon="resolve"
            iconColor="green"
            color="#00F20E16">
            <p [style]="{ 'color': 'black', 'margin-left': '5px' }">Acknowledged</p>
        </gaggle-pill>
    </div>

    <div class="title right-col">
        <gaggle-icon
            iconSize="s"
            iconName="ec-title"></gaggle-icon
        >{{ contact.title }}
    </div>
    <div class="phone-number right-col">
        <gaggle-icon
            iconSize="s"
            iconName="texting-number"></gaggle-icon
        >{{ formattedPhoneNumber }}
    </div>
</div>
