<app-expandable-panel
    data-testid="cal-preview-panel"
    *ngIf="workItem.content"
    [isExpanded] = true>
    <ng-template appPanelHeader>
        <div class="new-row concat">
            <div
                data-testid="cal-event-title"
                class="text cal-event-title "
                id = "cal-event-title"
                [innerHTML] = eventTitle>
            </div>
        </div>
    </ng-template>
    <ng-template appPanelContent>
        <div
            data-testid="cal-content"
            
            class="new-row content-frame cal-content">
            <div 
                class="text"
                id = "cal-event-content"
                [innerHTML] = contentBody>
            </div>
        </div>
    </ng-template>
    <ng-template appPanelButtons>
        <gaggle-icon
        iconName="translate-text"
        data-testid="translate-message"
        iconSize="3em"
        class="button-icon"
        (click)="onClickTranslate()"></gaggle-icon>
    </ng-template>
</app-expandable-panel>
