<div
    class="contact"
    [ngClass]="{ disabled: isDisabled }"
    *ngIf="contact">
    <div class="name">{{ contact.name }}</div>
    <div>
        <div class="title-container">
            <div class="title">
                {{ contact.title }}
                <button
                    (click)="toggleContactDetails()"
                    [id]="buttonId"
                    data-testid="toggle-contact-details"
                    [disabled]="isDisabled">
                    <gaggle-icon
                        iconName="info"
                        iconSize="2em"
                        [color]="infoIconColor"></gaggle-icon>
                </button>
            </div>
            <gaggle-toggle
                *ngIf="!contact.availability.phone"
                label="Ignore Unavailability"
                name="{{ contact.name }}-unavailable"
                [isToggleOn]="!isDisabled"
                (status)="onToggleDisabled()"></gaggle-toggle>
        </div>
        <div
            class="contact-details"
            *ngIf="showContactDetails"
            (clickOutside)="closeContactDetails()">
            <div class="details-header">Contact Details</div>
            <div class="details-content">
                <div class="label">Name:</div>
                <div>{{ contact.name }}</div>
                <div class="label">Title:</div>
                <div>{{ jobTitle }}</div>
                <div class="label">District:</div>
                <div>{{ district }}</div>
                <div class="label">School/Group:</div>
                <div>{{ schoolOrGroup }}</div>
                <div class="label">PSS Priority:</div>
                <div>{{ priority }}</div>
            </div>
        </div>
        <app-phone-row
            type="Office"
            [phoneNumber]="contact.unformattedOfficePhone"
            [contact]="contact"
            [disabled]="isDisabled"
            (startSectionTimer)="startCallSectionTimer()"></app-phone-row>
        <app-phone-row
            type="Mobile"
            [phoneNumber]="contact.unformattedMobilePhone"
            [contact]="contact"
            [disabled]="isDisabled"
            (startSectionTimer)="startCallSectionTimer()"></app-phone-row>
        <app-phone-row
            type="Home"
            [phoneNumber]="contact.unformattedHomePhone"
            [contact]="contact"
            [disabled]="isDisabled"
            (startSectionTimer)="startCallSectionTimer()"></app-phone-row>
    </div>
</div>
