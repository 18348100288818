import gql from "graphql-tag";
import { Fragments } from "src/app/graphql/fragments/fragments";

export default gql`
  query getWorkItem($workItemId: String!) {
    getWorkItem(workItemId: $workItemId) {
      ...BaseWorkItem
    }
  }
  ${Fragments.workItem}
`;
