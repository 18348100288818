<div
    *ngIf="hasContent"
    class="item-detail-row-container"
    [ngClass]="{ 'highlight-on-hover': hasActions }"
    data-testid="item-detail-row"
    (click)="onRowClick($event)">
    <div class="label">{{ label }}</div>
    <div class="content">
        {{ content }}
        <ng-container [ngTemplateOutlet]="nestedContent?.templateRef"></ng-container>
    </div>
    <div class="actions">
        <button
            *ngIf="actions.downloadFile"
            (click)="actions.downloadFile($event)"
            data-testid="download-file">
            <gaggle-spinner
                *ngIf="isDownloadingFile"
                size="L"></gaggle-spinner>
            <gaggle-icon
                iconName="export"
                iconSize="L"
                *ngIf="!isDownloadingFile"></gaggle-icon>
        </button>
        <button
            *ngIf="actions.downloadAttachment"
            (click)="actions.downloadAttachment($event)"
            data-testid="download-attachment">
            <gaggle-icon
                iconName="export"
                iconSize="L"></gaggle-icon>
        </button>
        <button
            *ngIf="actions.externalLink"
            (click)="actions.externalLink($event)"
            data-testid="external-link-item">
            <gaggle-icon
                iconName="external-link"
                iconSize="L"></gaggle-icon>
        </button>
        <button
            *ngIf="actions.copyValue"
            (click)="actions.copyValue($event)"
            data-testid="copy-item">
            <gaggle-icon
                iconName="copy"
                iconSize="L"></gaggle-icon>
        </button>
    </div>
</div>
