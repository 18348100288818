export enum SectionTitles {
  ContentCategory = "Content Category",
  EmailEmergencyContacts = "Email Emergency Contacts",
  TextEmergencyContacts = "Text Emergency Contacts",
  CallEmergencyContacts = "Call Emergency Contacts",
  SubmitIncident = "Submit Incident",
  NCMEC = "CyberTipline (NCMEC) Report",
  AfterHoursTextEmergencyContacts = "Text After Hours Emergency Contacts",
  AfterHoursCallEmergencyContacts = "Call After Hours Emergency Contacts",
  CreateSalesforceCase = "Create Salesforce Case",
}

export interface IncidentSection {
  name: string;
  timerName: string;
  afterHoursName: string;
  enableSection: () => boolean;
  showNext: () => boolean;
  disableNext: () => boolean;
  showPrevious: () => boolean;
  disablePrevious: () => boolean;
  shouldEscalatePSSOnNext: () => boolean;
  indicatorIcon?: () => string;
  indicatorTooltip?: () => string;
}
