<div
    [ngClass]="{ highlight: isMessageFromSelectedParticipant }"
    class="chat-message"
    data-testid="chat-message-preview"
    (mouseenter)="showMessageOptions()"
    (mouseleave)="hideMessageOptions()">
    <div class="message-header">
        <div class="sender-container">
            <span
                [ngStyle]="{ backgroundColor: message.colorCode }"
                class="user-dot"></span>
            <p class="sender">
                {{ message.sender }}
                <span class="email"><{{ message.senderEmail }}></span>
            </p>
        </div>
        <p class="timestamp">{{ message.timestamp }}</p>
    </div>
    <div
        class="message-content-container"
        (click)="highlightMessagesFromSender($event)">
        <div
            [ngClass]="{ flagged: message.flagged }"
            class="message-content">
            <p [innerHTML]="message.content | removeZalgo"></p>
            <div
                *ngIf="showOptions"
                class="message-options">
                <gaggle-button
                    (buttonClick)="translateContent(message.content)"
                    label="Translate"
                    [hideText]="true"
                    icon="translate-text"
                    iconSize="L"
                    buttonStyle="primary narrow"
                    [disabled]="currentlyTranslating"
                    [showSpinner]="currentlyTranslating"
                    [backgroundColor]="overridePrimaryButtonColor"
                    [textColor]="overrideTextColor"
                    [iconColor]="overrideTextColor"></gaggle-button>
            </div>
        </div>
        <div
            *ngIf="translatedText.length"
            class="message-content-translated">
            <div class="translate-header">Translation</div>
            <ng-container *ngFor="let translation of translatedText">{{ translation }}</ng-container>
        </div>
    </div>
</div>
