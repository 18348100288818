import { fromPromise, ApolloLink } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { LoggingLevel } from "src/generated/graphql";
import { ClientErrorService } from "src/app/shared/services/client-error.service";
import { AuthService } from "src/app/shared/services/auth.service";

/**
 * This link will handle errors encountered to ensure consistent handling of errors and output
 * to the client error service. Additionally, this link will handle 401 errors by refreshing the
 * session and forwarding the operation to retry.
 */
export const createErrorLink = (authService: AuthService, clientErrorService: ClientErrorService): ApolloLink => {
  return onError(({ graphQLErrors, networkError, operation, forward }) => {
    // Handle 401 error specifically with a token refresh
    if (networkError && networkError["status"] === 401) {
      return fromPromise(authService.refreshSession()).flatMap(() => forward(operation));
    } else {
      // Handle other network errors
      if (networkError) {
        clientErrorService.logClientEvent(LoggingLevel.Error, networkError.message, networkError);
      }

      // Handle the ML errors individually
      if (operation.getContext().isKmmEvent) {
        graphQLErrors.map((error) => {
          clientErrorService.logClientEvent(LoggingLevel.Error, error.message, {
            error,
            dataSent: operation.variables,
            attention: "ML",
          });
        });
      } else {
        if (graphQLErrors) {
          graphQLErrors.map((error) => {
            clientErrorService.logClientEvent(LoggingLevel.Error, error.message, error);
          });
        }
      }

      return forward(operation);
    }
  });
};
