import { Injectable } from "@angular/core";
import { ChangeItemVisibilityTimeoutGQL, LoggingLevel } from "src/generated/graphql";
import { ClientErrorService } from "./client-error.service";

const VISIBILITY_HEARTBEAT = 120 * 1000; // Milliseconds between item visibility refreshes
const VISIBILITY_SECONDS = 6 * 60; // Seconds to add to item visibility timeout

@Injectable({
  providedIn: "root",
})
export class WorkItemVisibilityService {
  private itemVisibilityInterval: any;
  private workItemId: string;

  constructor(private changeItemVisibilityTimeoutGQL: ChangeItemVisibilityTimeoutGQL, private clientErrorService: ClientErrorService) {}

  /**
   * Wrapper method for the changeItemVisibilityTimeoutGQL used
   * to change the visibility timeout for the current work item
   *
   * @private
   * @param seconds
   * @param clearWorkItem
   * @return void
   */
  public changeTimeout(seconds: number, clearWorkItem = false): void {
    if (!this.workItemId) {
      return;
    }

    this.changeItemVisibilityTimeoutGQL.client = "noStatus";
    this.changeItemVisibilityTimeoutGQL
      .mutate({
        itemId: this.workItemId,
        timeoutSecs: seconds,
      })
      .subscribe();

    if (clearWorkItem) {
      this.workItemId = null;
    }
  }

  /**
   * Set up an interval to refresh item visibility every VISIBILITY_HEARTBEAT seconds
   *
   * @param workItemId
   * @returns void
   */
  public startHeartbeat(workItemId: string): void {
    this.workItemId = workItemId;

    try {
      if (this.itemVisibilityInterval) {
        return;
      }

      this.itemVisibilityInterval = setInterval(() => {
        this.changeTimeout(VISIBILITY_SECONDS);
      }, VISIBILITY_HEARTBEAT);
    } catch (e) {
      this.clientErrorService.logClientEvent(LoggingLevel.Error, "WorkItemVisibilityService::startHeartbeat", e);
    }
  }

  /**
   * Tear down the interval to refresh item visibility
   *
   * @returns void
   */
  public stopHeartbeat(changeTimeoutSeconds?: number): void {
    // If we're stopping the heartbeat, we have the option of sending a final timeout
    if (changeTimeoutSeconds) {
      this.changeTimeout(changeTimeoutSeconds);
    }

    if (this.itemVisibilityInterval) {
      clearInterval(this.itemVisibilityInterval);
      this.itemVisibilityInterval = null;
      this.workItemId = null;
    }
  }
}
