import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "highlightKeyword",
})
export class HighlightKeywordPipe implements PipeTransform {
  transform(value: string, keyword: string): string {
    if (!value || !keyword) {
      return value;
    }

    let result = value.trim();

    const urlRegex = new RegExp("(https?|ftp|www.)://[^\\s]*", "ig");
    const urlFound = result.match(urlRegex);

    // If a URL is found in the given string, split into substrings on spaces in the string.
    // Loop through all substrings, building an intermediate string containing non-URL words.
    // If the word is a URL, then we highlight the keywords in the current intermediate string
    // and append it to the final result, then append the URL to the final result, then finally
    // clear the intermediate string before moving on to the next substring. If the word is not
    // a URL, it is simply appended to the current intermediate string.
    //
    // Once all words have been traversed, if there is an intermediate string remaining (i.e.,
    // there is text that follows the last occurrence of a URL in the original text, highlight the
    // keywords in the remaining intermediate string and append it to the final result.
    //
    // This handles the underlying issue reported in THNDR-2416, where the keyword is not just a
    // single word (i.e., "kill", "gun"), but rather a phrase ("hurt me", "kill myself").
    if (urlFound) {
      const splitString = result.split(" ");
      let newResult = "";
      let currentSubstring = "";

      splitString.forEach((s) => {
        if (s.match(urlRegex)) {
          if (currentSubstring.length > 0) {
            newResult += this.highlightKeyword(currentSubstring, keyword) + " ";
          }
          newResult += s + " ";
          currentSubstring = "";
        } else if (s.length > 0) {
          if (currentSubstring.length > 0) {
            currentSubstring += " ";
          }
          currentSubstring += s;
        }
      });

      if (currentSubstring.length > 0) {
        newResult += this.highlightKeyword(currentSubstring, keyword);
      }

      result = newResult;
    } else {
      result = this.highlightKeyword(result, keyword);
    }

    return result;
  }

  highlightKeyword(text: string, keyword: string): string {
    const keywordRegex = new RegExp(this.escapeRegex(keyword), "ig");
    return text.replace(keywordRegex, (match) => `<b class="highlightText">${match}</b>`);
  }

  // Sometimes keywords have special chars in them, for example 's***'.
  // These characters need to be escaped in order to highlight them.
  escapeRegex(keyword: string): string {
    return keyword.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  }
}
