import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { StateService } from "src/app/shared/services/state.service";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { formattedDate } from "src/app/shared/utils/format";
import { InternalWorkItemStatus } from "src/app/models/work-item-incident.model";
import { PriorIncident, PriorIncidents, WARNING_DISPLAY_MAP } from "src/app/models/incident.model";
import { MessageMetadata, WorkItemType } from "src/app/models/work-item.model";
import moment from "moment-timezone";
import { MatSnackBar } from "@angular/material/snack-bar";
import { formatActionReasonsFromPriorIncidents, getWarningTypeIcon, objectToUrlEncodedString } from "src/app/shared/utils/helpers";
import { Select } from "@ngxs/store";
import { SearchRecordNavigationInfo, SearchSelectors } from "@shared/state/search/search.selectors";
import { Observable } from "rxjs";
import { SearchType } from "@shared/state/search/search.state";
import { SearchFields } from "@features/search/models/search-fields.enum";

@Component({
  selector: "app-work-item-summary",
  templateUrl: "./work-item-summary.component.html",
  styleUrls: ["./work-item-summary.component.scss"],
})
export class WorkItemSummaryComponent implements OnInit {
  workItemId: string;
  internalStatus: InternalWorkItemStatus;
  loadingItem = false;
  incidentWasCreated = false;
  incidentIcon: string;
  itemDetails;
  events = [];
  priorIncidents: PriorIncidents;
  priorIncidentsToDisplay = [];
  lastModifiedDate = null;
  originalMessageDetails;
  messageMetaData;
  showMoreFileInfo = false;
  showMoreAttachments = false;
  showMoreIncidentInfo = false;
  showMoreSchoolInfo = false;

  @Select(SearchSelectors.searchRecordNavigationInfo) searchRecordNavigationInfo$: Observable<SearchRecordNavigationInfo>;

  constructor(
    private activeRoute: ActivatedRoute,
    private state: StateService,
    private appSync: AppSyncService,
    private router: Router,
    private snackBar: MatSnackBar,
    private location: Location,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.activeRoute.paramMap.subscribe(({ params }) => {
      this.workItemId = params["workItemId"];
      if (this.workItemId) {
        this.appSync.getWorkItemAndIncidentByGsmId(this.workItemId);
      }
    });

    this.activeRoute.data.subscribe((data) => {
      if (data) {
        this.incidentWasCreated = !!data.createdIncident;
      } else {
        this.incidentWasCreated = false;
      }
    });

    this.state.showFullPageLoadingSpinner$.subscribe((showingSpinner) => {
      this.loadingItem = showingSpinner;
      if (this.incidentWasCreated && !this.loadingItem) {
        this.snackBar.open(
          "Changes to the work item have been saved and may take a few minutes to populate throughout the system.",
          "Okay",
          {
            horizontalPosition: "center",
            verticalPosition: "bottom",
          },
        );
        this.incidentWasCreated = false;
      }

      setTimeout(() => this.changeDetectorRef.detectChanges());
    });

    this.state.currentItemDetails$.subscribe((data) => {
      if (data) {
        this.itemDetails = data;
        if (this.itemDetails?.lastModified) {
          this.lastModifiedDate = formattedDate(this.itemDetails.lastModified);
        } else {
          this.lastModifiedDate = null;
        }
        this.location.replaceState(`/work-items/${this.itemDetails.id}`);
      }
    });

    this.state.currentIncidentDetails$.subscribe((data) => {
      if (data) {
        this.incidentIcon = getWarningTypeIcon(data.warningType);
      }
    });

    this.state.messageMetadata$.subscribe((data) => {
      this.originalMessageDetails = this.createMessageDetails(data);
      this.messageMetaData = data;
    });

    this.state.currentInternalWorkItemStatus$.subscribe((data) => {
      this.internalStatus = data;
    });

    this.state.priorIncidents$.subscribe((data) => {
      this.priorIncidents = data;
      this.getDisplayablePriorIncidents();
    });
  }

  get hasIncidents(): boolean {
    return !!this.itemDetails.incidentIds?.length;
  }

  get isCreateIncidentButtonDisabled(): boolean {
    return this.incidentWasCreated || !this.finalEventIsCompleted || !this.workItemIsFullyCompleted;
  }

  get workItemIsFullyCompleted(): boolean {
    if (this.internalStatus) {
      return this.internalStatus.state === "final" && this.internalStatus.locked === false;
    }

    return false;
  }

  get finalEventIsCompleted(): boolean {
    // TODO: This shouldn't happen, maybe a canvas bug on the backend?
    if (this.itemDetails.events) {
      return this.itemDetails.events[this.itemDetails.events.length - 1].event === "complete";
    } else {
      return true;
    }
  }

  createIncident(): void {
    if (this.isCreateIncidentButtonDisabled) {
      return;
    }
    this.router.navigateByUrl(`/work-items/${this.itemDetails.id}/create-incident`);
  }

  viewIncident(): void {
    if (this.itemDetails.incidentIds.length === 1) {
      this.router.navigateByUrl(`/incidents/${this.itemDetails.incidentIds.pop()}`);
    } else {
      const criteria = objectToUrlEncodedString(
        {
          [SearchFields.Ids]: this.itemDetails.incidentIds,
        },
        true,
      );
      this.router.navigateByUrl(`/search?searchType=${SearchType.Incident}&criteria=${criteria}`);
    }
  }

  toggleSection(sectionToToggle: string): void {
    this[sectionToToggle] = !this[sectionToToggle];
  }

  // future TODOs:
  //  move incidentDate and getDisplayablePriorIncidents into helper for here and item-details
  //  make util for building events for here and incident summary
  private incidentDate(incident: PriorIncident): moment.Moment {
    return moment.unix(incident.processedDate / 1000).tz(moment.tz.guess());
  }

  private getDisplayablePriorIncidents(): void {
    if (!this.priorIncidents?.incidents?.length) {
      return;
    }
    const returnedIncidents = this.priorIncidents.incidents.slice(0, 10);
    this.priorIncidentsToDisplay = returnedIncidents.map((incident) => {
      const incidentDate = this.incidentDate(incident);
      const date = incidentDate.format("MMM DD");
      const year = incidentDate.format("YYYY");
      const time = incidentDate.format("hh:mm A");

      const displayWarningType = WARNING_DISPLAY_MAP[incident.warningType];
      const formattedReasons = formatActionReasonsFromPriorIncidents(incident);
      return {
        ...incident,
        date,
        year,
        time,
        displayWarningType,
        formattedReasons,
      };
    });
  }

  private createMessageDetails(messageMetadata: MessageMetadata | null): any {
    if (messageMetadata && this.itemDetails) {
      if (this.itemDetails.workItemEntityType === WorkItemType.MESSAGE) {
        return {
          recipients: messageMetadata.recipients.split(" "),
          sender: messageMetadata.sender,
        };
      }
    } else {
      return null;
    }
  }
}
