import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "removeZalgo",
})
export class RemoveZalgoPipe implements PipeTransform {
  transform(value: string): string {
    return !value ? null : value.replace(/([aeiouy]\u0308)|[\u0300-\u036f\u0489]/gi, "$1");
  }
}
