import gql from "graphql-tag";

export default gql`
  query getTbdTemplates($workItemType: String!) {
    getTbdTemplates(workItemType: $workItemType) {
      category
      warningType
      workItemType
      forMultipleItemsOnly
      forQuarantineItemsOnly
      featureFlagCondition
      dropdownOption
      subject
      body
    }
  }
`;
