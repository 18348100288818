import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { BlockReason, WorkItemType } from "src/app/models/work-item.model";
import { StateService } from "src/app/shared/services/state.service";
import { HighlightKeywordPipe } from "src/app/shared/pipes/highlightKeyword.pipe";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { ThemeService } from "src/app/shared/services/theme.service";
import { coerceBooleanProperty } from "@angular/cdk/coercion";

@Component({
  selector: "app-reason-panel",
  templateUrl: "./reason-panel.component.html",
  styleUrls: ["./reason-panel.component.scss"],
  providers: [HighlightKeywordPipe],
})
export class ReasonPanelComponent implements OnInit, OnChanges {
  @Input() blockReason: BlockReason;
  @Input() showExpanded: boolean;
  @Input() workItemId: string;

  private _hideAdditionalExcerpts: boolean;
  @Input()
  set hideAdditionalExcerpts(value: boolean) {
    this._hideAdditionalExcerpts = coerceBooleanProperty(value);
  }
  get hideAdditionalExcerpts(): boolean {
    return this._hideAdditionalExcerpts;
  }

  headerLabel: string;
  headerText: string;
  currentlyTranslating = false;
  translatedText = [];

  constructor(
    private state: StateService,
    private highlightKeywordPipe: HighlightKeywordPipe,
    private appsync: AppSyncService,
    private theme: ThemeService,
  ) {}

  ngOnInit(): void {
    this.setUpHeader();
  }

  ngOnChanges(): void {
    this.setUpHeader();
  }

  private setUpHeader(): void {
    if (this.showReasonOrMessage) {
      this.headerLabel = this.reasonOrMessageText;
      this.headerText = this.highlightKeyword(this.blockReason.reason, this.blockReason?.keyword);
    } else if (this.hasExcerpt) {
      this.headerLabel = "Excerpt";
      this.headerText = this.highlightKeyword(this.excerpt, this.blockReason?.keyword);
    }
  }

  get isSpeakUpOrChat(): boolean {
    return (
      WorkItemType.SPEAKUP === this.state.currentItemDetails?.workItemEntityType ||
      WorkItemType.HANGOUT === this.state.currentItemDetails?.workItemEntityType
    );
  }

  get hasExcerpt(): boolean {
    return this.blockReason?.excerpts?.length > 0;
  }

  get reasonOrMessageText(): string {
    return this.isSpeakUpOrChat ? "Message" : "Reason";
  }

  get excerpt(): string {
    if (this.blockReason.excerpts.length > 1) {
      return (
        this.blockReason.excerpts
          // If hideAdditionalExcerpts is true, only show the first excerpt,
          // otherwise show the first 5 excerpts.
          .slice(0, this.hideAdditionalExcerpts ? 1 : 5)

          // Transform each excerpt
          .map((excerpt, idx) =>
            excerpt

              // Remove HTML tags and replace non-breaking spaces with regular spaces
              .replace(/(&nbsp;|<([^>]+)>)/gi, " ")

              // Add a superscript number to the beginning of each excerpt
              .replace(/^/, `<sup>${idx + 1}</sup>`),
          )

          // Join the excerpts with a line break and ellipsis
          .join("<br />...<br />")
      );
    } else {
      return this.blockReason.excerpts[0];
    }
  }

  get showReasonOrMessage(): boolean {
    return this.blockReason?.reason && !this.hasExcerpt;
  }

  get overrideTextColor(): string {
    return "white";
  }

  get overridePrimaryButtonColor(): string {
    return this.theme.getVariable("--checkbox");
  }

  get content(): { label: string; text: string }[] {
    const panelRows = [];
    if (this.showReasonOrMessage && this.hasExcerpt) {
      panelRows.push({
        label: "Excerpt",
        text: this.highlightKeyword(this.excerpt, this.blockReason?.keyword),
      });
    } else if (this.hasExcerpt) {
      panelRows.push({
        label: this.reasonOrMessageText,
        text: this.highlightKeyword(this.blockReason.reason, this.blockReason?.keyword),
      });
    }
    if (this.blockReason?.keyword) {
      panelRows.push({
        label: "Keywords",
        text: this.blockReason.keyword,
      });
    }

    return panelRows;
  }

  private highlightKeyword(textToHighlight: string, keyword: string): string {
    return this.highlightKeywordPipe.transform(textToHighlight, keyword);
  }

  async translateContent(textToTranslate: string): Promise<void> {
    this.currentlyTranslating = true;
    const response = await this.appsync.translateString([textToTranslate], this.workItemId);

    this.translatedText = response;
    this.currentlyTranslating = false;
  }
}
