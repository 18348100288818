import { Component, OnInit, Input } from "@angular/core";
import { StateService } from "src/app/shared/services/state.service";
import { FileComment, WorkItem } from "src/app/models/work-item.model";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { untilDestroyed, UntilDestroy } from "@ngneat/until-destroy";
import { ThemeService, ThemeMode } from "src/app/shared/services/theme.service";

@UntilDestroy()
@Component({
  selector: "app-file-comment-panel",
  templateUrl: "./file-comment-panel.component.html",
  styleUrls: ["./file-comment-panel.component.scss"],
})
export class FileCommentPanelComponent implements OnInit {
  comments: FileComment[] = [];
  itemDetails: WorkItem;
  translationsMap: Map<string, string> = new Map();
  authorMap: Map<string, string> = new Map();
  useDarkMode: boolean;
  buttonBackgroundColor: string;
  buttonTextColor: string;
  showAllParticipants = false;
  toggleParticipantsIcon = "plus";
  toggleParticipantsText = "Show All Participants";

  @Input() keywords: string[];

  constructor(private stateService: StateService, private appsyncService: AppSyncService, private themeService: ThemeService) {}

  ngOnInit(): void {
    this.stateService.fileComments$.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data) {
        this.comments = data.comments;
        this.buildAuthorMap();
      }
    });

    this.stateService.currentItemDetails$.pipe(untilDestroyed(this)).subscribe((itemDetails) => {
      this.itemDetails = itemDetails;
    });

    this.themeService.themeMode$.pipe(untilDestroyed(this)).subscribe((themeMode) => {
      this.useDarkMode = themeMode === ThemeMode.DARK;
      this.buttonBackgroundColor = this.themeService.getVariable("--chat-background");
      this.buttonTextColor = this.themeService.getVariable("--text-primary-color");
    });
  }

  translateComment(comment: FileComment): void {
    if (this.translationsMap.has(comment.id)) {
      return;
    }

    this.translationsMap.set(comment.id, "translating...");
    this.appsyncService.translateString([comment.content], this.itemDetails.id).then((data) => {
      this.translationsMap.set(comment.id, data[0]);
    });
  }

  toggleAllParticipants(): void {
    this.showAllParticipants = !this.showAllParticipants;
    this.toggleParticipantsIcon = this.showAllParticipants ? "minus" : "plus";
    this.toggleParticipantsText = this.showAllParticipants ? "Hide All Participants" : "Show All Participants";
  }

  randomColor = (): string => "#" + Math.random().toString(16).slice(-6);

  buildAuthorMap(): void {
    this.comments.forEach((comment) => {
      if (!this.authorMap.has(comment.author)) {
        this.authorMap.set(comment.author, this.randomColor());
      }

      if (comment.replies) {
        comment.replies.forEach((reply) => {
          if (!this.authorMap.has(reply.author)) {
            this.authorMap.set(reply.author, this.randomColor());
          }
        });
      }
    });
  }
}
