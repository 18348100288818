import { Component, Input, OnInit, OnDestroy, OnChanges } from "@angular/core";
import { StateService } from "src/app/shared/services/state.service";
import { EmergencyContactExtended } from "src/app/shared/services/emergency-contact.service";

@Component({
  selector: "app-call-emergency-contacts",
  templateUrl: "./call-emergency-contacts.component.html",
  styleUrls: ["./call-emergency-contacts.component.scss"],
})
export class CallEmergencyContactsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() contacts: EmergencyContactExtended[] = [];
  @Input() expand: boolean;
  @Input() modalDisplay = false;
  timer?: NodeJS.Timeout = null;
  callTimer = 0;
  showUnavailableContacts = false;
  sortedEmergencyContacts = [];
  isAfterHours: boolean;
  filteredContacts: EmergencyContactExtended[] = [];

  constructor(private state: StateService) {}

  ngOnInit(): void {
    this.isAfterHours = this.state.itemIsInAfterHoursWindow;
    this.sortedEmergencyContacts = this.contacts;
  }

  ngOnChanges(): void {
    this.setFilteredContacts();
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  startTimer(): void {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.callTimer += 1;
      }, 1000);
    }
  }

  setFilteredContacts(): void {
    if (this.showUnavailableContacts) {
      this.filteredContacts = this.contacts;
    } else {
      this.filteredContacts = this.contacts?.filter((ec) => ec.availability.phone);
    }
  }

  toggleUnavailableContacts(): void {
    this.showUnavailableContacts = !this.showUnavailableContacts;
    this.setFilteredContacts();
  }
}
