<div
    class="sis"
    data-testid="sis"
    *ngIf="isPSAPEnabled">
    <div class="sis-header">
        <div>Student and Guardian Information</div>
        <gaggle-button
            (buttonClick)="fetchPSAPInfo()"
            label="View"
            icon="contact-details"
            buttonStyle="full-width"
            [disabled]="PSAPButtonIsDisabled"
            [showSpinner]="loadingPSAP"
            [backgroundColor]="buttonBackgroundColor"
            [textColor]="buttonTextColor"
            [iconColor]="buttonTextColor"></gaggle-button>
    </div>
    <gaggle-loading-skeleton *ngIf="loadingPSAP"></gaggle-loading-skeleton>
    <ng-container *ngIf="psapWasFetched">
        <ng-container *ngIf="studentContact">
            <div class="contact-card student">
                <div class="contact-header">Student</div>
                <div>{{ studentContact.name }}</div>
                <div>{{ studentContact.phone }}</div>
                <div *ngFor="let address of studentContact.addresses">
                    <div>
                        <div *ngIf="!isPSAPLinkEnabled">{{ address.address }}</div>
                        <a
                            *ngIf="isPSAPLinkEnabled"
                            [href]="address.url"
                            title="Map to Student Address"
                            target="_blank"
                            >{{ address.address }}</a
                        >
                    </div>
                </div>
            </div>
        </ng-container>
        <div *ngIf="!loadingPSAP && !studentContact">No SIS data found for Student</div>
        <ng-container *ngIf="psapContacts.length > 0">
            <ng-container *ngFor="let guardian of psapContacts; let index = index">
                <div class="contact-card">
                    <div class="contact-header">Guardian #{{ index + 1 }}</div>
                    <div>{{ guardian.name }}</div>
                    <div>{{ guardian.phone }}</div>
                    <div *ngFor="let address of guardian.addresses">
                        <div>
                            <div *ngIf="!isPSAPLinkEnabled">{{ address.address }}</div>
                            <a
                                *ngIf="isPSAPLinkEnabled"
                                [href]="address.url"
                                title="Map to Guardian #{{ index + 1 }} Address"
                                target="_blank"
                                >{{ address.address }}</a
                            >
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div *ngIf="!loadingPSAP && psapContacts.length === 0">No SIS data found for Guardians</div>
    </ng-container>
</div>
