import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

Sentry.init({
  release: environment.release,
  enabled: environment.name !== "local",
  dsn: "https://0c35005f815c0886fdc719753f12f8f6@sentry.gaggle.net/3",
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: [/.*gaggle\.services\//, /.*\/graphql/],
  // Performance Monitoring
  tracesSampler: () => {
    if (environment.name === "production") {
      return 0.1;
    }
    return 1.0;
  },
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (environment.enableProdMode) {
  enableProdMode();
}

function fadeLoadingElement() {
  // Clear the loading timer, so the application isn't reloaded automatically
  window.clearInterval(window["loadingInterval"]);

  // Fade out the loading element
  const loadingElement = document.querySelector(".loading-wrapper");
  if (loadingElement) {
    setTimeout(() => {
      loadingElement.classList.add("app-loaded");
      // Remove the element after the transition
      setTimeout(() => {
        loadingElement.remove();
      }, 1000);
    }, 500);
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => fadeLoadingElement())
  .catch((err) => console.error(err));
