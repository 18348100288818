/* eslint-disable no-restricted-imports */
import { Component, ContentChild, ElementRef, HostBinding, Input, Renderer2, ViewChild } from "@angular/core";
import { SlideDrawerHeaderDirective } from "./slide-drawer-header.directive";
import { SlideDrawerFooterDirective } from "./slide-drawer-footer.directive";
import { SlideDrawerContentDirective } from "./slide-drawer-content.directive";
import { ThemeService } from "../../services/theme.service";

@Component({
  selector: "app-slide-drawer",
  templateUrl: "./slide-drawer.component.html",
  styleUrls: ["./slide-drawer.component.scss"],
})
export class SlideDrawerComponent {
  @HostBinding("style.--app-slide-drawer-max-width") @Input() maxWidth = "33%";
  @HostBinding("style.--app-slide-drawer-min-width") @Input() minWidth: string;

  @ContentChild(SlideDrawerHeaderDirective) slideDrawerHeader: SlideDrawerHeaderDirective;
  @ContentChild(SlideDrawerContentDirective) slideDrawerContent: SlideDrawerContentDirective;
  @ContentChild(SlideDrawerFooterDirective) slideDrawerFooter: SlideDrawerFooterDirective;

  @ViewChild("drawerDiv") drawerDiv: ElementRef;
  @ViewChild("toggleBtn") toggleButton: ElementRef;

  @Input() toggleIconName: string;
  @Input() toggleIconState: string;
  @Input() hideToggle = false;
  @Input() mode: "over" | "side" = "side";

  private _showDrawer = true;
  get showDrawer(): boolean {
    return this._showDrawer;
  }

  @Input() set showDrawer(v: boolean) {
    this._showDrawer = v;
  }

  private _showDot = false;
  get showDot(): boolean {
    return this._showDot;
  }

  @Input() set showDot(v: boolean) {
    this._showDot = v;
  }

  get overrideTextColor(): string {
    return this.themeService.getVariable("--text-primary-color");
  }

  constructor(private renderer2: Renderer2, private elementRef: ElementRef, private themeService: ThemeService) {}

  private setDrawerMargin(): void {
    let marginLeft = "0px";
    if (!this.showDrawer) {
      const drawerWidth = this.elementRef.nativeElement.getBoundingClientRect().width;
      marginLeft = -1 * drawerWidth + "px";
    }
    this.renderer2.setStyle(this.elementRef.nativeElement, "margin-left", marginLeft);
  }

  getIconName = (): string => this.toggleIconName || "chevron";

  getIconState = (): string => (this.toggleIconState || this.showDrawer ? "left" : "right");

  show = (): void => this.toggle(true);

  hide = (): void => this.toggle(true);

  toggle(show?: boolean): void {
    this.showDrawer = show ? show : !this.showDrawer;
    this.setDrawerMargin();
  }
}
