<div
    class="qcon-pss-switch-container"
    id="{{ category.title }}-qcon-pss-toggle">
    <div
        class="qcon"
        (click)="setState(QCON)"
        [ngClass]="{ forbidden: forcePSS }"
        attr.data-cy="{{ category.title }}-qcon">
        QCON
    </div>
    <div class="toggle-slider-container">
        <div class="control">
            <div class="capsule">
                <div
                    class="capsule-left"
                    (click)="setState(QCON)"
                    [ngStyle]="{ 'background-color': capsuleColor }"></div>
                <div
                    class="capsule-right"
                    (click)="setState(PSS)"
                    [ngStyle]="{ 'background-color': capsuleColor }"></div>
            </div>
            <div
                class="dot"
                (click)="advanceState()"
                [ngStyle]="{ 'background-color': dotColor }"
                [ngClass]="dotClass"
                data-testid="switch-dot"></div>
        </div>
    </div>
    <div
        class="pss"
        (click)="setState(PSS)"
        attr.data-cy="{{ category.title }}-pss">
        PSS
    </div>
</div>
