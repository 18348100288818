<div class="input-container">
    <label
        [for]="name"
        [ngClass]="{ required: required }"
        >{{ label }}</label
    >
    <input
        [id]="name"
        [name]="name"
        [type]="inputType"
        [value]="value"
        [required]="required"
        [readonly]="readonly"
        (change)="setValue($event)"
        [placeholder]="placeholder"
        [disabled]="disabled"
        autocomplete="off" />
</div>
