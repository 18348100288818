<div class="queue-sidebar-container" *ngIf="showLeftDrawer">
    <div class="category-container" data-cy="work-queues" *ngIf="!hideQueues">
        <div class="title-container" (click)="onClickRefreshQueues()">
            <div class="title">Work Queues</div>
        </div>

        <ng-container *ngIf="queues">
            <div class="queues">
                <ng-container *ngFor="let queue of queues">
                    <div
                        *ngIf="!queue.hideQueue"
                        class="queue-type"
                        [ngClass]="{
                            'selected-category': selectedCombinedQueue === queue.combinedType,
                            'disabled': shouldDisableQueue(queue),
                            'disallow-queue-click': showRightDrawer
                        }">
                        <div
                            class="active-queue-bar"
                            [matTooltip]="
                                showRightDrawer === true ? 'You cannot exit a queue while the incident drawer is open.' : 'Exit Queue'
                            "
                            [ngClass]="{
                                disabled: showRightDrawer === true
                            }"
                            *ngIf="selectedCombinedQueue === queue.combinedType">
                            <gaggle-icon
                                data-testid="exit-queue"
                                (click)="onClickExitWorkQueue()"
                                iconName="exit-queue"
                                iconSize="L"></gaggle-icon>
                        </div>
                        <div class="queue-wrapper" (click)="onClickQueue(queue)">
                            <div class="name">
                                {{ queue.label }}
                            </div>
                            <div *ngIf="queuesHaveBeenProcessed" class="separator"></div>
                            <mat-progress-bar
                                class="separator"
                                *ngIf="!queuesHaveBeenProcessed"
                                mode="indeterminate"
                                color="warn"></mat-progress-bar>
                            <div>{{ queue.count }}</div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>

    <div class="item-details-container" *ngIf="itemDetails">
        <app-item-details
            [showMoreAttachments]="showMoreAttachments"
            [showMoreFileInfo]="showMoreFileInfo"
            [showMoreSchoolInfo]="showMoreSchoolInfo"
            [showMoreIncidentInfo]="showMoreIncidentInfo"
            [showWorkItemSource]="true"
            [showEventInfo]="false"
            [hideCurrentIncident]="true"
            title="Item Details"
            (toggleSection)="toggleSection($event)"></app-item-details>
    </div>
</div>
<div class="close-queue" (click)="toggleLeftDrawer()" data-cy="toggle-left-drawer">
    <gaggle-icon iconName="chevron" iconSize="2em" [iconState]="showLeftDrawer ? 'left' : 'right'" class="toggle-icon"></gaggle-icon>
</div>
