<img
    [src]="src | safeImage"
    [alt]="imageAlt"
    (click)="show()" />

<div
    class="container"
    *ngIf="showModal"
    data-testid="modal-background">
    <div
        class="content"
        (clickOutside)="close()">
        <img
            [src]="src | safeImage"
            [alt]="fullImageAlt" />
    </div>
</div>
