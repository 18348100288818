import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { StateService } from "src/app/shared/services/state.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalDialogComponent } from "src/app/components/controls/modal-dialog/modal-dialog.component";

const DEFAULT_POLL_INTERVAL = 2 * 60 * 1000; // 2 minutes

@Injectable({
  providedIn: "root",
})
export class SwUpdateService {
  constructor(private state: StateService, private matDialog: MatDialog, private swUpdate: SwUpdate) {}

  pollForUpdates(pollInterval = DEFAULT_POLL_INTERVAL): void {
    // Set a timer to automatically check for updates every 10 seconds
    if (this.swUpdate.isEnabled) {
      console.info("SwUpdate (APP): SWUpdate is enabled. Starting timer to poll for application updates.");
      setInterval(() => {
        this.checkForUpdates().then();
      }, pollInterval);
    }
  }

  checkForUpdates(calledFrom = "APP"): Promise<void> {
    if (!this.swUpdate.isEnabled) {
      console.info(`SwUpdate (${calledFrom}): SWUpdate is disabled. Skipping application update check.`);
      return Promise.resolve();
    }

    /**
     * checkForUpdate() will check for updates on the server and resolve to:
     *  - true when a new version is available / downloaded / and ready to activate
     *  - false when no new version is available
     *
     *  NOTE: The promise rejects if an error occurs during the check.
     */
    console.info(`SwUpdate (${calledFrom}): Checking for application updates.`);
    return new Promise((resolve) =>
      this.swUpdate.checkForUpdate().then(
        (appAvailable) => {
          if (appAvailable) {
            if (calledFrom === "INIT") {
              console.info(`SwUpdate (${calledFrom}): New application found, activating new version.`);
            }

            this.swUpdate.activateUpdate().then(
              (appReady) => {
                if (appReady) {
                  if (calledFrom === "INIT") {
                    console.info(`SwUpdate (${calledFrom}): Application ready, reloading.`);
                    window.location.reload();
                  } else {
                    this.promptUserToUpdate();
                  }
                }
                resolve();
              },
              (err) => {
                console.error(`SwUpdate (${calledFrom}): New application version failed to activate.`, err);
                resolve();
              },
            );
          } else {
            resolve();
          }
        },
        (err) => {
          console.error(`SwUpdate (${calledFrom}): Error checking for application updates.`, err);
          resolve();
        },
      ),
    );
  }

  promptUserToUpdate(): void {
    this.state.newApplicationAvailable = true;
    this.matDialog
      .open(ModalDialogComponent, {
        data: {
          title: "New Application Version Available",
          content:
            "There is a new version of L2UI available. \n" +
            "You can refresh now or when convenient by using the \n" +
            "icon located at the top right of the application.",
          okButtonText: "Refresh Now",
          dismissButtonText: "Refresh Later",
        },
        disableClose: true,
        maxWidth: "400px",
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          window.location.reload();
        }
      });
  }
}
