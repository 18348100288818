<div
    class="email-emergency-contacts-container"
    data-testid="email-emergency-contacts-component">
    <div
        *ngIf="expand"
        class="expandable-content">
        <span class="sub-header">Contacts may not receive emails outside of their available hours</span>

        <section class="email-container">
            <app-expandable-panel
                *ngIf="contactsAreValid; else noContacts"
                [isExpanded]="expandRecipients"
                expandOnHeaderClick="true">
                <ng-template appPanelHeader>
                    <span class="email-panel-header">
                        {{ contacts.length + (shouldIncludeBlockedUser ? 1 : 0) + (shouldIncludeAdministrator ? 1 : 0) }} Email Recipients
                    </span>
                </ng-template>

                <ng-template appPanelContent>
                    <section class="email-recipients">
                        <div
                            *ngIf="shouldIncludeBlockedUser"
                            class="recipient">
                            <span class="email">{{ incident.payload.userEmail }} (blocked user)</span>
                        </div>
                        <div
                            *ngIf="shouldIncludeAdministrator"
                            class="recipient">
                            <span class="email">{{ groupAdministrator.email }} (administrator)</span>
                        </div>

                        <div>
                            <div
                                class="recipient"
                                *ngFor="let ec of contacts">
                                <div class="name">
                                    <mat-icon
                                        *ngIf="ec.availability.digest"
                                        matTooltip="Digest Recipient"
                                        class="delivery-indicator"
                                        data-testid="digest-delivery-indicator">
                                        schedule_send
                                    </mat-icon>
                                    <mat-icon
                                        *ngIf="!ec.availability.digest"
                                        matTooltip="Deliver Now"
                                        class="delivery-indicator"
                                        data-testid="email-delivery-indicator">
                                        email
                                    </mat-icon>
                                    <span>{{ ec.name }}</span>
                                </div>
                                <div class="email">
                                    <span>{{ ec.email }}</span>
                                    <gaggle-tooltip *ngIf="ec.alternateEmail">
                                        <span class="additional-email-indicator">+1</span>
                                        <ng-container tooltip>{{ ec.alternateEmail }}</ng-container>
                                    </gaggle-tooltip>
                                </div>
                            </div>
                        </div>
                    </section>
                </ng-template>
            </app-expandable-panel>

            <!-- Do not allow additional recipients in GAFT -->
            <ng-container *ngIf="!isAfterHours">
                <app-input
                    name="email-to"
                    label="Additional To:"
                    (valueChange)="onAdditionalRecipientsChange($event)"
                    [(value)]="incident.additionalRecipientsInput"></app-input>
                <app-input
                    name="email-cc"
                    label="Additional CC:"
                    (valueChange)="onAdditionalCcRecipientsChange($event)"
                    [(value)]="incident.ccRecipientsInput"></app-input>
            </ng-container>

            <app-email-content
                [incident]="incident"
                submitLabel="Send Emails"
                (enableNextSection)="enableNextIncidentSection()"
                (sendEmail)="onClickSendEmail()"
                [additionalEmailRecipients]="additionalEmailRecipients"
                [additionalEmailCcRecipients]="additionalEmailCcRecipients"
                [shouldIncludeBlockedUser]="shouldIncludeBlockedUser"
                [disableSendButton]="disableSendButton"
                [isReopenIncident]="isReopenIncident"
                [isOpenWorkItem]="isOpenWorkItem"></app-email-content>
        </section>

        <ng-template #noContacts>
            <div class="missing-contacts">
                <span class="header">Whoops!</span>
                <span>We couldn't seem to find any emergency contacts for this incident.</span>
                <span>Click the button below to try again.</span>
                <button
                    mat-flat-button
                    color="warn"
                    data-testid="reload-contacts-button"
                    (click)="onClickReloadContacts()">
                    Reload Contacts
                </button>
            </div>
        </ng-template>
    </div>
</div>
