<div *ngIf="formattedPhoneNumberForDisplay?.length > 0" class="phone-row-container" [ngClass]="{ disabled: disabled }">
    <div class="phone-number-container">
        <div class="phone-number">
            <span class="phone-type">{{ type }}:</span>
            {{ formattedPhoneNumberForDisplay }}
            <div *ngIf="extension" class="extension" (click)="copyExtension()">ext. {{ extension }}</div>
        </div>
    </div>
    <div class="action-container">
        <div *ngIf="callTimer || callTimer > 0" class="time" data-testid="timer">
            {{ callTimer | formatTimer }}
        </div>

        <button
            *ngIf="!callingContact"
            class="phone-call"
            [disabled]="disabled || activeCall"
            [ngClass]="wasCalled === 0 ? 'purple' : 'green'"
            (click)="callUser()">
            <div class="phone-icon">
                <span class="call-count" [ngClass]="{ hidden: wasCalled === 0 }" data-testid="call-count">
                    {{ wasCalled }}
                </span>
                <mat-icon>phone_enabled</mat-icon>
            </div>
            Call
        </button>

        <gaggle-button-pair
            *ngIf="callingContact"
            leftButtonIcon="resolve"
            rightButtonIcon="escalate"
            data-testid="call-control-buttons"
            (leftButtonClick)="completeCall(IncidentCommunicationType.CallAnswered)"
            (rightButtonClick)="completeCall(IncidentCommunicationType.CallNotAnswered)"
            [backgroundColor]="buttonBackgroundColor"></gaggle-button-pair>
    </div>
</div>
