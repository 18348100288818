import gql from "graphql-tag";

export default gql`
  query getWorkItemFeatureFlags($flagNames: [String!]!, $workItem: WorkItemFeatureFlagInput!) {
    getWorkItemFeatureFlags(flagNames: $flagNames, workItem: $workItem) {
      name
      value
      configuration
    }
  }
`;
