import { inject, Injectable } from "@angular/core";
import { WorkItem, UiEventAction, UiApplication, UiQueue, SendUiAnalyticsEventV2GQL } from "src/generated/graphql";
import { WorkItem as LegacyWorkItem } from "src/app/models/work-item.model";

@Injectable({
  providedIn: "root",
})
export class UiAnalyticsService {
  private sendUiAnalyticsEventV2GQL = inject(SendUiAnalyticsEventV2GQL);
  private workItem: WorkItem;
  private startTimestamp: number;
  private uiQueue: UiQueue;
  private highPriority: boolean;

  // TODO: Once the migration to the new Apollo client is complete, we can remove the
  //       legacy work item type and only use the new work item type.
  public startWork(workItem: WorkItem | LegacyWorkItem): void {
    this.workItem = workItem as WorkItem;

    if (workItem.queueLevel === UiQueue.Priority) {
      this.uiQueue = UiQueue.Escalation;
      this.highPriority = true;
    } else {
      this.uiQueue = workItem.queueLevel;
    }

    this.highPriority = workItem.queueLevel === UiQueue.Priority;
    this.sendAction(UiEventAction.WorkStarted);
  }

  /**
   * This method is required because the endWork event does not clear the UIQueue, and that's by design.
   * The UIQueue is set when the user enters the review component, and only changes when the user changes
   * queues. We don't want to clear that when endWork is called, but rather only when the user leaves
   * the review component, preventing erroneous events from being sent from other parts of the UI.
   */
  public leaveQueue(): void {
    this.endWork();
    this.uiQueue = null;
  }

  public endWork(): void {
    this.sendAction(UiEventAction.WorkEnded);
    this.startTimestamp = this.workItem = this.uiQueue = this.highPriority = null;
  }

  public sendAction(action: UiEventAction, endWork = false): void {
    // If there isn't a workItem and queue we shouldn't be sending anything to the backend.
    // It's a good check to have in the case of reusing a component in a view that is not in
    // the "Review" section of the application.
    if (!this.workItem || !this.uiQueue) {
      return;
    }

    // Unless the action is WorkStarted, we need to make sure that we have a startTimestamp
    // before we send the event to the backend. WorkStarted is the only action that doesn't
    // require a startTimestamp as it's the event that sets the startTimestamp.
    if (this.startTimestamp || action === UiEventAction.WorkStarted) {
      this.sendUiAnalyticsEventV2GQL
        .mutate(
          {
            application: UiApplication.L2Ui,
            queue: this.uiQueue,
            workItemId: this.workItem.id,
            workItemType: this.workItem.workItemEntityType,
            blockReasons: this.workItem.blockReasons,
            action: action,
            startTimestamp: action === UiEventAction.WorkStarted ? null : this.startTimestamp,
            isHighPriority: this.highPriority,
          },
          {
            context: {
              // If the action is WorkStarted, we want to use the spinner to require the
              // user to wait until the event has saved so that we can get the startTimestamp
              // for all subsequent events for this item.
              bypassSpinner: action !== UiEventAction.WorkStarted,
            },
          },
        )
        .subscribe((result) => {
          // Because events from a previous work item can return after the next work item has
          // already started, we need to make sure that we're only setting the startTimestamp
          // if the action is WorkStarted. Otherwise, we could be setting the startTimestamp to
          // a value returned from an event sent for the prior work item.
          if (action === UiEventAction.WorkStarted) {
            this.startTimestamp = result.data.sendUIAnalyticsEventV2.startTimestamp;
          }
        });

      if (endWork) {
        this.endWork();
      }
    }
  }
}
