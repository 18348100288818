import gql from "graphql-tag";

export const Fragments = {
  workItem: gql`
    fragment BaseWorkItem on WorkItem {
      attachments {
        contentType
        id
        include
        name
        qualifier
        previewUrl
      }
      apsIds
      blockedUserId
      blockReasons {
        cacheItem {
          contentKey
          expiration
          url
        }
        excerpts
        keyword
        moreExcerpts
        reason
        regex
        url
        videoItem {
          contentHash
          frames {
            contentKey
            expiration
            url
          }
          sensitivity
        }
      }
      bundleDigest
      bundled
      completed
      content
      customerId
      created
      description
      districtId
      districtIdShort
      districtName
      districtNotes
      entityId
      events {
        date
        event
        workerId
        workerName
      }
      extraInfo
      groupId
      groupIdShort
      groupName
      id
      imageCacheIds
      inappropriateContentReasons
      incidentIds
      itemData
      logicalLocationId
      notes
      ncmecId
      occurrenceDate
      safetyAudit
      safetyRepId
      studentTimezone
      unbundled
      userFullName
      userId
      userIdShort
      userName
      workItemSource
      workItemStatus
      workItemEntityType
      workerId
      workerName
      gsmEmailSetting
      groupAdministrator {
        name
        email
      }
      userEmail
      item_meta_ingestionData
      districtInfo {
        address
        city
        country
        phone
        postalCode
        state
      }
      studentInfo {
        email
        firstName
        lastName
      }
      ecAvailability {
        isAfterHoursQCON
        isAfterHoursPSS
        hasGaftSubscription
        contacts {
          id
          name
          title
          priority
          districtContact
          dispatch
          afterHours
          email
          alternateEmail
          officePhone
          mobilePhone
          homePhone
          alternatePhone
          emailOnly
          firstWarning {
            categories
            email
            phone
            text
            digest
          }
          secondWarning {
            categories
            email
            phone
            text
            digest
          }
          thirdWarning {
            categories
            email
            phone
            text
            digest
          }
          qcon {
            categories
            email
            phone
            text
            digest
          }
          pss {
            categories
            email
            phone
            text
            digest
          }
          unformattedOfficePhone {
            areaCode
            countryCode
            ext
            number
          }
          unformattedMobilePhone {
            areaCode
            countryCode
            ext
            number
          }
          unformattedHomePhone {
            areaCode
            countryCode
            ext
            number
          }
          unformattedAlternatePhone {
            areaCode
            countryCode
            ext
            number
          }
        }
      }
      queueLevel
      ... on GenericWorkItem {
        messageMetadata {
          attachments {
            contentId
            contentType
            downloadUrl
            id
            include
            name
            previewUrl
            qualifier
            s3Key
          }
          chat_context
          content
          content_type
          download_info {
            item_type
            url
            file_size
            expiration
            compressed
          }
          recipients
          sender
          subject
        }
      }
      ... on ThirdPartyWorkItem {
        entityName
        entitySize
        folder
        lastModified
        lastModifiedBy
        remoteOwnerId
        revision
        sharedToUser
        remoteEntityId
        fileOwnerEmail
        contentType
        canQuarantine
        downloadInfo {
          item_type
          url
          file_size
          expiration
          compressed
          pdfUrl
          pdfGenerated
        }
      }
      ... on WebFilterWorkItem {
        objectId
        eventTime
        searchTerms
        messageMetadata {
          attachments {
            contentId
            contentType
            downloadUrl
            id
            include
            name
            previewUrl
            qualifier
            s3Key
          }
          chat_context
          content
          content_type
          download_info {
            item_type
            url
            file_size
            expiration
            compressed
          }
          recipients
          sender
          subject
        }
      }
      ... on SpeakupWorkItem {
        objectId
        messageTo
        messageFrom
        messageMetadata {
          attachments {
            contentId
            contentType
            downloadUrl
            id
            include
            name
            previewUrl
            qualifier
            s3Key
          }
          chat_context
          content
          content_type
          download_info {
            item_type
            url
            file_size
            expiration
            compressed
            pdfUrl
            pdfGenerated
          }
          recipients
          sender
          subject
        }
      }
      ... on ChatWorkItem {
        messageId
        uniqueId
        author
        externalAuthor
        messageMetadata {
          attachments {
            contentId
            contentType
            downloadUrl
            id
            include
            name
            previewUrl
            qualifier
            s3Key
          }
          chat_context
          content
          content_type
          download_info {
            item_type
            url
            file_size
            expiration
            compressed
            pdfUrl
            pdfGenerated
          }
          recipients
          sender
          subject
        }
      }
    }
  `,
};
