/*
 * Base Classes
 */
import { IncidentCommunicationType, IncidentProcessingCommunication, PhoneNumberInput } from "src/generated/graphql";

export abstract class NewIncident {
  static readonly base_type = "New Incident";
  constructor(public readonly incidentId: string) {}
}

export abstract class AddCommunication {
  static readonly base_type = "Add Communication";
  constructor(
    public readonly ecId: string,
    public readonly ecName: string,
    public readonly communicationType: IncidentCommunicationType,
    public readonly phoneNumber?: PhoneNumberInput,
  ) {}
}

export abstract class ResetIncident {
  static readonly base_type = "Reset Incident";
}

/*
 * Reactions (triggered by State)
 */
export class AddCommunicationSuccessFromIncidentState {
  static readonly type = "[Incident State] Add Communication Success";
  constructor(public readonly incidentProcessingCommunication: IncidentProcessingCommunication) {}
}

/*
 * Scoped Actions
 */
export class NewIncidentFromIncidentResponseRightDrawer extends NewIncident {
  static readonly type = `[Incident Response Right Drawer Component] ${this.base_type}`;
}

export class AddCommunicationFromEmailEmergencyContacts extends AddCommunication {
  static readonly type = `[Email Emergency Contacts Component] ${this.base_type}`;
}

export class AddCommunicationFromTextEmergencyContacts extends AddCommunication {
  static readonly type = `[Text Emergency Contacts Component] ${this.base_type}`;
}

export class AddCommunicationFromPhoneRow extends AddCommunication {
  static readonly type = `[Phone Row Component] ${this.base_type}`;
}

export class ResetIncidentFromIncidentResponseRightDrawer extends ResetIncident {
  static readonly type = `[Incident Response Right Drawer Component] ${this.base_type}`;
}
