import { inject, NgModule } from "@angular/core";
import { CanActivateFn, Router, RouterModule, Routes } from "@angular/router";
import { UnauthenticatedComponent } from "./components/unauthenticated/unauthenticated.component";
import { AuthenticatedComponent } from "./components/authenticated/authenticated.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { ReviewComponent } from "./components/review/review.component";
import { IncidentSummaryComponent } from "src/app/components/incident-summary/incident-summary.component";
import { ReopenActivation } from "src/app/shared/utils/reopen-activation";
import { WorkItemSummaryComponent } from "src/app/components/work-item-summary/work-item-summary.component";
import { RoutingLabels } from "src/app/models/routing.model";
import { environment } from "src/environments/environment";
import { AuthService } from "@shared/services/auth.service";

export const debugGuard = (): CanActivateFn => {
  return (activatedRoute) => {
    const router = inject(Router);
    return environment.name !== "production" || router.createUrlTree([`/work-items/${activatedRoute.params.workItemId}`]);
  };
};

export const userIsConfiguredGuard = (): CanActivateFn => {
  return () => {
    const authService = inject(AuthService);
    const router = inject(Router);
    if (!authService.userIsAllowed) {
      return router.createUrlTree(["/no-access"]);
    } else {
      return true;
    }
  };
};

const routes: Routes = [
  {
    path: "connection-test",
    component: UnauthenticatedComponent,
  },
  {
    path: "no-access",
    component: UnauthenticatedComponent,
  },
  {
    path: "",
    component: AuthenticatedComponent,
    canActivate: [userIsConfiguredGuard()],
    canActivateChild: [userIsConfiguredGuard()],
    children: [
      {
        path: "admin",
        loadChildren: () => import("src/app/features/admin/admin.module").then((m) => m.AdminModule),
        data: {
          label: RoutingLabels.ADMIN,
        },
      },
      {
        path: "search",
        loadChildren: () => import("src/app/features/search/search.module").then((m) => m.SearchModule),
        data: {
          label: RoutingLabels.SEARCH,
        },
      },
      {
        path: ":incidentId",
        pathMatch: "full",
        redirectTo: "/incidents/:incidentId",
        data: {
          label: RoutingLabels.REVIEW,
        },
      },
      {
        path: "debug",
        loadChildren: () => import("src/app/shared/modules/debug.module").then((m) => m.DebugModule),
        pathMatch: "prefix",
        canActivateChild: [debugGuard()],
        data: {
          label: RoutingLabels.REVIEW,
        },
      },
      {
        path: "incidents/:workItemId/reopen",
        component: ReviewComponent,
        pathMatch: "full",
        data: {
          reopenWorkItemViaIncident: true,
          label: RoutingLabels.REVIEW,
        },
        canActivate: [ReopenActivation],
      },
      {
        path: "incidents/:incidentId",
        component: IncidentSummaryComponent,
        pathMatch: "full",
        data: {
          label: RoutingLabels.REVIEW,
        },
      },
      {
        path: "incidents/:incidentId/reopened-incident",
        component: IncidentSummaryComponent,
        data: {
          reopenedIncident: true,
          label: RoutingLabels.REVIEW,
        },
      },

      {
        path: "work-items/:workItemId/create-incident",
        component: ReviewComponent,
        pathMatch: "full",
        data: {
          reopenWorkItemViaWorkItem: true,
          label: RoutingLabels.REVIEW,
        },
      },
      {
        path: "work-items/:workItemId",
        component: WorkItemSummaryComponent,
        pathMatch: "full",
        data: {
          label: RoutingLabels.REVIEW,
        },
      },

      {
        path: "work-items/:workItemId/created-incident",
        component: WorkItemSummaryComponent,
        data: {
          createdIncident: true,
          label: RoutingLabels.REVIEW,
        },
      },
      {
        path: "**",
        redirectTo: "",
        component: ReviewComponent,
        data: {
          label: RoutingLabels.REVIEW,
        },
      },
    ],
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [ReopenActivation],
})
export class AppRoutingModule {}
