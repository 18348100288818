import { Component, OnInit, OnDestroy } from "@angular/core";
import { StateService } from "src/app/shared/services/state.service";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { WorkItem } from "src/app/models/work-item.model";
import { getQueueCount } from "src/app/shared/utils/helpers";
import { ModalDialogComponent } from "src/app/components/controls/modal-dialog/modal-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EventType } from "src/app/models/incident.model";
import { ActivatedRoute } from "@angular/router";
import { UiAnalyticsService } from "src/app/shared/services/ui-analytics.service";
import { UiEventAction } from "src/generated/graphql";
import { Subject, takeUntil, fromEvent, throttleTime, merge } from "rxjs";
import { WorkItemVisibilityService } from "src/app/shared/services/work-item-visibility.service";
import { EmergencyContactService } from "src/app/shared/services/emergency-contact.service";

const INACTIVITY_POLL_INTERVAL = 5 * 1000; // Milliseconds between worker activity checks
const INACTIVITY_WARN = 4 * 60 * 1000; // Milliseconds between inactivity warning and inactivity dialog
const INACTIVITY_PATIENCE = 60 * 1000; // Milliseconds between inactivity warning and item expiration

@Component({
  selector: "app-review",
  templateUrl: "./review.component.html",
  styleUrls: ["./review.component.scss"],
})
export class ReviewComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private inactivityDialog: MatDialogRef<ModalDialogComponent, any> = null;
  private workerIsActive = false;
  private workerActivityInterval: any;
  private workerActivityTimeout: any;

  // Set up rxjs observables for various user events that indicate activity
  // and merge those into a single observable for tracking worker activity
  private mouseMove$ = fromEvent(document, "mousemove");
  private keyDown$ = fromEvent(document, "keydown");
  private click$ = fromEvent(document, "click");
  private activityEvents$ = merge(this.mouseMove$, this.keyDown$, this.click$);

  showRightDrawer: boolean;
  showLeftDrawer: boolean;
  ncmecOnlyDrawer = false;
  ncmecEvent = EventType.RESOLVE;
  itemDetails: WorkItem;
  isReopenIncident = false;
  isOpenWorkItem = false;
  workItemId: string;

  constructor(
    private state: StateService,
    private appSync: AppSyncService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private router: ActivatedRoute,
    private uiAnalyticsService: UiAnalyticsService,
    private itemVisibilityService: WorkItemVisibilityService,
    private emergencyContactService: EmergencyContactService,
  ) {}

  ngOnInit(): void {
    this.state.currentQueueType = null;

    // Load templates when reviewing items
    this.appSync.getAllEmailTemplates();
    this.appSync.getSMSTemplates();

    // When the user is active, set the workIsActive flag to true (throttled)
    this.activityEvents$
      .pipe(throttleTime(1000))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => (this.workerIsActive = true));

    this.router.data.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.isReopenIncident = !!data.reopenWorkItemViaIncident;
        this.isOpenWorkItem = !!data.reopenWorkItemViaWorkItem;
      } else {
        this.isReopenIncident = false;
        this.isOpenWorkItem = false;
      }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // TODO: Figure out why this is wired up so weirdly and fix it
    this.router.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe(({ params }) => {
      this.workItemId = params["workItemId"];
      if (this.workItemId && (this.isReopenIncident || this.isOpenWorkItem)) {
        this.appSync.getWorkItemById(this.workItemId);
      }
    });

    this.state.currentItemDetails$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.itemDetails = data;
      if (data) {
        this.inactivityDialog = null;
        this.uiAnalyticsService.startWork(data);
        this.itemVisibilityService.startHeartbeat(this.itemDetails.id);
        this.setupWorkerActivityMonitoring(true);
      } else {
        this.setupWorkerActivityMonitoring(false);
      }
    });

    this.state.showRightDrawer$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.showRightDrawer = data;
      this.setupWorkerActivityMonitoring(!data);
    });

    this.state.showLeftDrawer$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => (this.showLeftDrawer = data));
  }

  ngOnDestroy(): void {
    this.onExit();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Set up an interval to check for worker activity every 15 seconds
   *
   * @remarks
   * If this is a reopen incident, or an open work item, do not start the interval
   *
   * @private
   * @param enable
   * @returns void
   */
  private setupWorkerActivityMonitoring(enable: boolean): void {
    if (enable && this.itemDetails && !this.isReopenIncident && !this.isOpenWorkItem) {
      if (!this.workerActivityInterval) {
        this.workerActivityInterval = setInterval(() => {
          this.verifyWorkerActivity();
        }, INACTIVITY_POLL_INTERVAL);
      }
    } else {
      clearInterval(this.workerActivityInterval);
      this.workerActivityInterval = null;
      clearTimeout(this.workerActivityTimeout);
      this.workerActivityTimeout = null;
    }
  }

  /**
   * Check if the worker is active
   *
   * @remarks
   * If the worker is active, set the workIsActive flag to false for the next cycle
   * and clear the timeout that may or may not be there
   * If the worker is inactive, start a timeout that will fire in 5 minutes
   * and to prompt the user to acknowledge activity.
   *
   * @private
   * @returns void
   */
  private verifyWorkerActivity(): void {
    if (this.workerIsActive) {
      clearTimeout(this.workerActivityTimeout);
      this.workerActivityTimeout = null;
      this.workerIsActive = false;
    } else {
      if (!this.workerActivityTimeout) {
        this.workerActivityTimeout = setTimeout(() => this.promptForActivity(), INACTIVITY_WARN);
      }
    }
  }

  /**
   * Prompt the user to acknowledge activity and start the patience timer
   *
   * @remarks
   * When the user has been inactive for the configured interval, tear down the
   * interval that checks for worker activity and start then patience timeout.
   */
  private promptForActivity(): void {
    this.setupWorkerActivityMonitoring(false);

    this.inactivityDialog = this.matDialog.open(ModalDialogComponent, {
      disableClose: true,
      data: {
        title: "No activity detected",
        content:
          "If you are still working on this item, please indicate below. If you do not acknowledge, the current work item will be skipped and you will be returned to the queue selection screen.",
        dismissButtonText: "Close Work Item",
        okButtonText: "Keep Working",
      },
    });

    this.inactivityDialog.afterClosed().subscribe(async (result) => {
      clearTimeout(patienceTimeout);
      this.onInactivityDialogClose(result);
    });

    const patienceTimeout = setTimeout(() => {
      this.inactivityDialog.close(false);
      this.snackBar.open("Due to inactivity, you have been returned to queue selection.", "Dismiss", {
        horizontalPosition: "left",
        verticalPosition: "bottom",
      });
    }, INACTIVITY_PATIENCE);
  }

  /**
   * Handle the result of the inactivity dialog
   *
   * @remarks
   * If the user acknowledges activity (result === true) before the timeout fires, restart the
   * interval that checks for worker activity and clear the timeout.
   *
   * If the user does not acknowledge activity before the patience timer expires,
   * close the current work item and return to the queue selection screen.
   *
   * @param result
   * @private
   */
  private onInactivityDialogClose(result: boolean): void {
    if (result) {
      this.setupWorkerActivityMonitoring(true);
      this.uiAnalyticsService.sendAction(UiEventAction.InactiveWorkerRefresh);
    } else {
      this.uiAnalyticsService.sendAction(UiEventAction.InactiveWorkerSkip, true);
      this.onExit();
    }
  }

  async onClickSkip(): Promise<void> {
    this.uiAnalyticsService.sendAction(UiEventAction.Skip, true);
    this.itemVisibilityService.changeTimeout(15, true);
    if (getQueueCount(this.state.queues, this.state.currentQueueType) <= 0) {
      this.onExit();
      return;
    }

    await this.appSync.getNextWorkItem();
  }

  onClickExit = (): void => this.onExit();

  onExit(): void {
    this.uiAnalyticsService.sendAction(UiEventAction.Exit, true);
    this.uiAnalyticsService.leaveQueue();
    this.itemVisibilityService.stopHeartbeat(15);
    this.emergencyContactService.reset();
    this.state.exitReviewMode();
    this.ncmecOnlyDrawer = false;
  }

  onShowRightDrawer(event: boolean): void {
    this.ncmecOnlyDrawer = false;
    this.state.showRightDrawer = event;
    this.state.isItemActionBarVisible = false;
  }

  onShowNcmecDrawer(event: EventType): void {
    this.onShowRightDrawer(true);
    this.ncmecOnlyDrawer = true;
    this.ncmecEvent = event;
  }
}
