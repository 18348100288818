import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ThemeService } from "src/app/shared/services/theme.service";
import { formatPhoneNumberForDisplay } from "src/app/shared/utils/format";
import { EmergencyContactExtended } from "src/app/shared/services/emergency-contact.service";

@Component({
  selector: "app-after-hours-text-emergency-contact-row",
  templateUrl: "./after-hours-text-emergency-contact-row.component.html",
  styleUrls: ["./after-hours-text-emergency-contact-row.component.scss"],
})
export class AfterHoursTextEmergencyContactRowComponent implements OnInit {
  constructor(private themeService: ThemeService) {}

  @Input() contact: EmergencyContactExtended;
  @Output() copyPhoneNumber = new EventEmitter();

  isDisabled = false;
  wasCalled = 0;

  get formattedPhoneNumber(): string {
    return formatPhoneNumberForDisplay(this.contact.unformattedMobilePhone);
  }

  get jobTitle(): string {
    return this.contact.title || "N/A";
  }

  ngOnInit(): void {
    this.isDisabled = !this.contact.afterHours;
  }

  onToggleDisabled(): void {
    this.isDisabled = !this.isDisabled;
  }

  onCopyClick(): void {
    if (this.isDisabled) {
      return;
    }
    this.copyPhoneNumber.emit(this.formattedPhoneNumber);
    this.wasCalled += 1;
  }

  get copyButtonBackground(): string {
    let variable = "--checkbox";
    if (this.isDisabled) {
      variable = "--background-secondary-color";
    } else if (this.wasCalled > 0) {
      variable = "--green-color";
    }
    return this.themeService.getVariable(variable);
  }

  get textButtonColor(): string {
    return this.themeService.getVariable(this.isDisabled ? "--text-disabled-color" : "--nav-link-color");
  }
}
