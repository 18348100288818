import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { StateService } from "src/app/shared/services/state.service";
import { ThemeService } from "src/app/shared/services/theme.service";
import { EmergencyContactExtended } from "src/app/shared/services/emergency-contact.service";

@Component({
  selector: "app-call-emergency-contact-row",
  templateUrl: "./call-emergency-contact-row.component.html",
  styleUrls: ["./call-emergency-contact-row.component.scss"],
})
export class CallEmergencyContactRowComponent implements OnInit {
  @Input() contact: EmergencyContactExtended;
  @Output() startSectionTimer = new EventEmitter();
  isDisabled;
  showContactDetails;

  constructor(private state: StateService, private themeService: ThemeService) {}

  ngOnInit(): void {
    this.showContactDetails = false;
    this.isDisabled = !this.contact.availability.phone;
  }

  get buttonId(): string {
    return `button-${this.contact.name}-toggle-details`;
  }

  get jobTitle(): string {
    return this.contact.title || "N/A";
  }

  get priority(): string {
    return this.contact.priority?.toString() || "N/A";
  }

  get district(): string {
    return this.state.currentItemDetails.districtName;
  }

  get schoolOrGroup(): string {
    return this.contact.districtContact ? "District" : this.state.currentItemDetails.groupName;
  }

  get infoIconColor(): string {
    return this.themeService.getVariable("--text-primary-color");
  }

  onToggleDisabled(): void {
    this.isDisabled = !this.isDisabled;
  }

  startCallSectionTimer(): void {
    this.startSectionTimer.emit();
  }

  toggleContactDetails(): void {
    this.showContactDetails = !this.showContactDetails;
  }

  closeContactDetails(): void {
    this.showContactDetails = false;
  }
}
