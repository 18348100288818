import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { ThemeService } from "src/app/shared/services/theme.service";

@Component({
  selector: "app-chat-message",
  templateUrl: "./chat-message.component.html",
  styleUrls: ["./chat-message.component.scss"],
})
export class ChatMessageComponent {
  @Input() workItemId;
  @Input() message;
  @Input() selectedParticipantEmail;
  @Output() highlightChat = new EventEmitter();

  showOptions = false;
  currentlyTranslating = false;
  translatedText = [];

  constructor(private appsync: AppSyncService, private theme: ThemeService) {}

  get isMessageFromSelectedParticipant(): boolean {
    return this.message.senderEmail === this.selectedParticipantEmail;
  }

  get overrideTextColor(): string {
    return "white";
  }

  get overridePrimaryButtonColor(): string {
    return this.theme.getVariable("--checkbox");
  }

  toggleMessageOptions(): void {
    this.showOptions = !this.showOptions;
  }

  showMessageOptions(): void {
    this.showOptions = true;
  }

  hideMessageOptions(): void {
    this.showOptions = false;
  }

  highlightMessagesFromSender(event: any): void {
    const clickedFromOptionsMessage = event.composedPath().some((element) => element.localName?.includes("icon--translate-text"));

    if (!clickedFromOptionsMessage) {
      this.highlightChat.emit();
    }
  }

  async translateContent(textToTranslate: string): Promise<void> {
    this.showOptions = true;
    this.currentlyTranslating = true;
    const response = await this.appsync.translateString([textToTranslate], this.workItemId);

    this.translatedText = response;
    this.currentlyTranslating = false;
    this.showOptions = false;
  }
}
