import { ApolloLink } from "@apollo/client/core";

export const createStripTypenameLink = (): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key, value) => (key === "__typename" ? undefined : value);
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => {
      return data;
    });
  });
};
