import { ApolloLink } from "@apollo/client/core";
import { RetryLink } from "@apollo/client/link/retry";

/**
 * Simple Apollo RetryLink that will retry the request up to 3 times
 * with an incremental backoff starting at 500ms between each attempt.
 */
export const createRetryLink = (): ApolloLink => {
  return new RetryLink({
    delay: {
      initial: 500,
      max: Infinity,
    },
    attempts: {
      max: 3,
    },
  });
};
