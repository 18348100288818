import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

@Component({
  selector: "app-textarea",
  templateUrl: "./textarea.component.html",
  styleUrls: ["../input/input.component.scss", "./textarea.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextareaComponent,
    },
  ],
})
export class TextareaComponent implements ControlValueAccessor {
  // required to make text area function correctly
  @Input() name!: string; // should be unique per the current form
  @Input() label: string;
  @Input() value = "";

  @Output() valueChange = new EventEmitter<string>();
  @Input() placeholder = "";
  @Input() disabled = false;
  @Input() required = false;
  @Input() disableResize = false;
  @Input() readonly = false;
  @Input() verticalStyle = false;
  @Input() hideBorder = false;
  MAX_TEXT_AREA_HEIGHT = 20 * 4 + 10; // input height is 20px, this should show minimum four lines plus some extra

  touched = false;

  constructor() {}

  /* eslint-disable */ // This is all per the angular guide, so eslint can just be quiet already
  onChange = (value) => {};
  onTouched = () => {};
  /* eslint-enable*/

  setValue(event: any): void {
    this.value = event.target.value;
    this.valueChange.emit(this.value);
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  bringFocusToTextArea(): void {
    document.getElementById(this.name).focus();
  }
}
