import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[clickOutside]",
})
export class ClickedOutsideDirective {
  initialClick = true;
  @Output() clickOutside: EventEmitter<Event> = new EventEmitter<Event>();

  @HostListener("document:click", ["$event"])
  onClick(event: any): void {
    if (this.initialClick) {
      this.initialClick = false;
      return;
    } else {
      if (!this.elemRef.nativeElement.contains(event.target)) {
        this.clickOutside.emit();
      }
    }
  }

  constructor(private elemRef: ElementRef) {}
}
