import { WorkItemStatus } from "src/app/models/work-item-incident.model";
import { EmergencyContactsWithAvailabilityResponse, UiQueue, WarningEventType } from "src/generated/graphql";

export interface WorkItem {
  apsIds: string[];
  attachments: AttachmentInfo[];
  blockReasons: BlockReason[];
  blockedUserId?: string;
  bundled?: boolean;
  bundleDigest?: string;
  completed?: string;
  created?: string;
  customerId: string;
  description?: string;
  districtId: string;
  districtIdShort: string;
  districtName: string;
  districtNotes: string;
  entityId?: string;
  events: WorkItemEventRecord[];
  extraInfo?: string;
  groupId: string;
  groupIdShort: string;
  groupName?: string;
  id: string;
  imageCacheIds: string[];
  inappropriateContentReasons: string[];
  incidentIds: string[];
  logicalLocationId?: string;
  notes?: string;
  ncmecId?: string;
  occurrenceDate?: string;
  safetyAudit?: boolean;
  safetyRepId?: string;
  studentTimezone?: string;
  unbundled?: boolean;
  userId: string;
  userIdShort: string;
  userFullName?: string;
  userName?: string;
  workerId?: string;
  workerName?: string;
  workItemEntityType: WorkItemType;
  workItemSource?: string;
  workItemStatus?: string;
  districtInfo?: DistrictInfo;
  studentInfo?: StudentInfo;
  // for ThirdPartyWorkItems
  entityName?: string;
  entitySize?: number;
  folder?: boolean;
  lastModified?: string;
  lastModifiedBy?: string;
  remoteOwnerId?: string;
  revision?: number;
  sharedToUser?: boolean;
  remoteEntityId?: string;
  fileOwnerEmail?: string;
  contentType?: string;
  canQuarantine?: boolean;
  // for ChatWorkItems
  content?: string;
  messageId?: string;
  uniqueId?: string;
  author?: string;
  externalAuthor?: boolean;
  // for SpeakupWorkItems
  objectId?: string;
  messageTo?: string;
  messageFrom?: string;
  // for WebFilterWorkItems
  eventTime?: string;
  userEmail?: string;
  searchTerms?: string;
  itemData?: string;
  isL1?: boolean;
  gsmEmailSetting?: GsmEmailSetting;
  item_meta_ingestionData?: string;
  item_meta_externalData?: string;
  messageMetadata?: MessageMetadata;
  downloadInfo?: DownloadInfo;
  groupAdministrator?: GroupAdministrator;
  ecAvailability?: EmergencyContactsWithAvailabilityResponse;
  queueLevel: UiQueue;
}

export interface GroupAdministrator {
  name?: string;
  email?: string;
}

export interface AttachmentInfo {
  id?: string;
  name?: string;
  contentType?: string;
  qualifier?: string;
  include?: boolean;
  previewUrl?: string;
  contentId?: string;
  downloadUrl?: string;
  s3Key?: string;
}

export interface BlockReason {
  reason?: string;
  keyword?: string;
  url?: string;
  excerpts?: string[];
  regex?: string;
  moreExcerpts?: boolean;
  cacheItem?: BlockedContentCacheItem;
  videoItem?: BlockedVideoCacheItem;
}

export interface BlockedContentCacheItem {
  contentKey: string;
  expiration: string;
  url: string;
}

export interface BlockedVideoCacheItem {
  contentHash: string;
  sensitivity: number;
  frames: BlockedContentCacheItem[];
}

export interface WorkItemEventRecord {
  workerId: string;
  workerName: string;
  event: string;
  date: string;
}

interface DistrictInfo {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  phone: string;
}

export interface StudentInfo {
  firstName: string;
  lastName: string;
  email: string;
}

export enum ActionReason {
  GENERAL = "GENERAL",
  PROFANITY = "PROFANITY",
  NUDITY = "NUDITY",
  VIOLENCE_SELF = "VIOLENCE_SELF",
  VIOLENCE_OTHERS = "VIOLENCE_OTHERS",
  DRUG_USE = "DRUG_USE",
  UNABLE_TO_PROCESS = "UNABLE_TO_PROCESS",
  SPAM_BULK = "SPAM_BULK",
  RESTRICTED_USE = "RESTRICTED_USE",
  FALSE_POSITIVE = "FALSE_POSITIVE",
  HARASSMENT = "HARASSMENT",
  SELF_HARM = "SELF_HARM",
  SUICIDE = "SUICIDE",
  SEXUAL_CONTENT = "SEXUAL_CONTENT",
  _OTHER_TBD = "_OTHER_TBD",
}

export enum WorkItemSource {
  UNSPECIFIED = "UNSPECIFIED",
  GOOGLE_DRIVE = "GOOGLE_DRIVE",
  GOOGLE_HANGOUTS = "GOOGLE_HANGOUTS",
  CANVAS = "CANVAS",
  MICROSOFT_ONEDRIVE = "MICROSOFT_ONEDRIVE",
  MICROSOFT_TEAMS = "MICROSOFT_TEAMS",
  EMAIL = "EMAIL",
  SPEAKUP = "SPEAKUP",
  WEBFILTER = "WEBFILTER",
  CONTENT_KEEPER = "CONTENT_KEEPER",
  LINEWIZE = "LINEWIZE",
  LIGHTSPEED = "LIGHTSPEED",
  GOGUARDIAN = "GOGUARDIAN",
  GOOGLE_CALENDAR = "GOOGLE_CALENDAR",
  GOOGLE_GMAIL_ARCHIVE = "GOOGLE_GMAIL_ARCHIVE",
  O365_EMAIL_ARCHIVE = "O365_EMAIL_ARCHIVE",
  GOOGLE_PASSTHRU = "GOOGLE_PASSTHRU",
  O365_PASSTHRU = "O365_PASSTHRU",
  GROUPWISE_EMAIL = "GROUPWISE_EMAIL",
  IBOSS = "IBOSS",
  SECURLY = "SECURLY",
  ZSCALER = "ZSCALER",
  GOOGLE_CHROME_EXTENSION = "GOOGLE_CHROME_EXTENSION",
  HAPARA = "HAPARA",
  GAT_SHIELD = "GAT_SHIELD",
  BLOCKSI = "BLOCKSI",
  PALO_ALTO = "PALO_ALTO",
  DELEDAO = "DELEDAO",
}

export const workItemSourceMap = {
  [WorkItemSource.UNSPECIFIED]: "",
  [WorkItemSource.GOOGLE_DRIVE]: "Google Drive",
  [WorkItemSource.GOOGLE_HANGOUTS]: "Google Hangouts",
  [WorkItemSource.EMAIL]: "Email",
  [WorkItemSource.SPEAKUP]: "SpeakUp",
  [WorkItemSource.WEBFILTER]: "Web Filter",
  [WorkItemSource.CONTENT_KEEPER]: "Content Keeper",
  [WorkItemSource.LINEWIZE]: "Linewize",
  [WorkItemSource.LIGHTSPEED]: "Lightspeed",
  [WorkItemSource.GOGUARDIAN]: "GoGuardian",
  [WorkItemSource.GOOGLE_CALENDAR]: "Google Calendar",
  [WorkItemSource.GOOGLE_GMAIL_ARCHIVE]: "Google Gmail Archive",
  [WorkItemSource.O365_EMAIL_ARCHIVE]: "O365 Email Archive",
  [WorkItemSource.GOOGLE_PASSTHRU]: "Google Passthru",
  [WorkItemSource.O365_PASSTHRU]: "O365 Passthru",
  [WorkItemSource.GROUPWISE_EMAIL]: "Groupwise Email",
  [WorkItemSource.IBOSS]: "iBoss",
  [WorkItemSource.SECURLY]: "Securly",
  [WorkItemSource.ZSCALER]: "zScaler",
  [WorkItemSource.GOOGLE_CHROME_EXTENSION]: "Google Chrome Extension",
  [WorkItemSource.CANVAS]: "Canvas",
  [WorkItemSource.HAPARA]: "Hapara",
  [WorkItemSource.GAT_SHIELD]: "GAT Shield",
  [WorkItemSource.BLOCKSI]: "Blocksi",
  [WorkItemSource.PALO_ALTO]: "Palo Alto",
  [WorkItemSource.DELEDAO]: "Deledao",
};

export enum WorkItemType {
  CALENDAR = "CALENDAR",
  CANVAS = "CANVAS",
  FILE = "FILE",
  FILE_COMMENT = "FILE_COMMENT",
  HANGOUT = "HANGOUT",
  MESSAGE = "MESSAGE",
  SPEAKUP = "SPEAKUP",
  WEBFILTER = "WEBFILTER",
  YAMMER = "YAMMER",
  ANY = "ANY",
}

export const CategoriesWithoutViolations: Set<WorkItemType> = new Set<WorkItemType>()
  .add(WorkItemType.WEBFILTER)
  .add(WorkItemType.SPEAKUP)
  .add(WorkItemType.HANGOUT)
  .add(WorkItemType.CALENDAR)
  .add(WorkItemType.CANVAS)
  .add(WorkItemType.FILE)
  .add(WorkItemType.FILE_COMMENT);

export enum GsmEmailSetting {
  NONE = "NONE",
  STUDENT = "STUDENT",
  ADMINISTRATOR = "ADMINISTRATOR",
  ADMINISTRATOR_AND_STUDENT = "ADMINISTRATOR_AND_STUDENT",
}

export const workItemToDisplayTypeMap = {
  [WorkItemType.CALENDAR]: "Calendar",
  [WorkItemType.CANVAS]: "LMS",
  [WorkItemType.FILE]: "Documents (3rd party)",
  [WorkItemType.FILE_COMMENT]: "File Comment",
  [WorkItemType.HANGOUT]: "Chats",
  [WorkItemType.MESSAGE]: "Messages/Email",
  [WorkItemType.SPEAKUP]: "SpeakUp",
  [WorkItemType.WEBFILTER]: "Web Filter",
  [WorkItemType.YAMMER]: "Yammer",
};

export const workItemStatusToDisplayMap = {
  [WorkItemStatus.COMPLETED]: "Completed",
  [WorkItemStatus.PENDING]: "Pending",
};

export interface EventPayload {
  userId?: string;
  itemId?: string;
  userEmail?: string;
  fileOwnerEmail?: string;
  workerId?: string;
  safetyRepName?: string;
  entityOwnerId?: string;
  groupId?: string;
  allowBeta?: boolean;
  groupName?: string;
  domainId?: string;
  districtId?: string;
  districtName?: string;
  customerId?: string;
  customerName?: string;
  entityId?: string;
  remoteEntityId?: string;
  entityType?: WorkItemType;
  actionReasons?: ActionReason[];
  warningType?: WarningEventType;
}

export interface DownloadInfo {
  compressed?: boolean;
  url?: string;
  file_size?: number;
  expiration?: string;
  pdfUrl?: string;
  pdfGenerated?: boolean;
}

export interface MessageMetadata {
  download_info?: DownloadInfo;
  subject?: string;
  sender?: string;
  recipients?: string;
  content?: string[];
  attachments?: AttachmentInfo[];
  chat_context?: string;
}

interface FileCommentReply {
  author?: string;
  blocked?: boolean;
  content?: string;
  id?: string;
  replyNumber?: number;
  timestamp?: number;
}

export interface FileComment {
  anchor?: string;
  author?: string;
  blocked?: boolean;
  content?: string;
  id?: string;
  replies?: FileCommentReply[];
  timestamp?: number;
}

export interface FileCommentBlockedItem {
  author?: string;
  blocked?: boolean;
  content?: string;
  id?: string;
  timestamp?: number;
  type?: string;
}

export interface FileCommentContent {
  blockedItem?: FileCommentBlockedItem;
  comments?: FileComment[];
  documentId?: string;
  externalType?: string;
  ownerId?: string;
}

interface TextAttachmentInfo {
  id: number;
  name?: string;
  text?: string;
}

interface MetadataContent {
  id: number;
  url?: string;
  entityType?: string;
  title?: string;
  fileContent?: AttachmentInfo[];
  textContent?: TextAttachmentInfo[];
}

export interface ExternalMetadata {
  id: number;
  customerShortId: number;
  name: string;
  externalType?: string;
  url?: string;
  requestDate?: string;
  author?: string;
  content?: MetadataContent[];
  otherInfo?: string;
  participants?: string;
}

export enum ExtensionItemSource {
  INPUT = "/analyze/input",
  QUERY = "/analyze/query",
}

export const extensionItemSourceMap = {
  [ExtensionItemSource.INPUT]: "Form input",
  [ExtensionItemSource.QUERY]: "Executed search",
};

export interface ItemData {
  chromeExtensionData?: {
    inputType: ExtensionItemSource;
  };
}
