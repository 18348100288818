<app-expandable-panel
    [isExpanded]="true"
    data-testid="file-preview-panel">
    <ng-template appPanelHeader>
        <div class="new-row">
            <div class="label">Preview:</div>
            <div
                *ngIf="isOversized"
                class="text">
                File is too large to preview. Click "Download" above to download and view the file
            </div>
            <div
                *ngIf="!isPDF && !isImage"
                class="text">
                File is not an image. Click "Download" above to download and view the file.
            </div>
        </div>
    </ng-template>
    <ng-template
        appPanelContent
        *ngIf="!isOversized && !fileSizeIsZero">
        <div class="file-content-container">
            <app-image-modal
                *ngIf="isImage"
                [src]="fileDownloadInfo.url"
                imageAlt="File Preview"></app-image-modal>
            <iframe
                sandbox
                *ngIf="isPDF"
                [src]="fileDownloadInfo.url | safe"
                frameborder="0"
                class="pdf"
                title="pdf preview"></iframe>
        </div>
    </ng-template>
    <ng-template
        appPanelContent
        *ngIf="fileSizeIsZero">
        <div class="file-content-container">
            <div
                class="not-available"
                data-testid="not-available">
                This file is not available.
            </div>
        </div>
    </ng-template>
</app-expandable-panel>
