import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CheckboxComponent } from "src/app/components/controls/checkbox/checkbox.component";
import { InputComponent } from "src/app/components/controls/input/input.component";
import { TextareaComponent } from "src/app/components/controls/textarea/textarea.component";
import { ClickedOutsideDirective } from "src/app/shared/directives/click-outside.directive";
import { ModalDialogComponent } from "src/app/components/controls/modal-dialog/modal-dialog.component";
import { ExpandablePanelComponent } from "src/app/components/item-viewer/expandable-panel/expandable-panel.component";
import { PanelHeaderDirective } from "src/app/components/item-viewer/expandable-panel/panel-header.directive";
import { PanelContentDirective } from "src/app/components/item-viewer/expandable-panel/panel-content.directive";
import { PanelButtonsDirective } from "src/app/components/item-viewer/expandable-panel/panel-buttons.directive";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PipesModule } from "src/app/shared/modules/pipes.module";
import { ClipboardModule } from "ngx-clipboard";
import { GaggleUiCommonModule } from "gaggle-ui-common";
import { ImageModalComponent } from "src/app/components/item-viewer/image-modal/image-modal.component";
import { ItemDetailsComponent } from "src/app/components/queue-left-sidebar/item-details/item-details.component";
import { ItemDetailRowComponent } from "src/app/components/queue-left-sidebar/item-details/item-detail-row/item-detail-row.component";
import { ItemDetailRowContentDirective } from "src/app/components/queue-left-sidebar/item-details/item-detail-row/item-detail-row-content.directive";
import { ItemViewerActionBarComponent } from "src/app/components/item-viewer/item-viewer-action-bar/item-viewer-action-bar.component";
import { ItemViewerComponent } from "src/app/components/item-viewer/item-viewer.component";
import { SectionNavigationComponent } from "src/app/components/incident-response-right-drawer/section-navigation/section-navigation.component";
import { ContentCategoryComponent } from "src/app/components/incident-response-right-drawer/content-category/content-category/content-category.component";
import { NotFoundComponent } from "src/app/components/not-found/not-found.component";
import { ShowMoreLessToggleComponent } from "src/app/components/queue-left-sidebar/show-more-less-toggle/show-more-less-toggle.component";
import { QconPssSwitchComponent } from "src/app/components/incident-response-right-drawer/qcon-pss-switch/qcon-pss-switch/qcon-pss-switch.component";
import { PreviewPanelComponent } from "src/app/components/item-viewer/preview-panel/preview-panel/preview-panel.component";
import { FilePreviewPanelComponent } from "src/app/components/item-viewer/file-preview-panel/file-preview-panel/file-preview-panel.component";
import { CalPreviewPanelComponent } from "src/app/components/item-viewer/cal-preview-panel/cal-preview-panel.component";
import { MessagePreviewPanelComponent } from "src/app/components/item-viewer/message-preview-panel/message-preview-panel/message-preview-panel.component";
import { ReasonPanelComponent } from "src/app/components/item-viewer/reason-panel/reason-panel/reason-panel.component";
import { ChatMessageComponent } from "src/app/components/item-viewer/message-preview-panel/chat-message/chat-message.component";
import { ActionButtonComponent } from "src/app/components/item-viewer/item-viewer-action-bar/action-button/action-button.component";
import { DistrictNotesComponent } from "src/app/components/item-viewer/district-notes/district-notes.component";
import { EmailEmergencyContactsComponent } from "src/app/components/incident-response-right-drawer/email-emergency-contacts/email-emergency-contacts/email-emergency-contacts.component";
import { TextEmergencyContactsComponent } from "src/app/components/incident-response-right-drawer/text-emergency-contacts/text-emergency-contacts/text-emergency-contacts.component";
import { CallEmergencyContactsComponent } from "src/app/components/incident-response-right-drawer/call-emergency-contacts/call-emergency-contacts/call-emergency-contacts.component";
import { CallEmergencyContactRowComponent } from "src/app/components/incident-response-right-drawer/call-emergency-contacts/call-emergency-contact-row/call-emergency-contact-row.component";
import { EmailContentComponent } from "src/app/components/incident-response-right-drawer/email-content/email-content.component";
import { SubmitIncidentComponent } from "src/app/components/incident-response-right-drawer/submit-incident/submit-incident.component";
import { PhoneRowComponent } from "src/app/components/incident-response-right-drawer/call-emergency-contacts/phone-row/phone-row.component";
import { TextEmergencyContactRowComponent } from "src/app/components/incident-response-right-drawer/text-emergency-contacts/text-emergency-contact-row/text-emergency-contact-row.component";
import { TextEmergencyContactRowIntegratedComponent } from "src/app/components/incident-response-right-drawer/text-emergency-contacts/text-emergency-contant-row-integrated/text-emergency-contact-row-integrated.component";
import { PsapComponent } from "src/app/components/incident-response-right-drawer/psap/psap.component";
import { NcmecComponent } from "src/app/components/incident-response-right-drawer/ncmec/ncmec.component";
import { AfterHoursCallEmergencyContactRowComponent } from "src/app/components/incident-response-right-drawer/call-emergency-contacts/after-hours-call-emergency-contact-row/after-hours-call-emergency-contact-row.component";
import { AfterHoursTextEmergencyContactRowComponent } from "src/app/components/incident-response-right-drawer/text-emergency-contacts/after-hours-text-emergency-contact-row/after-hours-text-emergency-contact-row.component";
import { LmsInfoComponent } from "src/app/components/item-viewer/lms-info/lms-info.component";
import { MenuButtonComponent } from "src/app/components/controls/menu-button/menu-button.component";
import { ThemedDialogComponent } from "src/app/components/controls/themed-dialog/themed-dialog.component";
import { PanelFooterDirective } from "src/app/components/item-viewer/expandable-panel/panel-footer.directive";
import { SlideDrawerModule } from "./modules/slide-drawer/slide-drawer.module";
import { CreateSalesforceCaseComponent } from "src/app/components/incident-response-right-drawer/create-salesforce-case/create-salesforce-case.component";
import { TextFieldModule } from "@angular/cdk/text-field";
import { FileCommentPanelComponent } from "src/app/components/item-viewer/file-comment-panel/file-comment-panel.component";
import { MaterialModule } from "src/app/shared/material.module";
import { IncidentContactCommunicationsDialogComponent } from "src/app/components/incident-summary/incident-contact-communications-dialog/incident-contact-communications-dialog.component";
import { SendSmsDialogComponent } from "src/app/shared/components/send-sms-dialog/send-sms-dialog.component";

const sharedComponentsAndDirectives = [
  MenuButtonComponent,
  NotFoundComponent,
  CheckboxComponent,
  InputComponent,
  InputComponent,
  TextareaComponent,
  ClickedOutsideDirective,
  ModalDialogComponent,
  ExpandablePanelComponent,
  PanelHeaderDirective,
  PanelContentDirective,
  PanelButtonsDirective,
  PanelFooterDirective,
  ImageModalComponent,
  ItemDetailsComponent,
  ItemDetailRowComponent,
  ItemDetailRowContentDirective,
  ItemViewerActionBarComponent,
  ItemViewerComponent,
  SectionNavigationComponent,
  ContentCategoryComponent,
  ShowMoreLessToggleComponent,
  QconPssSwitchComponent,
  PreviewPanelComponent,
  FilePreviewPanelComponent,
  MessagePreviewPanelComponent,
  CalPreviewPanelComponent,
  ReasonPanelComponent,
  ChatMessageComponent,
  ActionButtonComponent,
  DistrictNotesComponent,
  EmailEmergencyContactsComponent,
  TextEmergencyContactsComponent,
  CallEmergencyContactsComponent,
  CallEmergencyContactRowComponent,
  EmailContentComponent,
  SubmitIncidentComponent,
  PhoneRowComponent,
  TextEmergencyContactRowComponent,
  TextEmergencyContactRowIntegratedComponent,
  PsapComponent,
  NcmecComponent,
  AfterHoursCallEmergencyContactRowComponent,
  AfterHoursTextEmergencyContactRowComponent,
  LmsInfoComponent,
  ThemedDialogComponent,
  CreateSalesforceCaseComponent,
  FileCommentPanelComponent,
  IncidentContactCommunicationsDialogComponent,
  SendSmsDialogComponent,
];

const sharedImports = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialModule,
  PipesModule,
  ClipboardModule,
  GaggleUiCommonModule,
  SlideDrawerModule,
  TextFieldModule,
];

@NgModule({
  declarations: [...sharedComponentsAndDirectives],
  imports: [...sharedImports],
  exports: [...sharedComponentsAndDirectives, ...sharedImports],
})
export class SharedModule {}
