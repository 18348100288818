import { Component, Input } from "@angular/core";
import { BlockReason } from "src/app/models/work-item.model";

@Component({
  selector: "app-preview-panel",
  templateUrl: "./preview-panel.component.html",
  styleUrls: ["./preview-panel.component.scss"],
})
export class PreviewPanelComponent {
  @Input() blockReason: BlockReason;

  get imageUrl(): string {
    return this.blockReason.cacheItem?.url;
  }

  constructor() {}
}
