import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AcknowledgementService, IncidentAcknowledgement } from "src/app/shared/services/acknowledgement.service";
import { ClipboardService } from "ngx-clipboard";
import { SMSRecipient, SMSTemplate } from "src/app/models/incident.model";
import { formatTimer, removeCharsFromPhoneNumber } from "src/app/shared/utils/format";
import { ThemeMode, ThemeService } from "src/app/shared/services/theme.service";
import { StateService } from "src/app/shared/services/state.service";
import { FeatureFlagName, FeatureFlagValue, WorkItemFeatureFlagName } from "src/app/models/feature-flags.model";
import { WorkItem } from "src/app/models/work-item.model";
import { animate, style, transition, trigger } from "@angular/animations";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatRadioChange } from "@angular/material/radio";
import { UiAnalyticsService } from "src/app/shared/services/ui-analytics.service";
import {
  GetSmsTemplatesGQL,
  SendSmsGQL,
  SendSmsMutationVariables,
  SmsMessageStatus,
  SmsStatusUpdatedGQL,
  UiEventAction,
} from "src/generated/graphql";
import { skipWhile, Subject, takeUntil } from "rxjs";
import { EmergencyContactExtended, EmergencyContactService } from "src/app/shared/services/emergency-contact.service";
import moment from "moment";

@Component({
  selector: "app-text-emergency-contacts",
  templateUrl: "./text-emergency-contacts.component.html",
  styleUrls: ["./text-emergency-contacts.component.scss"],
  animations: [
    trigger("InOut", [
      transition(":enter", [style({ height: "0px" }), animate("200ms ease-out", style({ height: "48px" }))]),
      transition(":leave", [animate("200ms ease-out", style({ height: "0px" }))]),
    ]),
  ],
})
export class TextEmergencyContactsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() expand: boolean;
  @Input() contacts: EmergencyContactExtended[] = [];
  @Input() modalDisplay = false;
  @Input() itemDetails: WorkItem;
  @Output() enableNextSection = new EventEmitter();

  acknowledgedBy: IncidentAcknowledgement;
  showUnavailableContacts = false;
  timer?: NodeJS.Timeout = null;
  copyTimer = 0;
  smsTimer = 0;
  smsTimeLimit = 5 * 60; // 5 Minutes
  smsTimeLimitExceeded = false;
  isAfterHours: boolean;
  smsSent = false;
  showAwaitingAcknowledgement = false;
  smsButtonLabel = "Send Text Messages";
  contactsSelected = false;
  selectedCategoryReasons = [];
  filteredSMSTemplates = [];
  afterHoursTemplatesAvailable = false;
  selectedTemplate: SMSTemplate;
  filteredContactsHasBeenCalled = false;
  selectedSmsSendDate: string;
  useDarkMode: boolean;
  buttonBackgroundColor: string;
  buttonTextColor: string;
  deliveryTiming: "now" | "later" = "now";
  minDeliveryDate: Date = new Date();

  constructor(
    private _clipboardService: ClipboardService,
    private themeService: ThemeService,
    private state: StateService,
    private acknowledgementService: AcknowledgementService,
    private uiAnalyticsService: UiAnalyticsService,
    private emergencyContactService: EmergencyContactService,
    private smsStatusUpdatedGQL: SmsStatusUpdatedGQL,
    private sendSmsGQL: SendSmsGQL,
    private getSmsTemplatesGQL: GetSmsTemplatesGQL,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.resetSelectedTemplate();

    if (!this.modalDisplay) {
      this.state.selectedCategories$
        .pipe(takeUntil(this.unsubscribe$))
        .pipe(skipWhile((data) => data === null || data?.length === 0))
        .subscribe((data) => {
          if (data && data.length > 0) {
            this.selectedCategoryReasons = data.map((category) => category.actionReason);
            this.populateSMStemplates();
          } else {
            this.selectedCategoryReasons = this.filteredSMSTemplates = [];
          }
        });

      this.enableNextSection.emit();
      this.isAfterHours = this.state.itemIsInAfterHoursWindow;

      this.state.currentItemDetails$
        .pipe(takeUntil(this.unsubscribe$))
        .pipe(skipWhile((data) => data === null))
        .subscribe((data) => {
          this.itemDetails = data;
          this.setMinDeliveryDate();
        });

      this.acknowledgementService.acknowledgedBy$
        .pipe(takeUntil(this.unsubscribe$))
        .pipe(skipWhile((data) => data === null))
        .subscribe((data) => {
          this.acknowledgedBy = data;
          clearInterval(this.timer);
        });

      this.themeService.themeMode$.pipe(takeUntil(this.unsubscribe$)).subscribe((themeMode) => {
        this.useDarkMode = themeMode === ThemeMode.DARK;
        this.buttonBackgroundColor = this.themeService.getVariable("--checkbox");
        this.buttonTextColor = this.themeService.getVariable("--nav-link-color");
      });
    }
  }

  /**
   * Store the datepicker value as a string in the format YYYY-MM-DD
   */
  onSelectDate(event: MatDatepickerInputEvent<any> | undefined): void {
    this.selectedSmsSendDate = event.value.format("YYYY-MM-DD");
  }

  get disableSMSButton(): boolean {
    if (!this.selectedTemplate?.id) {
      return true;
    }

    if (this.numberOfSelected === 0) {
      return true;
    }

    if (this.deliveryTiming === "later" && !this.selectedSmsSendDate) {
      return true;
    }

    return this.smsSent;
  }

  get isInAppSMSEnabled(): boolean {
    return this.state.getWorkItemFeatureFlag(WorkItemFeatureFlagName.SMS_INTEGRATION) === FeatureFlagValue.ON;
  }

  get isL2SMSEnabled(): boolean {
    return this.state.getFeatureFlag(FeatureFlagName.L2_TEXT_MESSAGING) === FeatureFlagValue.ON;
  }

  get emergencyContacts(): EmergencyContactExtended[] {
    return this.contacts;
  }

  get filteredContacts(): EmergencyContactExtended[] {
    const filtered = this.showUnavailableContacts ? this.emergencyContacts : this.availableEmergencyContacts;
    //first time it's been called, set selected to true
    if (!this.filteredContactsHasBeenCalled) {
      this.filteredContactsHasBeenCalled = true;
      filtered.forEach((contact) => {
        contact.selected = true;
      });
      this.contactsSelected = true;
      return filtered;
    } else {
      return filtered;
    }
  }

  get availableEmergencyContacts(): EmergencyContactExtended[] {
    return this.emergencyContacts.filter((ec) => ec.availability.text);
  }

  get formattedTimer(): string {
    return formatTimer(this.copyTimer);
  }

  selectAll(): void {
    this.filteredContacts.forEach((contact) => {
      contact.selected = true;
    });
  }

  clearAll(): void {
    this.filteredContacts.forEach((contact) => {
      contact.selected = false;
    });
  }

  setMinDeliveryDate(): void {
    /**
     * If the local time in the student's timezone is after 8AM, then we need to set
     * the min delivery date to tomorrow. Otherwise, we can set it to today.
     *
     * When calculating the current time, we need to add 15 minutes to account for the fact
     * that we are unable to schedule a message within 15 minutes of the current time.
     */
    if (this.itemDetails?.studentTimezone) {
      const currentTime = moment().tz(this.itemDetails.studentTimezone).add(15, "minutes");
      if (currentTime.hour() >= 8) {
        this.minDeliveryDate = moment().add(1, "days").toDate();
      } else {
        this.minDeliveryDate = new Date();
      }
    } else {
      this.minDeliveryDate = new Date();
    }
  }

  toggleUnavailableContacts(): void {
    this.showUnavailableContacts = !this.showUnavailableContacts;
  }

  toggleSendTime(event: MatRadioChange): void {
    this.deliveryTiming = event.value;
    if (this.deliveryTiming === "now") {
      this.selectedSmsSendDate = null;
    }
    this.populateSMStemplates();
  }

  copyValue(value: { formattedPhoneNumber: string; contact: EmergencyContactExtended }): void {
    const cleanNumber = removeCharsFromPhoneNumber(value.formattedPhoneNumber);
    this._clipboardService.copy(cleanNumber);
    this.startTimer();
  }

  onClickRefreshTemplates(): void {
    this.state.useFullPageSpinner(true);
    this.getSmsTemplatesGQL
      .fetch()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.populateSMStemplates();
        this.state.useFullPageSpinner(false);
      });
  }

  onClickTemplateOption(template: SMSTemplate): void {
    if (!template) {
      return;
    }
    this.selectedTemplate = template;
  }

  resetSelectedTemplate(): void {
    this.selectedTemplate = {
      id: "",
      dropdownOption: "",
      afterHours: false,
      workItemTypes: [],
      categories: [],
      message: "",
    };
  }

  populateSMStemplates(): void {
    if (this.selectedCategoryReasons.length === 0) {
      return;
    }

    this.resetSelectedTemplate();
    this.afterHoursTemplatesAvailable = false; // Reset this before filtering through templates

    const filterAfterHoursTemplates = this.deliveryTiming === "later";

    this.filteredSMSTemplates = this.state.SMSTemplates.filter((template) => {
      const categoryIntersection = template.categories.filter((category) => this.selectedCategoryReasons.includes(category));

      // While processing through, watch for an after hours template that WOULD be available if Send Later was checked
      if (
        !this.afterHoursTemplatesAvailable && // We haven't found one yet
        categoryIntersection.length > 0 && // Matching categories
        template.workItemTypes.includes(this.itemDetails?.workItemEntityType) && // Matching work item type
        template.afterHours === true // And after hours is true (we're looking for after hours templates
      ) {
        this.afterHoursTemplatesAvailable = true;
      }

      return (
        categoryIntersection.length > 0 && // Matching categories
        template.workItemTypes.includes(this.itemDetails?.workItemEntityType) && // Matching work item type
        template.afterHours === filterAfterHoursTemplates // And after hours matches
      );
    });

    // We should automatically populate the template if there's only one
    if (this.filteredSMSTemplates.length === 1) {
      this.selectedTemplate = this.filteredSMSTemplates[0];
    }
  }

  contactSelected(index: number): any {
    if (this.smsSent) {
      return;
    }
    this.filteredContacts[index].selected = !this.filteredContacts[index].selected;
  }

  get numberOfSelected(): number {
    return this.filteredContacts.filter((contact) => contact.selected).length;
  }

  sendSMS(): void {
    const recipientArray: SMSRecipient[] = [];
    this.uiAnalyticsService.sendAction(UiEventAction.SendTextMessages);
    this.filteredContacts.forEach((user) => {
      if (user.selected) {
        recipientArray.push({
          id: user.id,
          name: user.name,
          mobilePhone: {
            countryCode: user.unformattedMobilePhone.countryCode,
            areaCode: user.unformattedMobilePhone.areaCode,
            number: user.unformattedMobilePhone.number,
          },
        });

        /**
         * If the message is going out right now, we need to set up a subscription to listen for the updates
         * BEFORE we actually send the SMS messages, so that we do not miss any updates (since they can sometimes
         * happen very quickly).
         */
        if (this.deliveryTiming === "now") {
          this.smsStatusUpdatedGQL
            .subscribe({
              gsmId: this.itemDetails.id,
              contactId: user.id,
            })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((subResponse) =>
              this.processSMSResponse(subResponse.data.smsStatusUpdated.contactId, subResponse.data.smsStatusUpdated.status),
            );
        }
      }
    });

    this.smsButtonLabel = "Sending Message";
    this.smsSent = true;

    if (this.deliveryTiming === "now") {
      const sendSMSVariables: SendSmsMutationVariables = {
        body: this.selectedTemplate.message,
        gsmId: this.itemDetails.id,
        recipients: recipientArray,
      };

      this.sendSmsGQL
        .mutate(sendSMSVariables)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response) => {
          this.smsButtonLabel = "Message Queued";
          this.showAwaitingAcknowledgement = true;
          this.uiAnalyticsService.sendAction(UiEventAction.SmsSent);

          response.data.sendSMS.forEach((sentSMS) => {
            this.processSMSResponse(sentSMS.contactId, sentSMS.status);
          });

          // Once the UI has received the response from the backend that the SMS has been queued,
          // start the smsTimer for the safety representative to keep track of how long they've waited
          if (!this.timer) {
            this.smsTimer = 1;
            this.timer = setInterval(() => {
              this.smsTimer += 1;
              if (this.smsTimer > this.smsTimeLimit) {
                this.smsTimeLimitExceeded = true;
              }
            }, 1000);
          }
        });
    } else {
      //send later
      //time is hardcoded to 8AM for now
      const sendSMSVariables: SendSmsMutationVariables = {
        body: this.selectedTemplate.message,
        gsmId: this.itemDetails.id,
        recipients: recipientArray,
        schedule: {
          targetDate: this.selectedSmsSendDate,
          targetTime: "08:00:00",
          timeZone: this.itemDetails.studentTimezone,
        },
      };

      this.sendSmsGQL
        .mutate(sendSMSVariables)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response) => {
          if (!response) {
            this.smsButtonLabel = "Failed to schedule message";
          } else {
            this.smsButtonLabel = "Message Scheduled";
            this.uiAnalyticsService.sendAction(UiEventAction.SmsScheduled);
          }
        });
    }
  }

  processSMSResponse(contactId: string, status: SmsMessageStatus): void {
    const respondentContact = this.filteredContacts.find((contact) => contact.id === contactId);

    if (status === SmsMessageStatus.Sent || status === SmsMessageStatus.Delivered) {
      this.smsButtonLabel = "Message Sent";
    }

    respondentContact.smsStatus = {
      status: status,
      statusDate: new Date(),
    };
  }

  startTimer(): void {
    if (!this.timer) {
      this.timer = global.setInterval(() => {
        this.copyTimer += 1;
      }, 1000);
    }
  }
}
