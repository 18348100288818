import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { ThemeService } from "src/app/shared/services/theme.service";

@Component({
  selector: "app-action-button",
  templateUrl: "./action-button.component.html",
  styleUrls: ["./action-button.component.scss"],
})
export class ActionButtonComponent {
  constructor(private theme: ThemeService) {}

  @Input() buttonType!: "affirmative" | "negative" | "negative-inverse";
  @Input() label!: string;
  @Input() icon!: string;
  @Input() iconSize = "L";
  @Input() disabled: boolean;
  @Input() tooltip?: string;
  @Input() hasDropdown = false;

  @Output() buttonClick = new EventEmitter();

  showTooltip = false;

  @HostListener("mouseenter") onHover(): void {
    if (this.tooltip && !this.showTooltip) {
      this.showTooltip = true;
    }
  }

  @HostListener("mouseleave") onHoverLeave(): void {
    if (this.tooltip) {
      this.showTooltip = false;
    }
  }

  onClick(event: Event): void {
    if (this.disabled) {
      event.stopPropagation();
    }
    this.buttonClick.emit();
  }

  get buttonColor(): string {
    return this.disabled ? this.theme.getVariable("--divider-color") : "";
  }

  get buttonTextColor(): string {
    return this.disabled ? this.theme.getVariable("--background-secondary-color") : "";
  }

  get chevronColor(): string {
    return this.buttonType === "negative-inverse" ? "#d60000" : "white";
  }
}
