<div class="subject">
    <app-input
        name="email-subject"
        label="Subject:"
        [(value)]="incident.payload.emailSubject"
        [placeholder]="placeholderSubject"
        [required]="true"></app-input>
</div>
<app-textarea
    [(value)]="incident.payload.emailBody"
    name="message-content"
    label="Message:"
    [placeholder]="placeholderText"
    required="true">
    <div class="populate-response">
        <ng-container *ngIf="hasEmailTemplates">
            <div
                class="title"
                [matMenuTriggerFor]="selectTemplateMenu">
                Populate Response
                <gaggle-icon
                    iconName="chevron"
                    iconState="down"></gaggle-icon>
            </div>
            <mat-menu
                backdropClass="template-menu"
                #selectTemplateMenu="matMenu"
                overlapTrigger="true"
                data-testid="response-menu"
                data-cy="TBD-menu">
                <div
                    class="menu-header"
                    (click)="$event.stopPropagation()">
                    Responses
                    <gaggle-toggle
                        *ngIf="hasMultipleIncidentTemplates"
                        label="Multiple Items"
                        data-testid="multiple-items-toggle"
                        [isToggleOn]="showMultiple"
                        (status)="onToggleMultiple()"></gaggle-toggle>
                </div>
                <button
                    [disableRipple]="true"
                    mat-menu-item
                    *ngFor="let option of templateOptions"
                    (click)="onClickTemplateOption(option)">
                    <span>{{ option }}</span>
                </button>
                <div *ngIf="moreTemplateOptions">
                    <div
                        class="other-menu-header"
                        (click)="$event.stopPropagation()">
                        Other Responses
                    </div>
                    <div *ngIf="showMoreTemplates">
                        <button
                            [disableRipple]="true"
                            mat-menu-item
                            *ngFor="let option of otherTemplateOptions"
                            (click)="onClickOtherTemplateOption(option)">
                            <span>{{ option }}</span>
                        </button>
                    </div>
                    <app-show-more-less-toggle
                        [showMore]="showMoreTemplates"
                        (click)="toggleShowMoreTemplates($event)"></app-show-more-less-toggle>
                </div>
            </mat-menu>
            <div
                class="clear"
                [ngStyle]="{ color: clearColor }"
                (click)="onClickClear()">
                Clear
            </div>
        </ng-container>
    </div>
</app-textarea>

<app-textarea
    name="internal-notes"
    label="Notes:"
    [(value)]="incident.payload.internalNotes"
    placeholder="Add relevant notes"
    [required]="incidentIsPSS"></app-textarea>
<div
    *ngIf="shouldDisplayAttachFiles"
    class="attachment-container">
    <ng-container *ngFor="let attachment of attachments">
        <gaggle-tooltip
            [alreadyFocusable]="true"
            [disableTooltip]="!attachmentShouldBeDisabled(attachment)">
            <ng-container tooltip> The file is unavailable, and cannot be attached. </ng-container>
            <app-checkbox
                [label]="attachmentName(attachment)"
                [disabled]="attachmentShouldBeDisabled(attachment)"
                (toggleCheckbox)="onClickAttachFile(attachment)"
                [checked]="attachment.include"></app-checkbox>
        </gaggle-tooltip>
    </ng-container>
</div>

<div class="button-container">
    <div class="submit-button">
        <gaggle-button
            [label]="submitLabel"
            [disabled]="disableSendEmail"
            (buttonClick)="onClickSubmit()"
            [backgroundColor]="buttonBackgroundColor"
            [textColor]="buttonTextColor"
            buttonStyle="full-width"></gaggle-button>
    </div>
    <div
        *ngIf="noRecipients"
        class="no-recipients">
        You cannot move forward without adding at least one recipient.
    </div>
    <div
        *ngIf="disableSendButton"
        class="after-hours-message">
        Sending emails has been disabled for this view.
    </div>
    <div
        *ngIf="isAfterHours"
        class="after-hours-message">
        <gaggle-pill
            class="after-hours-pill"
            data-testid="after-hours-pill"
            icon="after-hours"
            color="#e6e6ff"
            iconColor="#665cff"></gaggle-pill>
        {{ afterHoursMessage }}
    </div>
</div>
