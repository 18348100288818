import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EmergencyContactExtended } from "src/app/shared/services/emergency-contact.service";

@Component({
  selector: "app-after-hours-call-emergency-contact-row",
  templateUrl: "./after-hours-call-emergency-contact-row.component.html",
  styleUrls: ["./after-hours-call-emergency-contact-row.component.scss"],
})
export class AfterHoursCallEmergencyContactRowComponent implements OnInit {
  @Input() contact: EmergencyContactExtended;
  @Output() startSectionTimer = new EventEmitter();
  isDisabled;
  showContactDetails;

  constructor() {}

  ngOnInit(): void {
    this.showContactDetails = false;
    this.isDisabled = !this.contact.afterHours;
  }

  get jobTitle(): string {
    return this.contact.title || "N/A";
  }

  onToggleDisabled(): void {
    this.isDisabled = !this.isDisabled;
  }

  startCallSectionTimer(): void {
    this.startSectionTimer.emit();
  }
}
