import { inject, Injectable, Injector } from "@angular/core";
import { State, Action, StateContext, NgxsAfterBootstrap } from "@ngxs/store";
import * as SearchActions from "./search.actions";

import {
  GsmAppsyncApi,
  IncidentSearchParametersInputV2,
  IncidentSearchResultItemsFragment,
  IncidentSearchSortField,
  SearchIncidentsV2Query,
  SearchWorkItemsV2Query,
  SortOrder,
  WorkItemSearchParametersInputV2,
  WorkItemSearchResultItemsFragment,
  WorkItemSearchSortField,
} from "src/generated/graphql";

import { patch } from "@ngxs/store/operators";
import { AppSyncService } from "@shared/services/appsync.service";
import { Observable } from "rxjs";
import { ApolloQueryResult } from "apollo-client";

export enum SearchType {
  WorkItem = "workItem",
  Incident = "incident",
}

abstract class BaseItemSearchModel {
  total: number;
  searchActive: boolean;
  currentRecordId: string | null;
  pageCount: number;
}

const baseItemSearchDefaults = {
  total: 0,
  searchActive: false,
  currentRecordId: null,
  pageCount: 0,
  records: [],
  criteria: {
    pageNumber: 1,
    pageSize: 15,
  },
};

class WorkItemSearchStateModel extends BaseItemSearchModel {
  criteria: WorkItemSearchParametersInputV2;
  records: WorkItemSearchResultItemsFragment[];
}

class IncidentSearchStateModel extends BaseItemSearchModel {
  criteria: IncidentSearchParametersInputV2;
  records: IncidentSearchResultItemsFragment[];
}

export class SearchStateModel {
  public searchType: SearchType;
  public [SearchType.WorkItem]?: WorkItemSearchStateModel;
  public [SearchType.Incident]?: IncidentSearchStateModel;
}

const workItemSearchDefaults: WorkItemSearchStateModel = {
  ...baseItemSearchDefaults,
  criteria: {
    ...baseItemSearchDefaults.criteria,
    sort: {
      fieldName: WorkItemSearchSortField.CompletedDate,
      order: SortOrder.Descending,
    },
  },
};

const incidentSearchDefaults: IncidentSearchStateModel = {
  ...baseItemSearchDefaults,
  criteria: {
    ...baseItemSearchDefaults.criteria,
    sort: {
      fieldName: IncidentSearchSortField.ProcessedDate,
      order: SortOrder.Descending,
    },
  },
};

const defaults = {
  searchType: SearchType.WorkItem,
  [SearchType.WorkItem]: workItemSearchDefaults,
  [SearchType.Incident]: incidentSearchDefaults,
};

@State<SearchStateModel>({
  name: "search",
  defaults,
})
@Injectable()
export class SearchState implements NgxsAfterBootstrap {
  private injector = inject(Injector);
  private appSyncService!: AppSyncService;
  private gsmAppsyncApi!: GsmAppsyncApi;

  ngxsAfterBootstrap(): void {
    this.gsmAppsyncApi = this.injector.get(GsmAppsyncApi);
    this.appSyncService = this.injector.get(AppSyncService);
  }

  @Action([SearchActions.SetSearchTypeFromSearchComponent])
  setSearchType({ patchState }: StateContext<SearchStateModel>, { searchType }: SearchActions.SetSearchType): void {
    patchState({ searchType: searchType });
  }

  @Action([SearchActions.PerformSearchFromSearchForm, SearchActions.PerformSearchFromSearchState])
  performSearch({ getState, setState }: StateContext<SearchStateModel>, { criteria, bypassSpinner }: SearchActions.PerformSearch): void {
    const searchType = getState().searchType;
    const context = { bypassSpinner: bypassSpinner };
    const previousCriteria = getState()[searchType].criteria ?? {
      pageNumber: defaults[searchType].criteria.pageNumber,
      pageSize: defaults[searchType].criteria.pageSize,
      sort: defaults[searchType].criteria.sort,
    };

    let queryName: string;
    let recordKey: string;
    let query: Observable<Partial<ApolloQueryResult<SearchWorkItemsV2Query | SearchIncidentsV2Query>>>;
    let searchParameters: WorkItemSearchParametersInputV2 | IncidentSearchParametersInputV2;

    if (searchType === SearchType.WorkItem) {
      queryName = "searchWorkItemsV2";
      recordKey = "workItems";

      searchParameters = {
        pageNumber: previousCriteria.pageNumber,
        pageSize: previousCriteria.pageSize,
        sort: previousCriteria.sort,
        ...criteria,
      } as WorkItemSearchParametersInputV2;

      query = this.gsmAppsyncApi.searchWorkItemsV2({ searchParameters }, { context });
    } else if (searchType === SearchType.Incident) {
      queryName = "searchIncidentsV2";
      recordKey = "incidents";

      searchParameters = {
        pageNumber: previousCriteria.pageNumber,
        pageSize: previousCriteria.pageSize,
        sort: previousCriteria.sort,
        ...criteria,
      } as IncidentSearchParametersInputV2;

      query = this.gsmAppsyncApi.searchIncidentsV2({ searchParameters }, { context });
    }

    setState(
      patch({
        [searchType]: patch({
          searchActive: true,
          criteria: searchParameters,
        }),
      }),
    );

    query.subscribe({
      next: (result) => {
        const data = result.data?.[queryName] ?? {};
        setState(
          patch({
            [searchType]: patch({
              records: data[recordKey] ?? [],
              searchActive: false,
              total: data.total ?? 0,
              pageCount: Math.ceil(data.total / searchParameters.pageSize) ?? 1,
            }),
          }),
        );
      },
      error: () => {
        setState(patch({ [searchType]: patch({ searchActive: true }) }));
      },
    });
  }

  @Action([SearchActions.SetSearchSortFromSearchResultsComponent])
  setSearchSort(
    { getState, setState, dispatch }: StateContext<SearchStateModel>,
    { sortFieldName, direction }: SearchActions.SetSearchSort,
  ): void {
    // Translate the direction into the GraphQL enum values
    const sortOrder = direction === "asc" ? SortOrder.Ascending : SortOrder.Descending;
    const searchType = getState().searchType;

    setState(
      patch({
        [searchType]: patch({
          criteria: patch({
            sort: {
              fieldName: sortFieldName,
              order: sortOrder,
            },
          }),
        }),
      }),
    );

    dispatch(new SearchActions.PerformSearchFromSearchState(getState()[searchType].criteria));
  }

  @Action([SearchActions.SetSearchPageNumberFromSearchResultsComponent])
  setSearchPage({ getState, setState, dispatch }: StateContext<SearchStateModel>, { page }: SearchActions.SetSearchPage): void {
    const searchType = getState().searchType;
    setState(
      patch({
        [searchType]: patch({
          criteria: patch({
            pageNumber: page.pageIndex + 1,
            pageSize: page.pageSize,
          }),
        }),
      }),
    );

    dispatch(new SearchActions.PerformSearchFromSearchState(getState()[searchType].criteria));
  }

  @Action([SearchActions.SetCurrentRecordIdFromSearchResultsComponent, SearchActions.SetCurrentRecordIdFromSearchState])
  setRecordId({ getState, setState }: StateContext<SearchStateModel>, { id }: SearchActions.SetCurrentRecordId): void {
    const searchType = getState().searchType;
    setState(patch({ [searchType]: patch({ currentRecordId: id }) }));
  }

  @Action([SearchActions.NavigateSearchRecordFromSearchNavigationComponent])
  navigateSearchRecord({ getState, dispatch }: StateContext<SearchStateModel>, { direction }: SearchActions.NavigateSearchRecord): void {
    const searchType = getState().searchType;
    const { records, currentRecordId } = getState()[searchType];
    const idFieldName = searchType === SearchType.WorkItem ? "id" : "incidentId";

    const currentRecordIndex = records.findIndex((r) => r[idFieldName] === currentRecordId);
    const nextRecordId = records[currentRecordIndex + direction][idFieldName];

    this.appSyncService.getWorkItemAndIncidentByGsmId(nextRecordId).then(() => {
      dispatch(new SearchActions.SetCurrentRecordIdFromSearchState(nextRecordId));
    });
  }

  @Action([SearchActions.ClearSearchFromSearchForm])
  clearSearch({ getState, setState }: StateContext<SearchStateModel>): void {
    const searchType = getState().searchType;
    setState(
      patch({
        [searchType]: defaults[searchType],
      }),
    );
  }
}
