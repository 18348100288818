import { ConfigService } from "src/app/shared/services/config.service";
import { SplitService } from "@splitsoftware/splitio-angular";
import { FeatureFlagName, FeatureFlagValue } from "src/app/models/feature-flags.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { getMainDefinition } from "@apollo/client/utilities";
import { DocumentNode } from "graphql";

export const isSubscriptionOperation = ({ query }: { query: DocumentNode }): boolean => {
  const definition = getMainDefinition(query);
  return definition.kind === "OperationDefinition" && definition.operation === "subscription";
};

export const getBaseApiUrl = (splitService: SplitService, configService: ConfigService, authService: AuthService): string => {
  const username = authService.getUsername();

  const urlOverride = splitService.getTreatment(FeatureFlagName.APPSYNC_URL_OVERRIDE, {
    username: username,
  });

  let baseApiUrl = configService.getConfig().baseApiUrl;
  if (urlOverride !== FeatureFlagValue.NOT_SET && urlOverride !== FeatureFlagValue.OFF) {
    baseApiUrl = `https://${urlOverride}.appsync-api.us-west-2.amazonaws.com/graphql`;
  }

  return baseApiUrl;
};

export const BYPASS_SPINNER = {
  context: {
    bypassSpinner: true,
  },
};
