import gql from "graphql-tag";

export default gql`
  query getPriorIncidents($userId: String!) {
    getPriorIncidents(userId: $userId) {
      total
      incidents {
        workItemId
        processedDate
        warningType
        actionReasons
      }
    }
  }
`;
