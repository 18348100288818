import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import {
  Incident,
  AcknowledgmentSource,
  EmergencyContactAvailability,
  GetIncidentProcessingDataGQL,
  IncidentProcessingEmergencyContact,
  IncidentProcessingAcknowledgment,
  IncidentProcessingCommunication,
  IncidentProcessingData,
  IncidentProcessingSalesforceCase,
  IncidentCommunicationType,
} from "src/generated/graphql";
import {
  EmergencyContactService,
  EmergencyContactExtended,
  EmergencyContactEmailResult,
  EmergencyContactCallResult,
  EmergencyContactTextResult,
  FilteredEmergencyContacts,
} from "src/app/shared/services/emergency-contact.service";
import { Subject, takeUntil } from "rxjs";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import {
  SendSmsDialogComponent,
  SendSmsDialogData,
  SendSmsDialogRecipient,
} from "src/app/shared/components/send-sms-dialog/send-sms-dialog.component";
import { BYPASS_SPINNER } from "src/app/features/appsync/utils";

@Component({
  selector: "app-incident-contact-communications-dialog",
  templateUrl: "./incident-contact-communications-dialog.component.html",
  styleUrls: ["./incident-contact-communications-dialog.component.scss"],
})
export class IncidentContactCommunicationsDialogComponent implements OnInit, OnDestroy {
  private emergencyContactService = inject(EmergencyContactService);
  private matDialog = inject(MatDialog);
  private getIncidentProcessingDataGQL = inject(GetIncidentProcessingDataGQL);
  private data: { incidentDetails: Incident } = inject(MAT_DIALOG_DATA);

  private unsubscribe$ = new Subject<void>();
  public contacts: {
    incident?: Partial<EmergencyContactExtended>[];
    current?: EmergencyContactExtended[];
  };
  public loading = true;
  public communicationsSource: "workerCommunications" | "incidentProcessing";
  public showContactsWithAvailability: "incident" | "current" = "incident";
  public noIncidentContacts = false;
  public contactsSelected = 0;
  public allContactsSelected = false;
  public smsContactsSelected = 0;
  public salesforceCaseUrl: string;

  ngOnDestroy(): void {
    this.clearContactSelections();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.emergencyContactService.filteredContacts$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((contacts) => this.enrichEmergencyContacts(contacts));
  }

  enrichEmergencyContacts(contacts: FilteredEmergencyContacts): void {
    if (!contacts || !contacts.all?.length) {
      this.contacts = { current: [] };
      return;
    }

    this.contacts = { current: contacts.all };

    if (this.data.incidentDetails?.workerCommunications?.contacts?.length) {
      this.communicationsSource = "workerCommunications";
      this.processIncidentWorkerCommunications(contacts.all);
    } else {
      this.communicationsSource = "incidentProcessing";
      this.fetchIncidentProcessingData();
    }
  }

  fetchIncidentProcessingData(): void {
    this.getIncidentProcessingDataGQL
      .fetch({ incidentId: this.data.incidentDetails.incidentId }, { ...BYPASS_SPINNER })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        const incidentProcessingData = result.data?.getIncidentProcessingData ?? null;

        if (incidentProcessingData) {
          const { availability, communication, acknowledgement, salesforce } = this.splitProcessingData(
            incidentProcessingData as IncidentProcessingData[],
          );

          if (salesforce.length) {
            this.salesforceCaseUrl = salesforce[0].caseUrl;
          }

          // Start with the availability data, as it's the driving force behind all the subsequent data.
          this.contacts.incident = availability
            .sort((a, b) => {
              // Sort by sortIndex unless sortIndex is -1, those go to the bottom
              if (a.sortIndex === -1) {
                return 1;
              }
              if (b.sortIndex === -1) {
                return -1;
              }
              return a.sortIndex - b.sortIndex;
            })
            .map((ic) => {
              // See if there is a matching contact in the current contacts list.
              const ec = this.contacts.current.find((c) => c.id === ic.ecId);

              // Filter out the communication array once and use it below
              const ecCommunications = communication.filter((c) => c.ecId === ic.ecId);

              // Build the incident Contact object that will be merged with the EC object.
              const incidentContact = {
                id: ic.ecId,
                name: ic.ecName,
                districtContact: ic.districtContact,
                afterHours: ic.afterHours,
                dispatch: ic.dispatch,
                availability: {
                  email: ic.availability.email,
                  text: ic.availability.text,
                  phone: ic.availability.call,
                  digest: ic.availability.digest,
                  categories: ic.availability.categories,
                },
                communications: {
                  email: this.getEmailResult(ecCommunications),
                  call: this.getCallResult(ecCommunications),
                  text: this.getTextResult(ecCommunications),
                  acknowledgment: this.getAcknowledgementResult(ic.ecId, acknowledgement),
                  callLog: ecCommunications.filter((c) => c.communicationType === IncidentCommunicationType.CallAnswered),
                },
              };

              // If the EC is not found, we still want to display the contact, but we won't be able to display the
              // extended information about the contact as it's not stored in the incident.
              if (ec) {
                return {
                  ...ec,
                  ...incidentContact,
                };
              } else {
                return incidentContact;
              }
            });
        }

        this.loading = false;
        this.noIncidentContacts = !this.contacts.incident?.length;
      });
  }

  splitProcessingData(incidentProcessingData: IncidentProcessingData[]): {
    availability: IncidentProcessingEmergencyContact[];
    communication: IncidentProcessingCommunication[];
    acknowledgement: IncidentProcessingAcknowledgment[];
    salesforce: IncidentProcessingSalesforceCase[];
  } {
    const availability: IncidentProcessingEmergencyContact[] = [];
    const communication: IncidentProcessingCommunication[] = [];
    const acknowledgement: IncidentProcessingAcknowledgment[] = [];
    const salesforce: IncidentProcessingSalesforceCase[] = [];

    incidentProcessingData.forEach((item) => {
      switch (item.__typename) {
        case "IncidentProcessingEmergencyContact":
          availability.push(item);
          break;
        case "IncidentProcessingCommunication":
          communication.push(item);
          break;
        case "IncidentProcessingAcknowledgment":
          acknowledgement.push(item);
          break;
        case "IncidentProcessingSalesforceCase":
          salesforce.push(item);
          break;
      }
    });

    return {
      availability: availability,
      communication: communication,
      acknowledgement: acknowledgement,
      salesforce: salesforce,
    };
  }

  getAcknowledgementResult(ecId: string, data: IncidentProcessingAcknowledgment[]): AcknowledgmentSource {
    return data.find((item) => item.ecId === ecId)?.source ?? null;
  }

  getCallResult(data: IncidentProcessingCommunication[]): EmergencyContactCallResult {
    const callWasAnswered = data.find((item) => item.communicationType === IncidentCommunicationType.CallAnswered);
    if (callWasAnswered) {
      return EmergencyContactCallResult.ANSWERED;
    }

    const callWasMade = data.find((item) => item.communicationType === IncidentCommunicationType.CallNotAnswered);
    if (callWasMade) {
      return EmergencyContactCallResult.ATTEMPTED;
    }

    return EmergencyContactCallResult.NONE;
  }

  getTextResult(data: IncidentProcessingCommunication[]): EmergencyContactTextResult {
    const textWasScheduled = data.find((item) => item.communicationType === IncidentCommunicationType.TextScheduled);
    if (textWasScheduled) {
      return EmergencyContactTextResult.SCHEDULED;
    }

    const textWasReplied = data.find((item) => item.communicationType === IncidentCommunicationType.TextReplied);
    if (textWasReplied) {
      return EmergencyContactTextResult.REPLIED;
    }

    const textWasSent = data.find((item) => item.communicationType === IncidentCommunicationType.TextSent);
    if (textWasSent) {
      return EmergencyContactTextResult.SENT;
    }

    return EmergencyContactTextResult.NONE;
  }

  getEmailResult(data: IncidentProcessingCommunication[]): EmergencyContactEmailResult {
    const emailWasSent = data.find((item) => item.communicationType === IncidentCommunicationType.EmailSent);

    if (emailWasSent) {
      return EmergencyContactEmailResult.SENT;
    } else {
      return EmergencyContactEmailResult.NONE;
    }
  }

  processIncidentWorkerCommunications(contacts: EmergencyContactExtended[]): void {
    this.contacts.incident = this.data.incidentDetails.workerCommunications?.contacts?.map((ic) => {
      const ec = contacts.find((ec) => ec.id === ic.id);

      const incidentContact = {
        id: ic.id,
        name: ic.name,
        districtContact: ic.districtContact,
        afterHours: ic.afterHours,
        dispatch: ic.dispatch,
        availability: ic.availability as unknown as EmergencyContactAvailability,
        communications: {
          email: EmergencyContactEmailResult[ic.communications.email],
          call: EmergencyContactCallResult[ic.communications.call],
          text: EmergencyContactTextResult[ic.communications.text],
          acknowledgment: AcknowledgmentSource[ic.communications.acknowledgment],
        },
      };

      // If the EC is not found, we still want to display the contact, but we won't be able to display the
      // extended information about the contact as it's not stored in the incident.
      if (ec) {
        return {
          ...ec,
          ...incidentContact,
        };
      } else {
        return incidentContact;
      }
    });

    this.loading = false;
    this.noIncidentContacts = !this.contacts.incident?.length;
  }

  onAvailabilityChange(event: MatButtonToggleChange): void {
    this.updateContactSelections(event.value);
  }

  private clearContactSelections(): void {
    Object.keys(this.contacts).forEach((key) => {
      this.contacts[key]?.forEach((c) => (c.selected = false));
    });
    this.updateContactSelections();
  }

  private updateContactSelections(forAvailability = this.showContactsWithAvailability): void {
    this.showContactsWithAvailability = forAvailability;
    this.contactsSelected = this.contacts[forAvailability]?.filter((c) => c.selected).length ?? 0;
    if (this.contactsSelected === 0) {
      this.smsContactsSelected = 0;
      this.allContactsSelected = false;
    } else {
      this.smsContactsSelected = this.getSelectedContactsForSms().length;
      this.allContactsSelected = this.contactsSelected === this.contacts[forAvailability]?.length;
    }
  }

  toggleContactSelection(contact: Partial<EmergencyContactExtended>): void {
    contact.selected = !contact.selected;
    this.updateContactSelections();
  }

  onSelectAllContactsChange(change: MatCheckboxChange): void {
    this.contacts[this.showContactsWithAvailability].forEach((c) => (c.selected = change.checked));
    this.updateContactSelections();
  }

  makeCall(event: MouseEvent, phoneNumber: string): void {
    event.stopPropagation();
    window.location.href = `tel:${phoneNumber}`;
  }

  private getSelectedContactsForSms(): SendSmsDialogRecipient[] {
    return this.contacts[this.showContactsWithAvailability]
      .filter((c) => c.selected)
      .filter((c) => c.mobilePhone)
      .map((contact) => {
        return {
          id: contact.id,
          name: contact.name,
          mobilePhone: {
            countryCode: contact.unformattedMobilePhone.countryCode,
            areaCode: contact.unformattedMobilePhone.areaCode,
            number: contact.unformattedMobilePhone.number,
          },
        };
      });
  }

  sendTextMessage(messageType: "incident" | "test"): void {
    const data: SendSmsDialogData = {
      smsType: messageType,
      recipients: this.getSelectedContactsForSms(),
      isResend: true,
      gsmId: this.data.incidentDetails.incidentId,
    };
    this.matDialog.open(SendSmsDialogComponent, {
      disableClose: true,
      panelClass: "send-sms-dialog",
      data: data,
    });
  }
}
