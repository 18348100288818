<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
    <p>{{ data.content }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        *ngIf="data.dismissButtonText"
        mat-button
        mat-dialog-close>
        {{ data.dismissButtonText }}
    </button>
    <button
        *ngIf="data.okButtonText"
        mat-stroked-button
        [mat-dialog-close]="true"
        cdkFocusInitial>
        {{ data.okButtonText }}
    </button>
</mat-dialog-actions>
