import { APP_INITIALIZER, NgModule, ErrorHandler } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavBarComponent } from "./components/nav-bar/nav-bar.component";
import { QueueLeftSidebarComponent } from "./components/queue-left-sidebar/queue-left-sidebar.component";
import { IncidentResponseRightDrawerComponent } from "./components/incident-response-right-drawer/incident-response-right-drawer.component";
import { AuthService } from "@shared/services/auth.service";
import { AppSyncService } from "@shared/services/appsync.service";
import { PlatformModule } from "@angular/cdk/platform";
import { UnauthenticatedComponent } from "./components/unauthenticated/unauthenticated.component";
import { AuthenticatedComponent } from "./components/authenticated/authenticated.component";
import { ThemeService } from "@shared/services/theme.service";
import { ReviewComponent } from "./components/review/review.component";
import { AppsyncClientService } from "src/app/shared/services/appsync-client.service";
import { IncidentSummaryComponent } from "./components/incident-summary/incident-summary.component";
import { EmailContactPillComponent } from "./components/incident-summary/email-contact-pill/email-contact-pill.component";
import { WorkItemSummaryComponent } from "./components/work-item-summary/work-item-summary.component";
import { SharedModule } from "src/app/shared/shared.module";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppsyncModule } from "src/app/features/appsync/appsync.module";
import { ConfigService } from "src/app/shared/services/config.service";
import { SplitService } from "@splitsoftware/splitio-angular";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "src/environments/environment";
import { SwUpdateService } from "src/app/core/services/sw-update.service";
import { NgxsModule } from "@ngxs/store";
import { IncidentState } from "src/app/shared/state/incident/incident.state";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { SearchState } from "src/app/shared/state/search/search.state";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { SearchNavigationComponent } from "@shared/components/search-navigation/search-navigation.component";
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";
import { StateService } from "@shared/services/state.service";
import { SnackbarService } from "@core/services/snackbar.service";
import { AppInitializerFactory } from "@core/factories/initializer.factory";

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    QueueLeftSidebarComponent,
    IncidentResponseRightDrawerComponent,
    UnauthenticatedComponent,
    AuthenticatedComponent,
    ReviewComponent,
    IncidentSummaryComponent,
    EmailContactPillComponent,
    WorkItemSummaryComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PlatformModule,
    AppRoutingModule,
    AppsyncModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.name !== "local",
      registrationStrategy: "registerImmediately",
    }),

    NgxsModule.forRoot([IncidentState, SearchState], {
      developmentMode: environment.name === "local",
    }),

    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.name === "production",
    }),
    ClipboardModule,
    SearchNavigationComponent,
  ],
  providers: [
    AuthService,
    AppsyncClientService,
    AppSyncService,
    ThemeService,
    SnackbarService,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [ConfigService, AuthService, StateService, SplitService, ThemeService, SwUpdateService],
      multi: true,
    },
    { provide: APP_INITIALIZER, useFactory: () => () => undefined, deps: [Sentry.TraceService], multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
