import gql from "graphql-tag";

export default gql`
  query getWorkItemStatus($workItemId: ID!) {
    getWorkItemStatus(workItemId: $workItemId) {
      uuid
      state
      locked
    }
  }
`;
