import { NgModule } from "@angular/core";
import { ApolloLink, DefaultOptions, InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { APOLLO_FLAGS, APOLLO_NAMED_OPTIONS, ApolloModule, NamedOptions } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { AuthService } from "src/app/shared/services/auth.service";
import { ClientErrorService } from "src/app/shared/services/client-error.service";
import { ConfigService } from "src/app/shared/services/config.service";
import { StateService } from "src/app/shared/services/state.service";
import { SplitService } from "@splitsoftware/splitio-angular";
import { createStatusLink } from "src/app/features/appsync/create-status-link";
import { getBaseApiUrl } from "src/app/features/appsync/utils";
import { createErrorLink } from "src/app/features/appsync/create-error-link";
import { createRetryLink } from "src/app/features/appsync/create-retry-link";
import { createHybridLink } from "src/app/features/appsync/create-hybrid-link";
import { createStripTypenameLink } from "src/app/features/appsync/create-strip-typename-link";

function createApollo(
  httpLink: HttpLink,
  configService: ConfigService,
  authService: AuthService,
  clientErrorService: ClientErrorService,
  stateService: StateService,
  splitService: SplitService,
): NamedOptions {
  const authHeaders = setContext((_operation, prevContext) => ({
    headers: {
      ...prevContext.headers,
      Authorization: `${authService.getAuthToken()}`,
    },
  }));

  const baseHeaders = setContext(() => ({
    headers: {
      "Accept": "charset=utf-8",
      "ngsw-bypass": "",
    },
  }));

  const uiSessionIdHeader = setContext((_operation, prevContext) => ({
    headers: {
      ...prevContext.headers,
      "X-Gaggle-UI-Session-ID": stateService.uiSessionId,
    },
  }));

  const defaultOptions: DefaultOptions = {
    watchQuery: { fetchPolicy: "no-cache", errorPolicy: "all" },
    query: { fetchPolicy: "no-cache", errorPolicy: "all" },
  };

  const cache = new InMemoryCache();
  const baseApiUrl = getBaseApiUrl(splitService, configService, authService);
  const statusLink = createStatusLink(stateService);
  const errorLink = createErrorLink(authService, clientErrorService);
  const retryLink = createRetryLink();
  const hybridLink = createHybridLink(baseApiUrl, authService, httpLink);

  // TODO: Remove after upgrading to Apollo 3.8+
  //  Until the apollo library can get updated to at least 3.8, we need to manually
  //  strip the __typename from the operation as it's not part of the appsync schema
  //  but rather an augmented field provided by the apollo library.
  const stripTypeNameLink = createStripTypenameLink();

  // Build named clients to be used in the application
  const unauthedApiLink = ApolloLink.from([hybridLink]);
  const hybridLinkNoStatus = ApolloLink.from([
    stripTypeNameLink,
    retryLink,
    errorLink,
    baseHeaders,
    uiSessionIdHeader,
    authHeaders,
    hybridLink,
  ]);
  const hybridLinkWithStatus = ApolloLink.from([statusLink, hybridLinkNoStatus]);

  return {
    default: {
      link: hybridLinkWithStatus,
      cache: cache,
      defaultOptions: defaultOptions,
    },
    noStatus: {
      link: hybridLinkNoStatus,
      cache: cache,
      defaultOptions: defaultOptions,
    },
    noAuth: {
      link: unauthedApiLink,
      cache: cache,
      defaultOptions: defaultOptions,
    },
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ConfigService, AuthService, ClientErrorService, StateService, SplitService],
    },
    { provide: APOLLO_FLAGS, useValue: { useInitialLoading: true } },
  ],
})
export class AppsyncModule {}
