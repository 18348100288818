import { AfterContentInit, ChangeDetectorRef, Component, ContentChild, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { PanelHeaderDirective } from "./panel-header.directive";
import { PanelContentDirective } from "./panel-content.directive";
import { PanelButtonsDirective } from "./panel-buttons.directive";
import { ThemeService } from "src/app/shared/services/theme.service";
import { PanelFooterDirective } from "src/app/components/item-viewer/expandable-panel/panel-footer.directive";

@Component({
  selector: "app-expandable-panel",
  templateUrl: "./expandable-panel.component.html",
  styleUrls: ["./expandable-panel.component.scss"],
})
export class ExpandablePanelComponent implements OnInit, OnChanges, AfterContentInit {
  @Input() hideToggle = false;
  @Input() isExpanded = false;

  expanded = false;

  @ContentChild(PanelHeaderDirective) panelHeader: PanelHeaderDirective;
  @ContentChild(PanelContentDirective) panelContent: PanelContentDirective;
  @ContentChild(PanelButtonsDirective) panelButtons: PanelButtonsDirective;
  @ContentChild(PanelFooterDirective) panelFooter: PanelFooterDirective;

  constructor(private changeDetector: ChangeDetectorRef, private themeService: ThemeService) {}

  ngOnInit(): void {
    this.expanded = this.isExpanded;
    this.hideToggle = !this.panelContent?.templateRef;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isExpanded) {
      this.expanded = changes.isExpanded.currentValue;
    }
  }

  ngAfterContentInit(): void {
    this.hideToggle = !this.panelContent?.templateRef;
    this.changeDetector.detectChanges();
  }

  togglePanel(): void {
    this.expanded = !this.expanded;
  }

  get toggleColor(): string {
    return this.themeService.getVariable("--text-primary-color");
  }
}
