<div class="text-emergency-contacts-container" data-testid="text-emergency-contacts-component" *ngIf="!modalDisplay">
    <div class="header">
        <button
            mat-icon-button
            [matMenuTriggerFor]="textOptionsMenu"
            *ngIf="expand && !isInAppSMSEnabled"
            class="toggle-menu"
            data-testid="trigger-unavailable-menu">
            <gaggle-icon iconName="menu-ellipsis" iconSize="2em"></gaggle-icon>
        </button>
        <div *ngIf="expand && timer && !isInAppSMSEnabled" class="timer">
            <span class="red-text">{{ formattedTimer }} sec</span>
            since sending
        </div>
    </div>

    <mat-menu #textOptionsMenu="matMenu">
        <button mat-menu-item class="show-all-contacts" (click)="toggleUnavailableContacts()">
            <span>Show Unavailable Contacts</span>
            <mat-icon *ngIf="showUnavailableContacts">check</mat-icon>
        </button>
    </mat-menu>

    <div *ngIf="expand" class="expandable-content">
        <!-- l2ui_text_messaging is off -->
        <section class="sms-disabled" *ngIf="!isL2SMSEnabled">
            <h2>Text messaging is currently unavailable</h2>
        </section>

        <!-- l2ui_text_messaging is on -->
        <section *ngIf="isL2SMSEnabled">
            <div class="contacts" *ngIf="!isInAppSMSEnabled">
                <div *ngFor="let contact of filteredContacts" class="contact">
                    <app-text-emergency-contact-row
                        *ngIf="!isAfterHours"
                        [contact]="contact"
                        (copyPhoneNumber)="copyValue($event)"></app-text-emergency-contact-row>
                    <app-after-hours-text-emergency-contact-row
                        *ngIf="isAfterHours"
                        [contact]="contact"
                        (copyPhoneNumber)="copyValue($event)"></app-after-hours-text-emergency-contact-row>
                </div>
            </div>

            <div class="contacts" *ngIf="isInAppSMSEnabled">
                <div class="mid-content-separator"></div>

                <div class="sms-section notification-delivery">
                    <div class="section-title">Notification Delivery:</div>
                    <mat-radio-group class="radio-group" [(ngModel)]="deliveryTiming" (change)="toggleSendTime($event)">
                        <mat-radio-button disableRipple class="radio-button" value="now" color="primary">Send Immediately</mat-radio-button>

                        <mat-radio-button
                            disableRipple
                            class="radio-button"
                            value="later"
                            color="primary"
                            [disabled]="!afterHoursTemplatesAvailable">
                            Schedule to Send Later
                        </mat-radio-button>
                    </mat-radio-group>

                    <div class="date-time" *ngIf="deliveryTiming === 'later'">
                        <mat-form-field
                            class="gaggle-form-field gaggle-date-picker"
                            floatLabel="always"
                            appearance="outline"
                            (click)="picker.open()">
                            <mat-label>Send Date</mat-label>
                            <input
                                matInput
                                [min]="minDeliveryDate"
                                [matDatepicker]="picker"
                                (click)="picker.open()"
                                readonly
                                (dateInput)="onSelectDate($event)" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <span class="sms-disclaimer">*This message will be sent on 8:00AM of the selected date</span>
                    </div>
                </div>

                <div class="mid-content-separator"></div>

                <div class="sms-section message">
                    <div class="section-title">Message Body:</div>

                    <div class="response-header">
                        <div class="populate-response">
                            <div class="title" [matMenuTriggerFor]="selectSMSTemplateMenu">
                                Populate Response
                                <gaggle-icon iconName="chevron" iconState="down"></gaggle-icon>
                            </div>
                            <mat-menu #selectSMSTemplateMenu="matMenu" overlapTrigger="true">
                                <ng-container *ngIf="filteredSMSTemplates">
                                    <button
                                        *ngFor="let template of filteredSMSTemplates"
                                        mat-menu-item
                                        (click)="onClickTemplateOption(template)">
                                        {{ template.dropdownOption }}
                                    </button>
                                </ng-container>
                                <button mat-menu-item (click)="onClickRefreshTemplates()">
                                    <i>Refresh Templates</i>
                                    <mat-icon>refresh</mat-icon>
                                </button>
                            </mat-menu>
                        </div>
                        <gaggle-pill icon="quarantine"></gaggle-pill>
                    </div>

                    <textarea
                        disabled
                        [value]="selectedTemplate.message"
                        class="sms-body"
                        [ngClass]="{ 'dark-mode': useDarkMode }"></textarea>
                </div>

                <div class="mid-content-separator"></div>

                <section class="action-bar">
                    <span class="bar-title">Available Contacts</span>

                    <div class="button-group">
                        <gaggle-button
                            class="action-button"
                            buttonStyle="operation"
                            label="Select All"
                            (buttonClick)="selectAll()"
                            [disabled]="smsSent"></gaggle-button>

                        <gaggle-button
                            class="action-button"
                            buttonStyle="operation"
                            label="Clear"
                            (buttonClick)="clearAll()"
                            [disabled]="smsSent"></gaggle-button>
                    </div>
                </section>

                <section class="contacts-container">
                    <div
                        *ngFor="let contact of filteredContacts; let i = index"
                        class="contact"
                        [ngClass]="{ 'dark-mode': useDarkMode }"
                        [hidden]="smsSent && !contact.selected">
                        <app-text-emergency-contact-row-integrated
                            [checked]="contact.selected"
                            [contact]="contact"
                            [disabled]="smsSent"
                            [smsStatus]="contact.smsStatus"
                            (selected)="(contact.selected)"
                            (click)="contactSelected(i)"></app-text-emergency-contact-row-integrated>
                    </div>
                </section>

                <div class="mid-content-separator"></div>
                <div class="sms-section message">
                    <div class="section-title">Issue Incident Notifications ({{ numberOfSelected }}):</div>
                    <div [@InOut] class="awaiting-acknowledgement standby" *ngIf="showAwaitingAcknowledgement && !acknowledgedBy">
                        Awaiting Acknowledgement
                        <span class="timer" *ngIf="timer" [ngClass]="smsTimeLimitExceeded ? 'expired' : null">
                            ({{ smsTimer | formatTimer }})
                        </span>
                    </div>

                    <div [@InOut] class="awaiting-acknowledgement received" *ngIf="showAwaitingAcknowledgement && acknowledgedBy">
                        Acknowledgement Received!
                    </div>
                    <gaggle-button
                        icon="send-sms"
                        [buttonStyle]="['primary', 'large', 'full-width']"
                        iconSize="L"
                        label="{{ smsButtonLabel }}"
                        [disabled]="disableSMSButton"
                        [backgroundColor]="buttonBackgroundColor"
                        [textColor]="buttonTextColor"
                        (buttonClick)="sendSMS()"></gaggle-button>
                </div>
            </div>
        </section>
    </div>
</div>

<div *ngIf="modalDisplay" class="modal-contact-container" data-testid="modal-contacts">
    <div class="header">
        <div>Text Emergency Contacts</div>
        <button mat-icon-button [matMenuTriggerFor]="textOptionsMenu" class="toggle-menu" data-testid="trigger-unavailable-menu">
            <gaggle-icon iconName="menu-ellipsis" iconSize="2em"></gaggle-icon>
        </button>
    </div>

    <mat-menu #textOptionsMenu="matMenu">
        <button mat-menu-item class="show-all-contacts" (click)="toggleUnavailableContacts()">
            <span>Show Unavailable Contacts</span>
            <mat-icon *ngIf="showUnavailableContacts">check</mat-icon>
        </button>
    </mat-menu>

    <div class="contacts" *ngIf="!isInAppSMSEnabled">
        <ng-container *ngFor="let contact of filteredContacts">
            <app-text-emergency-contact-row
                *ngIf="!isAfterHours"
                [contact]="contact"
                (copyPhoneNumber)="copyValue($event)"></app-text-emergency-contact-row>
            <app-after-hours-text-emergency-contact-row
                *ngIf="isAfterHours"
                [contact]="contact"
                (copyPhoneNumber)="copyValue($event)"></app-after-hours-text-emergency-contact-row>
        </ng-container>
        <!--            <gaggle-button-->
        <!--                label="Copy All Phone Numbers"-->
        <!--                buttonStyle="full-width"-->
        <!--                (buttonClick)="copyAllNumbers()"-->
        <!--                *ngIf="false"-->
        <!--                icon="copy"-->
        <!--                iconSize="L"-->
        <!--                class="copy-all-button"-->
        <!--                [backgroundColor]="buttonBackgroundColor"-->
        <!--                [textColor]="buttonTextColor"-->
        <!--                [iconColor]="buttonTextColor"-->
        <!--            ></gaggle-button>-->
    </div>

    <div class="contacts" *ngIf="isInAppSMSEnabled">
        <ng-container *ngFor="let contact of filteredContacts">
            <app-text-emergency-contact-row-integrated
                *ngIf="!isAfterHours"
                [contact]="contact"
                (copyPhoneNumber)="copyValue($event)"></app-text-emergency-contact-row-integrated>
        </ng-container>
    </div>
</div>
