import { Component, Input } from "@angular/core";

@Component({
  selector: "app-show-more-less-toggle",
  templateUrl: "./show-more-less-toggle.component.html",
  styleUrls: ["./show-more-less-toggle.component.scss"],
})
export class ShowMoreLessToggleComponent {
  @Input() showMore = false;

  constructor() {}

  get descriptor(): string {
    return this.showMore ? "Less" : "More";
  }
}
