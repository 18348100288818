import { Component, Input } from "@angular/core";
import { DownloadInfo } from "src/app/models/work-item.model";

@Component({
  selector: "app-file-preview-panel",
  templateUrl: "./file-preview-panel.component.html",
  styleUrls: ["./file-preview-panel.component.scss"],
})
export class FilePreviewPanelComponent {
  @Input() fileDownloadInfo: DownloadInfo;
  @Input() fileName: string;
  @Input() isPDF: boolean;
  @Input() isImage: boolean;
  @Input() isOversized: boolean;

  get fileSizeIsZero(): boolean {
    return this.fileDownloadInfo.file_size === 0;
  }

  constructor() {}
}
