import { Pipe, PipeTransform } from "@angular/core";

type InputCase = "CAMEL_CASE" | "SNAKE_CASE" | "PASCAL_CASE" | "KEBAB_CASE" | "MACRO_CASE";
type OutputCase = "UPPER_CASE" | "LOWER_CASE" | "TITLE_CASE";

@Pipe({
  name: "convertCase",
  standalone: true,
})
export class ConvertCasePipe implements PipeTransform {
  transform(value: string, inputCase: InputCase, outputCase: OutputCase = "TITLE_CASE"): string {
    let upperCase: string;
    switch (inputCase) {
      case "CAMEL_CASE":
        upperCase = this.camelCaseToUpper(value);
        break;
      case "SNAKE_CASE":
        upperCase = this.snakeCaseToUpper(value);
        break;
      case "PASCAL_CASE":
        upperCase = this.pascalCaseToUpper(value);
        break;
      case "KEBAB_CASE":
        upperCase = this.kebabCaseToUpper(value);
        break;
      case "MACRO_CASE":
        upperCase = this.macroCaseToUpper(value);
        break;
      default:
        upperCase = value;
    }

    switch (outputCase) {
      case "UPPER_CASE":
        return upperCase;
      case "LOWER_CASE":
        return upperCase.toLowerCase();
      case "TITLE_CASE":
        return this.convertToTitleCase(upperCase);
      default:
        return upperCase;
    }
  }

  private camelCaseToUpper(value: string): string {
    return value.replace(/([A-Z])/g, " $1").toUpperCase();
  }

  private snakeCaseToUpper(value: string): string {
    return value.replace(/_/g, " ").toUpperCase();
  }

  private pascalCaseToUpper(value: string): string {
    return value
      .replace(/([A-Z])/g, " $1")
      .trim()
      .toUpperCase();
  }

  private kebabCaseToUpper(value: string): string {
    return value.replace(/-/g, " ").toUpperCase();
  }

  private macroCaseToUpper(value: string): string {
    return value.replace(/_/g, " ").toUpperCase();
  }

  private convertToTitleCase(value: string): string {
    return value.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
  }
}
