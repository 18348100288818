<ng-container *ngIf="searchRecordNavigationInfo$ | async as recordNavigationInfo">
    <div class="navigation-wrapper" *ngIf="recordNavigationInfo.currentRecordId">
        <div class="navigation-buttons">
            <button
                (click)="onPreviousSearchItem()"
                [disabled]="recordNavigationInfo.isFirstRecord"
                color="primary"
                mat-raised-button
                class="search-button"
                matTooltip="Previous Search Result">
                <mat-icon>navigate_before</mat-icon>
            </button>
            <button (click)="onReturnToSearch()" color="primary" mat-raised-button class="search-button" matTooltip="Return to Search">
                <mat-icon>table_view</mat-icon>
            </button>
            <button
                (click)="onNextSearchItem()"
                [disabled]="recordNavigationInfo.isLastRecord"
                color="primary"
                mat-raised-button
                class="search-button"
                matTooltip="Next Search Result">
                <mat-icon>navigate_next</mat-icon>
            </button>
        </div>
        <div class="label">Search Navigation</div>
    </div>
</ng-container>
