import { ChangeDetectorRef, Component, inject, OnInit } from "@angular/core";
import { StateService } from "src/app/shared/services/state.service";
import { Platform } from "@angular/cdk/platform";
import { SwUpdateService } from "src/app/core/services/sw-update.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private state = inject(StateService);
  private platform = inject(Platform);
  private swUpdateService = inject(SwUpdateService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  isUnsupportedBrowser = false;
  showFullPageLoadingSpinner: boolean = false;

  ngOnInit(): void {
    this.isUnsupportedBrowser = !this.platform.BLINK;
    this.swUpdateService.pollForUpdates();

    this.state.showFullPageLoadingSpinner$.subscribe((value) => {
      this.showFullPageLoadingSpinner = value;
      this.changeDetectorRef.detectChanges();
    });
  }
}
