<div *ngIf="expand">
    <div class="subheading">Customize details to include in customer-service case management.</div>

    <form class="form-fields" [formGroup]="salesforceForm">
        <div class="no-contact-container">
            <mat-checkbox color="warn" disableRipple [checked]="noContactsMade" (change)="noContactsMade = !noContactsMade">
                <span class="checkbox-label">No contacts were directly notified (phone or text)</span>
            </mat-checkbox>
            <div class="additional-info">
                An immediate call was not made. A follow-up call will be scheduled during the next hours of operation.
            </div>
        </div>

        <app-input formControlName="subject" class="salesforce-input" [required]="true" label="Case Title:"></app-input>

        <div class="communications-wrapper">
            <app-textarea [readonly]="true" label="Communications:" [value]="incidentCommunications"></app-textarea>
            <button
                mat-icon-button
                color="primary"
                class="refresh-button"
                matTooltip="Refresh communications."
                (click)="getIncidentCommunications()">
                <mat-icon>refresh</mat-icon>
            </button>
        </div>

        <app-textarea formControlName="description" name="case-description" label="Safety Notes:"></app-textarea>
    </form>

    <div class="actions">
        <button
            mat-flat-button
            color="primary"
            class="sf-submit-button"
            data-testid="sf-submit-button"
            [disabled]="caseSubmitted || salesforceForm.invalid"
            (click)="submitCase()">
            {{ buttonLabel }}
        </button>

        <section class="result">
            <a *ngIf="caseUrl" [href]="caseUrl" class="case-url" target="_blank">Click here to open the case in Salesforce.</a>

            <span class="error" *ngIf="errorMessage">
                {{ errorMessage }}
            </span>
        </section>
    </div>
</div>
