<app-expandable-panel
    *ngIf="messageMetadata"
    [isExpanded]="!!messageMetadata"
    data-testid="message-preview-panel">
    <ng-container *ngIf="showChatContext">
        <ng-template appPanelHeader>
            <div class="new-row">
                <div class="label">Chat Room:</div>
                <div
                    class="text"
                    *ngIf="parsedChatContext.chatRoomName">
                    {{ parsedChatContext.chatRoomName }}
                </div>
            </div>
        </ng-template>
        <ng-template appPanelContent>
            <div class="chat-container">
                <div class="chat-preview">
                    <button
                        *ngIf="shouldDisplayShowEarlierChats"
                        (click)="showEarlierChats()"
                        class="chat-button"
                        data-testid="view-previous-messages">
                        <gaggle-icon
                            iconName="plus"
                            iconState="light"></gaggle-icon>
                        View More
                    </button>
                    <ng-container *ngFor="let message of displayedChatMessages">
                        <p
                            *ngIf="message.isNewMessageDate"
                            class="chat-date">
                            {{ message.date }}
                        </p>
                        <app-chat-message
                            [workItemId]="workItem?.id"
                            [message]="message"
                            [selectedParticipantEmail]="selectedChatParticipantEmail"
                            (highlightChat)="highlightChatsFrom(message.senderEmail)"></app-chat-message>
                    </ng-container>

                    <button
                        *ngIf="shouldDisplayShowLaterChats"
                        (click)="showLaterChats()"
                        class="chat-button"
                        data-testid="view-later-messages">
                        <gaggle-icon
                            iconName="plus"
                            iconState="light"></gaggle-icon>
                        View More
                    </button>
                </div>
                <div class="chat-participants">
                    <div class="participant-divider"></div>
                    <ng-container *ngIf="showChatParticipants">
                        <ng-container *ngFor="let participant of chatParticipants">
                            <p
                                (click)="highlightChatsFrom(participant.email)"
                                class="participant">
                                {{ participant.name }}
                                <span class="participant-email"><{{ participant.email }}></span>
                            </p>
                        </ng-container>
                    </ng-container>
                    <gaggle-button
                        [icon]="participantIcon"
                        [label]="participantLabel"
                        buttonStyle="secondary"
                        (click)="toggleChatParticipants()"
                        [backgroundColor]="participantToggleBackground"
                        [textColor]="participantToggleTextColor"
                        [iconColor]="participantToggleTextColor"></gaggle-button>
                </div>
            </div>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="!showChatContext">
        <ng-template appPanelHeader>
            <div class="new-row">
                <div class="label">Subject:</div>
                <div
                    *ngIf="messageMetadata.subject"
                    class="text">
                    {{ messageMetadata.subject }}
                </div>
            </div>
        </ng-template>

        <ng-template appPanelContent>
            <div class="message-content-container">
                <div
                    class="content-frame"
                    id="content-frame">
                    <div
                        class="message-content"
                        id="message-content"
                        [innerHTML]="htmlContent | removeZalgo | forceBlankTarget"></div>
                </div>
            </div>
        </ng-template>

        <ng-template appPanelButtons>
            <gaggle-icon
                iconName="external-link"
                data-testid="external-link"
                iconSize="3em"
                class="button-icon"
                (click)="openEmailInNewTab()"></gaggle-icon>
            <gaggle-icon
                iconName="plus"
                data-testid="zoom-in"
                iconSize="3em"
                iconState="light"
                class="button-icon"
                (click)="onClickZoomIn()"></gaggle-icon>
            <gaggle-icon
                iconName="minus"
                data-testid="zoom-out"
                iconSize="3em"
                iconState="light"
                class="button-icon"
                (click)="onClickZoomOut()"></gaggle-icon>
            <gaggle-icon
                iconName="translate-text"
                data-testid="translate-message"
                iconSize="3em"
                class="button-icon"
                (click)="onClickTranslate()"></gaggle-icon>
        </ng-template>
    </ng-container>
</app-expandable-panel>

<app-expandable-panel
    *ngIf="messageAttachments.length > 0"
    [isExpanded]="true"
    data-testid="attachments">
    <ng-template appPanelHeader>
        <div class="new-row">
            <div class="label">Attachments Preview:</div>
        </div>
    </ng-template>
    <ng-template appPanelContent>
        <div>
            <app-image-modal
                *ngFor="let attachment of messageAttachments"
                [src]="attachment.downloadUrl"
                imageAlt="Attachment Preview {{ attachment.name }}"></app-image-modal>
        </div>
    </ng-template>
</app-expandable-panel>

<app-expandable-panel
    *ngIf="messageNotFound"
    data-testid="message-not-found">
    <ng-template appPanelHeader>
        <div class="new-row">
            <div class="text">This message preview is no longer available. It may have already been deleted or unblocked.</div>
        </div>
    </ng-template>
</app-expandable-panel>
