<div
    class="contact"
    [ngClass]="{ disabled: isDisabled }">
    <div
        class="name"
        [ngClass]="{ 'unknown-name': !contact.name.length }">
        {{ contact.name || "Unknown" }}
    </div>
    <div class="phone-number">
        {{ formattedPhoneNumber }}
        <div
            *ngIf="!contact.availability.text"
            class="availability-toggle">
            <gaggle-toggle
                (status)="onToggleDisabled()"
                [isToggleOn]="!isDisabled"
                name="{{ contact.name }}"></gaggle-toggle>
        </div>
    </div>
    <gaggle-button
        icon="copy"
        label="Copy"
        (click)="onCopyClick()"
        [disabled]="isDisabled"
        [backgroundColor]="copyButtonBackground"
        [textColor]="textButtonColor"
        [iconColor]="textButtonColor">
    </gaggle-button>
</div>
