import { StateService } from "src/app/shared/services/state.service";
import { Incident } from "src/app/models/incident.model";
import { Component, HostListener, Input, OnChanges, OnInit, OnDestroy } from "@angular/core";
import { ActionReason, CategoriesWithoutViolations, WorkItemType } from "src/app/models/work-item.model";
import { FeatureFlagValue, WorkItemFeatureFlagName } from "src/app/models/feature-flags.model";
import { evaluateFeatureFlagCondition, getItemEmailTemplates, getItemOtherEmailTemplates } from "src/app/shared/utils/helpers";
import { contentCategories as categories, ContentCategory } from "src/app/models/content-category.model";
import { WarningEventType } from "src/generated/graphql";
import { EmergencyContactService } from "src/app/shared/services/emergency-contact.service";
import { skipWhile, takeUntil, Subject } from "rxjs";

const violationTextMap = {
  [WarningEventType.FirstWarning]: "1st violation",
  [WarningEventType.SecondWarning]: "2nd violation",
  [WarningEventType.ThirdWarning]: "multiple violations",
};

@Component({
  selector: "app-content-category",
  templateUrl: "./content-category.component.html",
  styleUrls: ["./content-category.component.scss"],
})
export class ContentCategoryComponent implements OnInit, OnChanges, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  quarantineContent = false;
  subscribedToQuarantine = false;
  isUserInitiatedQuarantine = false;
  violationsAllowed = true;
  hotkeysEnabled = true;
  inappropriateReasons;
  isEscalatePss: boolean;
  showContentType: boolean;
  contentCategories = categories;

  @Input() expand: boolean;
  @Input() l2Audit = false;
  @Input() incident: Incident;
  @Input() isReopenIncident: boolean;

  pssSuicideFlagActionReasons = [ActionReason.SUICIDE, ActionReason.SELF_HARM];
  pssSuicideFlagCategories = this.contentCategories.filter(
    (category) => this.pssSuicideFlagActionReasons.includes(category.actionReason) && this.pssSuicideFlagEnabled,
  );

  constructor(private state: StateService, private emergencyContactService: EmergencyContactService) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.state.shouldToggleEscalatePSS = false;
    this.isUserInitiatedQuarantine = false;
    this.state.currentItemDetails$.subscribe((data) => {
      this.isUserInitiatedQuarantine = false;
      this.isEscalatePss = false;
      this.violationsAllowed = true;
      this.inappropriateReasons = null;
      this.state.shouldQuarantineContent = false;
      this.state.nuditySelected = false;
      this.state.shouldAttachFiles = true;
      this.state.isAnyCategorySelected = false;
      this.contentCategories.forEach((c) => {
        c.isSelected = false;
        if (this.categoryIsPss(c)) {
          c.warningType = WarningEventType.QuestionableContent;
        }
      });
      if (data) {
        this.isEscalatePss = data.events?.some((e) => e?.event === "escalatepss");
        this.inappropriateReasons = data.inappropriateContentReasons;

        this.subscribedToQuarantine = data.canQuarantine;
        this.violationsAllowed = !CategoriesWithoutViolations.has(data.workItemEntityType);

        if (this.inappropriateReasons?.length > 0 && (this.isEscalatePss || this.isReopenIncident) && !this.l2Audit) {
          this.prefillCategorySelectionsForEscalatePSS(this.inappropriateReasons);
        }
      }
    });
    this.state.isAnyCategorySelected$.subscribe((data) => (this.showContentType = data));
    this.state.shouldQuarantineContent$.subscribe((data) => (this.quarantineContent = data));

    this.emergencyContactService.filteredContacts$
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(skipWhile((data) => data === null))
      .subscribe((data) => {
        this.incident.unfilteredContacts = data?.all;
        this.incident.filteredEmailContacts = data?.email;
        this.incident.filteredTextContacts = data?.sms;
        this.incident.filteredCallContacts = data?.call;
      });
  }

  ngOnChanges(): void {
    this.hotkeysEnabled = this.expand;
  }

  // Select content category by pressing displayed hotkeys
  @HostListener("document:keydown", ["$event"]) hotkey(event: KeyboardEvent): void {
    if (this.hotkeysEnabled) {
      const matchingCategory = this.contentCategories.find((c) => c.hotkey === event.key);
      if (matchingCategory && this.shouldDisplayCategory(matchingCategory)) {
        this.onClickCategory(matchingCategory);
      }
    }
  }

  get pssSuicideFlagEnabled(): boolean {
    return this.state.getWorkItemFeatureFlag(WorkItemFeatureFlagName.AUTO_PSS_SUICIDE) === FeatureFlagValue.ON;
  }

  get showQuarantineToggle(): boolean {
    return this.state.currentItemDetails?.workItemEntityType === WorkItemType.FILE && this.subscribedToQuarantine;
  }

  get quarantinedOrBlockedText(): string {
    return this.state.shouldQuarantineContent ? "quarantined" : "blocked";
  }

  get isPSS(): boolean {
    return this.selectedCategories.some((c) => this.categoryIsPss(c));
  }

  get isQcon(): boolean {
    return !this.isPSS && this.selectedCategories.some((c) => this.categoryIsQcon(c));
  }

  get isViolation(): boolean {
    return !this.isPSS && !this.isQcon && this.selectedCategories.length > 0;
  }

  get violationDescriptor(): string {
    return this.hasMultipleViolations ? "one of" : "a";
  }

  get violationText(): string {
    return violationTextMap[this.state.currentWarningLevel];
  }

  get hasMultipleViolations(): boolean {
    return (
      this.state.currentWarningLevel === WarningEventType.ThirdWarning || this.state.currentWarningLevel === WarningEventType.SecondWarning
    );
  }

  get selectedCategories(): ContentCategory[] {
    return this.contentCategories.filter((category) => category.isSelected);
  }

  shouldDisplayCategory(category: ContentCategory): boolean {
    // If category has a feature flag condition, and the condition is not met, hide it.
    if (!!category.featureFlagCondition && !evaluateFeatureFlagCondition(category.featureFlagCondition, this.state.featureFlags)) {
      category.isSelected = false;
      return false;
    }

    // Otherwise, check for violations allowed or PSS/QCON category.
    const display = (this.violationsAllowed && !category.isPssQcon) || category.isPssQcon;
    if (!display) {
      category.isSelected = false;
    }

    return display;
  }

  shouldForcePSS(category: ContentCategory): boolean {
    return this.pssSuicideFlagCategories.includes(category);
  }

  private prefillCategorySelectionsForEscalatePSS(reasons: string[]): void {
    const availableReasons = [...reasons];

    // First, find matches for PSS/QCON categories.
    for (let i = 0; i < availableReasons.length; i++) {
      const actionReasonCategory = this.contentCategories.find((c) => c.isPssQcon && c.actionReason === availableReasons[i]);
      if (actionReasonCategory) {
        if (this.shouldDisplayCategory(actionReasonCategory)) {
          // If the category is not already selected, use the onClickCategory() method to select it, so that all of
          // the logic kicks in for processing the selected category.
          this.onClickCategory(actionReasonCategory, true);
        }
        // Remove the matching reason from the list of available reasons.
        availableReasons.splice(i, 1);
      }
    }

    // If there are still available reasons to process, try to see if they match non-PSS/QCON categories.
    if (availableReasons.length > 0) {
      availableReasons.forEach((r) => {
        const warnCategory = this.contentCategories.find((c) => !c.isPssQcon && c.actionReason === r && !c.isSelected);
        // If a matching category is found that is not yet selected, use the onClickCategory() method to select it, so that
        // all of the logic kicks in for processing the selected category.
        if (warnCategory && this.shouldDisplayCategory(warnCategory)) {
          this.onClickCategory(warnCategory);
        }
      });
    }
  }

  onClickCategory(category: ContentCategory, prefillAndForcePSS = false): void {
    category.isSelected = !category.isSelected;

    // apply the correct warning level to the category (PSS, QCON, Violation)
    if (this.shouldForcePSS(category)) {
      category.warningType = WarningEventType.PossibleStudentSituation;
    } else if (category.warningType === WarningEventType.Violation && this.state.currentWarningLevel) {
      category.warningType = this.state.currentWarningLevel;
    } else if (this.isEscalatePss && prefillAndForcePSS) {
      // if prefilling due to escalatePSS, force PSS selection on those categories
      category.warningType = WarningEventType.PossibleStudentSituation;
    }

    // if category is deselected, make sure it is correctly reset back to the right warning type
    if (!category.isSelected) {
      if (this.categoryIsPss(category)) {
        category.warningType = WarningEventType.QuestionableContent;
      } else if (category.warningType in [WarningEventType.FirstWarning, WarningEventType.SecondWarning, WarningEventType.ThirdWarning]) {
        category.warningType = WarningEventType.Violation;
      }
    }

    this.onCategorySelection();
  }

  private handleQuarantine(): void {
    if (this.showQuarantineToggle) {
      if (this.isUserInitiatedQuarantine) {
        this.state.shouldQuarantineContent = true;
        return;
      }
      this.state.shouldQuarantineContent = this.isPSSCategoryWithQuarantineSelected() || this.isQconCategoryWithQuarantineSelected();
    } else {
      this.state.shouldQuarantineContent = false;
    }
  }

  private updateActionReasons(): void {
    const actionReasons = Array.from(new Set(this.selectedCategories.map((cat) => cat.actionReason)));

    this.incident.payload.actionReasons = actionReasons;
  }

  onCategorySelection(): void {
    if (this.selectedCategories) {
      this.state.selectedCategories = this.selectedCategories;
      this.emergencyContactService.selectedCategories = this.selectedCategories;
    }
    this.updateActionReasons();
    this.updateIncidentWithHighestWarningType();
    this.handleNudityCategorySelected();
    this.handleQuarantine();
  }

  private updateIncidentTemplatesAndContacts(highestWarningTypeCategories: ContentCategory[]): void {
    if (!highestWarningTypeCategories?.length) {
      this.incident.payload.warningType = null;
      this.state.workItemEmailTemplates = [];
      this.state.workItemOtherQconEmailTemplates = [];
      this.state.workItemOtherPssEmailTemplates = [];
      // this.filterContacts(null);
      return;
    } else {
      this.incident.payload.warningType = highestWarningTypeCategories[0].warningType;
      this.updateEmailTemplates(highestWarningTypeCategories);
      // this.filterContacts(highestWarningTypeCategories);
    }
  }

  private updateEmailTemplates(highestWarningTypeCategories: ContentCategory[]): void {
    this.state.workItemEmailTemplates = getItemEmailTemplates(
      this.state.allEmailTemplates,
      this.state.currentItemDetails,
      highestWarningTypeCategories[0],
      this.selectedCategories,
    );

    this.state.workItemOtherQconEmailTemplates = getItemOtherEmailTemplates(
      this.state.allEmailTemplates,
      this.state.currentItemDetails,
      WarningEventType.QuestionableContent,
      this.selectedCategories,
    );

    this.state.workItemOtherPssEmailTemplates = getItemOtherEmailTemplates(
      this.state.allEmailTemplates,
      this.state.currentItemDetails,
      WarningEventType.PossibleStudentSituation,
      this.selectedCategories,
    );
  }

  // private filterContacts(categories: ContentCategory[]): void {
  // eslint-disable-next-line max-len
  //   const filteredContacts = filterContacts(categories, this.incident.unfilteredContacts, this.state.currentItemDetails.workItemEntityType);
  //   this.incident.filteredEmailContacts = filteredContacts.emailContacts;
  //   this.incident.filteredTextContacts = filteredContacts.textContacts;
  //   this.incident.filteredCallContacts = filteredContacts.callContacts;
  //   this.incident.filteredAfterHoursEmailContacts = filteredContacts.afterHoursEmailContacts;
  //   this.incident.filteredAfterHoursTextContacts = filteredContacts.afterHoursTextContacts;
  //   this.incident.filteredAfterHoursCallContacts = filteredContacts.afterHoursCallContacts;
  // }

  private handleNudityCategorySelected(): void {
    const nudityCategorySelected = this.selectedCategories.some((c) => {
      return c.actionReason === ActionReason.NUDITY;
    });
    this.state.shouldAttachFiles = !nudityCategorySelected;
    this.state.nuditySelected = nudityCategorySelected;
    if (nudityCategorySelected && this.showQuarantineToggle) {
      this.state.shouldQuarantineContent = true;
    }
  }

  private updateIncidentWithHighestWarningType(): void {
    const selectedCategories = this.selectedCategories;

    if (!selectedCategories.length) {
      this.state.isAnyCategorySelected = false;
      this.updateIncidentTemplatesAndContacts(null);
    } else {
      this.state.isAnyCategorySelected = true;

      const qconCategories = selectedCategories.filter((c) => this.categoryIsQcon(c));
      const pssCategories = selectedCategories.filter((c) => this.categoryIsPss(c));

      this.state.shouldToggleEscalatePSS = false;

      if (pssCategories.length > 0) {
        this.state.shouldToggleEscalatePSS = !this.state.hasPSSPermission;
        this.updateIncidentTemplatesAndContacts(pssCategories);
        return;
      } else if (qconCategories.length > 0) {
        this.updateIncidentTemplatesAndContacts(qconCategories);
        return;
      } else {
        this.updateIncidentTemplatesAndContacts(selectedCategories);
      }
    }
  }

  private isPSSCategoryWithQuarantineSelected(): boolean {
    return this.selectedCategories.some((category) => this.categoryIsPss(category) && category.shouldQuarantineOnPSS);
  }

  private isQconCategoryWithQuarantineSelected(): boolean {
    return this.selectedCategories.some((category) => this.categoryIsQcon(category) && category.shouldQuarantineOnQcon);
  }

  onToggleQuarantineContent(): void {
    this.state.shouldQuarantineContent = !this.state.shouldQuarantineContent;
    this.isUserInitiatedQuarantine = this.state.shouldQuarantineContent;
  }

  private categoryIsPss(category: ContentCategory): boolean {
    return category?.warningType === WarningEventType.PossibleStudentSituation;
  }

  private categoryIsQcon(category: ContentCategory): boolean {
    return category?.warningType === WarningEventType.QuestionableContent;
  }
}
