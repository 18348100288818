import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { formatPhoneNumberForDisplay } from "src/app/shared/utils/format";
import { ThemeService } from "src/app/shared/services/theme.service";
import { EmergencyContactExtended } from "src/app/shared/services/emergency-contact.service";

@Component({
  selector: "app-text-emergency-contact-row-integrated",
  templateUrl: "./text-emergency-contact-row-integrated.component.html",
  styleUrls: ["./text-emergency-contact-row-integrated.component.scss"],
})
export class TextEmergencyContactRowIntegratedComponent implements OnInit {
  constructor(private theme: ThemeService) {}

  @Input() contact: EmergencyContactExtended;
  @Input() acknowledgedBy: any;
  @Input() checked: boolean;
  @Input() smsStatus: any;
  @Input() disabled: boolean;

  @Output() copyPhoneNumber = new EventEmitter();
  @Output() selected = new EventEmitter();

  isDisabled = false;

  get useDarkMode(): boolean {
    return this.theme.useDarkMode;
  }

  get formattedPhoneNumber(): string {
    return formatPhoneNumberForDisplay(this.contact.unformattedMobilePhone);
  }

  ngOnInit(): void {
    this.isDisabled = !this.contact.availability.text;
  }

  onToggleDisabled(): void {
    this.isDisabled = !this.isDisabled;
  }

  showAck(): boolean {
    if (this.acknowledgedBy) {
      return this.contact.id === this.acknowledgedBy.id;
    }
  }
}
