import moment from "moment";
import { PhoneNumber } from "src/app/models/incident.model";

export const EMAIL_REGEX = new RegExp(
  // eslint-disable-next-line max-len
  /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/,
  "ig",
);

function timestampToMoment(timestamp: number | string, timezone = null): moment.Moment {
  let date = moment.unix(Number(timestamp) / 1000).tz(timezone ? timezone : moment.tz.guess());

  // If it's not a valid date, it probably didn't come as a unix timestamp, try to parse it as a string
  if (!date.isValid()) {
    date = moment(timestamp).tz(timezone ? timezone : moment.tz.guess());
  }

  return date;
}

export function escapeOpeningAngleBrackets(value: string): string {
  if (!value) {
    return value;
  }

  const stringToReplace = value.toString();

  return stringToReplace.replace(new RegExp("<", "g"), "&lt;");
}

export function formattedDateObject(timestamp: number, timezone = null): any {
  const date = timestampToMoment(timestamp, timezone);
  return {
    dayAndMonth: date.format("ddd MMM DD"),
    year: date.format("YYYY"),
    time: date.format("hh:mm A"),
    originalDate: date,
  };
}

export function formattedCanvasDate(timestamp: string): string {
  const momentDate = moment.utc(timestamp);
  return momentDate.format("ddd MMM DD, YYYY [at] hh:mm:ss A");
}

export function formattedDate(timestamp: number): string {
  const date = timestampToMoment(timestamp);
  return date.format("ddd MMM DD, YYYY [at] hh:mm a z");
}

export function formatTranslation(translation: string[]): string {
  let response = "";
  translation?.forEach((line) => {
    response += `<p>${line}</p>`;
  });
  return response;
}

export function removeCharsFromPhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace(/[\s\-_()+]+/g, "").trim();
}

const EXTENSION_REGEX = new RegExp(/ x\d+$/);

export function formatPhoneNumberForDisplay(phoneNumber: PhoneNumber): string {
  let formattedNumber = "";

  if (phoneNumber?.countryCode) {
    formattedNumber = `(${phoneNumber.areaCode}) ${phoneNumber.number.slice(0, 3)}-${phoneNumber.number.slice(3)}`;
  } else if (phoneNumber?.number) {
    formattedNumber = manuallyParsePhoneNumberForDisplay(phoneNumber.number);
  }

  return formattedNumber;
}

function manuallyParsePhoneNumberForDisplay(phoneNumber: string): string {
  let formattedNumber = phoneNumber;

  //remove extension
  formattedNumber = formattedNumber.replace(EXTENSION_REGEX, "");

  // TODO: update when AUS is introduced to remove country code (+61)
  // remove country code if +1 or 1
  formattedNumber = formattedNumber.replace("+1", "");
  if (formattedNumber.startsWith("1")) {
    formattedNumber = formattedNumber.replace("1", "");
  }
  // remove any extra characters
  formattedNumber = removeCharsFromPhoneNumber(formattedNumber);
  // format to (###) ###-####
  return `(${formattedNumber.slice(0, 3)}) ${formattedNumber.slice(3, 6)}-${formattedNumber.slice(6)}`;
}

export function formatTimer(timestamp: number): string {
  return moment(0).second(timestamp).format("mm:ss");
}
