import gql from "graphql-tag";

export default gql`
  query simpleQueueStatistics {
    simpleQueueStatistics {
      logicalQueue
      count
    }
  }
`;
