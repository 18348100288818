<div class="item-viewer-container" [ngClass]="isAfterHours ? 'after-hours-background' : null">
    <gaggle-pill *ngIf="isAfterHours" class="after-hours-pill big-pill" icon="after-hours" color="#e6e6ff" iconColor="#665cff">
        <p>Gaggle After Hours</p>
    </gaggle-pill>
    <gaggle-pill *ngIf="isReopenIncident" class="after-hours-pill" icon="reload" color="#e6e6ff" iconColor="#665cff">
        <p>Reopened Incident</p>
    </gaggle-pill>
    <gaggle-pill *ngIf="isOpenWorkItem" class="after-hours-pill" icon="reload" color="#e6e6ff" iconColor="#665cff">
        <p>Reopened Work Item</p>
    </gaggle-pill>
    <div class="row">
        <div class="label">Source:</div>
        <div class="file">
            <gaggle-pill [color]="pillBackgroundColor" [textColor]="pillTextColor">
                {{ itemEntityType }}
            </gaggle-pill>
        </div>
        <div *ngIf="showBundleToggle" class="bundle">
            <gaggle-toggle label="Bundle" [isToggleOn]="bundleContent" (status)="onToggleBundleContent()"></gaggle-toggle>
        </div>
    </div>
    <div class="row" *ngIf="webFilterUrl">
        <div class="label">URL:</div>
        <div class="comment url" [title]="webFilterUrl">
            {{ webFilterUrl }}
        </div>
    </div>
    <div class="row">
        <div *ngIf="itemDetails?.entityName" class="label">File:</div>
        <div class="file">{{ itemDetails?.entityName }}</div>
        <div class="translate-download">
            <!-- Show if downloadable, but no PDF is available -->
            <ng-container *ngIf="(showFileDownload || showDownload) && !hasPdfAvailable">
                <button
                    data-testid="download-button"
                    (click)="onClickDownload()"
                    [disabled]="disableFileDownloadButton"
                    [matTooltip]="disableFileDownloadButton ? 'The file is unable to be downloaded.' : 'Download'"
                    matTooltipShowDelay="500"
                    mat-flat-button
                    color="primary">
                    Download
                    <mat-icon>download</mat-icon>
                </button>
            </ng-container>

            <!-- Show if downloadable, and a PDF is available -->
            <ng-container *ngIf="(showFileDownload || showDownload) && hasPdfAvailable">
                <div class="menu-button">
                    <button
                        data-testid="split-download-main"
                        class="main-action"
                        mat-flat-button
                        color="primary"
                        (click)="onClickDownload()"
                        [disabled]="disableFileDownloadButton"
                        [matTooltip]="disableFileDownloadButton ? 'The file is unable to be downloaded.' : 'Download Original File'"
                        matTooltipShowDelay="500">
                        Download
                    </button>
                    <button
                        data-testid="split-download-menu-trigger"
                        class="menu-trigger"
                        mat-flat-button
                        color="primary"
                        [disabled]="disableFileDownloadButton"
                        [matMenuTriggerFor]="downloadMenu">
                        <mat-icon>expand_more</mat-icon>
                    </button>
                    <mat-menu data-testid="split-download-menu" #downloadMenu="matMenu" yPosition="below" xPosition="before">
                        <button data-testid="split-download-original" mat-menu-item (click)="onClickDownload()">Download Original</button>
                        <button data-testid="split-download-pdf" mat-menu-item (click)="onClickDownload('PDF')">Download PDF</button>
                    </mat-menu>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="label"></div>
        <div *ngIf="itemDetails?.entityName" class="comment">
            Students may continue to access content identified in the item until notification emails are issued.
        </div>
    </div>
    <div class="row right-align" *ngIf="hasBlockReasonWithMultipleExcerpts">
        <div class="hide-excerpts">
            <gaggle-toggle
                label="Hide Additional Excerpts"
                [isToggleOn]="hideAdditionalExcerpts"
                (status)="hideAdditionalExcerpts = !hideAdditionalExcerpts"></gaggle-toggle>
        </div>
    </div>

    <ng-container *ngIf="!isSpeakUp">
        <ng-container *ngFor="let blockReason of blockReasons">
            <app-reason-panel
                [hideAdditionalExcerpts]="hideAdditionalExcerpts"
                [blockReason]="blockReason.blockReason"
                [showExpanded]="blockReason.showExpanded"
                [workItemId]="itemDetails.id"></app-reason-panel>
        </ng-container>
    </ng-container>
    <app-file-comment-panel *ngIf="isFileComment" [keywords]="keywordList"></app-file-comment-panel>
    <app-cal-preview-panel *ngIf="isCalendar" [keywords]="keywordList"></app-cal-preview-panel>
    <app-message-preview-panel *ngIf="shouldShowMessagePreviewPanel" [keywords]="keywordList"></app-message-preview-panel>
    <app-file-preview-panel
        *ngIf="showFilePreview"
        [fileDownloadInfo]="fileDownloadInfo"
        [fileName]="fileDownloadName"
        [isImage]="isImage"
        [isPDF]="isPDF"
        [isOversized]="isOversized"></app-file-preview-panel>
    <app-lms-info *ngIf="isLMS" [keywordList]="keywordList" [workItemId]="itemDetails.id"></app-lms-info>

    <div *ngIf="blockedImages && blockedImages.length > 2" class="flex-row end">
        <gaggle-button
            data-testid="show-all-images-button"
            (buttonClick)="onClickShowImages()"
            buttonStyle="text-and-icon"
            iconSize="L"
            [label]="' Show all ' + blockedImages.length + ' images'"
            icon="full-screen"></gaggle-button>

        <gaggle-dialog
            data-testid="show-all-images-dialog"
            *ngIf="showingAllImages"
            [useModalBackground]="true"
            [dialogTitle]="'Showing ' + blockedImages.length + ' images'"
            (closeModal)="onClickShowImages()">
            <div class="multi-image-grid">
                <div
                    class="image-pane"
                    *ngFor="let image of blockedImages"
                    (click)="onClickCopyImageFilename(image.name)"
                    title="{{ image.name }}">
                    <img [src]="image.downloadUrl" />
                </div>
            </div>
            <div buttons class="flex-row center">
                <gaggle-button buttonStyle="operation" label="Smaller" (buttonClick)="onClickAdjustImageColumns(1)"></gaggle-button>
                <gaggle-button buttonStyle="operation" label="Bigger" (buttonClick)="onClickAdjustImageColumns(-1)"></gaggle-button>
            </div>
        </gaggle-dialog>
    </div>

    <app-preview-panel *ngFor="let blockReason of dedupedBlockReasons" [blockReason]="blockReason"></app-preview-panel>
</div>
