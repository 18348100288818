export interface QueueStatistics {
  type?: string;
  level1Count?: number;
  level2Count?: number;
  highPriorityCount?: number;
  total?: number;
}

export interface QueueStatisticsList {
  queues: QueueStatistics[];
}

export interface AuditQueueStatistics {
  logicalQueue: string;
  count: number;
}

export enum CombinedQueueType {
  L1 = "L1",
  L2 = "L2",
  HIGH_PRIORITY = "HIGH_PRIORITY",
}

export interface DisplayedQueue {
  label: string;
  combinedType: CombinedQueueType;
  count: number;
  hideQueue: boolean;
}
