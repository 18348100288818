import gql from "graphql-tag";

export default gql`
  query getExternalWorkItemMetadata($workItemId: ID!) {
    getExternalWorkItemMetadata(workItemId: $workItemId) {
      author
      content {
        id
        entityType
        fileContent {
          id
          contentId
          contentType
          downloadUrl
          include
          name
          previewUrl
          qualifier
          s3Key
        }
        textContent {
          id
          name
          text
        }
        title
        url
      }
      customerShortId
      externalType
      id
      name
      otherInfo
      participants
      requestDate
      url
    }
  }
`;
