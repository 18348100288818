import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { ModalDialogComponent } from "src/app/components/controls/modal-dialog/modal-dialog.component";
import { LoggingLevel } from "src/app/models/client-logging.model";
import { EmailTemplate, EventType, Incident, WARNING_TYPE_MAP } from "src/app/models/incident.model";
import { ActionReason, AttachmentInfo, DownloadInfo, GsmEmailSetting } from "src/app/models/work-item.model";
import { AcknowledgementService } from "src/app/shared/services/acknowledgement.service";
import { AppsyncClientService } from "src/app/shared/services/appsync-client.service";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { StateService } from "src/app/shared/services/state.service";
import { ThemeService } from "src/app/shared/services/theme.service";
import { AddEmergencyContactsIncidentAvailabilityGQL, UiEventAction, WarningEventType } from "src/generated/graphql";
import { UiAnalyticsService } from "src/app/shared/services/ui-analytics.service";
import { EmergencyContactExtended, EmergencyContactService } from "src/app/shared/services/emergency-contact.service";
import { WorkItemVisibilityService } from "src/app/shared/services/work-item-visibility.service";
import { firstValueFrom, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-email-content",
  templateUrl: "./email-content.component.html",
  styleUrls: ["./email-content.component.scss"],
})
export class EmailContentComponent implements OnInit, OnDestroy {
  @Input() incident: Incident;
  @Input() submitLabel: string;
  @Input() disableSendButton = false;
  @Input() isReopenIncident: boolean;
  @Input() isOpenWorkItem: boolean;
  @Input() additionalEmailRecipients: number;
  @Input() additionalEmailCcRecipients: number;
  @Input() shouldIncludeBlockedUser: boolean;
  @Output() enableNextSection = new EventEmitter();
  @Output() sendEmail = new EventEmitter();

  @ViewChild(MatMenuTrigger) templateMenuTrigger: MatMenuTrigger;

  hasMultipleIncidentTemplates = false;
  showMoreTemplates = false;
  emailTemplates: EmailTemplate[];
  gsmEmailSetting: GsmEmailSetting;
  quarantined = false;
  loadingTemplates = false;
  isAfterHours: boolean;
  afterHoursMessage: string;
  fileDownloadInfo: DownloadInfo;

  private unsubscribe$ = new Subject<void>();
  private multipleOptionEnabled = false;
  private availableTemplates: EmailTemplate[];
  private otherQCONTemplates: EmailTemplate[];
  private otherPSSTemplates: EmailTemplate[];
  private disableEmailButtonAfterSend = false;
  private selectedTemplate: EmailTemplate;

  constructor(
    private state: StateService,
    private appsync: AppSyncService,
    private appSyncClient: AppsyncClientService,
    private themeService: ThemeService,
    private dialog: MatDialog,
    private acknowledgementService: AcknowledgementService,
    private uiAnalyticsService: UiAnalyticsService,
    private workItemVisibilityService: WorkItemVisibilityService,
    private emergencyContactService: EmergencyContactService,
    private addEmergencyContactsIncidentAvailabilityGQL: AddEmergencyContactsIncidentAvailabilityGQL,
  ) {}

  ngOnInit(): void {
    this.state.downloadLink$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => (this.fileDownloadInfo = data));

    this.state.currentItemDetails$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.incident.payload.attachments = [];
      if (data) {
        this.gsmEmailSetting = data.gsmEmailSetting;
        if (data.attachments) {
          data.attachments.forEach((a) => {
            this.incident.payload.attachments.push({
              id: a.id,
              name: a.name,
              contentType: a.contentType,
              qualifier: a.qualifier,
              include: this.state.shouldAttachFiles,
            });
          });
        }
      }
    });
    this.state.messageMetadata$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (!!data && !!data.attachments) {
        data.attachments.forEach((a) => {
          this.incident.payload.attachments.push({
            id: a.id,
            name: a.name,
            contentType: a.contentType,
            qualifier: a.qualifier,
            include: this.state.shouldAttachFiles,
          });
        });
      }
    });

    this.state.externalMetadata$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.content[0]?.fileContent) {
        data.content[0].fileContent.forEach((attachment) => {
          this.incident.payload.attachments.push({
            id: attachment.id,
            name: attachment.name,
            contentType: attachment.contentType,
            qualifier: attachment.qualifier,
            include: this.state.shouldAttachFiles,
          });
        });
      }

      /**
       * Because we get Canvas items as text attachments, we have to handle them a bit differently. The id
       * has to be a randomly generated UUID as the ID that comes with the item is a Canvas generated number.
       * We'll send content type as text/html. The qualifier is calculated so that  we're just adding these to
       * the end of the attachments array. We don't have to worry about the state value of shouldAttachFiles
       * because these will always be appropriate. */
      if (data?.content[0]?.textContent) {
        data.content[0].textContent.forEach((attachment) => {
          this.incident.payload.attachments.push({
            id: self.crypto.randomUUID(),
            name: attachment.name,
            contentType: "text/html",
            qualifier: this.incident.payload.attachments.length.toString(),
            include: true,
          });
        });
      }
    });

    this.state.shouldAttachFiles$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (this.incident.payload.attachments) {
        this.incident.payload.attachments.forEach((a) => {
          a.include = !this.attachmentShouldBeDisabled(a) && data;
        });
      }
    });

    this.state.workItemEmailTemplates$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.emailTemplates = data;
      this.hasMultipleIncidentTemplates = false;
      if (this.emailTemplates) {
        this.filterEmailTemplates();
      }
    });

    this.state.shouldQuarantineContent$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.quarantined = data;
      if (this.emailTemplates) {
        this.filterEmailTemplates();
      }
    });
    this.state.workItemOtherQconEmailTemplates$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.otherQCONTemplates = data.length > 1 ? this.sortDropdownOptions(data) : data;
        this.filterEmailTemplates();
      }
    });
    this.state.workItemOtherPssEmailTemplates$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.otherPSSTemplates = data.length > 1 ? this.sortDropdownOptions(data) : data;
        this.filterEmailTemplates();
      }
    });

    this.state.loadingTemplates$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => (this.loadingTemplates = data));
    this.isAfterHours = this.state.itemIsInAfterHoursWindow;

    if (this.isAfterHours) {
      this.afterHoursMessage = "Incident emails will be delayed until the end of the school/group's After Hours window.";
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get buttonBackgroundColor(): string {
    return this.themeService.getVariable("--checkbox");
  }

  get buttonTextColor(): string {
    return this.themeService.getVariable("--nav-link-color");
  }

  get attachments(): AttachmentInfo[] {
    return this.incident.payload.attachments;
  }

  get contacts(): EmergencyContactExtended[] {
    return this.incident.filteredEmailContacts;
  }

  get incidentIsPSS(): boolean {
    return this.incident.payload.warningType === WarningEventType.PossibleStudentSituation;
  }

  get incidentIsQCON(): boolean {
    return this.incident.payload.warningType === WarningEventType.QuestionableContent;
  }

  get isQconPss(): boolean {
    return this.incidentIsPSS || this.incidentIsQCON;
  }

  get placeholderSubject(): string {
    if (this.loadingTemplates) {
      return "Loading templates...";
    }
    if (this.hasEmailTemplates) {
      return "Select desired response below";
    }
    return `Add Subject for ${WARNING_TYPE_MAP[this.incident.payload.warningType]}`;
  }

  get placeholderText(): string {
    if (this.loadingTemplates) {
      return "Loading templates...";
    }
    if (this.hasEmailTemplates) {
      return "Select desired response above";
    }
    const ending = this.isQconPss ? "message" : "warning";
    return `Add ${WARNING_TYPE_MAP[this.incident.payload.warningType]} ${ending}`;
  }

  get hasEmailTemplates(): boolean {
    return this.hasMultipleStandardTemplates || this.hasMultipleOtherTemplates;
  }

  get hasMultipleStandardTemplates(): boolean {
    return this.availableTemplates?.length > 1;
  }

  get otherTemplates(): EmailTemplate[] {
    return this.incidentIsQCON ? this.otherQCONTemplates : this.incidentIsPSS ? this.otherPSSTemplates : [];
  }

  get hasMultipleOtherTemplates(): boolean {
    return this.otherTemplates?.length > 1;
  }

  get clearColor(): string {
    return !!this.incident.payload.emailBody && this.incident.payload.emailBody.length > 0 ? "rgb(102,112,129)" : "rgba(0,0,0,0.24)";
  }

  get showMultiple(): boolean {
    return this.multipleOptionEnabled;
  }

  get templateOptions(): string[] {
    if (this.availableTemplates?.length > 0) {
      return this.filterTemplatesWithoutDropdown(this.availableTemplates).map((t) => t.dropdownOption);
    } else {
      return this.otherTemplateOptions;
    }
  }

  get otherTemplateOptions(): string[] {
    if (this.otherTemplates?.length > 0) {
      return this.filterTemplatesWithoutDropdown(this.otherTemplates).map((t) => t.dropdownOption);
    } else {
      return [];
    }
  }

  get moreTemplateOptions(): boolean {
    const hasStandardTemplates = this.availableTemplates?.length > 0;
    const hasOtherTemplates = this.otherTemplates?.length > 0;
    return hasStandardTemplates && hasOtherTemplates;
  }

  get shouldDisplayAttachFiles(): boolean {
    return this.attachments.length > 0;
  }

  get disableSendEmail(): boolean {
    if (this.disableSendButton) {
      return true;
    } else if (this.disableEmailButtonAfterSend) {
      return true;
    } else if (!this.incidentIsPSS && !this.incidentIsQCON) {
      // We don't want to hold up violations for missing email content or recipients
      return false;
    } else if (this.incidentIsPSS && !this.incident?.payload?.internalNotes) {
      return true;
    } else if (this.noRecipients) {
      return true;
    } else {
      return !this.incident?.payload?.emailBody || !this.incident?.payload?.emailSubject;
    }
  }

  get noRecipients(): boolean {
    // If this is a violation, we don't need to check the recipient count, as violations can go out without
    // recipients (THNDR-2890)
    if (
      this.incident.payload.warningType == WarningEventType.ThirdWarning ||
      this.incident.payload.warningType == WarningEventType.SecondWarning ||
      this.incident.payload.warningType == WarningEventType.FirstWarning
    ) {
      return false;
    }
    let recipientCount =
      this.incident.payload.recipients?.length +
      this.incident.payload.digestRecipients?.length +
      this.incident.payload.ccRecipients?.length +
      this.additionalEmailRecipients +
      this.additionalEmailCcRecipients;

    if (this.shouldIncludeBlockedUser) {
      recipientCount++;
    }

    return recipientCount === 0;
  }

  attachmentName(attachment: AttachmentInfo): string {
    return "Attach " + attachment.name;
  }

  attachmentShouldBeDisabled(attachment: AttachmentInfo): boolean {
    return attachment.qualifier == "-1" && !!this.fileDownloadInfo && this.fileDownloadInfo.file_size === 0;
  }

  onClickAttachFile(attachment: AttachmentInfo): void {
    attachment.include = !attachment.include;
  }

  onToggleMultiple(): void {
    this.multipleOptionEnabled = !this.multipleOptionEnabled;
    this.filterEmailTemplates();
  }

  onClickTemplateOption(dropdownText: string): void {
    const matchingTemplate = this.findMatchingTemplate(this.availableTemplates, dropdownText);
    if (matchingTemplate) {
      this._setSubjectAndBody(matchingTemplate);
    } else {
      this.onClickOtherTemplateOption(dropdownText);
    }
  }

  onClickOtherTemplateOption(dropdownText: string): void {
    const matchingTemplate = this.findMatchingTemplate(this.otherTemplates, dropdownText);
    if (matchingTemplate) {
      this._setSubjectAndBody(matchingTemplate);
    }
  }

  toggleShowMoreTemplates(event: any): void {
    event.stopPropagation();
    this.showMoreTemplates = !this.showMoreTemplates;
  }

  onClickClear(): void {
    if (this.incident && this.incident.payload) {
      this.incident.payload.emailSubject = "";
      this.incident.payload.emailBody = "";
      this.incident.payload.notes = "";
    }
  }

  onClickSubmit(): void {
    if (this.isAfterHours && this.incident.payload.warningType === WarningEventType.PossibleStudentSituation) {
      this.dialog
        .open(ModalDialogComponent, {
          data: {
            title: "Gaggle After Hours",
            content: "Heads up! " + this.afterHoursMessage,
            dismissButtonText: "Cancel",
            okButtonText: "Understood",
          },
        })
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (!result) {
            return;
          } else {
            this.emailContacts();
          }
        });
    } else {
      this.emailContacts();
    }
  }

  async emailContacts(): Promise<void> {
    // only passed by email-emergency-contacts; submit incident will not send an email
    this.sendEmail.emit();

    const event = this.quarantined ? EventType.QUARANTINE : EventType.WARN;
    if (!this.incident.payload.notes) {
      this.incident.payload.notes = this.incident.payload.emailBody;
    }

    if (this.selectedTemplate) {
      const attributes = JSON.parse(this.incident.payload.attributes);
      const { updatedSubject, updatedBody } = this.replaceVariablesForAudit(this.selectedTemplate);

      // need to base64 encode these values since they sometimes contain double quotes
      attributes.selectedSubject = btoa(updatedSubject);
      attributes.selectedBody = btoa(updatedBody);
      this.incident.payload.attributes = JSON.stringify(attributes);
    }

    this.disableEmailButtonAfterSend = true;
    this.captureAnalytics();

    if (this.isQconPss) {
      await firstValueFrom(
        this.addEmergencyContactsIncidentAvailabilityGQL.mutate({
          incidentId: this.incident.id,
          contacts: this.emergencyContactService.filteredContacts.all.map((c) => {
            return {
              ecId: c.id,
              ecName: c.name,
              emailAddress: c.email,
              districtContact: c.districtContact,
              afterHours: c.afterHours,
              dispatch: c.dispatch,
              email: c.availability.email,
              digest: c.availability.digest,
              text: c.availability.text,
              call: c.availability.phone,
              categories: c.availability.categories,
              sortIndex: this.emergencyContactService.filteredContacts.call.findIndex((ec) => ec.id === c.id),
            };
          }),
        }),
      ).catch((error) => {
        this.appSyncClient.logClientEvent(LoggingLevel.ERROR, "Error adding incident availability", {
          incidentId: this.incident.id,
          error: error,
        });
      });
    }

    await this.appsync.resolveItem(event, this.incident.payload, this.isReopenIncident, this.isOpenWorkItem);

    this.workItemVisibilityService.stopHeartbeat();

    if (this.incidentIsPSS) {
      this.acknowledgementService.subscribe(this.incident.workItemId);
    }

    if (!this.incidentIsPSS) {
      this.appsync.handleNextWorkItem();
    } else {
      this.enableNextSection.emit();
    }
  }

  filterEmailTemplates(): void {
    if (this.emailTemplates) {
      let filteredTemplates: EmailTemplate[] = [...this.emailTemplates];

      // If this is a QCON or PSS, filter templates based on whether the content is being quarantined or not.
      if (this.isQconPss) {
        filteredTemplates = filteredTemplates.reduce((matchingTemplates, template) => {
          if (
            (template.category === ActionReason.NUDITY && template.forQuarantineItemsOnly === null) ||
            template.forQuarantineItemsOnly === undefined ||
            template.forQuarantineItemsOnly === this.quarantined
          ) {
            matchingTemplates.push(template);
          } else if (template.category != ActionReason.NUDITY) {
            matchingTemplates.push(template);
          }
          return matchingTemplates;
        }, [] as EmailTemplate[]);
      }

      this.hasMultipleIncidentTemplates = filteredTemplates.some((t) => t.forMultipleItemsOnly);
      if (this.hasMultipleIncidentTemplates) {
        filteredTemplates = this.multipleOptionEnabled
          ? filteredTemplates.filter((t) => t.forMultipleItemsOnly)
          : filteredTemplates.filter((t) => !t.forMultipleItemsOnly);
      }

      if (filteredTemplates.length > 1) {
        filteredTemplates = this.sortDropdownOptions(filteredTemplates);
      }

      this.availableTemplates = filteredTemplates;

      this._updateSubjectAndBody();
    } else {
      this.appSyncClient.logClientEvent(LoggingLevel.WARNING, "Email content component: no templates available to filter", {
        workItemId: this.state.currentItemDetails.id,
        workItemType: this.state.currentItemDetails.workItemEntityType.valueOf(),
        isQuarantined: this.quarantined,
        isMultiple: this.multipleOptionEnabled,
      });
    }
  }

  private _updateSubjectAndBody(): void {
    if (this.hasEmailTemplates) {
      // If there are multiple templates available (either standard or other), clear the subject and body
      this._setSubjectAndBody(null);
      return;
    } else if (this._hasOnlyOneTemplate(this.availableTemplates) && this._hasOnlyOneTemplate(this.otherTemplates)) {
      // If there is one standard template and one other template, clear the subject and body.
      this._setSubjectAndBody(null);
      return;
    } else if (this._hasOnlyOneTemplate(this.availableTemplates)) {
      // Set the subject and body based on the available standard template
      this._setSubjectAndBody(this.availableTemplates[0]);
      return;
    } else if (this._hasOnlyOneTemplate(this.otherTemplates)) {
      // There are no standard templates available, so set subject and body to other template available
      this._setSubjectAndBody(this.otherTemplates[0]);
      return;
    } else {
      this._setSubjectAndBody(null);
    }
    // Otherwise, DON'T UPDATE ANYTHING!!!
  }

  private _hasOnlyOneTemplate(templateList: EmailTemplate[]): boolean {
    return templateList?.length === 1;
  }

  private _setSubjectAndBody(template: EmailTemplate): void {
    if (template) {
      this.selectedTemplate = template;
      this.incident.payload.emailSubject = template.subject;
      this.incident.payload.emailBody = template.body;
    } else {
      this.incident.payload.emailSubject = this.incident.payload.emailBody = "";
    }
  }

  private sortDropdownOptions(templates: EmailTemplate[]): EmailTemplate[] {
    return templates.sort((t1, t2) => t1.dropdownOption.localeCompare(t2.dropdownOption));
  }

  private findMatchingTemplate(templatesToSearch, dropdownText): EmailTemplate {
    return templatesToSearch.find((template) => template.dropdownOption.trim() === dropdownText);
  }

  private filterTemplatesWithoutDropdown(templates): EmailTemplate[] {
    return templates
      .map((t) => {
        if (t.dropdownOption?.trim().length > 0) {
          return {
            ...t,
            dropdownOption: t.dropdownOption.trim(),
          };
        } else {
          return t;
        }
      })
      .filter((t) => t);
  }

  private captureAnalytics(): void {
    let uiAnalyticsEvent = UiEventAction.CreateViolation;
    let uiWorkEnded = true;

    if (this.incidentIsPSS) {
      uiAnalyticsEvent = UiEventAction.CreatePss;
      uiWorkEnded = false;
    } else if (this.incidentIsQCON) {
      uiAnalyticsEvent = UiEventAction.CreateQcon;
      uiWorkEnded = true;
    }

    this.uiAnalyticsService.sendAction(uiAnalyticsEvent, uiWorkEnded);
  }

  private replaceVariablesForAudit(template: EmailTemplate): any {
    const updatedSubject = template.originalSubject.replace("%INAPPROPRIATE_CONTENT_REASONS%", template.inappropriateContentReasons);

    const updatedBody = template.originalBody
      .replace("%USER_EMAIL%", "user email")
      .replace("%USER_FULL_NAME%", "user full name")
      .replace("%ITEM_ID%", this.state.currentItemDetails?.id || "work item id")
      .replace("%SAFETY_TEAM_NAME%", "");

    return {
      updatedSubject,
      updatedBody,
    };
  }
}
