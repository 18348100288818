import { ApolloLink } from "@apollo/client/core";
import { StateService } from "src/app/shared/services/state.service";
import { isSubscriptionOperation } from "src/app/features/appsync/utils";

/**
 * Automatically manages the spinner count in the state service to ensure the all
 * backend calls that should trigger the spinner do. When the call returns, this
 * will ensure that the count is updated appropriately, automatically hiding the
 * spinner when the last backend call completes.
 */
export const createStatusLink = (stateService: StateService): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    // If this is a subscription or if bypassSpinner has been
    // specified in context, we don't want to show the spinner
    const bypassSpinner = operation.getContext().bypassSpinner;
    const isSubscription = isSubscriptionOperation(operation);
    if (!bypassSpinner && !isSubscription) {
      stateService.useFullPageSpinner(true);
    }

    return forward(operation).map((data) => {
      if (!bypassSpinner && !isSubscription) {
        stateService.useFullPageSpinner(false);
      }
      return data;
    });
  });
};
