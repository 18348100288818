import { ActionReason } from "./work-item.model";
import { WarningEventType } from "src/generated/graphql";

export interface ContentCategory {
  key: string;
  title: string;
  actionReason?: ActionReason;
  descriptions?: string[];
  isPssQcon: boolean;
  isSelected: boolean;
  warningType: WarningEventType;
  hotkey: string;
  shouldQuarantineOnPSS: boolean;
  shouldQuarantineOnQcon: boolean;
  featureFlagCondition?: string;
}

export const contentCategories: ContentCategory[] = [
  {
    key: "profanity",
    title: "Profanity / racism / inappropriate language",
    actionReason: ActionReason.PROFANITY,
    isPssQcon: false,
    isSelected: false,
    warningType: WarningEventType.Violation,
    hotkey: "z",
    shouldQuarantineOnPSS: false,
    shouldQuarantineOnQcon: false,
  },
  {
    key: "usage-restrictions",
    title: "Usage restrictions",
    actionReason: ActionReason.RESTRICTED_USE,
    isPssQcon: false,
    isSelected: false,
    warningType: WarningEventType.Violation,
    hotkey: "r",
    shouldQuarantineOnPSS: false,
    shouldQuarantineOnQcon: false,
  },
  {
    key: "inappropriate-content",
    title: "Inappropriate content",
    actionReason: ActionReason.GENERAL,
    isPssQcon: false,
    isSelected: false,
    warningType: WarningEventType.Violation,
    hotkey: "g",
    shouldQuarantineOnPSS: false,
    shouldQuarantineOnQcon: false,
  },
  {
    key: "inappropriate-content-major",
    title: "Inappropriate content (major)",
    actionReason: ActionReason.GENERAL,
    descriptions: ["Inappropriate content of a serious nature", "(gang violence, running away, suspect trend)"],
    isPssQcon: true,
    isSelected: false,
    warningType: WarningEventType.QuestionableContent,
    hotkey: "G",
    shouldQuarantineOnPSS: false,
    shouldQuarantineOnQcon: false,
  },
  {
    key: "nudity",
    title: "Nudity",
    actionReason: ActionReason.NUDITY,
    isPssQcon: false,
    isSelected: false,
    warningType: WarningEventType.Violation,
    hotkey: "n",
    shouldQuarantineOnPSS: false,
    shouldQuarantineOnQcon: false,
  },
  {
    key: "nudity-major",
    title: "Nudity (major)",
    actionReason: ActionReason.NUDITY,
    descriptions: ["Pornography involving a minor (PSS only)", "Pornography of a professional nature"],
    isPssQcon: true,
    isSelected: false,
    warningType: WarningEventType.QuestionableContent,
    hotkey: "N",
    shouldQuarantineOnPSS: true,
    shouldQuarantineOnQcon: true,
  },
  {
    key: "sexual-content",
    title: "Sexual Content",
    actionReason: ActionReason.SEXUAL_CONTENT,
    descriptions: ["Suggestive or provocative content"],
    isPssQcon: false,
    isSelected: false,
    warningType: WarningEventType.Violation,
    hotkey: "x",
    shouldQuarantineOnPSS: false,
    shouldQuarantineOnQcon: false,
  },
  {
    key: "sexual-content-major",
    title: "Sexual Content (major)",
    actionReason: ActionReason.SEXUAL_CONTENT,
    descriptions: ["Inappropriate sexual activity involving a student"],
    isPssQcon: true,
    isSelected: false,
    warningType: WarningEventType.QuestionableContent,
    hotkey: "X",
    shouldQuarantineOnPSS: false,
    shouldQuarantineOnQcon: false,
  },
  {
    key: "drug-use",
    title: "Alcohol / smoking / drug use",
    actionReason: ActionReason.DRUG_USE,
    isPssQcon: true,
    isSelected: false,
    warningType: WarningEventType.QuestionableContent,
    hotkey: "a",
    shouldQuarantineOnPSS: false,
    shouldQuarantineOnQcon: false,
  },
  {
    key: "harassment",
    title: "Harassment",
    actionReason: ActionReason.HARASSMENT,
    isPssQcon: true,
    isSelected: false,
    warningType: WarningEventType.QuestionableContent,
    hotkey: "h",
    shouldQuarantineOnPSS: false,
    shouldQuarantineOnQcon: false,
  },
  {
    key: "violence-others",
    title: "Violence / rape / abuse (others)",
    actionReason: ActionReason.VIOLENCE_OTHERS,
    isPssQcon: true,
    isSelected: false,
    warningType: WarningEventType.QuestionableContent,
    hotkey: "v",
    shouldQuarantineOnPSS: false,
    shouldQuarantineOnQcon: false,
  },
  {
    key: "self-harm",
    title: "Self-harm",
    actionReason: ActionReason.SELF_HARM,
    isPssQcon: true,
    isSelected: false,
    warningType: WarningEventType.QuestionableContent,
    hotkey: "S",
    shouldQuarantineOnPSS: false,
    shouldQuarantineOnQcon: false,
  },
  {
    key: "suicide",
    title: "Suicide",
    actionReason: ActionReason.SUICIDE,
    isPssQcon: true,
    isSelected: false,
    warningType: WarningEventType.QuestionableContent,
    hotkey: "s",
    shouldQuarantineOnPSS: false,
    shouldQuarantineOnQcon: false,
  },
];
