export interface FeatureFlag {
  name: string;
  value: string;
  configuration?: string;
}

// Enumeration of feature flags to be retrieved from Split.io.
// All flags in this enumeration will be fetched from Split.io
// every two minutes.
export enum FeatureFlagName {
  APPSYNC_URL_OVERRIDE = "thndr-appsync-url-override",
  DISPLAY_SEXUAL_CONTENT = "l2ui_sexual_content_category",
  REQUEST_REVIEW_OPTION = "l2ui_request_review_option",
  NCMEC = "l2ui_enable_ncmec",
  NCMEC_SAFETY_AUDIT = "l2ui_ncmec_safety_audit",
  GAGGLE_AFTER_HOURS = "l2ui_gaft",
  L2_AUDIT = "l2ui_audit",
  PSAP = "l2ui_psap",
  PSAP_LINK = "l2ui_psap_hyperlink_address",
  REOPEN = "thndr-l2ui-reopen",
  CANVAS_DOWNLOADS = "thndr-cors-enabled-buckets",
  L2_TEXT_MESSAGING = "l2ui_text_messaging",
  L2_SALESFORCE = "l2ui-salesforce",
  L2_ADMIN_UI = "l2ui-admin-ui",
  CREATE_INCIDENT_FROM_STANDARD_QUEUE = "thndr-l2ui-create-incident-from-standard-queue",
}

export enum WorkItemFeatureFlagName {
  AUTO_PSS_SUICIDE = "l2ui_pss_suicide_by_district",
  SMS_INTEGRATION = "l2ui_sms_integration",
  EC_INCIDENT_SOURCE = "ateam-incident-source",
}

export enum FeatureFlagValue {
  SHOW = "show",
  HIDE = "hide",
  NOT_SET = "control",
  ON = "on",
  OFF = "off",
}
