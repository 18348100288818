import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlideDrawerComponent } from "./slide-drawer.component";
import { SlideDrawerHeaderDirective } from "./slide-drawer-header.directive";
import { SlideDrawerContentDirective } from "./slide-drawer-content.directive";
import { SlideDrawerFooterDirective } from "./slide-drawer-footer.directive";
import { GaggleUiCommonModule } from "gaggle-ui-common";

const declarations = [SlideDrawerComponent, SlideDrawerHeaderDirective, SlideDrawerContentDirective, SlideDrawerFooterDirective];

@NgModule({
  imports: [CommonModule, GaggleUiCommonModule],
  declarations: [...declarations],
  exports: [...declarations],
})
export class SlideDrawerModule {}
