import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/shared/services/auth.service";
import { StateService } from "src/app/shared/services/state.service";
import { AppSyncService } from "src/app/shared/services/appsync.service";

@Component({
  selector: "app-authenticated",
  templateUrl: "./authenticated.component.html",
  styleUrls: ["./authenticated.component.scss"],
})
export class AuthenticatedComponent implements OnInit {
  constructor(private authService: AuthService, private stateService: StateService, private appSync: AppSyncService) {}

  async ngOnInit(): Promise<void> {
    this.stateService.authenticated$.subscribe((authenticated) => {
      if (authenticated) {
        this.appSync.getFeatureFlags();
      }
    });
  }

  get signedIn(): boolean {
    return this.authService.signedIn();
  }

  get validGaggleUser(): boolean {
    // always returns true once you're logged in - not actually checking L2UI auth
    return this.authService.validGaggleUser();
  }

  get username(): string {
    return this.authService.getUsername();
  }
}
