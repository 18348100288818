<app-district-notes></app-district-notes>
<div
    *ngIf="showActionBar"
    class="actions">
    <ng-container *ngFor="let button of buttons">
        <ng-container *ngIf="button.useDropdown(); else normalButton">
            <app-action-button
                *ngIf="button.shouldDisplay()"
                [disabled]="!itemDetails"
                [matMenuTriggerFor]="buttonMenu"
                [label]="button.label()"
                [icon]="button.icon"
                [iconSize]="button.iconSize || 'L'"
                [buttonType]="button.buttonType"
                [attr.data-cy]="button.cy"
                [tooltip]="!itemDetails ? null : button.tooltip"
                [ngClass]="{ 'double-wide': button.useSpecialSizing() }"
                [hasDropdown]="true"></app-action-button>

            <mat-menu #buttonMenu="matMenu">
                <ng-container *ngFor="let option of button.dropdownOptions">
                    <button
                        mat-menu-item
                        (click)="option.onClick()"
                        [attr.data-testid]="option.testId">
                        <span>{{ option.label }}</span>
                    </button>
                </ng-container>
            </mat-menu>
        </ng-container>
        <ng-template #normalButton>
            <app-action-button
                *ngIf="button.shouldDisplay()"
                [disabled]="!itemDetails"
                (buttonClick)="button.onClick()"
                [label]="button.label()"
                [icon]="button.icon"
                [iconSize]="button.iconSize || 'L'"
                [buttonType]="button.buttonType"
                [attr.data-cy]="button.cy"
                [tooltip]="!itemDetails ? null : button.tooltip"
                [ngClass]="{ 'double-wide': button.useSpecialSizing() }"></app-action-button>
        </ng-template>
    </ng-container>
</div>
