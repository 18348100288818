<app-expandable-panel data-testid="reason-panel" [isExpanded]="showExpanded">
    <ng-template appPanelHeader>
        <div class="new-row">
            <div class="label">{{ headerLabel }}:</div>
            <div class="text" [innerHTML]="headerText | removeZalgo | highlightUrl"></div>
        </div>
        <div class="new-row" *ngIf="blockReason.moreExcerpts && !hideAdditionalExcerpts">
            <div class="label"></div>
            <div class="text more-excerpts" data-testid="more-excerpts">
                <mat-icon>info</mat-icon>
                <span>More than five excerpts exists, but only the first five can be shown.</span>
            </div>
        </div>
    </ng-template>
    <ng-template appPanelContent *ngIf="content.length > 0">
        <div *ngFor="let row of content" class="new-row" data-testid="row">
            <div class="label">{{ row.label }}:</div>
            <div class="text" [innerHTML]="row.text | removeZalgo | highlightUrl"></div>
        </div>
    </ng-template>
    <ng-template appPanelFooter *ngIf="hasExcerpt">
        <ng-container *ngIf="translatedText.length > 0">
            <div class="translated-content">
                <div class="translate-header">Translation</div>
                <ng-container *ngFor="let translation of translatedText">{{ translation }}</ng-container>
            </div>
        </ng-container>
        <gaggle-button
            (buttonClick)="translateContent(excerpt)"
            label="Translate Excerpt"
            iconSize="L"
            icon="translate-text"
            buttonStyle="link"
            [disabled]="currentlyTranslating"
            [showSpinner]="currentlyTranslating"
            [backgroundColor]="overridePrimaryButtonColor"
            [textColor]="overrideTextColor"
            [iconColor]="overrideTextColor"></gaggle-button>
    </ng-template>
</app-expandable-panel>
