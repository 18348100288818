import { Component, Input, OnInit } from "@angular/core";
import { StateService } from "src/app/shared/services/state.service";
import { HighlightKeywordPipe } from "src/app/shared/pipes/highlightKeyword.pipe";
import { AppSyncService } from "src/app/shared/services/appsync.service";

@Component({
  selector: "app-cal-preview-panel",
  templateUrl: "./cal-preview-panel.component.html",
  styleUrls: ["./cal-preview-panel.component.scss"],
  providers: [HighlightKeywordPipe]
})
export class CalPreviewPanelComponent implements OnInit {
  @Input() keywords: string[];
  workItem;
  eventTitle: string;
  contentBody: string;

  constructor(
    private state: StateService,
    private highlightKeyword: HighlightKeywordPipe,
    private appsync: AppSyncService
  ) {}


  ngOnInit(): void {
    //get workItem from state
    this.state.currentItemDetails$.subscribe((data) => {
      if (data) {
        this.workItem = data;
        //format content for display in view
        if (this.workItem.content) {
          this.processContent(this.workItem.content);
        }
      }
    })
  }

  private processContent(content): void {
    //replacing line breaks with <br/>
    const lineBreakContent = content.replaceAll("\n", "<br />");

    //highlight keywords
    const processedContent = this.highlightKeywords(lineBreakContent);

    //breaking event title and body
    this.eventTitle = processedContent.split("<br />")[0];
    this.contentBody = processedContent.substring(processedContent.indexOf("<br />") + 6);
  }

  private highlightKeywords(text: string): string {
    if (!text?.length) {
      return "";
    }
    return this.keywords.reduce((accum, keyword) => this.highlightKeyword.transform(accum, keyword), text);
  }


  onClickTranslate(): void {
    this.appsync.translateContent(this.workItem.content, this.workItem.id);
  }
}
