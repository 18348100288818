import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment/moment";

@Pipe({ name: "formatTimer" })
export class FormatTimerPipe implements PipeTransform {
  transform(timestamp: number): string {
    if (!timestamp) {
      return null;
    }
    return moment(0).second(timestamp).format("mm:ss");
  }
}
