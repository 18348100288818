<app-search-navigation></app-search-navigation>

<ng-container *ngIf="itemDetails && !loadingItem">
    <div class="work-item-header">
        <div>
            <div class="detail-row">
                <p class="student-name bold">{{ itemDetails.userFullName }}</p>
            </div>
            <div class="detail-row">
                <div class="district">
                    <span class="bold">District:</span>
                    <span>{{ itemDetails.districtName }}</span>
                </div>
                <div class="school-group">
                    <span class="bold">School Group:</span>
                    <span>{{ itemDetails.groupName }}</span>
                </div>
            </div>
        </div>
        <div class="actions">
            <app-menu-button
                label="Reopen Item"
                icon="reload"
                (buttonClick)="createIncident()"
                [disabled]="isCreateIncidentButtonDisabled"
                *ngIf="!hasIncidents"></app-menu-button>
            <app-menu-button
                [label]="itemDetails.incidentIds.length === 1 ? 'View Incident' : 'Search Incidents'"
                [icon]="incidentIcon"
                (buttonClick)="viewIncident()"
                *ngIf="hasIncidents"></app-menu-button>
        </div>
    </div>
    <div class="item-summary-container">
        <app-item-details
            class="item-summary"
            [showMoreAttachments]="showMoreAttachments"
            [showMoreFileInfo]="showMoreFileInfo"
            [showMoreSchoolInfo]="showMoreSchoolInfo"
            [showMoreIncidentInfo]="showMoreIncidentInfo"
            [showEventInfo]="true"
            [showWorkItemSource]="true"
            (toggleSection)="toggleSection($event)"></app-item-details>

        <div class="item-preview">
            <app-district-notes></app-district-notes>
            <app-item-viewer [hideBundleToggle]="true"></app-item-viewer>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!itemDetails && !loadingItem">
    <div class="not-found">
        <p>
            No work item found under the id
            <code>{{ workItemId }}</code>
        </p>
    </div>
</ng-container>
