<div
    class="panel-wrapper"
    [ngClass]="{ 'with-buttons': !hideToggle }">
    <div class="panel-container">
        <div class="panel-content">
            <ng-container [ngTemplateOutlet]="panelHeader?.templateRef"></ng-container>
            <ng-container *ngIf="expanded">
                <ng-container [ngTemplateOutlet]="panelContent?.templateRef"></ng-container>
            </ng-container>
        </div>
        <div class="panel-buttons">
            <gaggle-icon
                iconName="chevron"
                [hidden]="hideToggle"
                class="toggle"
                [ngClass]="{ down: !expanded }"
                [color]="toggleColor"
                (click)="togglePanel()"
                data-testid="togglePanel"></gaggle-icon>
            <div
                *ngIf="expanded"
                data-testid="additionalPanelButtons"
                class="additional-buttons">
                <ng-container [ngTemplateOutlet]="panelButtons?.templateRef"></ng-container>
            </div>
        </div>
    </div>
    <div
        class="panel-footer"
        data-testid="panel-footer"
        *ngIf="expanded && panelFooter?.templateRef">
        <ng-container [ngTemplateOutlet]="panelFooter?.templateRef"></ng-container>
    </div>
</div>
