export enum SearchFields {
  Sort = "sort",
  PageSize = "pageSize",
  PageNumber = "pageNumber",
  Keyword = "keyword",
  Ids = "ids",
  WorkerId = "workerId",
  ProcessedDate = "processedDate",
  DistrictId = "districtId",
  GroupId = "groupId",
  GroupIds = "groupIds",
  UserId = "userId",
  UserIdShort = "userIdShort",
  Username = "username",
  WorkItemTypes = "workItemTypes",
  WorkItemStatus = "workItemStatus",
  Events = "events",
  FileName = "fileName",
  HasIncidents = "hasIncidents",
  OccurrenceDate = "occurrenceDate",
  CreatedDate = "createdDate",
  CompletedDate = "completedDate",
  IncidentTypes = "incidentTypes",
  ContentCategories = "contentCategories",
  ExcludeMlItems = "excludeMLItems",
}
