import { ConfigService } from "@shared/services/config.service";
import { SplitService } from "@splitsoftware/splitio-angular";
import { AuthService } from "@shared/services/auth.service";
import { StateService } from "@shared/services/state.service";
import { ThemeService } from "@shared/services/theme.service";
import { SwUpdateService } from "@core/services/sw-update.service";
import { setTag } from "@sentry/angular-ivy";
import { environment } from "src/environments/environment";

const printStep = (message: string) => {
  if (environment.name !== "production") {
    console.info("AppInitializerFactory: " + message);
  }
};

const initializeSplit = async (splitService: SplitService, configService: ConfigService): Promise<void> => {
  const configuration = {
    core: {
      authorizationKey: configService.getConfig().splitIoAuthKey,
      key: "key",
    },
  };

  new Promise<void>((resolve) => {
    // Only wait for 1 second for the split service to initialize
    const timeout = setTimeout(() => {
      printStep("Split.io service initialization timeout.");
      resolve();
    }, 1000);

    splitService.init(configuration).subscribe({
      next: () => {
        printStep("Split.io service initialized.");
        clearTimeout(timeout);
        resolve();
      },
      error: (error) => console.error(error),
    });
  });
};

export const AppInitializerFactory = (
  configService: ConfigService,
  authService: AuthService,
  stateService: StateService,
  splitService: SplitService,
  themeService: ThemeService,
  swUpdateService: SwUpdateService,
): (() => Promise<any>) => {
  return async (): Promise<any> => {
    printStep("Initializing application.");

    printStep("Checking for application updates.");
    await swUpdateService.checkForUpdates("INIT");

    printStep("Loading application configuration.");
    await configService.load();

    // Skip the remainder of initialization for connection test page
    if (window.location.pathname === "/connection-test") {
      return;
    }

    printStep("Initializing Split.io service.");
    await initializeSplit(splitService, configService);

    printStep("Setting environment tag for Sentry.");
    setTag("gaggle_env", configService.getConfig().environment);

    printStep("Setting theme on application start.");
    themeService.setThemeOnStart();

    printStep("Initializing authentication service.");
    await authService.initialize();

    printStep("Initializing state service.");
    await stateService.initialize();

    printStep("Application initialization complete.");
  };
};
