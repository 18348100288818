<div
    class="input-container"
    [ngClass]="{ 'vertical-style': verticalStyle, 'hide-border': hideBorder }">
    <label
        [for]="name"
        [ngClass]="{ required: required }">
        {{ label }}</label
    >
    <span
        class="input-header-wrapper"
        (click)="bringFocusToTextArea()">
        <ng-content></ng-content>
        <textarea
            [id]="name"
            [name]="name"
            [value]="value"
            [required]="required"
            [readonly]="readonly"
            (input)="setValue($event)"
            (paste)="setValue($event)"
            [placeholder]="placeholder"
            [disabled]="disabled"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="5"
            [ngClass]="{ 'disable-resize': disableResize }">
        </textarea>
    </span>
</div>
