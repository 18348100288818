import { inject, Injectable, Injector } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { EmailTemplate, EmailTemplates, PriorIncidents, SMSTemplate } from "src/app/models/incident.model";
import { CombinedQueueType, QueueStatistics } from "src/app/models/queue-statistics.model";
import {
  DownloadInfo,
  ExternalMetadata,
  MessageMetadata,
  WorkItem,
  WorkItemType,
  FileCommentContent,
} from "src/app/models/work-item.model";
import { FeatureFlag, FeatureFlagName, FeatureFlagValue } from "src/app/models/feature-flags.model";
import { InternalWorkItemStatus } from "src/app/models/work-item-incident.model";
import { contentCategories, ContentCategory } from "src/app/models/content-category.model";
import { WarningEventType, Incident, UiQueue } from "src/generated/graphql";
import { v4 as uuid } from "uuid";
import { setTag } from "@sentry/angular-ivy";
import { SplitService } from "@splitsoftware/splitio-angular";
import { AuthService } from "@shared/services/auth.service";

interface SectionTimer {
  timer: NodeJS.Timeout;
  timerCount: number;
}

interface SectionTimers {
  [index: string]: SectionTimer;
}

@Injectable({
  providedIn: "root",
})
export class StateService {
  constructor() {}

  private splitService: SplitService;
  private authService: AuthService;
  private injector = inject(Injector);

  timer: NodeJS.Timeout;
  incidentSectionTimers: SectionTimers = {};

  private spinnerTimer: NodeJS.Timeout;
  private spinnerCount = 0;
  private templateSpinnerCount = 0;

  private readonly _newApplicationAvailable = new BehaviorSubject<boolean>(false);
  readonly newApplicationAvailable$ = this._newApplicationAvailable.asObservable();

  private readonly _environment = new BehaviorSubject<string>("local");
  readonly environment$ = this._environment.asObservable();

  private readonly _gewiBaseUrl = new BehaviorSubject<string>("");
  readonly gewiBaseUrl$ = this._gewiBaseUrl.asObservable();

  private readonly _currentItemDetails = new BehaviorSubject<WorkItem>(null);
  readonly currentItemDetails$ = this._currentItemDetails.asObservable();

  private readonly _currentInternalWorkItemStatus = new BehaviorSubject<InternalWorkItemStatus>(null);
  readonly currentInternalWorkItemStatus$ = this._currentInternalWorkItemStatus.asObservable();

  private readonly _currentIncidentDetails = new BehaviorSubject<Incident>(null);
  readonly currentIncidentDetails$ = this._currentIncidentDetails.asObservable();

  private readonly _workItemTimer = new BehaviorSubject<number>(0);
  readonly workItemTimer$ = this._workItemTimer.asObservable();

  private readonly _availableLanguages = new BehaviorSubject<any>({});
  readonly availableLanguages$ = this._availableLanguages.asObservable();

  private readonly _validDownloadTypes = new BehaviorSubject<WorkItemType[]>([]);
  readonly validDownloadTypes$ = this._validDownloadTypes.asObservable();

  private readonly _queues = new BehaviorSubject<QueueStatistics[]>([]);
  readonly queues$ = this._queues.asObservable();

  private readonly _auditQueue = new BehaviorSubject<QueueStatistics>(null);
  readonly auditQueue$ = this._auditQueue.asObservable();

  private readonly _showFullPageLoadingSpinner = new BehaviorSubject<boolean>(false);
  readonly showFullPageLoadingSpinner$ = this._showFullPageLoadingSpinner.asObservable();

  private readonly _showRightDrawer = new BehaviorSubject<boolean>(false);
  readonly showRightDrawer$ = this._showRightDrawer.asObservable();

  private readonly _showLeftDrawer = new BehaviorSubject<boolean>(true);
  readonly showLeftDrawer$ = this._showLeftDrawer.asObservable();

  private readonly _newIncident = new BehaviorSubject<Incident>({} as Incident);
  readonly newIncident$ = this._newIncident.asObservable();

  private readonly _currentWarningLevel = new BehaviorSubject<WarningEventType>(WarningEventType.FirstWarning);
  readonly currentWarningLevel$ = this._currentWarningLevel.asObservable();

  private readonly _itemIsInAfterHoursWindow = new BehaviorSubject<boolean>(false);
  readonly itemIsInAfterHoursWindow$ = this._itemIsInAfterHoursWindow.asObservable();

  private readonly _messageMetadata = new BehaviorSubject<MessageMetadata>(null);
  readonly messageMetadata$ = this._messageMetadata.asObservable();

  private readonly _fileComments = new BehaviorSubject<FileCommentContent>(null);
  readonly fileComments$ = this._fileComments.asObservable();

  private readonly _externalMetadata = new BehaviorSubject<ExternalMetadata>(null);
  readonly externalMetadata$ = this._externalMetadata.asObservable();

  private readonly _chatContext = new BehaviorSubject<string>(null);
  readonly chatContext$ = this._chatContext.asObservable();

  private readonly _loadingMessageMetadata = new BehaviorSubject<boolean>(false);
  readonly loadingMessageMetadata$ = this._loadingMessageMetadata.asObservable();

  private readonly _shouldQuarantineContent = new BehaviorSubject<boolean>(false);
  readonly shouldQuarantineContent$ = this._shouldQuarantineContent.asObservable();

  private readonly _hasPSSPermission = new BehaviorSubject<boolean>(false);
  readonly hasPSSPermission$ = this._hasPSSPermission.asObservable();

  private readonly _shouldToggleEscalatePSS = new BehaviorSubject<boolean>(false);
  readonly shouldToggleEscalatePSS$ = this._shouldToggleEscalatePSS.asObservable();

  private readonly _SMSTemplates = new BehaviorSubject<SMSTemplate[]>(null);
  readonly SMSTemplates$ = this._SMSTemplates.asObservable();

  private readonly _workItemEmailTemplates = new BehaviorSubject<EmailTemplate[]>(null);
  readonly workItemEmailTemplates$ = this._workItemEmailTemplates.asObservable();

  private readonly _workItemOtherQconEmailTemplates = new BehaviorSubject<EmailTemplate[]>(null);
  readonly workItemOtherQconEmailTemplates$ = this._workItemOtherQconEmailTemplates.asObservable();

  private readonly _workItemOtherPssEmailTemplates = new BehaviorSubject<EmailTemplate[]>(null);
  readonly workItemOtherPssEmailTemplates$ = this._workItemOtherPssEmailTemplates.asObservable();

  private readonly _allEmailTemplates = new BehaviorSubject<EmailTemplates>({});
  readonly allEmailTemplates$ = this._allEmailTemplates.asObservable();

  private readonly _currentQueueType = new BehaviorSubject<CombinedQueueType>(null);
  readonly currentQueueType$ = this._currentQueueType.asObservable();

  private readonly _priorIncidents = new BehaviorSubject<PriorIncidents>(null);
  readonly priorIncidents$ = this._priorIncidents.asObservable();

  private readonly _shouldAttachFiles = new BehaviorSubject<boolean>(true);
  readonly shouldAttachFiles$ = this._shouldAttachFiles.asObservable();

  private readonly _nuditySelected = new BehaviorSubject<boolean>(true);
  readonly nuditySelected$ = this._nuditySelected.asObservable();

  private readonly _itemUnbundled = new BehaviorSubject<boolean>(false);
  readonly itemUnbundled$ = this._itemUnbundled.asObservable();

  private readonly _isAnyCategorySelected = new BehaviorSubject<boolean>(false);
  readonly isAnyCategorySelected$ = this._isAnyCategorySelected.asObservable();

  private readonly _downloadLink = new BehaviorSubject<DownloadInfo>(null);
  readonly downloadLink$ = this._downloadLink.asObservable();

  private readonly _isItemActionBarVisible = new BehaviorSubject<boolean>(false);
  readonly isItemActionBarVisible$ = this._isItemActionBarVisible.asObservable();

  private readonly _featureFlags = new BehaviorSubject<FeatureFlag[]>([]);
  readonly featureFlags$ = this._featureFlags.asObservable();

  private readonly _workItemFeatureFlags = new BehaviorSubject<FeatureFlag[]>([]);
  readonly workItemFeatureFlags$ = this._workItemFeatureFlags.asObservable();

  private readonly _loadingTemplates = new BehaviorSubject<boolean>(false);
  readonly loadingTemplates$ = this._loadingTemplates.asObservable();

  private readonly _appSyncError = new BehaviorSubject<string>("");
  readonly appSyncError$ = this._appSyncError.asObservable();

  private readonly _authenticated = new BehaviorSubject<boolean>(false);
  readonly authenticated$ = this._authenticated.asObservable();

  private readonly _selectedCategories = new BehaviorSubject<ContentCategory[]>(null);
  readonly selectedCategories$ = this._selectedCategories.asObservable();

  private readonly _activeCall$ = new BehaviorSubject<boolean>(false);
  public activeCall$ = this._activeCall$.asObservable();

  protected readonly _uiSessionId$ = new BehaviorSubject<string>("");

  protected readonly _userCanWarnOnItem$ = new BehaviorSubject<boolean>(false);
  public userCanWarnOnItem$ = this._userCanWarnOnItem$.asObservable();

  get userCanWarnOnItem(): boolean {
    return this._userCanWarnOnItem$.getValue();
  }

  get uiSessionId(): string {
    return this._uiSessionId$.getValue();
  }

  set uiSessionId(val: string) {
    this._uiSessionId$.next(val);
  }

  get environment(): string {
    return this._environment.getValue();
  }

  set environment(val: string) {
    this._environment.next(val);
  }

  set newApplicationAvailable(val: boolean) {
    this._newApplicationAvailable.next(val);
  }

  get activeCall(): boolean {
    return this._activeCall$.getValue();
  }

  set activeCall(val: boolean) {
    this._activeCall$.next(val);
  }

  get gewiBaseUrl(): string {
    return this._gewiBaseUrl.getValue();
  }

  set gewiBaseUrl(val: string) {
    this._gewiBaseUrl.next(val);
  }

  get currentItemDetails(): WorkItem {
    return this._currentItemDetails.getValue();
  }

  set currentItemDetails(workItem: WorkItem) {
    if (workItem) {
      this.determineIfUserCanWarnOnItem(workItem);
    }
    this._currentItemDetails.next(workItem);
  }

  get currentInternalWorkItemStatus(): InternalWorkItemStatus {
    return this._currentInternalWorkItemStatus.getValue();
  }

  set currentInternalWorkItemStatus(val: InternalWorkItemStatus) {
    this._currentInternalWorkItemStatus.next(val);
  }

  get currentIncidentDetails(): Incident {
    return this._currentIncidentDetails.getValue();
  }

  set currentIncidentDetails(val: Incident) {
    this._currentIncidentDetails.next(val);
  }

  get workItemTimer(): number {
    return this._workItemTimer.getValue();
  }

  set workItemTimer(val: number) {
    this._workItemTimer.next(val);
  }

  get availableLanguages(): any {
    return this._availableLanguages.getValue();
  }

  set availableLanguages(val: any) {
    this._availableLanguages.next(val);
  }

  get validDownloadTypes(): WorkItemType[] {
    return this._validDownloadTypes.getValue();
  }

  set validDownloadTypes(val: WorkItemType[]) {
    this._validDownloadTypes.next(val);
  }

  get queues(): QueueStatistics[] {
    return this._queues.getValue();
  }

  set queues(val: QueueStatistics[]) {
    this._queues.next(val);
  }

  get auditQueue(): QueueStatistics {
    return this._auditQueue.getValue();
  }

  set auditQueue(val: QueueStatistics) {
    this._auditQueue.next(val);
  }

  get showFullPageLoadingSpinner(): boolean {
    return this._showFullPageLoadingSpinner.getValue();
  }

  set showFullPageLoadingSpinner(val: boolean) {
    this._showFullPageLoadingSpinner.next(val);
  }

  get showRightDrawer(): boolean {
    return this._showRightDrawer.getValue();
  }

  set showRightDrawer(val: boolean) {
    this._showRightDrawer.next(val);
  }

  get showLeftDrawer(): boolean {
    return this._showLeftDrawer.getValue();
  }

  set showLeftDrawer(val: boolean) {
    this._showLeftDrawer.next(val);
  }

  get newIncident(): Incident {
    return this._newIncident.getValue();
  }

  set newIncident(val: Incident) {
    this._newIncident.next(val);
  }

  get currentWarningLevel(): WarningEventType {
    return this._currentWarningLevel.getValue();
  }

  set currentWarningLevel(val: WarningEventType) {
    this._currentWarningLevel.next(val);
  }

  get itemIsInAfterHoursWindow(): boolean {
    return this._itemIsInAfterHoursWindow.getValue();
  }

  set itemIsInAfterHoursWindow(val: boolean) {
    this._itemIsInAfterHoursWindow.next(val);
  }

  get messageMetadata(): MessageMetadata {
    return this._messageMetadata.getValue();
  }

  set messageMetadata(val: MessageMetadata) {
    this._messageMetadata.next(val);
  }

  get fileComments(): FileCommentContent {
    return this._fileComments.getValue();
  }

  set fileComments(val: FileCommentContent) {
    this._fileComments.next(val);
  }

  get externalMetadata(): ExternalMetadata {
    return this._externalMetadata.getValue();
  }

  set externalMetadata(val: ExternalMetadata) {
    this._externalMetadata.next(val);
  }

  get chatContext(): string {
    return this._chatContext.getValue();
  }

  set chatContext(val: string) {
    this._chatContext.next(val);
  }

  get loadingMessageMetadata(): boolean {
    return this._loadingMessageMetadata.getValue();
  }

  set loadingMessageMetadata(val: boolean) {
    this._loadingMessageMetadata.next(val);
  }

  get shouldQuarantineContent(): boolean {
    return this._shouldQuarantineContent.getValue();
  }

  set shouldQuarantineContent(val: boolean) {
    this._shouldQuarantineContent.next(val);
  }

  get shouldToggleEscalatePSS(): boolean {
    return this._shouldToggleEscalatePSS.getValue();
  }

  set shouldToggleEscalatePSS(val: boolean) {
    this._shouldToggleEscalatePSS.next(val);
  }

  get hasPSSPermission(): boolean {
    return this._hasPSSPermission.getValue();
  }

  set hasPSSPermission(val: boolean) {
    this._hasPSSPermission.next(val);
  }

  get SMSTemplates(): SMSTemplate[] {
    return this._SMSTemplates.getValue();
  }

  set SMSTemplates(val: SMSTemplate[]) {
    this._SMSTemplates.next(val);
  }

  get workItemEmailTemplates(): EmailTemplate[] {
    return this._workItemEmailTemplates.getValue();
  }

  set workItemEmailTemplates(val: EmailTemplate[]) {
    this._workItemEmailTemplates.next(val);
  }

  get workItemOtherQconEmailTemplates(): EmailTemplate[] {
    return this._workItemOtherQconEmailTemplates.getValue();
  }

  set workItemOtherQconEmailTemplates(val: EmailTemplate[]) {
    this._workItemOtherQconEmailTemplates.next(val);
  }

  get workItemOtherPssEmailTemplates(): EmailTemplate[] {
    return this._workItemOtherPssEmailTemplates.getValue();
  }

  set workItemOtherPssEmailTemplates(val: EmailTemplate[]) {
    this._workItemOtherPssEmailTemplates.next(val);
  }

  get allEmailTemplates(): EmailTemplates {
    return this._allEmailTemplates.getValue();
  }

  set allEmailTemplates(val: EmailTemplates) {
    this._allEmailTemplates.next(val);
  }

  get currentQueueType(): CombinedQueueType {
    return this._currentQueueType.getValue();
  }

  set currentQueueType(val: CombinedQueueType) {
    this._currentQueueType.next(val);
  }

  get priorIncidents(): PriorIncidents {
    return this._priorIncidents.getValue();
  }

  set priorIncidents(val: PriorIncidents) {
    this._priorIncidents.next(val);
  }

  get shouldAttachFiles(): boolean {
    return this._shouldAttachFiles.getValue();
  }

  set shouldAttachFiles(val: boolean) {
    this._shouldAttachFiles.next(val);
  }

  get nuditySelected(): boolean {
    return this._nuditySelected.getValue();
  }

  set nuditySelected(val: boolean) {
    this._nuditySelected.next(val);
  }

  get isItemUnbundled(): boolean {
    return this._itemUnbundled.getValue();
  }

  set isItemUnbundled(val: boolean) {
    this._itemUnbundled.next(val);
  }

  get isAnyCategorySelected(): boolean {
    return this._isAnyCategorySelected.getValue();
  }

  set isAnyCategorySelected(val: boolean) {
    this._isAnyCategorySelected.next(val);
  }

  get downloadLink(): DownloadInfo {
    return this._downloadLink.getValue();
  }

  set downloadLink(val: DownloadInfo) {
    this._downloadLink.next(val);
  }

  get featureFlags(): FeatureFlag[] {
    return this._featureFlags.getValue();
  }

  set featureFlags(val: FeatureFlag[]) {
    this._featureFlags.next(val);
  }

  getFeatureFlag(flagName: string): string {
    if (this.featureFlags) {
      const flag = this.featureFlags.find((f) => f.name === flagName);
      return flag ? flag.value : FeatureFlagValue.NOT_SET;
    }
    return FeatureFlagValue.NOT_SET;
  }

  get workItemFeatureFlags(): FeatureFlag[] {
    return this._workItemFeatureFlags.getValue();
  }

  set workItemFeatureFlags(val: FeatureFlag[]) {
    this._workItemFeatureFlags.next(val);
  }

  get selectedCategories(): ContentCategory[] {
    return this._selectedCategories.getValue();
  }

  set selectedCategories(val: ContentCategory[]) {
    this._selectedCategories.next(val);
  }

  getWorkItemFeatureFlag(flagName: string): string {
    if (this.workItemFeatureFlags) {
      const flag = this.workItemFeatureFlags.find((f) => f.name === flagName);
      return flag ? flag.value : FeatureFlagValue.NOT_SET;
    }
    return FeatureFlagValue.NOT_SET;
  }

  useFullPageSpinner(incrementUsage: boolean): void {
    if (incrementUsage) {
      this.spinnerCount++;
    } else {
      this.spinnerCount > 0 ? this.spinnerCount-- : (this.spinnerCount = 0);
    }

    // If there's only one usage of the spinner, set a timer to hide it after 15 seconds
    if (this.spinnerCount === 1) {
      this.spinnerTimer = setTimeout(() => {
        this.spinnerCount = 0;
        this.showFullPageLoadingSpinner = false;
      }, 30000);
    } else {
      clearTimeout(this.spinnerTimer);
      this.spinnerTimer = null;
    }

    this.showFullPageLoadingSpinner = this.spinnerCount > 0;
  }

  get isItemActionBarVisible(): boolean {
    return this._isItemActionBarVisible.getValue();
  }

  set isItemActionBarVisible(val: boolean) {
    this._isItemActionBarVisible.next(val);
  }

  get loadingTemplates(): boolean {
    return this._loadingTemplates.getValue();
  }

  set loadingTemplates(val: boolean) {
    this._loadingTemplates.next(val);
  }

  get authenticated(): boolean {
    return this._authenticated.getValue();
  }

  set authenticated(val: boolean) {
    this._authenticated.next(val);
  }

  async initialize(): Promise<void> {
    this.uiSessionId = uuid();
    setTag("UI-Session-ID", this.uiSessionId);
  }

  private determineIfUserCanWarnOnItem(workItem: WorkItem): void {
    // If this is the first time we're determining the user's permission, we need to get the AuthService and SplitService
    if (!this.authService) {
      this.authService = this.injector.get(AuthService);
      this.splitService = this.injector.get(SplitService);
    }

    const attributes = { userEmailAddress: this.authService.userEmailAddress };
    const flagValue = this.splitService.getTreatment(FeatureFlagName.CREATE_INCIDENT_FROM_STANDARD_QUEUE, attributes);

    // If the user is in the L1 queue, we need to check if the feature flag is enabled
    this._userCanWarnOnItem$.next(workItem.queueLevel !== UiQueue.Standard || flagValue === "on");
  }

  showLoadingTemplates(incrementUsage: boolean): void {
    if (incrementUsage) {
      this.templateSpinnerCount++;
    } else {
      this.templateSpinnerCount--;
      if (this.templateSpinnerCount < 0) {
        this.templateSpinnerCount = 0;
      }
    }
    this.loadingTemplates = this.templateSpinnerCount > 0;
  }

  get appSyncError(): string {
    return this._appSyncError.getValue();
  }

  set appSyncError(val: string) {
    this._appSyncError.next(val);
  }

  exitReviewMode(): void {
    this.resetItemState();
    this.clearAllSectionTimers();
    this.showRightDrawer = false;
    this.currentQueueType = null;
  }

  resetItemState(): void {
    this.currentItemDetails = null;
    this.currentIncidentDetails = null;
    this.currentInternalWorkItemStatus = null;
    this.newIncident = null;
    this.currentWarningLevel = null;
    this.messageMetadata = null;
    this.externalMetadata = null;
    this.chatContext = null;
    this.shouldQuarantineContent = false;
    this.shouldToggleEscalatePSS = false;
    this.priorIncidents = null;
    this.shouldAttachFiles = true;
    this.nuditySelected = true;
    this.isItemUnbundled = false;
    this.isAnyCategorySelected = false;
    this.downloadLink = null;
    this.workItemFeatureFlags = [];
    this.showRightDrawer = false;
    this.isItemActionBarVisible = true;
    this.SMSTemplates = [];
    this.workItemEmailTemplates = [];
    this.workItemOtherQconEmailTemplates = [];
    this.workItemOtherPssEmailTemplates = [];
    this.stopAndClearTimer();
    this.itemIsInAfterHoursWindow = false;
    this.clearAllSectionTimers();
    // Make sure categories are reset to defaults
    contentCategories.forEach((c) => {
      if (c.warningType !== WarningEventType.PossibleStudentSituation && c.warningType != WarningEventType.QuestionableContent) {
        c.warningType = WarningEventType.Violation;
      } else {
        c.warningType = WarningEventType.QuestionableContent;
      }
      c.isSelected = false;
    });
  }

  startTimer(): void {
    this.stopAndClearTimer();

    this.timer = global.setInterval(() => {
      this.workItemTimer += 1;
    }, 1000);
  }

  stopAndClearTimer(): void {
    global.clearInterval(this.timer);
    this.workItemTimer = 0;
  }

  startSectionTimer(sectionTimer: string): void {
    let section = this.incidentSectionTimers[sectionTimer];
    if (section) {
      section.timer = global.setInterval(() => {
        section.timerCount += 1;
      }, 1000);
    } else {
      section = {
        timer: null,
        timerCount: 0,
      };
      section.timer = global.setInterval(() => {
        section.timerCount += 1;
      }, 1000);
      this.incidentSectionTimers[sectionTimer] = section;
    }
  }

  pauseSectionTimer(sectionTimer: string): void {
    const section = this.incidentSectionTimers[sectionTimer];
    if (section) {
      global.clearInterval(section.timer);
    }
  }

  clearAllSectionTimers(): void {
    Object.keys(this.incidentSectionTimers).forEach((section) => {
      const currentSection = this.incidentSectionTimers[section];
      global.clearInterval(currentSection.timer);
    });
    this.incidentSectionTimers = {};
  }
}
