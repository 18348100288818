import gql from "graphql-tag";

export default gql`
  query getFeatureFlags($flagNames: [String!]!) {
    getFeatureFlags(flagNames: $flagNames) {
      name
      value
      configuration
    }
  }
`;
