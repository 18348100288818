import { Injectable } from "@angular/core";
import { DownloadInfo, EventPayload, MessageMetadata, WorkItem, WorkItemSource, WorkItemType } from "src/app/models/work-item.model";
import { EmailTemplate, EventType, UnbundleType } from "src/app/models/incident.model";
import nextWorkItem from "src/app/graphql/queries/nextWorkItem";
import { StateService } from "./state.service";
import {
  applyFeatureFlagsToTemplates,
  convertGoogleChatContentToHTML,
  getQueueCount,
  isExpired,
  isOversized,
} from "src/app/shared/utils/helpers";
import newEvent from "src/app/graphql/mutations/newEvent";
import suggestedWarningLevel from "src/app/graphql/queries/suggestedWarningLevel";
import getPriorIncidents from "src/app/graphql/queries/getPriorIncidents";
import { v4 as uuid } from "uuid";
import getFileComments from "src/app/graphql/queries/getFileComments";
import tbdTemplates from "src/app/graphql/queries/tbdTemplates";
import { FeatureFlagName, WorkItemFeatureFlagName } from "src/app/models/feature-flags.model";
import getFeatureFlags from "src/app/graphql/queries/getFeatureFlags";
import getWorkItemFeatureFlags from "src/app/graphql/queries/getWorkItemFeatureFlags";
import getQueues from "src/app/graphql/queries/getQueues";
import { CombinedQueueType } from "src/app/models/queue-statistics.model";
import getValidDownloadTypes from "src/app/graphql/queries/validDownloadTypes";
import getAvailableLanguages from "src/app/graphql/queries/getAvailableLanguges";
import translate from "src/app/graphql/queries/translate";
import logout from "src/app/graphql/mutations/logout";
import { LoggingLevel } from "src/app/models/client-logging.model";
import { AuthService } from "./auth.service";
import { formatTranslation } from "src/app/shared/utils/format";
import ncmecReport from "src/app/graphql/mutations/ncmecReport";
import changeItemVisibilityTimeout from "src/app/graphql/mutations/changeVisibilityTimeout";
import { AppsyncClientService } from "src/app/shared/services/appsync-client.service";
import {
  AuditQueueStatisticsResponse,
  AvailableLanguagesResponse,
  DownloadTypesResponse,
  ExternalMetadataResponse,
  FeatureFlagResponse,
  FileCommentResponse,
  NCMECResponse,
  PriorIncidentsResponse,
  PSAPInfoResponse,
  QueueStatisticsResponse,
  SimpleNextWorkItemResponse,
  SMSTemplatesResponse,
  SuggestedWarningLevelResponse,
  TbdTemplatesResponse,
  TranslationResponse,
  WorkItemByIdResponse,
  WorkItemFeatureFlagResponse,
  WorkItemResponse,
  WorkItemStatusResponse,
} from "src/app/models/appsync-responses.model";
import getWorkItem from "src/app/graphql/queries/getWorkItem";
import getSimpleQueueStatistics from "src/app/graphql/queries/getSimpleQueueStatistics";
import getSimpleNextWorkItem from "src/app/graphql/queries/getSimpleNextWorkItem";
import simpleNewEvent from "src/app/graphql/mutations/simpleNewEvent";
import resendIncidentEmail from "src/app/graphql/mutations/resendIncidentEmail";
import completeGSMProcessing from "src/app/graphql/mutations/completeGSMProcessing";
import cancelIncidentAcknowledged from "src/app/graphql/mutations/cancelIncidentAcknowledged";
import getPSAPInfoForStudent from "src/app/graphql/queries/getPSAPInfoForStudent";
import { PSAPContact } from "src/app/models/sis.model";
import getWorkItemStatus from "src/app/graphql/queries/getWorkItemStatus";
import getExternalMetadata from "src/app/graphql/queries/getExternalMetadata";
import getSMSTemplates from "src/app/graphql/queries/getSMSTemplates";
import { UiAnalyticsService } from "src/app/shared/services/ui-analytics.service";
import {
  GetDownloadLinkGQL,
  GetDownloadLinkQueryVariables,
  GetMessageMetadataGQL,
  GetMessageMetadataQueryVariables,
  GetWorkItemAndIncidentByGsmIdGQL,
  UiEventAction,
  UiQueue,
  WarningEventType,
  WorkItemEntityType,
} from "src/generated/graphql";
import { EmergencyContactService } from "src/app/shared/services/emergency-contact.service";
import { firstValueFrom } from "rxjs";
import { RemoveZalgoPipe } from "src/app/shared/pipes/remove-zalgo.pipe";
import { SnackbarService } from "@core/services/snackbar.service";

// TODO: long term goal: create mappers that transform data from API model to something the UI can work with

@Injectable({
  providedIn: "root",
})
export class AppSyncService {
  constructor(
    private state: StateService,
    private authService: AuthService,
    private appSyncClient: AppsyncClientService,
    private uiAnalyticsService: UiAnalyticsService,
    private emergencyContactService: EmergencyContactService,
    private getWorkItemAndIncidentByGsmIdGQL: GetWorkItemAndIncidentByGsmIdGQL,
    private getMessageMetadataGQL: GetMessageMetadataGQL,
    private getDownloadLinkGQL: GetDownloadLinkGQL,
    private removeZalgoPipe: RemoveZalgoPipe,
    private snackbarService: SnackbarService,
  ) {}

  // TODO: what is this being used for?
  async getDownloadTypes(): Promise<void> {
    const response = await this.appSyncClient.query<DownloadTypesResponse>(getValidDownloadTypes);
    this.state.validDownloadTypes = response?.validDownloadTypes;
  }

  // TODO: what is this used for?
  async getAvailableLanguages(): Promise<void> {
    const response = await this.appSyncClient.query<AvailableLanguagesResponse>(getAvailableLanguages);
    if (response?.availableLanguages) {
      this.state.availableLanguages = JSON.parse(response.availableLanguages);
    }
  }

  async translateContent(content: string[], workItemId: string): Promise<void> {
    this.state.useFullPageSpinner(true);
    this.uiAnalyticsService.sendAction(UiEventAction.TranslateContent);

    // Clear out any Zalgo characters before sending to the API
    content = content.map((c) => this.removeZalgoPipe.transform(c));

    const response = await this.appSyncClient.query<TranslationResponse>(translate, {
      textToTranslate: content,
      targetLanguage: "en",
      workItemId: workItemId,
    });
    const translation = response?.translate;
    this.state.useFullPageSpinner(false);
    const win = window.open(
      "",
      "Level2UI Translation",
      "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=437,height=469",
    );
    win.document.body.innerHTML = formatTranslation(translation ?? ["Translation Unavailable"]);
    win.document.title = "Level2UI Translation";
  }

  async translateString(content: string[], workItemId: string): Promise<string[] | null> {
    // Clear out any Zalgo characters before sending to the API
    content = content.map((c) => this.removeZalgoPipe.transform(c));

    const response = await this.appSyncClient.query<TranslationResponse>(translate, {
      textToTranslate: content,
      targetLanguage: "en",
      workItemId: workItemId,
    });
    if (response?.translate) {
      return response.translate;
    } else {
      return ["Translation unavailable."];
    }
  }

  async getNextWorkItem(): Promise<void> {
    this.state.useFullPageSpinner(true);
    this.state.resetItemState();
    this.emergencyContactService.reset();
    const isInL1Queue = this.state.currentQueueType === CombinedQueueType.L1;

    const pullFromHighPriority = this.state.hasPSSPermission ? this.state.currentQueueType === CombinedQueueType.HIGH_PRIORITY : false;

    const response = await this.appSyncClient.query<WorkItemResponse>(nextWorkItem, {
      isL1: isInL1Queue,
      pss: pullFromHighPriority,
    });

    if (response?.nextWorkItem) {
      this.state.currentItemDetails = response.nextWorkItem;
      await this.getAdditionalWorkItemInfo();

      if (
        this.state.currentQueueType === CombinedQueueType.HIGH_PRIORITY &&
        this.state.currentItemDetails.queueLevel !== UiQueue.Priority
      ) {
        this.state.currentQueueType = CombinedQueueType.L2;
        this.snackbarService.sendMessage("No high priority items available. Returning to escalation queue.", "Okay", {
          duration: 7000,
        });
      }

      this.decrementQueueCount();
      this.state.startTimer();
    } else {
      this.snackbarService.sendMessage("No items available for the selected queue.", "Okay");
      this.state.exitReviewMode();
    }
    this.state.useFullPageSpinner(false);
  }

  private decrementQueueCount(): void {
    const queuesCopy = [...this.state.queues];

    if (this.state.currentQueueType === CombinedQueueType.L1) {
      queuesCopy[0].level1Count > 0 && queuesCopy[0].level1Count--;
    } else if (this.state.currentQueueType === CombinedQueueType.L2) {
      queuesCopy[0].level2Count > 0 && queuesCopy[0].level2Count--;
    } else {
      queuesCopy[0].highPriorityCount > 0 && queuesCopy[0].highPriorityCount--;
    }

    this.state.queues = queuesCopy;
  }

  async getNextAuditWorkItem(): Promise<void> {
    if (this.state.auditQueue?.type) {
      this.state.useFullPageSpinner(true);
      this.state.resetItemState();
      const response = await this.appSyncClient.query<SimpleNextWorkItemResponse>(getSimpleNextWorkItem, {
        queueName: this.state.auditQueue.type,
      });
      if (response?.simpleNextWorkItem) {
        this.state.currentItemDetails = response.simpleNextWorkItem;
        await this.getAdditionalWorkItemInfo();
      }
      this.state.useFullPageSpinner(false);
    } else {
      await this.getAuditQueueStatistics();
      await this.getNextAuditWorkItem();
    }
  }

  async getWorkItemAndIncidentByGsmId(gsmId: string): Promise<void> {
    this.state.resetItemState();
    this.state.currentQueueType = CombinedQueueType.L2;

    const response = await firstValueFrom(this.getWorkItemAndIncidentByGsmIdGQL.fetch({ gsmId: gsmId }));

    if (response?.data.getWorkItemAndIncidentByGsmId) {
      if (response.data.getWorkItemAndIncidentByGsmId.incident) {
        this.state.currentIncidentDetails = response.data.getWorkItemAndIncidentByGsmId.incident;
      }

      if (response.data.getWorkItemAndIncidentByGsmId.workItem) {
        // TODO: Hacky workaround for type checking during transition, remove this later
        this.state.currentItemDetails = response.data.getWorkItemAndIncidentByGsmId.workItem as unknown as WorkItem;
        await this.getAdditionalWorkItemInfo({ workItemStatus: true });
      }
    }
  }

  async getWorkItemById(workItemId: string): Promise<void> {
    this.state.resetItemState();
    this.state.currentQueueType = CombinedQueueType.L2;
    this.state.useFullPageSpinner(true);

    const response = await this.appSyncClient.query<WorkItemByIdResponse>(getWorkItem, {
      workItemId,
    });

    if (response?.getWorkItem) {
      this.state.currentItemDetails = response.getWorkItem;
      await this.getAdditionalWorkItemInfo();
    }

    this.state.useFullPageSpinner(false);
  }

  private async getAdditionalWorkItemInfo({
    priorIncidents = true,
    emergencyContacts = true,
    suggestedWarningLevel = true,
    workItemFeatureFlags = true,
    workItemStatus = false,
  } = {}): Promise<void> {
    const workItem = this.state.currentItemDetails;

    if (workItem) {
      if (emergencyContacts) {
        // If the contacts are already with the work item, we don't need to fetch them again
        if (workItem.ecAvailability) {
          this.emergencyContactService.setContactsFromWorkItem(workItem.ecAvailability);
        } else {
          // TODO: Hacky workaround for type checking during transition, remove this later
          const workItemEntityType = workItem.workItemEntityType as unknown as WorkItemEntityType;
          this.emergencyContactService.fetchContacts(workItem.districtId, workItem.groupId, workItemEntityType);
        }
      }

      const callsToMake = [
        suggestedWarningLevel ? () => this.getSuggestedWarningLevel() : null,
        priorIncidents ? () => this.getPriorIncidents() : null,
        workItemFeatureFlags ? () => this.getWorkItemFeatureFlags(workItem) : null,
        workItemStatus ? () => this.getWorkItemStatus(workItem.id) : null,
      ].filter((callExists) => callExists);

      this.state.messageMetadata = null;
      this.state.chatContext = null;
      this.state.downloadLink = null;

      const metaDataItemTypes = [WorkItemType.MESSAGE, WorkItemType.SPEAKUP, WorkItemType.WEBFILTER, WorkItemType.HANGOUT];

      if (metaDataItemTypes.includes(workItem.workItemEntityType)) {
        this.setMessageMetadata(workItem.messageMetadata).then();
      } else if (workItem.workItemEntityType === WorkItemType.FILE) {
        this.setDownloadLink(workItem.downloadInfo).then();
      } else if (workItem.workItemEntityType === WorkItemType.CANVAS) {
        callsToMake.push(() => this.getExternalWorkItemMetadata(workItem.id));
      } else if (workItem.workItemEntityType === WorkItemType.FILE_COMMENT) {
        this.setDownloadLink(workItem.downloadInfo).then();
        if (workItem.itemData) {
          const parsedData = JSON.parse(workItem.itemData);
          callsToMake.push(() => this.getFileComments(parsedData["commentId"]));
        }
      }

      await Promise.all(callsToMake.map((callToInvoke) => callToInvoke()));
    }
  }

  async setMessageMetadata(metadata: MessageMetadata): Promise<MessageMetadata> {
    const workItem = this.state.currentItemDetails;
    const fetchNewMetadata = metadata ? isExpired(metadata.download_info.expiration, 600) : true;

    // If we don't have the metadata, or it's expired, fetch it
    if (fetchNewMetadata) {
      // If the work item is missing both the logical location
      // and object id, we can't fetch the metadata
      if (!!workItem.logicalLocationId || !!workItem.objectId) {
        const fetchVariables: GetMessageMetadataQueryVariables = {
          customerId: workItem.customerId,
          logicalId: workItem.logicalLocationId || workItem.objectId,
          workItemType: workItem.workItemEntityType as unknown as WorkItemEntityType,
        };
        await firstValueFrom(this.getMessageMetadataGQL.fetch(fetchVariables)).then((result) => {
          metadata = result?.data?.getMessageMetadata;
        });
      } else {
        metadata = null;
      }
    }

    if (workItem.workItemEntityType === WorkItemType.HANGOUT) {
      this.state.messageMetadata = this.updateChatMetadata(metadata);
    } else {
      this.state.messageMetadata = metadata;
    }
    this.state.downloadLink = metadata?.download_info;
    return metadata;
  }

  async setDownloadLink(downloadInfo: DownloadInfo, inBackground = false): Promise<DownloadInfo> {
    let fetchNewLink = true;
    if (downloadInfo) {
      // If we're fetching this in the background, it's safe to assume
      // we're downloading right now, so we'll give ourselves a 10-second
      // buffer to download the file, otherwise we'll fetch a new link if
      // the link is expiring in the next 10 minutes
      if (inBackground) {
        fetchNewLink = isExpired(downloadInfo.expiration, 10);
      } else {
        fetchNewLink = isExpired(downloadInfo.expiration, 600);
      }
    }

    // If we don't have the downloadInfo, or it's expired, fetch it
    if (fetchNewLink) {
      const workItem = this.state.currentItemDetails;

      // If the work item is missing both the logical location
      // and object id, we can't fetch the download link
      if (!!workItem.logicalLocationId || !!workItem.objectId) {
        let workItemType = workItem.workItemEntityType as unknown as WorkItemType;
        if (workItemType === WorkItemType.FILE_COMMENT) {
          workItemType = WorkItemType.FILE;
        }
        const fetchVariables: GetDownloadLinkQueryVariables = {
          customerId: workItem.customerId,
          logicalId: workItem.logicalLocationId || workItem.objectId,
          workItemType: workItemType,
          targetFileName: workItem.entityName,
          noCopy: isOversized(workItem),
        };

        // When downloading a file, setDownloadLink is called to ensure that the
        // file download link is available. If the link is expired, we'll fetch
        // a new one. We don't want to show the spinner in this case
        this.getDownloadLinkGQL.client = inBackground ? "noStatus" : "default";
        await firstValueFrom(this.getDownloadLinkGQL.fetch(fetchVariables)).then((result) => {
          downloadInfo = result?.data?.downloadLink;
        });
      } else {
        downloadInfo = null;
      }
    }

    this.state.downloadLink = downloadInfo;
    return downloadInfo;
  }

  async logout(): Promise<void> {
    await this.appSyncClient.mutate(logout, {
      refreshToken: this.authService.getRefreshToken(),
      userPoolClientId: this.authService.getUserPoolClientId(),
    });
    this.authService.signOut();
  }

  async resolveItemInBackground(eventType: EventType, payload: EventPayload, reopenIncident = false, reopenWorkItem = false): Promise<any> {
    const finalOverride = reopenWorkItem ? !!this.state.currentItemDetails.completed : reopenIncident;
    const resolvePayload = {
      workItemId: this.state.currentItemDetails.id,
      event: eventType,
      finalOverride: finalOverride,
      bundleDigest: this.state.currentItemDetails.bundleDigest ? this.state.currentItemDetails.bundleDigest : "NONE",
      unbundleType: this.state.isItemUnbundled ? UnbundleType.ALL : null,
      payload,
    };

    return await this.appSyncClient.mutate(newEvent, resolvePayload).then((data) => {
      return data;
    });
  }

  async resolveItem(eventType: EventType, payload: EventPayload, reopenIncident = false, reopenWorkItem = false): Promise<any> {
    let eventResult;
    this.state.useFullPageSpinner(true);
    await this.resolveItemInBackground(eventType, payload, reopenIncident, reopenWorkItem).then((data) => {
      eventResult = data;
    });
    this.state.useFullPageSpinner(false);
    return eventResult;
  }

  async resolveAuditItem(eventType: EventType, payload: EventPayload): Promise<void> {
    this.state.useFullPageSpinner(true);

    await this.appSyncClient.mutate(simpleNewEvent, {
      workItemId: this.state.currentItemDetails.id,
      event: eventType,
      payload,
    });

    this.state.useFullPageSpinner(false);

    if (this.state.auditQueue.total > 0) {
      this.state.auditQueue = {
        type: this.state.auditQueue.type,
        total: this.state.auditQueue.total--,
      };
    }

    await this.getNextAuditWorkItem();
  }

  async changeItemVisibilityTimeout(itemId: string, timeoutInSeconds: number): Promise<void> {
    await this.appSyncClient.mutate(changeItemVisibilityTimeout, {
      itemId,
      timeoutSecs: timeoutInSeconds,
    });
  }

  async resendIncidentEmail(incidentId: string, messageBody: string, recipients: any[]): Promise<void> {
    const variables = {
      incidentId,
      messageBody,
      recipients,
    };
    await this.appSyncClient.mutate(resendIncidentEmail, variables);
  }

  async completeGSMProcessing(workItemId: string): Promise<void> {
    await this.appSyncClient.mutate(
      completeGSMProcessing,
      {
        workItemId,
      },
      {
        enableRetry: true,
        retryCount: 2,
      },
    );
  }

  async cancelIncidentAcknowledgedSubscription(workItemId: string): Promise<void> {
    await this.appSyncClient.mutate(cancelIncidentAcknowledged, {
      workItemId,
    });
  }

  async sendNCMECReport(payload: any, schoolOverrides: any, districtOverrides: any, studentOverrides: any): Promise<void> {
    this.state.useFullPageSpinner(true);

    const response = await this.appSyncClient.mutate<NCMECResponse>(ncmecReport, {
      incidentTimestamp: payload.incidentTimestamp,
      incidentTimezone: payload.incidentTimezone,
      safetyAudit: payload.safetyAudit,
      classification: payload.classification,
      request: payload.request,
      schoolOverrides,
      districtOverrides,
      studentOverrides,
    });

    if (!payload.safetyAudit && response?.submitNCMECReport?.reports?.[0].reportId === "N/A") {
      const reportData = {
        payload,
        schoolOverrides,
        districtOverrides,
        studentOverrides,
      };
      this.appSyncClient.logClientEvent(LoggingLevel.ERROR, "Failed to deliver NCMEC Report", reportData);
    }
    this.state.useFullPageSpinner(false);
  }

  async handleNextWorkItem(): Promise<void> {
    this.state.resetItemState();

    if (getQueueCount(this.state.queues, this.state.currentQueueType) > 0) {
      await this.getNextWorkItem();
      return;
    }

    if (this.state.currentQueueType === CombinedQueueType.HIGH_PRIORITY) {
      if (getQueueCount(this.state.queues, this.state.currentQueueType) === 0) {
        this.state.currentQueueType = CombinedQueueType.L2;
      }

      await this.getNextWorkItem();
      return;
    }
  }

  private async getExternalWorkItemMetadata(workItemId: string): Promise<void> {
    this.state.externalMetadata = null;
    this.state.useFullPageSpinner(true);
    const response = await this.appSyncClient.query<ExternalMetadataResponse>(getExternalMetadata, {
      workItemId,
    });

    this.state.externalMetadata = response?.getExternalWorkItemMetadata;
    this.state.useFullPageSpinner(false);
  }

  private async getFileComments(commentId: string, contextCount = 5): Promise<void> {
    this.state.fileComments = null;
    this.state.useFullPageSpinner(true);
    const response = await this.appSyncClient.query<FileCommentResponse>(getFileComments, { commentId, contextCount });
    this.state.fileComments = response?.getFileComments;
    this.state.useFullPageSpinner(false);
  }

  private updateChatMetadata(metadata: MessageMetadata): MessageMetadata {
    if (this.state.currentItemDetails.workItemEntityType === WorkItemType.HANGOUT && !!metadata) {
      const updatedAttachments = [];
      updatedAttachments.push({
        id: uuid(),
        name: "Inappropriate Chat Message.html",
        contentType: "text/html",
        qualifier: "-1",
        include: false,
      });
      if (metadata.attachments) {
        metadata.attachments
          .filter((a) => a.qualifier !== "-1" && a.name !== "CHAT_CONTEXT_GAGGLE_JSON")
          .forEach((a) => updatedAttachments.push(a));
      }
      metadata.attachments = updatedAttachments;

      if (metadata.chat_context) {
        this.state.chatContext = metadata.chat_context;
      }
    }
    if (this.state.currentItemDetails.workItemSource === WorkItemSource.GOOGLE_HANGOUTS && metadata.content?.length > 0) {
      metadata.content[0] = convertGoogleChatContentToHTML(metadata.content[0]);
    }

    return metadata;
  }

  private async getSuggestedWarningLevel(): Promise<void> {
    this.state.currentWarningLevel = WarningEventType.FirstWarning;

    if (this.state.currentItemDetails?.blockedUserId) {
      this.state.useFullPageSpinner(true);
      const response = await this.appSyncClient.query<SuggestedWarningLevelResponse>(suggestedWarningLevel, {
        blockedUserId: this.state.currentItemDetails.blockedUserId,
        workItemType: this.state.currentItemDetails.workItemEntityType,
      });

      this.state.currentWarningLevel = response?.suggestedWarningLevel;
      this.state.useFullPageSpinner(false);
    } else {
      await this.appSyncClient.logClientEvent(LoggingLevel.DEBUG, "No blocked user ID on work item", this.state.currentItemDetails);
    }
  }

  async getAllEmailTemplates(): Promise<void> {
    if (Object.keys(this.state.allEmailTemplates).length > 0) {
      return;
    }

    this.state.showLoadingTemplates(true);
    const templateTypes = Object.values(WorkItemType).filter((type) => type !== WorkItemType.YAMMER);
    const allTemplates = await Promise.all(templateTypes.map((type) => this.getEmailTemplatesForEntityType(type)));
    const sortedByWorkItemTypeTemplates = allTemplates.reduce((accum, groupedByEntityType) => {
      if (groupedByEntityType.length > 0) {
        accum[groupedByEntityType[0].workItemType] = groupedByEntityType.reduce(
          (groupedByWarnAccum, currentTemplate) => {
            if (groupedByWarnAccum[currentTemplate.warningType]?.length >= 0) {
              groupedByWarnAccum[currentTemplate.warningType].push(currentTemplate);
            } else {
              groupedByWarnAccum[currentTemplate.warningType] = [currentTemplate];
            }

            return groupedByWarnAccum;
          },
          {
            [WarningEventType.PossibleStudentSituation]: [],
            [WarningEventType.QuestionableContent]: [],
            [WarningEventType.FirstWarning]: [],
            [WarningEventType.SecondWarning]: [],
            [WarningEventType.ThirdWarning]: [],
          },
        );

        return accum;
      }

      return accum;
    }, {});

    this.state.allEmailTemplates = sortedByWorkItemTypeTemplates;
    this.state.showLoadingTemplates(false);
  }

  private async getEmailTemplatesForEntityType(workItemType: WorkItemType, retrying = false): Promise<EmailTemplate[]> {
    const response = await this.appSyncClient.query<TbdTemplatesResponse>(tbdTemplates, {
      workItemType: workItemType,
    });
    if (!response?.getTbdTemplates) {
      this.appSyncClient.logClientEvent(LoggingLevel.WARNING, `Failed to retrieve email templates for type ${workItemType}`, response);

      if (!retrying) {
        return this.getEmailTemplatesForEntityType(workItemType, true);
      }

      return [];
    }
    return applyFeatureFlagsToTemplates(response.getTbdTemplates, this.state.featureFlags);
  }

  async getSMSTemplates(): Promise<SMSTemplatesResponse> {
    return this.appSyncClient
      .query(getSMSTemplates)
      .then((response: any) => {
        this.state.SMSTemplates = response.getSMSTemplates;
        return response;
      })
      .catch((error: any) => {
        return error.errors;
      });
  }

  async getFeatureFlags(): Promise<void> {
    const flagNames = Object.values(FeatureFlagName);
    const response = await this.appSyncClient.query<FeatureFlagResponse>(getFeatureFlags, {
      flagNames,
    });

    this.state.featureFlags = response?.getFeatureFlags || [];
  }

  async getPSAPForStudent(userId: string): Promise<PSAPContact[]> {
    const response = await this.appSyncClient.query<PSAPInfoResponse>(getPSAPInfoForStudent, {
      userId,
    });

    if (response?.getPSAPInfoForStudent) {
      return response.getPSAPInfoForStudent;
    }

    return [];
  }

  private async getWorkItemFeatureFlags(workItem: WorkItem): Promise<void> {
    this.state.useFullPageSpinner(true);
    const flagNames = Object.values(WorkItemFeatureFlagName);
    const response = await this.appSyncClient.query<WorkItemFeatureFlagResponse>(getWorkItemFeatureFlags, {
      flagNames,
      workItem: {
        customerId: workItem.customerId,
        districtId: workItem.districtId,
        groupId: workItem.groupId,
        workItemEntityType: workItem.workItemEntityType,
      },
    });

    this.state.workItemFeatureFlags = response?.getWorkItemFeatureFlags;
    this.state.useFullPageSpinner(false);
  }

  private async getWorkItemStatus(workItemId: string): Promise<void> {
    this.state.useFullPageSpinner(true);
    const response = await this.appSyncClient.query<WorkItemStatusResponse>(getWorkItemStatus, {
      workItemId,
    });

    this.state.currentInternalWorkItemStatus = response?.getWorkItemStatus;
    this.state.useFullPageSpinner(false);
  }

  private async getPriorIncidents(): Promise<void> {
    this.state.priorIncidents = null;
    const userId = this.state.currentItemDetails?.blockedUserId || this.state.currentItemDetails?.userId;

    if (userId) {
      this.state.useFullPageSpinner(true);
      const response = await this.appSyncClient.query<PriorIncidentsResponse>(getPriorIncidents, {
        userId,
      });

      this.state.priorIncidents = response?.getPriorIncidents;
      this.state.useFullPageSpinner(false);
    } else {
      this.appSyncClient.logClientEvent(
        LoggingLevel.ERROR,
        `Could not retrieve prior incidents due to no user ID found. Work Item ID: ${this.state.currentItemDetails?.id}`,
        null,
      );
    }
  }

  async getQueueStatisticsUnauthorized(): Promise<void> {
    this.state.useFullPageSpinner(true);
    const response = await this.appSyncClient.query<QueueStatisticsResponse>(
      getQueues,
      {},
      {
        useApiKeyClient: true,
        returnError: true,
        retryCount: 0,
        retryingCall: true,
      },
    );

    const queues = response?.queueStatistics?.queues || [];
    this.state.queues = queues;

    if (response?.error) {
      this.state.appSyncError = response.error.toString();
    } else {
      this.state.appSyncError = "";
    }

    this.state.useFullPageSpinner(false);
  }

  async getQueueStatistics(): Promise<void> {
    const response = await this.appSyncClient.query<QueueStatisticsResponse>(getQueues);
    const queues = response?.queueStatistics?.queues || [];
    this.state.queues = queues;
  }

  async getAuditQueueStatistics(): Promise<void> {
    const response = await this.appSyncClient.query<AuditQueueStatisticsResponse>(getSimpleQueueStatistics);
    const queues = response?.simpleQueueStatistics;

    if (queues?.length) {
      this.state.auditQueue = {
        type: queues[0].logicalQueue,
        total: queues[0].count,
      };
    } else {
      this.state.auditQueue = null;
    }
  }
}
