import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { formatPhoneNumberForDisplay, removeCharsFromPhoneNumber } from "src/app/shared/utils/format";
import { ClipboardService } from "ngx-clipboard";
import { PhoneNumber } from "src/app/models/incident.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { StateService } from "src/app/shared/services/state.service";
import { ThemeMode, ThemeService } from "src/app/shared/services/theme.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiAnalyticsService } from "src/app/shared/services/ui-analytics.service";
import { IncidentCommunicationType, PhoneNumberInput, UiEventAction } from "src/generated/graphql";
import { EmergencyContactExtended, EmergencyContactService } from "src/app/shared/services/emergency-contact.service";
import { Store } from "@ngxs/store";
import { AddCommunicationFromPhoneRow } from "src/app/shared/state/incident/incident.actions";

@UntilDestroy()
@Component({
  selector: "app-phone-row",
  templateUrl: "./phone-row.component.html",
  styleUrls: ["./phone-row.component.scss"],
})
export class PhoneRowComponent implements OnInit, OnChanges {
  @Input() type: "Mobile" | "Home" | "Office";
  @Input() phoneNumber: PhoneNumber;
  @Input() contact: EmergencyContactExtended;
  @Input() disabled = false;
  @Output() startSectionTimer = new EventEmitter();
  activeCall = false;
  callingContact = false;
  formattedPhoneNumberForDisplay: string;
  extension: string;
  wasCalled = 0;
  timer?: NodeJS.Timeout = null;
  callTimer = 0;
  useDarkMode: boolean;
  buttonBackgroundColor: string;
  buttonTextColor: string;

  protected readonly IncidentCommunicationType = IncidentCommunicationType;

  constructor(
    private _clipboardService: ClipboardService,
    private snackBar: MatSnackBar,
    private stateService: StateService,
    private themeService: ThemeService,
    private uiAnalyticsService: UiAnalyticsService,
    private emergencyContactService: EmergencyContactService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.themeService.themeMode$.pipe(untilDestroyed(this)).subscribe((themeMode) => {
      this.useDarkMode = themeMode === ThemeMode.DARK;
      this.buttonBackgroundColor = this.themeService.getVariable("--checkbox");
      this.buttonTextColor = this.themeService.getVariable("--nav-link-color");
    });

    this.stateService.activeCall$.pipe(untilDestroyed(this)).subscribe((activeCall) => {
      this.activeCall = activeCall;
    });

    this.formattedPhoneNumberForDisplay = formatPhoneNumberForDisplay(this.phoneNumber);
    this.extension = this.phoneNumber?.ext ? this.phoneNumber.ext : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.phoneNumber) {
      const curPhoneNumber = changes.phoneNumber.currentValue;
      this.formattedPhoneNumberForDisplay = formatPhoneNumberForDisplay(curPhoneNumber);
      this.extension = curPhoneNumber?.ext ? curPhoneNumber.ext : null;
    }
  }

  callUser(): void {
    if (this.disabled) {
      return;
    }
    this.callingContact = this.stateService.activeCall = true;
    this.wasCalled++;
    this.startSectionTimer.emit();
    this.uiAnalyticsService.sendAction(UiEventAction.CallEmergencyContact);
    window.location.href = `tel:${removeCharsFromPhoneNumber(this.formattedPhoneNumberForDisplay)}`;
    this.startTimer();
  }

  completeCall(outcome: IncidentCommunicationType): void {
    this.store.dispatch(
      new AddCommunicationFromPhoneRow(this.contact.id, this.contact.name, outcome, this.phoneNumber as PhoneNumberInput),
    );

    this.callingContact = this.stateService.activeCall = false;
    this.stopAndClearTimer();
  }

  private startTimer(): void {
    this.timer = setInterval(() => {
      this.callTimer += 1;
    }, 1000);
  }

  private stopAndClearTimer(): void {
    clearInterval(this.timer);
    this.callTimer = 0;
  }

  copyExtension(): void {
    if (this.disabled) {
      return;
    }
    this._clipboardService.copy(this.extension);
    this.snackBar.open("Extension copied!", null, {
      duration: 2000,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }
}
