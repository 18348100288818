import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActionReason, EventPayload, WorkItem, WorkItemType } from "src/app/models/work-item.model";
import { StateService } from "src/app/shared/services/state.service";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { FeatureFlagName, FeatureFlagValue } from "src/app/models/feature-flags.model";
import { MatDialog } from "@angular/material/dialog";
import { ModalDialogComponent } from "src/app/components/controls/modal-dialog/modal-dialog.component";
import { EventType } from "src/app/models/incident.model";
import { Router } from "@angular/router";
import { UiAnalyticsService } from "src/app/shared/services/ui-analytics.service";
import { UiEventAction } from "src/generated/graphql";
import { Subject, takeUntil } from "rxjs";

enum ButtonTypes {
  Negative = "negative",
  Affirmative = "affirmative",
  NegativeInverse = "negative-inverse",
}

interface DropdownOption {
  label: string;
  testId?: string;
  onClick(): void;
}

interface Button {
  shouldDisplay(): boolean;

  onClick(): void;

  label(): string;

  icon: string;
  buttonType: ButtonTypes;
  cy: string;

  useSpecialSizing(): boolean;

  tooltip?: string;
  iconSize?: string;

  useDropdown(): boolean;

  dropdownOptions?: DropdownOption[];
}

@Component({
  selector: "app-item-viewer-action-bar",
  templateUrl: "./item-viewer-action-bar.component.html",
  styleUrls: ["./item-viewer-action-bar.component.scss"],
})
export class ItemViewerActionBarComponent implements OnInit, OnDestroy {
  @Output() showRightDrawer = new EventEmitter();
  @Output() showNcmecDrawer = new EventEmitter();

  @Input() showActionBar: boolean;
  @Input() l2Audit = false;
  @Input() isReopenIncident: boolean;
  @Input() isOpenWorkItem: boolean;

  private unsubscribe$ = new Subject<void>();
  private userCanWarnOnItem: boolean;

  itemDetails: WorkItem;
  hotkeysEnabled = true;

  constructor(
    private state: StateService,
    private appSync: AppSyncService,
    private uiAnalyticsService: UiAnalyticsService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.state.currentItemDetails$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.itemDetails = data;
    });

    this.state.isItemActionBarVisible$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.hotkeysEnabled = data;
    });

    this.state.userCanWarnOnItem$.pipe(takeUntil(this.unsubscribe$)).subscribe((userCanWarnOnItem) => {
      this.userCanWarnOnItem = userCanWarnOnItem;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // Hotkeys
  @HostListener("document:keydown", ["$event"]) hotkey(event: KeyboardEvent): void {
    if (!this.itemDetails) {
      return;
    }

    if (this.hotkeysEnabled) {
      if (event.altKey && event.code === "KeyW") {
        this.onClickCreateIncident();
      } else if ((event.metaKey || event.ctrlKey) && event.altKey && event.code === "KeyR") {
        this.onClickCompleteItem();
      } else if (event.ctrlKey && event.shiftKey && event.code === "KeyP") {
        if (!this.hideQuarantineButton) {
          this.onClickQuarantine();
        }

        // CTRL+SHIFT+P is a system command for print, so we have to prevent this from bubbling up.
        event.preventDefault();
      }
    }
  }

  buttons: Button[] = [
    {
      shouldDisplay: (): boolean => !this.hideQuarantineButton,
      onClick: (): void => this.onClickQuarantine(),
      label: (): string => "Quarantine",
      icon: "quarantine",
      buttonType: ButtonTypes.NegativeInverse,
      cy: "quarantine-button",
      tooltip: "ctrl + shift + p",
      useSpecialSizing: (): boolean => false,
      useDropdown: (): boolean => this.isNcmecOnlyEnabled,
      dropdownOptions: [
        {
          label: "Quarantine",
          onClick: (): void => this.onClickQuarantine(),
        },
        {
          label: "Quarantine & NCMEC",
          onClick: (): void => this.onClickQuarantineNcmec(),
        },
      ],
    },
    {
      shouldDisplay: (): boolean => this.hideQuarantineButton && this.isNcmecOnlyEnabled && !this.l2Audit,
      onClick: (): void => this.onClickNcmec(),
      label: (): string => "NCMEC",
      icon: "add-activity-log-entry",
      buttonType: ButtonTypes.NegativeInverse,
      cy: "ncmec-button",
      useSpecialSizing: (): boolean => false,
      useDropdown: (): boolean => false,
    },
    {
      shouldDisplay: (): boolean => true,
      onClick: (): void => this.onClickCreateIncident(),
      label: (): string => this.createIncidentOrEscalateText,
      icon: "escalate",
      buttonType: ButtonTypes.Negative,
      cy: "create-incident-button",
      tooltip: "alt + w / ⌥ + w(mac)",
      useSpecialSizing: (): boolean => this.redButtonCount === 1 && this.greenButtonCount === 2,
      useDropdown: (): boolean => false,
    },
    {
      shouldDisplay: (): boolean => this.showRequestReviewButton,
      onClick: (): void => this.onClickRequestReview(),
      label: (): string => "Request Review",
      icon: "request-review",
      iconSize: "M",
      buttonType: ButtonTypes.Negative,
      cy: "request-review-button",
      useSpecialSizing: (): boolean => false,
      useDropdown: (): boolean => false,
    },
    {
      shouldDisplay: (): boolean => !this.hideResolveButton,
      onClick: (): void => this.onClickCompleteItem(),
      label: (): string => "Resolve",
      icon: "resolve",
      buttonType: ButtonTypes.Affirmative,
      cy: "resolve-button",
      tooltip: "ctl + alt + r / cmd + ⌥ + r(mac)",
      useSpecialSizing: (): boolean => false,
      useDropdown: (): boolean => this.showResolveOptionsMenu,
      dropdownOptions: [
        {
          label: "Resolve (No Action Required)",
          onClick: (): void => this.onClickCompleteItem(),
        },
        {
          label: "Resolve (Additional Factor)",
          onClick: (): void => this.onClickCompleteItem(true),
        },
      ],
    },
    {
      shouldDisplay: (): boolean => !this.hideDeleteButton,
      onClick: (): void => this.onClickUnblock(),
      label: (): string => "Unblock",
      icon: "unblock",
      buttonType: ButtonTypes.Affirmative,
      cy: "unblock-button",
      useSpecialSizing: (): boolean => false,
      useDropdown: (): boolean => false,
    },
    {
      shouldDisplay: (): boolean => !this.hideDeleteButton,
      onClick: (): void => this.onClickDelete(),
      label: (): string => "Delete",
      icon: "delete",
      buttonType: ButtonTypes.Affirmative,
      cy: "delete-button",
      useSpecialSizing: (): boolean => false,
      useDropdown: (): boolean => this.showDeleteOptionsMenu,
      dropdownOptions: [
        {
          label: "Delete",
          testId: "menu-delete-button",
          onClick: (): void => this.onClickDelete(),
        },
        {
          label: "Delete (Additional Factor)",
          testId: "menu-delete-additional-factor-button",
          onClick: (): void => this.onClickDelete(true),
        },
      ],
    },
  ];

  get itemIsMessageType(): boolean {
    return this.itemDetails?.workItemEntityType === WorkItemType.MESSAGE;
  }

  get redButtonCount(): number {
    return this.buttons.filter(
      (button) =>
        button.shouldDisplay() && (button.buttonType === ButtonTypes.Negative || button.buttonType === ButtonTypes.NegativeInverse),
    ).length;
  }

  get greenButtonCount(): number {
    return this.buttons.filter((button) => button.shouldDisplay() && button.buttonType === ButtonTypes.Affirmative).length;
  }

  get hasDistrictNotes(): boolean {
    return !!this.itemDetails?.districtNotes;
  }

  get hideDeleteButton(): boolean {
    return !this.itemIsMessageType || this.state.currentItemDetails?.safetyAudit;
  }

  get hideResolveButton(): boolean {
    return this.itemIsMessageType && !this.state.currentItemDetails?.safetyAudit;
  }

  get isNcmecOnlyEnabled(): boolean {
    return (
      this.state.getFeatureFlag(FeatureFlagName.NCMEC) === FeatureFlagValue.ON && this.userCanWarnOnItem && this.state.hasPSSPermission
    );
  }

  get showResolveOptionsMenu(): boolean {
    return this.userCanWarnOnItem && !this.itemIsMessageType;
  }

  get showDeleteOptionsMenu(): boolean {
    return this.userCanWarnOnItem && this.itemIsMessageType;
  }

  get showRequestReviewButton(): boolean {
    return (
      !this.state.hasPSSPermission &&
      this.state.getFeatureFlag(FeatureFlagName.REQUEST_REVIEW_OPTION) === FeatureFlagValue.ON &&
      this.userCanWarnOnItem &&
      !this.l2Audit
    );
  }

  get hideQuarantineButton(): boolean {
    if (!this.userCanWarnOnItem) {
      return true;
    }

    if (!this.itemDetails?.canQuarantine) {
      return true;
    }

    return this.itemDetails?.workItemEntityType !== WorkItemType.FILE;
  }

  get createIncidentOrEscalateText(): string {
    return !this.userCanWarnOnItem ? "Escalate" : "Create Incident";
  }

  resolveItem(event: EventType): void {
    const payload: EventPayload = {
      userId: this.state.currentItemDetails.userId,
      groupId: this.state.currentItemDetails.groupId,
      districtId: this.state.currentItemDetails.districtId,
      customerId: this.state.currentItemDetails.customerId,
      entityId: this.state.currentItemDetails.entityId,
      entityType: this.state.currentItemDetails.workItemEntityType,
      userEmail: event === EventType.QUARANTINE_ONLY ? this.state.currentItemDetails.userEmail : null,
      remoteEntityId: event === EventType.QUARANTINE_ONLY ? this.state.currentItemDetails.remoteEntityId : null,
      actionReasons: event === EventType.ESCALATE ? null : [ActionReason.FALSE_POSITIVE],
    };

    if (this.l2Audit) {
      this.appSync.resolveAuditItem(event, payload);
    } else {
      this.appSync.resolveItem(event, payload, this.isReopenIncident, this.isOpenWorkItem).then(() => {
        if (this.isReopenIncident) {
          this.router.navigateByUrl(`/incidents/${this.itemDetails.id}/reopened-incident`);
          return;
        }

        if (this.isOpenWorkItem) {
          this.router.navigateByUrl(`/work-items/${this.itemDetails.id}/created-incident`);
          return;
        }

        this.appSync.handleNextWorkItem();
      });
    }
  }

  onClickCreateIncident(): void {
    if (this.hasDistrictNotes && this.userCanWarnOnItem) {
      const dialogRef = this.dialog.open(ModalDialogComponent, {
        data: {
          title: "Special Instructions",
          content: "Please confirm that you have read and understood the special instructions for this district.",
          dismissButtonText: "Cancel",
          okButtonText: "Confirm",
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          this.showRightDrawer.emit(false);
          return;
        } else {
          this.createIncident();
        }
      });
    } else {
      this.createIncident();
    }
  }

  createIncident(): void {
    if (!this.userCanWarnOnItem) {
      this.uiAnalyticsService.sendAction(UiEventAction.Escalate, true);
      this.resolveItem(EventType.ESCALATE);
      return;
    }

    this.showRightDrawer.emit(true);
  }

  onClickCompleteItem(priorIncidents = false): void {
    if (priorIncidents) {
      this.uiAnalyticsService.sendAction(UiEventAction.ResolveItemPriorIncident, true);
      this.resolveItem(EventType.RESOLVE_PRIOR_INCIDENT);
    } else {
      this.uiAnalyticsService.sendAction(UiEventAction.ResolveItem, true);
      this.resolveItem(EventType.RESOLVE);
    }
  }

  onClickUnblock(): void {
    this.uiAnalyticsService.sendAction(UiEventAction.Unblock, true);
    this.resolveItem(EventType.UNBLOCK);
  }

  onClickDelete(priorIncidents = false): void {
    if (priorIncidents) {
      this.uiAnalyticsService.sendAction(UiEventAction.DeletePriorIncident, true);
      this.resolveItem(EventType.DELETE_PRIOR_INCIDENT);
    } else {
      this.uiAnalyticsService.sendAction(UiEventAction.Delete, true);
      this.resolveItem(EventType.DELETE);
    }
  }

  onClickRequestReview(): void {
    this.uiAnalyticsService.sendAction(UiEventAction.RequestReview, true);
    this.resolveItem(EventType.REQUEST_REVIEW);
  }

  onClickNcmec(): void {
    if (this.itemIsMessageType) {
      this.showNcmecDrawer.emit(EventType.DELETE_PRIOR_INCIDENT);
      this.uiAnalyticsService.sendAction(UiEventAction.DeleteNcmec);
    } else {
      this.showNcmecDrawer.emit(EventType.RESOLVE);
      this.uiAnalyticsService.sendAction(UiEventAction.ResolveItemNcmec);
    }
  }

  onClickQuarantine(): void {
    this.uiAnalyticsService.sendAction(UiEventAction.QuarantineOnlyNoPriors, true);
    this.resolveItem(EventType.QUARANTINE_ONLY);
  }

  onClickQuarantineNcmec(): void {
    this.showNcmecDrawer.emit(EventType.QUARANTINE_ONLY);
    this.uiAnalyticsService.sendAction(UiEventAction.QuarantineOnlyNcmec, true);
  }
}
