import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { ContentCategory } from "src/app/models/content-category.model";
import { ThemeService } from "src/app/shared/services/theme.service";
import { WarningEventType } from "src/generated/graphql";

enum State {
  QCON,
  DISABLED,
  PSS,
}

@Component({
  selector: "app-qcon-pss-switch",
  templateUrl: "./qcon-pss-switch.component.html",
  styleUrls: ["./qcon-pss-switch.component.scss"],
})
export class QconPssSwitchComponent implements OnChanges {
  @Input() category: ContentCategory;
  @Input() enabled: boolean;
  @Input() forcePSS: boolean;
  @Output() toggled = new EventEmitter<ContentCategory>();

  QCON = State.QCON;
  PSS = State.PSS;

  constructor(private themeService: ThemeService) {}

  ngOnChanges(): void {
    if (this.enabled && this.state === State.DISABLED) {
      this.advanceState();
    }
  }

  get capsuleColor(): string {
    switch (this.state) {
      case State.PSS:
        return this.themeService.getVariable("--toggle-capsule-pss");
      case State.QCON:
        return this.themeService.getVariable("--toggle-capsule-qcon");
      case State.DISABLED:
      default:
        return this.themeService.getVariable("--toggle-capsule-center");
    }
  }

  get dotColor(): string {
    switch (this.state) {
      case State.PSS:
        return this.themeService.getVariable("--toggle-dot-pss");
      case State.QCON:
        return this.themeService.getVariable("--toggle-dot-qcon");
      case State.DISABLED:
      default:
        return this.themeService.getVariable("--toggle-dot-center");
    }
  }

  get dotClass(): string {
    switch (this.state) {
      case State.QCON:
        return "left";
      case State.PSS:
        return "right";
      case State.DISABLED:
      default:
        return "middle";
    }
  }

  get state(): State {
    if (this.category.isSelected) {
      switch (this.category.warningType) {
        case WarningEventType.QuestionableContent:
          return State.QCON;
        case WarningEventType.PossibleStudentSituation:
          return State.PSS;
        default:
          return State.DISABLED;
      }
    }
    return State.DISABLED;
  }

  setState(newState: State): void {
    if (this.forcePSS && newState === State.QCON) {
      return;
    }

    if (this.forcePSS) {
      this.category.isSelected = true;
      this.category.warningType = WarningEventType.PossibleStudentSituation;
    } else {
      switch (newState) {
        case State.QCON:
          this.category.isSelected = true;
          this.category.warningType = WarningEventType.QuestionableContent;
          break;
        case State.PSS:
          this.category.isSelected = true;
          this.category.warningType = WarningEventType.PossibleStudentSituation;
          break;
        case State.DISABLED:
        default:
          this.category.isSelected = false;
          break;
      }
    }
    this.toggled.emit(this.category);
  }

  advanceState(): void {
    switch (this.state) {
      case State.QCON:
        this.setState(State.PSS);
        break;
      case State.PSS:
        this.setState(State.QCON);
        break;
      case State.DISABLED:
      default:
        this.setState(State.QCON);
    }
  }
}
