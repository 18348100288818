<div
    class="app-content"
    *ngIf="signedIn">
    <app-nav-bar class="nav-content"></app-nav-bar>
    <main *ngIf="validGaggleUser">
        <router-outlet></router-outlet>
    </main>
    <div
        *ngIf="!validGaggleUser"
        class="non-gaggle-user">
        User {{ username }} is not a valid Gaggle application user! Please close this browser window.
    </div>
</div>
