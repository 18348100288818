import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { CombinedQueueType, DisplayedQueue } from "src/app/models/queue-statistics.model";
import { WorkItem } from "src/app/models/work-item.model";
import { StateService } from "src/app/shared/services/state.service";
import { Subject, takeUntil, timer } from "rxjs";

@Component({
  selector: "app-queue-left-sidebar",
  templateUrl: "./queue-left-sidebar.component.html",
  styleUrls: ["./queue-left-sidebar.component.scss"],
})
export class QueueLeftSidebarComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() showRightDrawer: boolean;
  @Input() showLeftDrawer: boolean;
  @Input() hideQueues = false;
  @Output() exitCurrentQueue = new EventEmitter();

  selectedCombinedQueue: CombinedQueueType;
  queuesHaveBeenProcessed = false;

  showMoreFileInfo = false;
  showMoreAttachments = false;
  showMoreIncidentInfo = false;
  showMoreSchoolInfo = false;
  itemDetails: WorkItem;
  queues: DisplayedQueue[] = [];

  constructor(private appSync: AppSyncService, private state: StateService) {}

  ngOnInit(): void {
    // Start a timer to refresh statistics every 30 seconds
    timer(0, 1000 * 30)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.appSync.getQueueStatistics();
      });

    this.queues = [
      {
        label: "Standard",
        combinedType: CombinedQueueType.L1,
        hideQueue: false,
        count: 0,
      },
      {
        label: "Escalation",
        combinedType: CombinedQueueType.L2,
        hideQueue: false,
        count: 0,
      },
      {
        label: "High Priority",
        combinedType: CombinedQueueType.HIGH_PRIORITY,
        hideQueue: !this.state.hasPSSPermission,
        count: 0,
      },
    ];

    this.state.currentItemDetails$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.itemDetails = data;
    });

    this.state.queues$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.processQueuesForDisplay();
    });

    this.state.currentQueueType$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.selectedCombinedQueue = data;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  processQueuesForDisplay(): any {
    if (this.state.queues.length > 0) {
      this.queues[0].count = this.state.queues[0].level1Count;
      this.queues[1].count = this.state.queues[0].level2Count;
      this.queues[2].count = this.state.queues[0].highPriorityCount >= 0 ? this.state.queues[0].highPriorityCount : 0;
      this.queuesHaveBeenProcessed = true;
    }
  }

  toggleSection(sectionToToggle: string): void {
    this[sectionToToggle] = !this[sectionToToggle];
  }

  toggleLeftDrawer(): void {
    this.state.showLeftDrawer = !this.state.showLeftDrawer;
  }

  shouldDisableQueue(queue: DisplayedQueue): boolean {
    return queue.count === 0 || (this.showRightDrawer && this.state.currentQueueType !== queue.combinedType);
  }

  onClickRefreshQueues(): void {
    this.queuesHaveBeenProcessed = false;
    this.appSync.getQueueStatistics();
  }

  onClickQueue(queue: DisplayedQueue): void {
    // If the queue is disabled or the right drawer is open, do nothing
    if (this.shouldDisableQueue(queue) || this.showRightDrawer) {
      return;
    }

    // If the clicked queue is the current queue, do nothing
    if (this.state.currentQueueType === queue.combinedType) {
      return;
    }

    // If there's a work item open, treat the click as a request to exit the queue
    // and emit the exitCurrentQueue event before entering the new queue
    if (this.itemDetails) {
      this.exitCurrentQueue.emit();
    }

    this.state.currentQueueType = queue.combinedType;
    this.appSync.getNextWorkItem();
  }

  onClickExitWorkQueue(): void {
    if (this.showRightDrawer) {
      return;
    } else {
      this.exitCurrentQueue.emit();
    }
  }
}
