import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ThemeService } from "src/app/shared/services/theme.service";

@Component({
  selector: "app-menu-button",
  templateUrl: "./menu-button.component.html",
  styleUrls: ["./menu-button.component.scss"],
})
export class MenuButtonComponent {
  @Input() type: "submit" | "reset" | "button" = "button";
  @Input() label: string;
  @Input() icon: string;
  @Input() disabled = false;
  @Input() showSpinner = false;

  @Output() buttonClick = new EventEmitter();

  constructor(private theme: ThemeService) {}

  performClick(): void {
    if (this.disabled) {
      return;
    }

    this.buttonClick.emit();
  }

  get textColor(): string {
    return this.theme.getVariable("--text-primary-color");
  }

  get iconColor(): string {
    return this.theme.getVariable("--text-secondary-color");
  }

  get backgroundColor(): string {
    return this.theme.getVariable("--menu-button-background");
  }
}
