import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";
import { SendSmsGQL, SmsStatusUpdatedGQL, SmsStatus, GetSmsTemplatesGQL, SmsTemplate, PhoneNumberInput } from "src/generated/graphql";
import { MatSelectChange } from "@angular/material/select";

export interface SendSmsDialogRecipient {
  id: string;
  name: string;
  mobilePhone: PhoneNumberInput;
  smsStatus?: string;
}

export interface SendSmsDialogData {
  smsType: "test" | "incident";
  recipients: SendSmsDialogRecipient[];
  gsmId: string;
  isResend: boolean;
}

@Component({
  selector: "app-send-sms-dialog",
  templateUrl: "./send-sms-dialog.component.html",
  styleUrls: ["./send-sms-dialog.component.scss"],
})
export class SendSmsDialogComponent implements OnInit, OnDestroy {
  private _unsubscribe$ = new Subject<void>();
  private bypassSpinnerContext = { context: { bypassSpinner: true } };

  sending = false;
  messageSent = false;
  smsBody: string;
  smsTemplates: SmsTemplate[];

  constructor(
    private sendSmsGQL: SendSmsGQL,
    private smsStatusUpdatedGQL: SmsStatusUpdatedGQL,
    private getSmsTemplatesGQL: GetSmsTemplatesGQL,
    @Inject(MAT_DIALOG_DATA) public data: SendSmsDialogData,
  ) {}

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  ngOnInit(): void {
    if (this.data.smsType === "test") {
      this.smsBody = "This is a test of the Gaggle PSS SMS alert.";
    } else {
      this.getSmsTemplatesGQL.fetch({}, this.bypassSpinnerContext).subscribe((result) => {
        this.smsTemplates = result.data.getSMSTemplates;
      });
    }
  }

  onTemplateSelected(event: MatSelectChange): void {
    const template: SmsTemplate = event.value;
    this.smsBody = template.message;
  }

  onClickSend(): void {
    this.sendSms();
  }

  private updateRecipientStatus(id: string, status: string): void {
    this.data.recipients.find((r) => r.id === id).smsStatus = status;
  }

  private subscribeToSmsStatus(recipient: SmsStatus): void {
    this.smsStatusUpdatedGQL
      .subscribe({ contactId: recipient.contactId, gsmId: recipient.gsmId })
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((message) => {
        const update = message.data.smsStatusUpdated;
        this.updateRecipientStatus(update.contactId, update.status);
      });
  }

  private sendSms(): void {
    this.sending = true;
    this.sendSmsGQL
      .mutate(
        {
          gsmId: this.data.gsmId,
          body: this.smsBody,
          recipients: this.data.recipients,
        },
        this.bypassSpinnerContext,
      )
      .subscribe((sendResult) => {
        this.sending = false;
        sendResult.data.sendSMS.forEach((recipient) => {
          this.updateRecipientStatus(recipient.contactId, recipient.status);
          this.subscribeToSmsStatus(recipient);
        });
      });

    this.messageSent = true;
  }
}
