import { inject, Injectable, NgZone } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarModule } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  private matSnackBar = inject(MatSnackBar);

  // For some reason, the snackbar does not always show up in the proper
  // location unless we inject the NgZone and run the snackbar.open() call
  // inside of the ngZone.run() method.
  private ngZone = inject(NgZone);

  private defaultConfig: MatSnackBarConfig = {
    duration: 2000,
    horizontalPosition: "center",
    verticalPosition: "bottom",
    direction: "ltr",
    politeness: "polite",
  };

  public sendMessage(message = "Success", action: string = "", config?: Partial<MatSnackBarModule>): void {
    const configWithDefaults: MatSnackBarConfig = {
      ...this.defaultConfig,
      // Allow the user to override all config options
      ...config,
    };
    this.ngZone.run(() => this.matSnackBar.open(message, action, configWithDefaults));
  }

  public sendWarning(message = "Warning", action: string = "", config?: Partial<MatSnackBarModule>): void {
    const configWithDefaults: MatSnackBarConfig = {
      ...this.defaultConfig,

      // Allow the user to override all config options
      ...config,

      panelClass: ["snackbar-service-warning"],
    };
    this.ngZone.run(() => this.matSnackBar.open(message, action, configWithDefaults));
  }

  public sendError(message = "Error", action: string = "", config?: Partial<MatSnackBarModule>): void {
    const configWithDefaults: MatSnackBarConfig = {
      ...this.defaultConfig,

      // Override default duration for errors
      duration: 5000,
      politeness: "assertive",

      // Allow the user to override all config options
      ...config,

      panelClass: ["snackbar-service-error"],
    };
    this.ngZone.run(() => this.matSnackBar.open(message, action, configWithDefaults));
  }
}
