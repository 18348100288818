import { AfterContentInit, ChangeDetectorRef, Component, ContentChild, Input, OnChanges, OnInit } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { ItemDetailRowContentDirective } from "./item-detail-row-content.directive";
import { StateService } from "src/app/shared/services/state.service";
import { AppsyncClientService } from "src/app/shared/services/appsync-client.service";
import { LoggingLevel } from "src/app/models/client-logging.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UiEventAction } from "src/generated/graphql";
import { UiAnalyticsService } from "src/app/shared/services/ui-analytics.service";
import { downloadFile, downloadAttachment } from "src/app/shared/utils/helpers";
import { DownloadInfo } from "src/app/models/work-item.model";
import { AppSyncService } from "src/app/shared/services/appsync.service";

const copyMap = {
  student: {
    uiAnalytics: UiEventAction.CopyStudentId,
  },
  item: {
    uiAnalytics: UiEventAction.CopyItemId,
  },
  district: {
    uiAnalytics: UiEventAction.CopyDistrictName,
  },
  school: {
    uiAnalytics: UiEventAction.CopyGroupName,
  },
};

const linkMap = {
  incident: {
    uiAnalytics: UiEventAction.PriorIncidentsLinkSelected,
  },
  student: {
    uiAnalytics: UiEventAction.ViewStudentLinkSelected,
  },
};

@Component({
  selector: "app-item-detail-row",
  templateUrl: "./item-detail-row.component.html",
  styleUrls: ["./item-detail-row.component.scss"],
})
export class ItemDetailRowComponent implements OnInit, OnChanges, AfterContentInit {
  @Input() label: string;
  @Input() content;
  @Input() downloadFile?: DownloadInfo;
  @Input() downloadAttachment?: { fileName?: string; link: string };
  @Input() fileName?: string;
  @Input() externalLink?: string;
  @Input() copyValue?: string;

  @ContentChild(ItemDetailRowContentDirective) nestedContent!: ItemDetailRowContentDirective;

  hasNestedContent = true;
  hasPassedContent: boolean;
  hasDownloadedFile = false;
  isDownloadingFile = false;

  actions = {
    downloadFile: null,
    downloadAttachment: null,
    externalLink: null,
    copyValue: null,
  };

  constructor(
    private _clipboardService: ClipboardService,
    private changeDetector: ChangeDetectorRef,
    private state: StateService,
    private appsyncClient: AppsyncClientService,
    private snackBar: MatSnackBar,
    private uiAnalyticsService: UiAnalyticsService,
    private appSyncService: AppSyncService,
  ) {}

  ngOnInit(): void {
    this.hasPassedContent = !!this.content;
    this.createActions();
  }

  ngOnChanges(): void {
    this.hasPassedContent = !!this.content;
    this.createActions();
  }

  ngAfterContentInit(): void {
    this.hasNestedContent = !!this.nestedContent?.templateRef;
    this.changeDetector.detectChanges();
  }

  get hasContent(): boolean {
    return this.hasNestedContent || this.hasPassedContent;
  }

  private logAnalyticsEvent(map): void {
    const matchingKey = Object.keys(map).find((link) => this.label.toLowerCase().includes(link));
    if (matchingKey) {
      this.uiAnalyticsService.sendAction(map[matchingKey].uiAnalytics);
    }
  }

  createActions(): void {
    if (!this.hasContent || Object.values(this.actions).every((v) => v)) {
      return;
    }

    if (this.downloadFile && !this.actions.downloadFile) {
      const fileName = this.fileName || "download";

      this.actions.downloadFile = async (event) => {
        if (this.hasDownloadedFile) {
          return;
        }

        this.hasDownloadedFile = true;
        this.isDownloadingFile = true;

        try {
          this.appSyncService.setDownloadLink(this.downloadFile, true).then((newDownloadInfo) => {
            this.uiAnalyticsService.sendAction(UiEventAction.DownloadContent);
            downloadFile(newDownloadInfo.url, fileName);
          });
        } catch (error) {
          this.appsyncClient.logClientEvent(LoggingLevel.ERROR, "Unable to download file in L2UI", {
            workItemId: this.state.currentItemDetails.id,
            download: this.downloadFile.url,
            usingBlobMethod: true,
          });
          this.snackBar.open("Unable to download file", "Okay", {
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        }

        this.isDownloadingFile = false;
        this.hasDownloadedFile = false;

        event.stopPropagation();
      };
    }

    if (this.downloadAttachment && !this.actions.downloadAttachment) {
      this.actions.downloadAttachment = () => {
        try {
          this.uiAnalyticsService.sendAction(UiEventAction.DownloadContent);
          downloadAttachment({ url: this.downloadAttachment.link });
        } catch (error) {
          this.appsyncClient.logClientEvent(LoggingLevel.ERROR, "Unable to download attachment in L2UI", {
            workItemId: this.state.currentItemDetails.id,
            download: this.downloadFile,
            usingBlobMethod: false,
          });
          this.snackBar.open("Unable to download attachment", "Okay", {
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        }
      };
    }

    if (this.externalLink && !this.actions.externalLink) {
      this.actions.externalLink = (event) => {
        this.logAnalyticsEvent(linkMap);
        window.open(this.externalLink, "_blank");
        event.stopPropagation();
      };
    }

    if (this.copyValue && !this.actions.copyValue) {
      this.actions.copyValue = (event) => {
        this.logAnalyticsEvent(copyMap);
        this._clipboardService.copy(this.copyValue);
        this.snackBar.open("Copied!", null, {
          duration: 1000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
        event.stopPropagation();
      };
    }
  }

  get hasActions(): boolean {
    return Object.values(this.actions).some((v) => v);
  }

  onRowClick(event: MouseEvent): void {
    if (!this.hasActions) {
      return;
    }
    if (this.actions.copyValue) {
      this.actions.copyValue(event);
      return;
    } else if (this.actions.downloadFile) {
      this.actions.downloadFile(event);
      return;
    } else if (this.actions.downloadAttachment) {
      this.actions.downloadAttachment(event);
      return;
    } else if (this.actions.externalLink) {
      this.actions.externalLink(event);
      return;
    }
  }
}
