import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from "@angular/core";
import { Incident } from "src/app/models/incident.model";
import { StateService } from "src/app/shared/services/state.service";
import { AttachmentInfo, WorkItem, WorkItemType } from "src/app/models/work-item.model";
import { ThemeService } from "src/app/shared/services/theme.service";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { NCMECDistrictOverrides, NCMECReport, NCMECSchoolOverrides, NCMECStudentOverrides } from "src/app/models/ncmec.model";
import { MatDialog } from "@angular/material/dialog";
import { ModalDialogComponent } from "src/app/components/controls/modal-dialog/modal-dialog.component";
import { FeatureFlagName, FeatureFlagValue } from "src/app/models/feature-flags.model";
import { UiAnalyticsService } from "src/app/shared/services/ui-analytics.service";
import { UiEventAction } from "src/generated/graphql";

// future TODO: save incident in state so no need to pass info around - can work with view incident and reviewing work items
@Component({
  selector: "app-ncmec",
  templateUrl: "./ncmec.component.html",
  styleUrls: ["./ncmec.component.scss"],
})
export class NcmecComponent implements OnInit, OnChanges {
  @Input() expand: boolean;
  @Input() incident: Incident;
  @Input() disableSend = false;

  @Output() submitNcmecReport = new EventEmitter();
  workItem: WorkItem;
  fileDownloadLink: string;
  report: NCMECReport;

  allAttachments: AttachmentInfo[] = [];
  schoolOverrides: NCMECSchoolOverrides = {};
  districtOverrides: NCMECDistrictOverrides = {};
  studentOverrides: NCMECStudentOverrides = {};
  disableButtonAfterSend = false;

  constructor(
    private state: StateService,
    private themeService: ThemeService,
    private appSync: AppSyncService,
    private dialog: MatDialog,
    private uiAnalyticsService: UiAnalyticsService,
  ) {}

  ngOnInit(): void {
    this.state.currentItemDetails$.subscribe((data) => {
      if (data) {
        this.workItem = data;

        // We want to send the most relevant timestamp to NCMEC, only using the date
        // the work item was created if there is no other timestamp available.
        const incidentTimestamp = data.lastModified || data.occurrenceDate || data.created;

        this.report = {
          incidentTimestamp: incidentTimestamp,
          incidentTimezone: data.studentTimezone,
          safetyAudit: data.safetyAudit,
          classification: "A1",
          request: {
            workItemId: data.id,
            workerId: data.workerId,
            payload: {
              customerId: data.customerId,
              groupName: data.groupName,
              districtId: data.districtId,
              userId: this.incident?.payload?.userId,
              groupId: data.groupId,
              userEmail: this.incident?.payload?.userEmail,
              attachments: [],
            },
          },
        };

        this.districtOverrides.name = data.districtName;
        if (data.districtInfo) {
          this.districtOverrides.address = data.districtInfo.address;
          this.districtOverrides.city = data.districtInfo.city;
          this.districtOverrides.state = data.districtInfo.state;
          this.districtOverrides.phone = data.districtInfo.phone;
        }

        this.schoolOverrides.name = data.groupName;
        if (data.studentInfo) {
          this.studentOverrides.firstName = data.studentInfo.firstName;
          this.studentOverrides.lastName = data.studentInfo.lastName;
          this.studentOverrides.email = data.studentInfo.email;
        }

        if (data.attachments) {
          data.attachments.forEach((attachment) => {
            this.allAttachments.push({
              id: attachment.id,
              name: attachment.name,
              contentType: attachment.contentType,
              qualifier: attachment.qualifier,
              downloadUrl: attachment.downloadUrl,
              include: false,
            });
          });
        }
      }
    });

    this.state.messageMetadata$.subscribe((data) => {
      if (data?.attachments) {
        data.attachments.forEach((attachment) => {
          this.allAttachments.push({
            id: attachment.id,
            name: attachment.name,
            contentType: attachment.contentType,
            qualifier: attachment.qualifier,
            downloadUrl: attachment.downloadUrl,
            include: false,
          });
        });
      }
    });

    this.state.externalMetadata$.subscribe((data) => {
      if (data?.content[0]?.fileContent) {
        data.content[0].fileContent.forEach((attachment) => {
          this.allAttachments.push({
            id: attachment.id,
            name: attachment.name,
            contentType: attachment.contentType,
            qualifier: attachment.qualifier,
            downloadUrl: attachment.downloadUrl,
            include: false,
          });
        });
      }
    });

    this.state.downloadLink$.subscribe((data) => {
      if (data?.url) {
        this.fileDownloadLink = data.url;
      }
    });
  }

  ngOnChanges(): void {
    if (this.report && this.incident) {
      this.report.request.payload.userId = this.incident.payload?.userId;
      this.report.request.payload.userEmail = this.incident.payload?.userEmail;
    }
  }

  get ncmecSafetyAuditIsEnabled(): boolean {
    return this.state.getFeatureFlag(FeatureFlagName.NCMEC_SAFETY_AUDIT) === FeatureFlagValue.ON;
  }

  get itemIsSafetyAudit(): boolean {
    return this.state.currentItemDetails.safetyAudit;
  }

  get allowAutomatedReport(): boolean {
    return this.itemIsSafetyAudit ? this.ncmecSafetyAuditIsEnabled : true;
  }

  get shouldDisplayAttachFiles(): boolean {
    return this.attachableFiles.length > 0;
  }

  get selectedAttachments(): AttachmentInfo[] {
    return this.attachableFiles.filter((attachment) => attachment?.include);
  }

  get buttonBackgroundColor(): string {
    return this.themeService.getVariable("--checkbox");
  }

  get buttonTextColor(): string {
    return this.themeService.getVariable("--nav-link-color");
  }

  get attachableFiles(): AttachmentInfo[] {
    if (this.workItem.workItemEntityType === WorkItemType.FILE && this.allAttachments.length === 1) {
      this.allAttachments[0].downloadUrl = this.fileDownloadLink;
    }
    return this.allAttachments.filter((a) => a.downloadUrl);
  }

  get submitIsDisabled(): boolean {
    return this.disableSend || this.disableButtonAfterSend;
  }

  onClickAttachFile(attachment: AttachmentInfo): void {
    attachment.include = !attachment.include;
  }

  sendReport(): void {
    if (this.attachableFiles.length > 0 && !this.selectedAttachments.length) {
      const dialogRef = this.dialog.open(ModalDialogComponent, {
        data: {
          title: "Warning",
          content: "You are sending a NCMEC report without selecting any attachments. Are you sure you want to proceed?",
          dismissButtonText: "Cancel",
          okButtonText: "Send Report",
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.submitReport();
        }
      });
    } else {
      this.submitReport();
    }
  }

  private submitReport(): void {
    this.report.request.payload.attachments = this.selectedAttachments;
    this.disableButtonAfterSend = true;
    this.appSync.sendNCMECReport(this.report, this.schoolOverrides, this.districtOverrides, this.studentOverrides);
    this.uiAnalyticsService.sendAction(UiEventAction.SendNcmecReport);
    this.submitNcmecReport.emit();
  }
}
