import { Component, inject } from "@angular/core";
import { NavigateSearchRecordFromSearchNavigationComponent } from "@shared/state/search/search.actions";
import { Select, Store } from "@ngxs/store";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SearchRecordNavigationInfo, SearchSelectors } from "@shared/state/search/search.selectors";
import { Observable } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-search-navigation",
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule],
  templateUrl: "./search-navigation.component.html",
  styleUrls: ["./search-navigation.component.scss"],
})
export class SearchNavigationComponent {
  private store = inject(Store);
  private router = inject(Router);

  @Select(SearchSelectors.searchRecordNavigationInfo) searchRecordNavigationInfo$: Observable<SearchRecordNavigationInfo>;

  onNextSearchItem(): void {
    this.store.dispatch(new NavigateSearchRecordFromSearchNavigationComponent(1));
  }

  onPreviousSearchItem(): void {
    this.store.dispatch(new NavigateSearchRecordFromSearchNavigationComponent(-1));
  }

  onReturnToSearch(): void {
    this.router.navigate(["/search"]);
  }
}
