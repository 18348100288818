import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  private cacheBuster: string = new Date().getTime().toString();
  private configUrl = environment.configUrl !== null ? environment.configUrl : "/assets/config.json";
  private config: Config;

  constructor(private httpClient: HttpClient) {}

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get<Config>(this.cacheBustedUrl).subscribe({
        next: (config) => {
          this.config = config;
          resolve(true);
        },
        error: (error) => {
          console.error(error);
          reject(true);
        },
      });
    });
  }

  get cacheBustedUrl(): string {
    return this.configUrl + "?bust-cache=" + this.cacheBuster;
  }

  getConfig(): Config {
    return this.config;
  }
}

export interface Config {
  userPoolId: string;
  userPoolWebClientId: string;
  userPoolDomain: string;
  identityProviderId: string;
  baseApiUrl: string;
  redirectSignIn: string;
  redirectSignOut: string;
  gewiBaseUrl: string;
  nextItemRetryCount: number;
  environment: string;
  splitIoAuthKey: string;
}
