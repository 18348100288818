<ng-template
    #commentTemplate
    let-comment="comment">
    <header class="details">
        <span class="author">
            <span
                class="user-dot"
                [ngStyle]="{ backgroundColor: authorMap.get(comment.author) }"></span>
            <span>{{ comment.author }}</span>
        </span>
        <span class="spacer"></span>
        <time>{{ comment.timestamp | date: "shortTime" }}</time>
    </header>

    <section class="content">
        <span
            class="text"
            [attr.data-testid]="'comment-text-' + comment.id"
            [ngClass]="{ flagged: comment.blocked }"
            *ngIf="comment.blocked; else plainComment"
            [innerHTML]="comment.content | removeZalgo | highlightKeywords: keywords"></span>

        <ng-template #plainComment>
            <span
                class="text"
                [attr.data-testid]="'comment-text-' + comment.id">
                {{ comment.content | removeZalgo }}
            </span>
        </ng-template>

        <aside
            class="translate"
            [ngClass]="{ translated: translationsMap && translationsMap.has(comment.id) }">
            <gaggle-icon
                [attr.data-testid]="'translate-' + comment.id"
                [ngClass]="{ translated: translationsMap && translationsMap.has(comment.id) }"
                iconName="translate-text"
                iconSize="L"
                (click)="translateComment(comment)"></gaggle-icon>

            <span
                class="translated-text"
                [attr.data-testid]="'translated-text-' + comment.id"
                *ngIf="translationsMap && translationsMap.has(comment.id)">
                {{ translationsMap.get(comment.id) }}
            </span>
        </aside>
    </section>
</ng-template>

<mat-card class="file-comment-panel mat-elevation-z2">
    <div class="section-title">Comments:</div>

    <section
        class="comment"
        *ngFor="let comment of comments">
        <ng-container
            [ngTemplateOutlet]="commentTemplate"
            [ngTemplateOutletContext]="{ comment: comment }"></ng-container>

        <section
            class="comment reply"
            *ngFor="let reply of comment.replies">
            <ng-container
                [ngTemplateOutlet]="commentTemplate"
                [ngTemplateOutletContext]="{ comment: reply }"></ng-container>
        </section>
    </section>

    <section class="participants">
        <div class="divider"></div>

        <ng-container *ngIf="showAllParticipants">
            <section class="list">
                <article
                    class="author"
                    *ngFor="let author of authorMap | keyvalue">
                    <span
                        class="user-dot"
                        [ngStyle]="{ backgroundColor: author.value }"></span>
                    <span>{{ author.key }}</span>
                </article>
            </section>
        </ng-container>

        <gaggle-button
            [icon]="toggleParticipantsIcon"
            [label]="toggleParticipantsText"
            buttonStyle="secondary"
            (click)="toggleAllParticipants()"
            [backgroundColor]="buttonBackgroundColor"
            [textColor]="buttonTextColor"
            [iconColor]="buttonTextColor"></gaggle-button>
    </section>
</mat-card>
