import { inject, Injectable, OnDestroy } from "@angular/core";
import moment from "moment-timezone";
import { BehaviorSubject, Subscription } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModalDialogComponent, ModalDialogData } from "src/app/components/controls/modal-dialog/modal-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AcknowledgmentSource, IncidentAcknowledgedGQL } from "src/generated/graphql";
import { IncidentSelector } from "src/app/shared/state/incident/incident.selector";
import { Store } from "@ngxs/store";

export type IncidentAcknowledgement = {
  incidentId?: string;
  userId: string;
  fullName: string;
  workItemId: string;
  time?: string;
  source: AcknowledgmentSource;
};

@Injectable({
  providedIn: "root",
})
export class AcknowledgementService implements OnDestroy {
  private dialog = inject(MatDialog);
  private matSnackBar = inject(MatSnackBar);
  private store = inject(Store);
  private incidentAcknowledgedGQL = inject(IncidentAcknowledgedGQL);

  /**
   * By keeping the subjects private, we prevent outsiders from calling next(),
   * error(), or complete() on them, ensuring they are always alive and well as
   * part of this service. Expose the observable to allow consumption of data
   */
  private _acknowledgedBy$ = new BehaviorSubject<IncidentAcknowledgement>(null);
  public acknowledgedBy$ = this._acknowledgedBy$.asObservable();
  private dialogRef: MatDialogRef<ModalDialogComponent>;
  private incidentAcknowledgedSubscription: Subscription;

  ngOnDestroy(): void {
    this.cancelSubscription();
    this.dialogRef?.close();
    this.matSnackBar.dismiss();
  }

  subscribe(workItemId: string): void {
    this._acknowledgedBy$.next(null);

    this.incidentAcknowledgedSubscription = this.incidentAcknowledgedGQL.subscribe({ workItemId: workItemId }).subscribe((data) => {
      const acknowledgement = data.data?.incidentAcknowledged;

      if (!acknowledgement) {
        return;
      }

      // Check the incident in the application state to ensure we're still on the same incident
      const currentIncidentId = this.store.selectSnapshot(IncidentSelector.incidentId);

      if (currentIncidentId !== acknowledgement.incidentId) {
        return;
      }

      if (acknowledgement.fullName.trim().length > 0) {
        this._acknowledgedBy$.next({
          fullName: acknowledgement.fullName,
          userId: acknowledgement.userId,
          workItemId: acknowledgement.workItemId,
          incidentId: acknowledgement.incidentId,
          time: moment().format("hh:mm a"),
          source: acknowledgement.source,
        });

        this.cancelSubscription();
        this.showAckDialog();
      }
    });
  }

  showAckDialog(): void {
    const acknowledgedBy = this._acknowledgedBy$.value;
    const ackMessage = `${acknowledgedBy.fullName} acknowledged this incident
          at ${acknowledgedBy.time} via ${acknowledgedBy.source}.`;

    const dialogData: ModalDialogData = {
      title: "Incident Acknowledged",
      content: ackMessage,
      okButtonText: "OK",
    };

    this.dialogRef = this.dialog.open(ModalDialogComponent, {
      disableClose: true,
      data: dialogData,
      panelClass: "modal-dialog-green",
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.matSnackBar.open(ackMessage, null, {
        horizontalPosition: "center",
        verticalPosition: "top",
      });
    });
  }

  cancelSubscription(): void {
    if (this.incidentAcknowledgedSubscription) {
      this.incidentAcknowledgedSubscription.unsubscribe();
      this.incidentAcknowledgedSubscription = undefined;
    }
  }
}
