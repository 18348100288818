import { Component, inject, OnInit } from "@angular/core";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { StateService } from "src/app/shared/services/state.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-unauthenticated",
  templateUrl: "./unauthenticated.component.html",
  styleUrls: ["./unauthenticated.component.scss"],
})
export class UnauthenticatedComponent implements OnInit {
  private appSync = inject(AppSyncService);
  private state = inject(StateService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);

  appSyncError = "";
  noAccess = false;
  success!: boolean;
  statusCode!: string;

  ngOnInit(): void {
    this.noAccess = this.activatedRoute.snapshot.url[0].path === "no-access";

    if (!this.noAccess) {
      this.runConnectionTest();
    }
  }

  onClickReload(): void {
    window.location.href = "/";
  }

  runConnectionTest(): void {
    this.state.appSyncError$.subscribe((data) => {
      this.appSyncError = data;
      this.success = this.appSyncError?.includes("401");
      const statusCode = new RegExp(/\d{3}/gi).exec(this.appSyncError);
      if (statusCode?.length) {
        this.statusCode = statusCode[0];
      } else {
        console.error("No status code found when calling AppSync to test connection.");
        this.statusCode = "N/A";
      }
    });
    this.appSync.getQueueStatisticsUnauthorized();
  }
}
