import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ThemeService } from "src/app/shared/services/theme.service";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: [],
})
export class CheckboxComponent {
  @Input() label: string;
  @Input() name: string;
  @Input() checked: boolean;
  @Input() disabled = false;
  @Input() size = "L";
  @Input() checkmarkStyle;
  @Output() toggleCheckbox = new EventEmitter();

  constructor(private themeService: ThemeService) {}

  get checkboxColor(): string {
    return this.themeService.getVariable("--checkbox");
  }

  get labelColor(): string {
    return this.themeService.getVariable("--text-primary-color");
  }

  onToggle($event: boolean): void {
    this.toggleCheckbox.emit($event);
  }
}
