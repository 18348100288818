<div *ngIf="itemDetails" class="item-details-container">
    <div class="title-container" *ngIf="title">
        <div class="title">{{ title }}</div>
        <div class="timer" *ngIf="formattedTimer" data-testid="item-timer">
            <div class="text">{{ formattedTimer }}</div>
        </div>
    </div>

    <div class="details">
        <app-item-detail-row label="Queue:" data-cy="type-row" [content]="itemDetails?.queueLevel | titlecase"></app-item-detail-row>
        <app-item-detail-row label="Source:" data-cy="source-row">
            <ng-template appItemDetailRowContent>
                <div class="source">
                    <gaggle-pill [color]="pillBackgroundColor" [textColor]="pillTextColor">
                        {{ itemEntityType }}
                    </gaggle-pill>
                    <p *ngIf="showWorkItemSource" class="work-item-source">
                        {{ workItemSource }}
                        <span *ngIf="extensionItemSource">({{ extensionItemSource }})</span>
                    </p>
                </div>
            </ng-template>
        </app-item-detail-row>
        <app-item-detail-row
            label="File Name:"
            data-cy="file-name-row"
            [content]="itemDetails?.entityName"
            [fileName]="itemDetails?.entityName"
            [downloadFile]="fileDownloadInfo"></app-item-detail-row>
        <app-item-detail-row label="Last Editor:" data-cy="last-editor-row" [content]="itemDetails?.lastModifiedBy"></app-item-detail-row>
        <app-item-detail-row
            label="Last Modified:"
            data-cy="last-modified-row"
            [content]="formattedDate(itemDetails?.lastModified)"></app-item-detail-row>
        <app-item-detail-row label="Revision:" data-cy="revision-row" [content]="itemDetails?.revision"></app-item-detail-row>

        <div *ngIf="showMoreFileInfo">
            <app-item-detail-row label="Folder:" data-cy="folder-row" [content]="itemDetails?.folder"></app-item-detail-row>
            <app-item-detail-row label="File ID:" data-cy="file-id-row" [content]="itemDetails?.entityId"></app-item-detail-row>
        </div>

        <div *ngIf="hasMoreFileInfo && itemDetails?.entityName" class="show-toggle-container" data-cy="file-info-show-more">
            <app-show-more-less-toggle [showMore]="showMoreFileInfo" (click)="onClickShowFileInfo()"></app-show-more-less-toggle>
        </div>

        <div *ngIf="hasMessageFields; else noMessageFields">
            <div *ngIf="hasAttachments">
                <div *ngFor="let attachment of attachmentsToDisplay; let index = index">
                    <app-item-detail-row
                        data-cy="attachments-row"
                        [label]="attachmentLabel(index)"
                        [downloadAttachment]="getAttachmentDownload(attachment)"
                        [fileName]="attachment.name">
                        <ng-template appItemDetailRowContent>
                            <span class="attachment-name">
                                {{ attachment.name }}
                            </span>
                        </ng-template>
                    </app-item-detail-row>
                </div>
            </div>
            <div *ngIf="hasMultipleAttachments" class="show-toggle-container" data-cy="multiple-attachments-show-more">
                <app-show-more-less-toggle [showMore]="showMoreAttachments" (click)="onClickShowAttachments()"></app-show-more-less-toggle>
            </div>
            <div *ngIf="!isWebFilterItem">
                <app-item-detail-row label="From:" data-cy="from-row" [content]="messageMetadata.sender"></app-item-detail-row>
                <app-item-detail-row label="To:" data-cy="to-row" [content]="messageMetadata.recipients"></app-item-detail-row>
                <app-item-detail-row
                    label="{{ flaggedUserHeader }}:"
                    data-cy="flagged-user-row"
                    [content]="itemDetails.userName"></app-item-detail-row>
            </div>
            <div *ngIf="isWebFilterItem">
                <app-item-detail-row
                    label="Created By:"
                    data-cy="created-by-row"
                    [content]="itemDetails.userFullName"></app-item-detail-row>
            </div>
        </div>
        <ng-template #noMessageFields>
            <app-item-detail-row label="Created By:" data-cy="created-by-row" [content]="itemDetails.userFullName"></app-item-detail-row>
            <div *ngIf="hasAttachments">
                <div *ngFor="let attachment of attachmentsToDisplay; let index = index">
                    <app-item-detail-row
                        data-cy="attachments-row"
                        [label]="attachmentLabel(index)"
                        [content]="attachment.name"
                        [downloadAttachment]="getAttachmentDownload(attachment)"
                        [fileName]="attachment.name"></app-item-detail-row>
                </div>
            </div>
            <div *ngIf="hasMultipleAttachments" class="show-toggle-container" data-cy="multiple-attachments-show-more">
                <app-show-more-less-toggle [showMore]="showMoreAttachments" (click)="onClickShowAttachments()"></app-show-more-less-toggle>
            </div>
        </ng-template>

        <app-item-detail-row
            label="Student ID:"
            data-cy="user-id-row"
            [content]="userId"
            [copyValue]="userId"
            [externalLink]="userAccountURL"></app-item-detail-row>
        <app-item-detail-row
            label="Item ID:"
            data-cy="item-id-row"
            [content]="itemDetails?.id"
            [copyValue]="itemDetails?.id"></app-item-detail-row>
        <app-item-detail-row
            *ngIf="itemDetails?.ncmecId"
            label="NCMEC ID:"
            data-cy="ncmec-id-row"
            [content]="itemDetails?.ncmecId"></app-item-detail-row>
        <app-item-detail-row
            label="Occurred On:"
            data-cy="occurred-on-row"
            [content]="formattedDate(itemDetails?.occurrenceDate)"></app-item-detail-row>

        <div *ngIf="hasPriorIncidents">
            <app-item-detail-row label="Incidents ({{ priorIncidentsCount }}):" data-cy="incidents-row" [externalLink]="incidentURL">
                <ng-template appItemDetailRowContent>
                    <div class="repeated-incident" data-cy="repeated-incident" *ngFor="let incident of priorIncidentsToDisplay">
                        <div class="incident-date" *ngIf="incident.isNewDate">
                            {{ incident.date }}
                            <span class="incident-year">{{ incident.year }}</span>
                        </div>
                        <div class="incident">
                            <div class="incident-time">{{ incident.time }}</div>
                            <div class="incident-type" [ngClass]="incident.formattedReasons ? 'formatted' : null">
                                {{ incident.displayWarningType }}
                            </div>
                            <div class="incident-reasons">{{ incident.formattedReasons }}</div>
                        </div>
                    </div>
                </ng-template>
            </app-item-detail-row>
        </div>
        <div *ngIf="hasMoreThanTwoPriorIncidents" class="show-toggle-container" data-cy="prior-incidents-show-more">
            <app-show-more-less-toggle [showMore]="showMoreIncidentInfo" (click)="onClickShowIncidentInfo()"></app-show-more-less-toggle>
        </div>

        <app-item-detail-row
            label="District:"
            data-cy="district-row"
            [content]="itemDetails?.districtName"
            [copyValue]="itemDetails?.districtName"></app-item-detail-row>
        <app-item-detail-row
            label="School:"
            data-cy="school-row"
            [content]="itemDetails?.groupName"
            [copyValue]="itemDetails?.groupName"></app-item-detail-row>
        <app-item-detail-row
            label="Quarantine:"
            data-cy="quarantine-row"
            [content]="itemDetails?.canQuarantine ? 'Yes' : 'No'"></app-item-detail-row>
        <div *ngIf="showMoreSchoolInfo">
            <app-item-detail-row label="Initiator:" data-cy="initiator-row" [content]="itemDetails?.workerName"></app-item-detail-row>
            <app-item-detail-row
                label="Flagged Timezone:"
                data-cy="flagged-timezone-row"
                [content]="itemDetails?.studentTimezone"></app-item-detail-row>
        </div>
        <div *ngIf="hasMoreSchoolInfo" class="show-toggle-container" data-cy="school-info-show-more">
            <app-show-more-less-toggle [showMore]="showMoreSchoolInfo" (click)="onClickShowSchoolInfo()"></app-show-more-less-toggle>
        </div>

        <div *ngIf="showEventInfo">
            <div class="events">
                <p class="event-label">Events:</p>
                <div class="repeated-events">
                    <div class="event-row" *ngFor="let event of events; let first = first; let i = index">
                        <p class="event-date" *ngIf="first || event.date.dayAndMonth !== events[i - 1].date.dayAndMonth">
                            {{ formatEventDate(event.date.originalDate) }}
                            <span class="year">{{ event.date.year }}</span>
                        </p>

                        <div class="event-row">
                            <div class="event-row-columns">
                                <ng-container *ngIf="event.date.message; else eventDate">
                                    <span>{{ event.date.message }}</span>
                                </ng-container>
                                <ng-template #eventDate>
                                    <span>{{ event.date.time }}</span>
                                </ng-template>

                                <div class="event-content">
                                    <p [ngClass]="{ reopened: event.label === 'Reopened' }">{{ event.label }}</p>
                                    <div class="worker-name" data-testid="worker-name">
                                        <p *ngIf="event.workerName">
                                            <span>{{ event.workerName }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
