import { CombinedQueueType, QueueStatistics } from "src/app/models/queue-statistics.model";
import { ActionReason, DownloadInfo, WorkItem, WorkItemType } from "src/app/models/work-item.model";
import {
  ACTION_REASON_MAP,
  ACTION_REASON_SUBJECT_MAP,
  EmailTemplates,
  PriorIncident,
  EmailTemplate,
  WARNING_TYPE_MAP,
} from "src/app/models/incident.model";
import { FeatureFlag } from "src/app/models/feature-flags.model";
import { ContentCategory } from "src/app/models/content-category.model";
import { WarningEventType } from "src/generated/graphql";

export function getQueueCount(queues: QueueStatistics[], combinedQueueType: CombinedQueueType): number {
  const currentQueue = queues[0];

  if (combinedQueueType === CombinedQueueType.HIGH_PRIORITY) {
    return currentQueue.highPriorityCount;
  }
  return combinedQueueType === CombinedQueueType.L1 ? currentQueue.level1Count : currentQueue.level2Count;
}

export async function downloadFile(url: string, fileName: string): Promise<void> {
  if (url) {
    fetch(url)
      .then((response) => response.blob())
      .then((blobResponse) => {
        const blob = new Blob([blobResponse], { type: "application/octet-stream" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
}

export function downloadAttachment(downloadInfo: DownloadInfo): void {
  if (downloadInfo?.url) {
    const link = window.document.createElement("a");
    link.href = downloadInfo.url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function isOversized(workItem: WorkItem): boolean {
  return !!workItem && workItem.workItemEntityType === WorkItemType.FILE && workItem?.entitySize > 25 * 1024 * 1024;
}

export function applyFeatureFlagsToTemplates(templates: EmailTemplate[], featureFlags: FeatureFlag[]): EmailTemplate[] {
  if (!templates || templates.length === 0) {
    return [];
  }
  return templates.filter((t) => {
    const hasFeatureFlagCondition = t.featureFlagCondition?.length > 0;
    return hasFeatureFlagCondition ? evaluateFeatureFlagCondition(t.featureFlagCondition, featureFlags) : true;
  });
}

export function isExpired(expirationDate: string, secondsFromNow = 0): boolean {
  const expDate = new Date(expirationDate);
  const now = new Date();
  const validUntil = new Date(now.getTime() + secondsFromNow * 1000);
  return expDate < validUntil;
}

export function getItemEmailTemplates(
  templates: EmailTemplates,
  workItem: WorkItem,
  highestWarningType: ContentCategory,
  allSelectedCategories: ContentCategory[],
): EmailTemplate[] {
  if (!templates) {
    return [];
  }

  const selectedCategoryTemplateReasons = allSelectedCategories.map((category) => {
    if (!highestWarningType.isPssQcon && categoryIsUsedForViolations(category)) {
      return ActionReason.GENERAL.valueOf();
    } else {
      return category.actionReason.valueOf();
    }
  });

  const selectedCategoryActionReasons = allSelectedCategories
    .map((category) => ACTION_REASON_SUBJECT_MAP[category.actionReason])
    .sort()
    .join(", ");

  const relevantToEntityType = templates[workItem.workItemEntityType];
  const relevantToWarningLevel = relevantToEntityType[highestWarningType.warningType];
  const templatesMatchingActionReasons = relevantToWarningLevel.filter((template) =>
    selectedCategoryTemplateReasons.includes(template.category),
  );

  if (!highestWarningType.isPssQcon && categoryIsUsedForViolations(highestWarningType)) {
    const newSubject = WARNING_TYPE_MAP[highestWarningType.warningType] + ": " + selectedCategoryActionReasons;
    return replaceVariablesInItemTemplates(templatesMatchingActionReasons, workItem, selectedCategoryActionReasons, newSubject);
  } else {
    return replaceVariablesInItemTemplates(templatesMatchingActionReasons, workItem, selectedCategoryActionReasons);
  }
}

export function getItemOtherEmailTemplates(
  templates: EmailTemplates,
  workItem: WorkItem,
  warningType: WarningEventType,
  selectedCategories: ContentCategory[],
): EmailTemplate[] {
  if (!templates || !workItem) {
    return [];
  }
  const selectedCategoryActionReasons = selectedCategories
    .map((category) => ACTION_REASON_SUBJECT_MAP[category.actionReason])
    .sort()
    .join(", ");

  const relevantToEntityType = templates[workItem.workItemEntityType];
  const relevantToWarningLevel = relevantToEntityType[warningType];
  const templateMatchingOther = relevantToWarningLevel.filter((template) => template.category === "_OTHER_TBD");

  return replaceVariablesInItemTemplates(templateMatchingOther, workItem, selectedCategoryActionReasons);
}

function categoryIsUsedForViolations(category): boolean {
  const violationActionReasons = [ActionReason.NUDITY, ActionReason.SEXUAL_CONTENT, ActionReason.PROFANITY, ActionReason.RESTRICTED_USE];
  return violationActionReasons.includes(category.actionReason);
}

function replaceVariablesInItemTemplates(
  templates: EmailTemplate[],
  workItem: WorkItem,
  inappropriateContentReason: string,
  newSubject?: string,
): EmailTemplate[] {
  if (!templates || templates.length === 0 || !workItem) {
    return templates;
  }

  if (newSubject) {
    return templates.map((template) => ({
      ...template,
      subject: newSubject,
      body: replaceVariablesInBody(template.body, workItem),
      originalSubject: template.subject,
      originalBody: template.body,
      inappropriateContentReasons: inappropriateContentReason,
    }));
  } else {
    return templates.map((template) => ({
      ...template,
      subject: replaceInappropriateContentReasonsInSubject(template.subject, inappropriateContentReason),
      body: replaceVariablesInBody(template.body, workItem),
      originalSubject: template.subject,
      originalBody: template.body,
      inappropriateContentReasons: inappropriateContentReason,
    }));
  }
}

function replaceInappropriateContentReasonsInSubject(subject: string, inappropriateContentReason: string): string {
  let updated = subject.slice();
  updated = updated.replace("%INAPPROPRIATE_CONTENT_REASONS%", inappropriateContentReason);
  return updated;
}

function replaceVariablesInBody(input: string, workItem: WorkItem): string {
  let output = input;

  output = output.replace("%USER_EMAIL%", workItem.userEmail);
  output = output.replace("%USER_FULL_NAME%", workItem.userFullName);
  output = output.replace("%ITEM_ID%", workItem.id);
  output = output.replace("%SAFETY_TEAM_NAME%", ""); // Always returns empty string, continue to do the same...

  return output;
}

export function evaluateFeatureFlagCondition(condition: string, featureFlags: FeatureFlag[]): boolean {
  if (!featureFlags.length) {
    return true;
  }
  const conditionParts = condition.split(/\s*\!\=\s*|\s*\=\s*/);
  if (conditionParts.length < 2) {
    // Invalid condition specified, so assume no match
    return false;
  }
  const matchingFlag = featureFlags ? featureFlags.find((f) => f.name === conditionParts[0]) : null;
  const conditionValueLowerCase = conditionParts[1].toLowerCase();
  const checkIsEqual = condition.indexOf("!=") <= 0;
  if (matchingFlag) {
    const flagValueLowerCase = matchingFlag.value.toLowerCase();
    // Found matching feature flag, so return the comparison result
    return checkIsEqual ? flagValueLowerCase === conditionValueLowerCase : flagValueLowerCase !== conditionValueLowerCase;
  } else {
    // No matching feature flag, so if condition is checking for equality, return false since condition cannot match a
    // non-existent flag. Otherwise, return true.
    console.error(`Could not find feature flag "${conditionParts[0]}"`);
  }
}

/*
  NOTE: if the raw Google Chat content is eventually available as real HTML (not text with line breaks), then this method
  can be converted to a no-op.
   */
export function convertGoogleChatContentToHTML(content: string): string {
  return !!content && content.length > 0 ? content.replace(/(\r\n|\n)/g, "<br>") : "";
}

export function getWarningTypeIcon(warningType: WarningEventType): string {
  switch (warningType) {
    case WarningEventType.PossibleStudentSituation:
      return "incident-pss";
    case WarningEventType.QuestionableContent:
      return "incident-qcon";
    default:
      return "incident-viol";
  }
}

export function formatActionReasonsFromPriorIncidents(incident: PriorIncident): string {
  if (incident.actionReasons) {
    return incident.actionReasons.map((r) => ACTION_REASON_MAP[r]).join(", ");
  } else {
    return ACTION_REASON_MAP[ActionReason.GENERAL];
  }
}

export function objectToUrlEncodedString(obj: Record<string, unknown>, base64Encode = false): string {
  if (Object.keys(obj).length === 0) {
    return "";
  }

  const retVal = encodeURI(JSON.stringify(obj));
  return base64Encode ? btoa(retVal) : retVal;
}

export function urlEncodedStringToObject(str: string, base64Decode = false): Record<string, unknown> {
  try {
    if (base64Decode) {
      str = atob(str);
    }
    return JSON.parse(decodeURI(str));
  } catch (e) {
    console.error("Error parsing URL encoded string to object", e);
    return {};
  }
}
