import gql from "graphql-tag";

export default gql`
  mutation submitNCMECReport(
    $classification: NCMECIndustryClassification!
    $incidentTimestamp: String!
    $request: WorkItemEventInput!
    $safetyAudit: Boolean!
    $incidentTimezone: String!
    $schoolOverrides: NCMECSchoolOverridesInput
    $districtOverrides: NCMECDistrictOverridesInput
    $studentOverrides: NCMECStudentOverridesInput
  ) {
    submitNCMECReport(
      classification: $classification
      incidentTimestamp: $incidentTimestamp
      request: $request
      safetyAudit: $safetyAudit
      incidentTimezone: $incidentTimezone
      schoolOverrides: $schoolOverrides
      districtOverrides: $districtOverrides
      studentOverrides: $studentOverrides
    ) {
      reports {
        reportId
      }
    }
  }
`;
