import { Component, HostListener, Input } from "@angular/core";

@Component({
  selector: "app-image-modal",
  templateUrl: "./image-modal.component.html",
  styleUrls: ["./image-modal.component.scss"],
})
export class ImageModalComponent {
  showModal = false;

  @Input() src: string;
  @Input() imageAlt: string;

  @HostListener("document:keydown", ["$event"]) hotkey(event: KeyboardEvent): void {
    if (this.showModal && event.code === "Escape") {
      this.close();
    }
  }

  constructor() {}

  close(): void {
    this.showModal = false;
  }

  show(): void {
    this.showModal = true;
  }

  get fullImageAlt(): string {
    return `Full ${this.imageAlt}`;
  }
}
