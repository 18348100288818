import { inject, Injectable, Injector } from "@angular/core";
import { State, Action, StateContext, NgxsAfterBootstrap } from "@ngxs/store";
import {
  AddCommunication,
  AddCommunicationFromEmailEmergencyContacts,
  AddCommunicationFromPhoneRow,
  AddCommunicationFromTextEmergencyContacts,
  AddCommunicationSuccessFromIncidentState,
  NewIncident,
  NewIncidentFromIncidentResponseRightDrawer,
  ResetIncidentFromIncidentResponseRightDrawer,
} from "src/app/shared/state/incident/incident.actions";
import { AddEmergencyContactIncidentCommunicationGQL, IncidentProcessingCommunication } from "src/generated/graphql";

export class IncidentStateModel {
  public incidentId!: string;
  public processingCommunications!: IncidentProcessingCommunication[];
}

const defaults = {
  incidentId: undefined,
  processingCommunications: undefined,
};

@State<IncidentStateModel>({
  name: "incident",
  defaults,
})
@Injectable()
export class IncidentState implements NgxsAfterBootstrap {
  private injector = inject(Injector);
  private addEmergencyContactIncidentCommunicationGQL: AddEmergencyContactIncidentCommunicationGQL;

  // The services that are provided by Apollo are not available until after the app has bootstrapped,
  // so we need to inject them manually in order to eliminate an issue where the Apollo client is trying
  // to be configured prior to the config / authentication services being initialized.
  ngxsAfterBootstrap(): void {
    this.addEmergencyContactIncidentCommunicationGQL = this.injector.get(AddEmergencyContactIncidentCommunicationGQL);
    this.addEmergencyContactIncidentCommunicationGQL.client = "noStatus";
  }

  @Action([NewIncidentFromIncidentResponseRightDrawer])
  newIncident({ setState }: StateContext<IncidentStateModel>, { incidentId }: NewIncident): void {
    setState({ incidentId, processingCommunications: [] });
  }

  // prettier-ignore
  @Action([
    AddCommunicationFromEmailEmergencyContacts,
    AddCommunicationFromTextEmergencyContacts,
    AddCommunicationFromPhoneRow
  ])
  addCommunication(
    { getState, dispatch }: StateContext<IncidentStateModel>,
    { ecId, ecName, communicationType, phoneNumber }: AddCommunication
  ): void {
    const incidentId = getState().incidentId;
    if (incidentId) {
      this.addEmergencyContactIncidentCommunicationGQL.mutate({ incidentId, ecId, ecName, communicationType, phoneNumber }).subscribe({
        next: (result) => {
          dispatch(new AddCommunicationSuccessFromIncidentState(result.data.addEmergencyContactIncidentCommunication));
        },
        error: (error) => {
          // TODO: Implement some sort of error handling
          console.error(error);
        },
      });
    }
  }

  @Action([AddCommunicationSuccessFromIncidentState])
  addCommunicationSuccess(
    { getState, patchState }: StateContext<IncidentStateModel>,
    { incidentProcessingCommunication }: AddCommunicationSuccessFromIncidentState,
  ): void {
    const processingCommunications = getState().processingCommunications;
    const incidentId = getState().incidentId;

    // Make sure the incidentId matches the incidentId in the store
    // It's possible that the incidentId in the store is stale if the user
    // has moved on to another incident / work item.
    if (incidentId !== incidentProcessingCommunication.incidentId) {
      return;
    }

    patchState({
      processingCommunications: [
        ...processingCommunications.filter((pc) => pc.recordId !== incidentProcessingCommunication.recordId),
        incidentProcessingCommunication,
      ],
    });
  }

  @Action([ResetIncidentFromIncidentResponseRightDrawer])
  resetIncident({ setState }: StateContext<IncidentStateModel>): void {
    setState(defaults);
  }
}
