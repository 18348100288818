import { Selector } from "@ngxs/store";
import { SearchState, SearchStateModel, SearchType } from "src/app/shared/state/search/search.state";
import {
  IncidentSearchParametersInputV2,
  IncidentSearchResultItemsFragment,
  WorkItemSearchParametersInputV2,
  WorkItemSearchResultItemsFragment,
} from "src/generated/graphql";

export type SearchPaginationInfo = {
  resultsCount: number;
  pageIndex: number;
  pageSize: number;
  pageCount: number;
};

export type SearchRecordNavigationInfo = {
  currentRecordId: string | null;
  isFirstRecord: boolean;
  isLastRecord: boolean;
};

export class SearchSelectors {
  @Selector([SearchState])
  static searchType(state: SearchStateModel): SearchType {
    return state.searchType;
  }

  @Selector([SearchState])
  static records(state: SearchStateModel): WorkItemSearchResultItemsFragment[] | IncidentSearchResultItemsFragment[] {
    return state[state.searchType].records;
  }

  @Selector([SearchState])
  static criteria(state: SearchStateModel): WorkItemSearchParametersInputV2 | IncidentSearchParametersInputV2 {
    return state[state.searchType].criteria;
  }

  @Selector([SearchState])
  static searchActive(state: SearchStateModel): boolean {
    return state[state.searchType].searchActive;
  }

  @Selector([SearchState])
  static currentRecordId(state: SearchStateModel): string {
    return state[state.searchType].currentRecordId;
  }

  @Selector([SearchState])
  static searchRecordNavigationInfo(state: SearchStateModel): SearchRecordNavigationInfo {
    const { currentRecordId, records } = state[state.searchType];
    const currentRecordIndex = records.findIndex((r) => r.id === currentRecordId);
    return {
      currentRecordId,
      isFirstRecord: currentRecordIndex === 0,
      isLastRecord: currentRecordIndex === records.length - 1,
    };
  }

  @Selector([SearchState])
  static paginationInfo(state: SearchStateModel): SearchPaginationInfo {
    const results = state[state.searchType];
    return {
      resultsCount: results.total,
      pageIndex: results.criteria.pageNumber - 1, // Material Paginator is 0-based, API is 1-based
      pageSize: results.criteria.pageSize,
      pageCount: results.pageCount,
    };
  }
}
