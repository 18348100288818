<div
    class="contact"
    [ngClass]="{ disabled: isDisabled }"
    *ngIf="contact">
    <div class="name">
        {{ contact.name }}
    </div>
    <div>
        <div class="title-container">
            <div class="title">
                {{ jobTitle }}
                <gaggle-pill
                    class="after-hours-pill"
                    icon="after-hours"
                    data-testid="after-hours-pill"
                    color="#e6e6ff"
                    iconColor="#665cff"
                    *ngIf="contact.afterHours"></gaggle-pill>
            </div>
            <gaggle-toggle
                *ngIf="!contact.afterHours"
                label="Ignore Unavailability"
                name="{{ contact.name }}-unavailable"
                [isToggleOn]="!isDisabled"
                (status)="onToggleDisabled()"></gaggle-toggle>
        </div>
        <app-phone-row
            type="Office"
            [phoneNumber]="contact.unformattedOfficePhone"
            [contact]="contact"
            [disabled]="isDisabled"
            (startSectionTimer)="startCallSectionTimer()"></app-phone-row>
        <app-phone-row
            type="Mobile"
            [phoneNumber]="contact.unformattedMobilePhone"
            [contact]="contact"
            [disabled]="isDisabled"
            (startSectionTimer)="startCallSectionTimer()"></app-phone-row>
        <app-phone-row
            type="Home"
            [phoneNumber]="contact.unformattedHomePhone"
            [contact]="contact"
            [disabled]="isDisabled"
            (startSectionTimer)="startCallSectionTimer()"></app-phone-row>
    </div>
</div>
