import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { EmailRecipient, Incident } from "src/app/models/incident.model";
import { GroupAdministrator, GsmEmailSetting } from "src/app/models/work-item.model";
import { StateService } from "src/app/shared/services/state.service";
import { WarningEventType, WorkItemEntityType } from "src/generated/graphql";
import { EmergencyContactExtended, EmergencyContactService } from "src/app/shared/services/emergency-contact.service";

@Component({
  selector: "app-email-emergency-contacts",
  templateUrl: "./email-emergency-contacts.component.html",
  styleUrls: ["./email-emergency-contacts.component.scss"],
})
export class EmailEmergencyContactsComponent implements OnChanges {
  @Input() expand: boolean;
  @Input() contacts: EmergencyContactExtended[];
  @Input() incident: Incident;
  @Input() gsmEmailSetting: GsmEmailSetting;
  @Input() groupAdministrator: GroupAdministrator;
  @Input() disableSendButton = false;
  @Input() isReopenIncident: boolean;
  @Input() isOpenWorkItem: boolean;
  @Output() enableNextSection = new EventEmitter();

  public isAfterHours: boolean;
  public additionalEmailRecipients: number = 0;
  public additionalEmailCcRecipients: number = 0;
  public expandRecipients: boolean = false;

  constructor(private stateService: StateService, private emergencyContactService: EmergencyContactService) {
    this.isAfterHours = stateService.itemIsInAfterHoursWindow;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Anytime the contacts update, update the incident payload
    if (changes.contacts) {
      this.incident.payload.recipients = [];
      this.incident.payload.digestRecipients = [];
      if (this.contacts.length > 0) {
        this.contacts.forEach((ec) => {
          const targetList = ec.availability.email ? "recipients" : "digestRecipients";
          this.incident.payload[targetList].push({ name: ec.name, email: ec.email });
          if (ec.alternateEmail) {
            this.incident.payload[targetList].push({ name: `${ec.name} (alternate)`, email: ec.alternateEmail });
          }
        });
      }
    }
  }

  enableNextIncidentSection(): void {
    this.enableNextSection.emit();
  }

  get contactsAreValid(): boolean {
    return (
      this.incident.payload.warningType == WarningEventType.FirstWarning ||
      this.incident.payload.warningType == WarningEventType.SecondWarning ||
      this.incident.payload.warningType == WarningEventType.ThirdWarning ||
      this.contacts?.length > 0
    );
  }

  get shouldIncludeBlockedUser(): boolean {
    return (
      (this.gsmEmailSetting === GsmEmailSetting.ADMINISTRATOR_AND_STUDENT || this.gsmEmailSetting === GsmEmailSetting.STUDENT) &&
      this.incident.payload.warningType !== WarningEventType.PossibleStudentSituation &&
      this.incident.payload.warningType !== WarningEventType.QuestionableContent &&
      !!this.incident.payload.userEmail &&
      this.incident.payload.userEmail.length > 0
    );
  }

  get shouldIncludeAdministrator(): boolean {
    return (
      (this.gsmEmailSetting === GsmEmailSetting.ADMINISTRATOR_AND_STUDENT || this.gsmEmailSetting === GsmEmailSetting.ADMINISTRATOR) &&
      this.incident.payload.warningType !== WarningEventType.PossibleStudentSituation &&
      this.incident.payload.warningType !== WarningEventType.QuestionableContent &&
      !!this.groupAdministrator?.email
    );
  }

  private getRecipientCount(recipients): number {
    if (!recipients || recipients.trim().length === 0) {
      return 0;
    }
    return recipients.trim().split(",").length;
  }

  onAdditionalRecipientsChange(recipients: string): void {
    this.additionalEmailRecipients = this.getRecipientCount(recipients);
  }

  onAdditionalCcRecipientsChange(recipients: string): void {
    this.additionalEmailCcRecipients = this.getRecipientCount(recipients);
  }

  onClickReloadContacts(): void {
    this.emergencyContactService.fetchContacts(
      this.stateService.currentItemDetails.districtId,
      this.stateService.currentItemDetails.groupId,
      this.stateService.currentItemDetails.workItemEntityType as unknown as WorkItemEntityType,
    );
  }

  onClickSendEmail(): void {
    if (this.shouldIncludeBlockedUser) {
      this.incident.payload.recipients.push({ name: "", email: this.incident.payload.userEmail });
    }
    if (this.shouldIncludeAdministrator) {
      this.incident.payload.recipients.push({ name: this.groupAdministrator.name, email: this.groupAdministrator.email });
    }

    this.addAdditionalRecipients(this.incident.additionalRecipientsInput, this.incident.payload.recipients);
    this.addAdditionalRecipients(this.incident.ccRecipientsInput, this.incident.payload.ccRecipients);
  }

  private addAdditionalRecipients(recipients: string, targetList: EmailRecipient[]): void {
    const recipientsList = recipients.split(",");
    recipientsList.forEach((r) => {
      if (r.trim()) {
        targetList.push({ name: "", email: r.trim() });
      }
    });
  }
}
