import gql from "graphql-tag";

export default gql`
  query getPSAPInfoForStudent($userId: ID!) {
    getPSAPInfoForStudent(userId: $userId) {
      relationship
      address {
        name
        address
        city
        state
        country
        postalCode
        phone
      }
      psaps {
        mapUrl
        address {
          name
          address
          city
          state
          country
          postalCode
          phone
        }
      }
    }
  }
`;
