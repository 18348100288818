import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { StateService } from "src/app/shared/services/state.service";
import { IncidentSection, SectionTitles } from "src/app/models/incident-response.model";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { EventType, Incident } from "src/app/models/incident.model";
import { ActionReason, EventPayload } from "src/app/models/work-item.model";
import { Router } from "@angular/router";
import { UiAnalyticsService } from "src/app/shared/services/ui-analytics.service";
import { UiEventAction, WarningEventType } from "src/generated/graphql";
import { AcknowledgementService } from "src/app/shared/services/acknowledgement.service";

@Component({
  selector: "app-section-navigation",
  templateUrl: "./section-navigation.component.html",
  styleUrls: ["./section-navigation.component.scss"],
})
export class SectionNavigationComponent implements OnInit, OnChanges {
  @Input() incident: Incident;
  @Input() ncmecOnly: boolean;
  @Input() ncmecEvent: EventType.RESOLVE | EventType.QUARANTINE_ONLY | EventType.DELETE_PRIOR_INCIDENT;
  @Input() currentSection;
  @Input() enabledSections;
  @Input() enableContentNext: boolean;
  @Input() enableEmailNext: boolean;
  @Input() enableTextNext: boolean;
  @Input() isReopenIncident: boolean;
  @Input() isOpenWorkItem: boolean;
  @Input() isAuditItem = false;
  @Output() setCurrentSection = new EventEmitter();
  shouldEscalatePSSOnNext = false;
  isAfterHours = false;

  constructor(
    private state: StateService,
    private appsync: AppSyncService,
    private uiAnalyticsService: UiAnalyticsService,
    private router: Router,
    private acknowledgementService: AcknowledgementService,
  ) {}

  ngOnInit(): void {
    this.state.shouldToggleEscalatePSS$.subscribe((data) => {
      this.shouldEscalatePSSOnNext = data && this.currentSection.name === SectionTitles.ContentCategory;
    });

    this.isAfterHours = this.state.itemIsInAfterHoursWindow;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentSection) {
      this.state.startSectionTimer(this.timerName);
    }
  }

  goToPreviousSection(): void {
    this.state.pauseSectionTimer(this.timerName);

    this.setCurrentSection.emit(this.enabledSections[this.currentSectionIndex - 1]);
  }

  async goToNextSection(): Promise<void> {
    if (this.disableNextButton) {
      return;
    }

    this.state.pauseSectionTimer(this.timerName);

    if (this.currentSection.shouldEscalatePSSOnNext()) {
      this.uiAnalyticsService.sendAction(UiEventAction.EscalatePss, true);
      await this.appsync.resolveItem(EventType.ESCALATE_PSS, this.incident.payload, this.isReopenIncident, this.isOpenWorkItem);
      this.appsync.handleNextWorkItem();
      return;
    } else {
      if (this.upcomingSections.length) {
        this.setCurrentSection.emit(this.enabledSections[this.currentSectionIndex + 1]);
      } else {
        this.uiAnalyticsService.endWork();

        if (this.ncmecOnly) {
          const payload: EventPayload = {
            userId: this.state.currentItemDetails.userId,
            groupId: this.state.currentItemDetails.groupId,
            districtId: this.state.currentItemDetails.districtId,
            customerId: this.state.currentItemDetails.customerId,
            entityId: this.state.currentItemDetails.entityId,
            entityType: this.state.currentItemDetails.workItemEntityType,
            userEmail: this.ncmecEvent === EventType.QUARANTINE_ONLY ? this.state.currentItemDetails.userEmail : null,
            remoteEntityId: this.ncmecEvent === EventType.QUARANTINE_ONLY ? this.state.currentItemDetails.remoteEntityId : null,
            actionReasons: [ActionReason.NUDITY], // always nudity for NCMEC
          };

          await this.appsync.resolveItem(this.ncmecEvent, payload, this.isReopenIncident);
          this.appsync.handleNextWorkItem();
        } else if (this.isReopenIncident) {
          await this.router.navigateByUrl(`/incidents/${this.incident.workItemId}/reopened-incident`);
        } else if (this.isOpenWorkItem) {
          await this.router.navigateByUrl(`/work-items/${this.incident.workItemId}/created-incident`);
        } else {
          if (this.incident.payload.warningType === WarningEventType.PossibleStudentSituation) {
            this.appsync.completeGSMProcessing(this.incident.workItemId);
          }

          await this.appsync.handleNextWorkItem();
        }
      }
    }
  }

  navigateTo(sectionName: string): void {
    if (sectionName === SectionTitles.ContentCategory && this.enableEmailNext) {
      return;
    } else if (sectionName === SectionTitles.EmailEmergencyContacts && (this.enableEmailNext || !this.enableContentNext)) {
      return;
    } else if (sectionName === SectionTitles.SubmitIncident && !this.enableContentNext) {
      return;
    } else if (sectionName === SectionTitles.TextEmergencyContacts && !this.enableEmailNext) {
      return;
    } else if (sectionName === SectionTitles.CallEmergencyContacts && !this.enableEmailNext) {
      return;
    } else if (sectionName === SectionTitles.CreateSalesforceCase && !this.enableEmailNext) {
      return;
    } else if (sectionName === SectionTitles.NCMEC && !this.enableEmailNext) {
      return;
    } else {
      this.state.pauseSectionTimer(this.timerName);
      this.setCurrentSection.emit(this.enabledSections.find(({ name }) => name === sectionName));
    }
  }

  sectionHeader(section: IncidentSection): string {
    const sectionNumber = this.enabledSections.indexOf(this.enabledSections.find(({ name }) => name === section.name)) + 1;
    return `${sectionNumber}. ${this.isAfterHours ? section.afterHoursName : section.name}`;
  }

  indicatorIcon(section: IncidentSection): string {
    if (section.indicatorIcon) {
      return section.indicatorIcon();
    }
    return null;
  }

  indicatorTooltip(section: IncidentSection): string {
    if (section.indicatorTooltip) {
      return section.indicatorTooltip();
    }
    return null;
  }

  get timerName(): string {
    if (this.currentSection.name !== this.currentSection.afterHoursName) {
      return `${this.isAfterHours ? "afterHours" : ""}${this.currentSection.timerName}`;
    } else {
      return this.currentSection.timerName;
    }
  }

  get nextLabelText(): string {
    if (this.shouldEscalatePSSOnNext) {
      return "Escalate PSS";
    }
    return this.upcomingSections.length > 0 ? "Next" : "Complete";
  }

  get currentSectionIndex(): number {
    return this.enabledSections.indexOf(this.enabledSections.find(({ name }) => name === this.currentSection.name));
  }

  get currentOrCompletedSections(): IncidentSection[] {
    if (this.currentSectionIndex >= 0) {
      return this.enabledSections.slice(0, this.currentSectionIndex + 1);
    } else {
      return [];
    }
  }

  get upcomingSections(): IncidentSection[] {
    if (this.currentSectionIndex >= 0) {
      return this.enabledSections.slice(this.currentSectionIndex + 1);
    } else {
      return [];
    }
  }

  get disablePreviousButton(): boolean {
    return this.currentSection.disablePrevious();
  }

  get hidePreviousButton(): boolean {
    return !this.currentSection.showPrevious() || this.currentOrCompletedSections.length <= 1;
  }

  get disableNextButton(): boolean {
    return this.currentSection.disableNext();
  }

  get hideNextButton(): boolean {
    return !this.currentSection.showNext();
  }
}
