<h1 mat-dialog-title>Communications</h1>

<div class="contact-grid">
    <div class="grid-row header">
        <mat-checkbox
            data-testid="select-all-contacts-checkbox"
            color="primary"
            (change)="onSelectAllContactsChange($event)"
            [indeterminate]="contactsSelected > 0 && !allContactsSelected"
            [checked]="contactsSelected > 0"
            disableRipple>
            Contacts
            <span *ngIf="contactsSelected > 0">- {{ contactsSelected }} contacts selected ({{ smsContactsSelected }} SMS)</span>
        </mat-checkbox>

        <div>
            <span class="label">Availability</span>

            <mat-button-toggle-group
                data-testid="contacts-toggle"
                appearance="legacy"
                class="gaggle-short-button-toggle"
                (change)="onAvailabilityChange($event)"
                [(value)]="showContactsWithAvailability">
                <mat-button-toggle
                    data-testid="incident-contacts-toggle"
                    value="incident">
                    Incident
                </mat-button-toggle>
                <mat-button-toggle
                    data-testid="current-contacts-toggle"
                    value="current">
                    Current
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
</div>

<mat-dialog-content>
    <ng-container *ngIf="loading; else contactList">
        <div class="loading-indicator">
            <mat-spinner
                diameter="40"
                color="accent"></mat-spinner>
            <span class="text">Loading communications...</span>
        </div>
    </ng-container>

    <ng-template #contactList>
        <ng-container *ngIf="showContactsWithAvailability === 'incident' && noIncidentContacts">
            <div class="no-contacts">
                <div>There are no contacts available to display.</div>
            </div>
        </ng-container>

        <ng-container *ngIf="contacts">
            <div class="contact-grid">
                <ng-container *ngFor="let contact of contacts[showContactsWithAvailability]">
                    <div class="grid-row contact">
                        <mat-checkbox
                            [attr.data-testid]="contact.id + '-contact-checkbox'"
                            color="primary"
                            [checked]="contact.selected"
                            (change)="toggleContactSelection(contact)"
                            disableRipple>
                            {{ contact.name }}
                        </mat-checkbox>

                        <div class="contact-details">
                            <span class="detail-line">
                                <mat-icon>assignment_ind</mat-icon>
                                {{ contact.title || "Unknown Contact" }}
                            </span>

                            <span
                                class="detail-line"
                                *ngIf="contact.email">
                                <mat-icon>alternate_email</mat-icon>
                                {{ contact.email }}
                            </span>

                            <span
                                class="detail-line"
                                *ngIf="contact.unformattedOfficePhone">
                                <mat-icon>work</mat-icon>
                                {{ contact.unformattedOfficePhone | formatPhoneNumber }}
                                <div
                                    class="show-on-hover"
                                    (click)="makeCall($event, contact.officePhone)">
                                    <mat-icon>call</mat-icon>
                                    <span>Make Call</span>
                                </div>
                            </span>

                            <span
                                class="detail-line"
                                *ngIf="contact.unformattedMobilePhone">
                                <mat-icon>smartphone</mat-icon>
                                {{ contact.unformattedMobilePhone | formatPhoneNumber }}
                                <div
                                    class="show-on-hover"
                                    (click)="makeCall($event, contact.mobilePhone)">
                                    <mat-icon>call</mat-icon>
                                    <span>Make Call</span>
                                </div>
                            </span>

                            <span
                                class="detail-line"
                                *ngIf="contact.unformattedHomePhone">
                                <mat-icon>home</mat-icon>
                                {{ contact.unformattedHomePhone | formatPhoneNumber }}
                                <div
                                    class="show-on-hover"
                                    (click)="makeCall($event, contact.homePhone)">
                                    <mat-icon>call</mat-icon>
                                    <span>Make Call</span>
                                </div>
                            </span>
                        </div>

                        <div class="availability ack-cell">
                            <mat-icon
                                matTooltip="Acknowledged"
                                matTooltipShowDelay="500"
                                class="acknowledged"
                                *ngIf="contact.communications.acknowledgment">
                                check
                            </mat-icon>

                            <span
                                class="text"
                                *ngIf="contact.communications.acknowledgment">
                                {{ contact.communications.acknowledgment | titlecase }}
                            </span>
                        </div>

                        <div
                            class="availability"
                            *ngIf="!contact.availability.digest">
                            <mat-icon
                                [ngClass]="{ available: contact.availability.email }"
                                matTooltip="Email"
                                matTooltipShowDelay="500">
                                email
                            </mat-icon>

                            <span
                                class="text"
                                *ngIf="showContactsWithAvailability === 'incident'">
                                {{ contact.communications.email || null | titlecase }}
                            </span>
                        </div>

                        <div
                            class="availability"
                            *ngIf="contact.availability.digest">
                            <mat-icon
                                [ngClass]="{ available: contact.availability.digest }"
                                matTooltip="Digest"
                                matTooltipShowDelay="500">
                                schedule_send
                            </mat-icon>

                            <span
                                class="text"
                                *ngIf="showContactsWithAvailability === 'incident'">
                                {{ contact.communications.email || null | titlecase }}
                            </span>
                        </div>

                        <div class="availability">
                            <mat-icon
                                [ngClass]="{ available: contact.availability.text }"
                                matTooltip="SMS"
                                matTooltipShowDelay="500">
                                sms
                            </mat-icon>

                            <span
                                class="text"
                                *ngIf="showContactsWithAvailability === 'incident'">
                                {{ contact.communications.text | titlecase }}
                            </span>
                        </div>

                        <div class="availability">
                            <mat-icon
                                [ngClass]="{ available: contact.availability.phone }"
                                matTooltip="Call"
                                matTooltipShowDelay="500">
                                phone
                            </mat-icon>

                            <span
                                class="text"
                                *ngIf="showContactsWithAvailability === 'incident'">
                                {{ contact.communications.call | titlecase }}
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <!--    <button-->
    <!--        mat-button-->
    <!--        [disabled]="contactsSelected === 0">-->
    <!--        Resend Email-->
    <!--    </button>-->

    <button
        (click)="sendTextMessage('incident')"
        data-testid="send-incident-sms-button"
        mat-raised-button
        color="primary"
        [disabled]="smsContactsSelected === 0">
        Incident SMS
    </button>

    <button
        (click)="sendTextMessage('test')"
        data-testid="send-test-sms-button"
        mat-raised-button
        color="primary"
        [disabled]="smsContactsSelected === 0">
        Test SMS
    </button>

    <div class="flex-spacer"></div>

    <button
        mat-button
        mat-dialog-close>
        Close
    </button>
</mat-dialog-actions>
