import gql from "graphql-tag";
import { Fragments } from "src/app/graphql/fragments/fragments";

export default gql`
  query nextWorkItem($isL1: Boolean!, $pss: Boolean) {
    nextWorkItem(isL1: $isL1, pss: $pss) {
      ...BaseWorkItem
    }
  }
  ${Fragments.workItem}
`;
