import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HighlightKeywordPipe } from "src/app/shared/pipes/highlightKeyword.pipe";
import { HighlightUrlPipe } from "src/app/shared/pipes/highlightUrl.pipe";
import { SafePipe } from "src/app/shared/pipes/safe.pipe";
import { SafeHtmlPipe } from "src/app/shared/pipes/safeHtml.pipe";
import { SafeImagePipe } from "src/app/shared/pipes/safe-image.pipe";
import { HighlightKeywordsPipe } from "src/app/shared/pipes/highlight-keywords.pipe";
import { ForceBlankTargetPipe } from "src/app/shared/pipes/force-blank-target.pipe";
import { FormatTimerPipe } from "src/app/shared/pipes/format-timer.pipe";
import { FormatPhoneNumberPipe } from "src/app/shared/pipes/format-phone-number.pipe";
import { RemoveZalgoPipe } from "src/app/shared/pipes/remove-zalgo.pipe";
import { ConvertCasePipe } from "@shared/pipes/convert-case.pipe";

const pipes = [
  ForceBlankTargetPipe,
  FormatTimerPipe,
  FormatPhoneNumberPipe,
  HighlightKeywordPipe,
  HighlightKeywordsPipe,
  HighlightUrlPipe,
  RemoveZalgoPipe,
  SafePipe,
  SafeHtmlPipe,
  SafeImagePipe,
];

const standalonePipes = [ConvertCasePipe];

@NgModule({
  imports: [CommonModule, ...standalonePipes],
  declarations: [...pipes],
  exports: [...pipes],
  providers: [...pipes, ...standalonePipes],
})
export class PipesModule {}
