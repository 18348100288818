import gql from "graphql-tag";

export default gql`
  query getFileComments($commentId: String!, $contextCount: Int) {
    getFileComments(commentId: $commentId, contextCount: $contextCount) {
      blockedItem {
        author
        blocked
        content
        id
        timestamp
        type
      }
      comments {
        anchor
        author
        blocked
        content
        id
        timestamp
        replies {
          author
          blocked
          content
          id
          timestamp
          replyNumber
        }
      }
    }
  }
`;
