import gql from "graphql-tag";
import { Fragments } from "src/app/graphql/fragments/fragments";

export default gql`
  query simpleNextWorkItem($queueName: String!) {
    simpleNextWorkItem(queueName: $queueName) {
      ...BaseWorkItem
    }
  }
  ${Fragments.workItem}
`;
