import { ActionReason, AttachmentInfo, WorkItemType } from "./work-item.model";
import { WarningEventType } from "src/generated/graphql";
import { EmergencyContactExtended } from "src/app/shared/services/emergency-contact.service";

// these models are used to build a new incident in the UI
export interface Incident {
  id?: string;
  auditKey?: string;
  bundleDigest?: string;
  bundled?: boolean;
  finalOverride?: boolean;
  event?: EventType;
  payload?: IncidentPayload;
  unbundleType?: UnbundleType;
  workItemId?: string;
  workerId?: string;
  additionalRecipientsInput?: string;
  ccRecipientsInput?: string;
  internalNotes?: string;
  unfilteredContacts?: EmergencyContactExtended[];
  filteredTextContacts?: EmergencyContactExtended[];
  filteredCallContacts?: EmergencyContactExtended[];
  filteredEmailContacts?: EmergencyContactExtended[];
  filteredAfterHoursTextContacts?: EmergencyContactExtended[];
  filteredAfterHoursCallContacts?: EmergencyContactExtended[];
  filteredAfterHoursEmailContacts?: EmergencyContactExtended[];
}

export interface IncidentPayload {
  actionReasons?: ActionReason[];
  attachments?: AttachmentInfo[];
  attributes?: string;
  baseUrl?: string;
  ccRecipients?: EmailRecipient[];
  customerId?: string;
  customerName?: string;
  districtId?: string;
  districtName?: string;
  domainId?: string;
  emailBody?: string;
  emailFrom?: {
    email?: string;
    name?: string;
  };
  emailSubject?: string;
  emergency?: boolean;
  entityId?: string;
  entityOwnerId?: string;
  entityType?: WorkItemType;
  fileOwnerEmail?: string;
  groupId?: string;
  groupName?: string;
  internalNotes?: string;
  itemID?: string;
  mobileRecipients?: {
    deviceNumber?: string;
    email?: string;
    name?: string;
  };
  notes?: string;
  recipients?: EmailRecipient[];
  digestRecipients?: EmailRecipient[];
  remoteEntityId?: string;
  safetyRepName?: string;
  userEmail?: string;
  userId?: string;
  warningType?: WarningEventType;
  workerId?: string;
  timeZone?: string;
  gaft?: boolean;
}

export interface EmailRecipient {
  email?: string;
  name?: string;
}

export enum EventType {
  ESCALATE_PSS = "escalatepss",
  ESCALATE = "escalate",
  RESOLVE = "resolved",
  RESOLVE_PRIOR_INCIDENT = "resolved_incident_exists",
  UNBLOCK = "unblock",
  DELETE = "delete",
  DELETE_PRIOR_INCIDENT = "delete_incident_exists",
  REQUEST_REVIEW = "requestreview",
  QUARANTINE_ONLY = "quarantine_only",
  QUARANTINE = "quarantine",
  WARN = "warn",
}

export const WARNING_TYPE_MAP = {
  [WarningEventType.FirstWarning]: "FIRST WARNING",
  [WarningEventType.SecondWarning]: "SECOND WARNING",
  [WarningEventType.ThirdWarning]: "MULTIPLE WARNINGS",
  [WarningEventType.QuestionableContent]: "QUESTIONABLE CONTENT",
  [WarningEventType.PossibleStudentSituation]: "POSSIBLE STUDENT SITUATION",
  [WarningEventType.Violation]: "VIOLATION",
};

export const WARNING_DISPLAY_MAP = {
  [WarningEventType.FirstWarning]: "VIOL",
  [WarningEventType.SecondWarning]: "VIOL",
  [WarningEventType.ThirdWarning]: "VIOL",
  [WarningEventType.QuestionableContent]: "QCON",
  [WarningEventType.PossibleStudentSituation]: "PSS",
  [WarningEventType.Violation]: "VIOL",
};

export const ACTION_REASON_MAP = {
  [ActionReason.PROFANITY]: "Profanity",
  [ActionReason.NUDITY]: "Nudity",
  [ActionReason.GENERAL]: "IC",
  [ActionReason.RESTRICTED_USE]: "UC",
  [ActionReason.DRUG_USE]: "Drugs",
  [ActionReason.HARASSMENT]: "Harassment",
  [ActionReason.VIOLENCE_OTHERS]: "Violence (others)",
  [ActionReason.VIOLENCE_SELF]: "Suicide / Self Harm",
  [ActionReason.SELF_HARM]: "Self-Harm",
  [ActionReason.SUICIDE]: "Suicide",
  [ActionReason.SEXUAL_CONTENT]: "Sexual content",
};

export const ACTION_REASON_SUBJECT_MAP = {
  [ActionReason.PROFANITY]: "PROFANITY",
  [ActionReason.NUDITY]: "NUDITY",
  [ActionReason.GENERAL]: "INAPPROPRIATE USE",
  [ActionReason.RESTRICTED_USE]: "RESTRICTED USAGE",
  [ActionReason.DRUG_USE]: "DRUG USE",
  [ActionReason.HARASSMENT]: "HARASSMENT",
  [ActionReason.VIOLENCE_OTHERS]: "VIOLENCE (OTHERS)",
  [ActionReason.VIOLENCE_SELF]: "VIOLENCE (SELF)",
  [ActionReason.SELF_HARM]: "SELF HARM",
  [ActionReason.SUICIDE]: "SUICIDE",
  [ActionReason.SEXUAL_CONTENT]: "SEXUAL CONTENT",
};

export enum UnbundleType {
  SELF = "SELF",
  ALL = "ALL",
}

export interface EmergencyContactSubscription {
  categories?: ActionReason[];
  workItemTypes?: WorkItemType[];
  level?: number;
}

export interface EmergencyContactSubscriptions {
  pss?: EmergencyContactSubscription;
  qcon?: EmergencyContactSubscription;
  viol?: EmergencyContactSubscription;
}

export interface SMSRecipient {
  id: string;
  name: string;
  mobilePhone: {
    countryCode: string;
    areaCode: string;
    number: string;
  };
}

export interface DelayWindow {
  windowType: WarningEventType;
  afterHours: boolean;
  startTime: string;
  endTime: string;
}

export interface EmailTemplate {
  category: string;
  warningType: string;
  workItemType: string;
  forMultipleItemsOnly: boolean;
  forQuarantineItemsOnly: boolean;
  featureFlagCondition: string;
  dropdownOption: string;
  subject: string;
  body: string;
  originalSubject?: string;
  originalBody?: string;
  inappropriateContentReasons?: string;
}

export interface SMSTemplate {
  id: string;
  dropdownOption: string;
  afterHours: boolean;
  workItemTypes: WorkItemType[];
  categories: [];
  message: string;
}

export interface SMSSchedule {
  targetDate: string;
  targetTime: string;
  timeZone: string;
}

export interface PriorIncident {
  workItemId: string;
  processedDate: number;
  warningType: WarningEventType;
  actionReasons: ActionReason[];
  isNewDate: boolean;
}

export interface PriorIncidents {
  total: number;
  incidents: PriorIncident[];
}

export interface PhoneNumber {
  countryCode?: string;
  areaCode?: string;
  number?: string;
  ext?: string;
}

type TemplateGroup = {
  [warningType in WarningEventType]?: EmailTemplate[];
};

export type EmailTemplates = {
  [entityType in WorkItemType]?: TemplateGroup;
};
