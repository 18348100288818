import { ActionReason, AttachmentInfo, WorkItem, WorkItemEventRecord, WorkItemType } from "./work-item.model";
import { WarningEventType } from "src/generated/graphql";

// these models are used when fetching a completed incident by the work item ID
export interface WorkItemIncident {
  workItem: WorkItem;
  incident: CompletedIncident;
}

export interface CompletedIncident {
  incidentId: string;
  acknowledgmentDetails: AcknowledgmentDetail;
  message: string;
  customerId: string;
  customerName: string;
  userId: string;
  userName: string;
  userFullName: string;
  workerId: string;
  safetyRepName: string;
  workItemId: string;
  entityId: string;
  gaft: boolean;
  groupId: string;
  groupName: string;
  districtId: string;
  districtName: string;
  warningType: WarningEventType;
  entityType: WorkItemType;
  notes: string;
  comments: IncidentCommentRecord[];
  internalNotes: string;
  processedDate: number;
  occurrenceDate: string;
  actionReasons: ActionReason[];
  attachments: AttachmentInfo[];
  contentLinkTitles: string;
  contentLinkBaseUrl: string;
  blockedReasons: string;
  keywords: string;
  events: WorkItemEventRecord[];
  deletedByUserid: string;
  deletedDate: string;
  deletedIpaddress: string;
  resolvedByUserid: string;
  resolvedDate: string;
  resolvedIpaddress: string;
  resolutionType: ResolutionType;
  workItemStatus: WorkItemStatus;
  emergencyContactName: string;
  emergencyContactId: string;
  incidentEmail: IncidentEmail;
}

interface AcknowledgmentDetail {
  date: string;
  fullName: string;
  userId: string;
  source?: string;
}

interface IncidentEmail {
  from: string;
  to: string[];
  cc: string[];
  subject: string;
  bodyHtml: string;
  bodyText: string;
}

interface IncidentCommentRecord {
  commentBody: string;
  commentDate: string;
  userUuid: string;
}

enum ResolutionType {
  NON_ISSUE = "NON_ISSUE",
  THREAT_ADDRESSED = "THREAD_ADDRESSED",
}

export enum WorkItemStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}

export const WorkItemEventMap = {
  occurred: "Occurred",
  created: "Created On",
  preprocess: "Preprocess",
  l1_queue: "L1 Queue",
  l2_queue: "L2 Queue",
  requestreview: "Request Review",
  escalate: "Escalate",
  warn: "Warn",
  quarantine: "Quarantine",
  quarantine_only: "Quarantine",
  complete: "Complete",
  resolved: "Resolve",
  delete: "Delete",
  unblock: "Unblock",
  escalatepss: "Escalate PSS",
  reopened: "Reopened",
  createdIncident: "Incident Created",
  l2_recheck: "L2 Recheck",
  resolved_incident_exists: "Resolve (Additional Factor)",
  delete_incident_exists: "Delete (Additional Factor)",
};

export interface InternalWorkItemStatus {
  uuid: string;
  state: string;
  locked: boolean;
}
