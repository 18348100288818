import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { StateService } from "src/app/shared/services/state.service";
import moment from "moment-timezone";
import {
  AttachmentInfo,
  DownloadInfo,
  MessageMetadata,
  workItemSourceMap,
  workItemToDisplayTypeMap,
  WorkItemType,
  WorkItemSource,
  extensionItemSourceMap,
  ItemData,
} from "src/app/models/work-item.model";
import { Incident, PriorIncident, PriorIncidents, WARNING_DISPLAY_MAP } from "src/app/models/incident.model";
import { formattedDate, formattedDateObject, formatTimer } from "src/app/shared/utils/format";
import { formatActionReasonsFromPriorIncidents, objectToUrlEncodedString } from "src/app/shared/utils/helpers";
import { FeatureFlagName, FeatureFlagValue } from "src/app/models/feature-flags.model";
import { ThemeService } from "src/app/shared/services/theme.service";
import { WorkItemEventMap } from "src/app/models/work-item-incident.model";
import { ActivatedRoute } from "@angular/router";
import { SearchFields } from "@features/search/models/search-fields.enum";
import { IncidentSearchParametersInputV2, IncidentSearchSortField, SortOrder } from "src/generated/graphql";
import { SearchType } from "@shared/state/search/search.state";

interface FormattedIncident extends Incident {
  isNewDate: boolean;
  date: string;
  year: string;
  time: string;
  displayWarningType: string;
  formattedReasons: string;
}

interface WorkItemEventDisplay {
  label: string;
  workerId?: string;
  workerName?: string;
  date: {
    dayAndMonth?: string;
    year?: string;
    time?: string;
    originalDate?: moment.Moment;
    message?: string;
  };
}

@Component({
  selector: "app-item-details",
  templateUrl: "./item-details.component.html",
  styleUrls: ["./item-details.component.scss"],
})
export class ItemDetailsComponent implements OnInit, OnChanges {
  @Input() showMoreFileInfo = false;
  @Input() showMoreAttachments = false;
  @Input() showMoreIncidentInfo = false;
  @Input() showMoreSchoolInfo = false;
  @Input() showEventInfo?: boolean = false;
  @Input() title?: string;
  @Input() showWorkItemSource?: boolean = false;
  @Input() hideCurrentIncident?: boolean = false;
  @Output() toggleSection = new EventEmitter();

  events: WorkItemEventDisplay[] = [];
  incidentWasCreated;
  itemDetails;
  fileDownloadLink;
  fileDownloadInfo: DownloadInfo;
  incidentURL = "https://";
  userAccountURL = "https://";
  messageMetadata: MessageMetadata;
  priorIncidents: PriorIncidents;
  attachments: AttachmentInfo[];
  lastDateDisplayed: moment.Moment;
  isWebFilterItem;
  itemEntityType: string;
  workItemSource: string;

  priorIncidentsToDisplay: FormattedIncident[];
  priorIncidentsCount: number;
  attachmentsToDisplay: AttachmentInfo[];

  extensionItemSource = "";

  constructor(private state: StateService, private theme: ThemeService, private activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.state.currentItemDetails$.subscribe((data) => {
      this.itemDetails = data;
      if (data) {
        this.attachments = [];
        this.incidentURL = this.generateIncidentSearchURL();
        this.userAccountURL = this.generateUserLookupURL();
        this.isWebFilterItem = data.workItemEntityType === WorkItemType.WEBFILTER;
        this.itemEntityType = workItemToDisplayTypeMap[data.workItemEntityType];
        this.workItemSource = workItemSourceMap[this.itemDetails.workItemSource] || "N/A";

        if (this.showEventInfo && this.itemDetails.events) {
          this.events = this.createEvents();
        }

        this.activeRoute.data.subscribe((data) => {
          if (data) {
            this.incidentWasCreated = !!data.createdIncident;
          } else {
            this.incidentWasCreated = false;
          }
        });

        this.setExtensionItemSource();
      }
    });

    this.state.downloadLink$.subscribe((data) => {
      this.fileDownloadLink = data?.url;
      this.fileDownloadInfo = data;
    });

    this.state.messageMetadata$.subscribe((data) => {
      this.messageMetadata = data;
      if (this.messageMetadata) {
        if (this.messageMetadata.attachments) {
          this.attachments = this.messageMetadata.attachments.filter((a) => a.qualifier !== "-1");
          this.getDisplayableAttachments();
        }
      }
    });

    this.state.externalMetadata$.subscribe((data) => {
      if (data) {
        if (data.content[0].fileContent) {
          this.attachments = data.content[0].fileContent;
          this.getDisplayableAttachments();
        }
      }
    });

    this.state.priorIncidents$.subscribe((data) => {
      this.priorIncidents = data;
      this.getDisplayablePriorIncidents();
    });
  }

  ngOnChanges(): void {
    this.getDisplayablePriorIncidents();
    this.getDisplayableAttachments();
  }

  get pillTextColor(): string {
    return this.theme.getVariable("--pill-highlight-text-color");
  }

  get pillBackgroundColor(): string {
    return this.theme.getVariable(`--${this.itemDetails?.workItemEntityType?.toLowerCase()}-highlight`);
  }

  get userId(): string {
    return this.itemDetails?.userIdShort ? this.itemDetails.userIdShort : this.itemDetails?.userId ? this.itemDetails.userId : null;
  }

  get formattedTimer(): string {
    if (this.state.workItemTimer) {
      return formatTimer(this.state.workItemTimer);
    } else {
      return null;
    }
  }

  get hasDownloadLink(): boolean {
    return !!this.fileDownloadInfo && this.fileDownloadInfo.file_size > 0;
  }

  get downloadLink(): { fileName: string; link: string } | null {
    return this.hasDownloadLink
      ? {
          fileName: this.itemDetails.entityName,
          link: this.fileDownloadInfo.url,
        }
      : null;
  }

  get hasMessageFields(): boolean {
    return !!this.messageMetadata;
  }

  get hasAttachments(): boolean {
    return this.attachments?.length > 0;
  }

  get attachmentCount(): number {
    return this.hasAttachments ? this.attachments.length : 0;
  }

  get hasMultipleAttachments(): boolean {
    return this.attachmentCount > 1;
  }

  attachmentLabel(indexOfAttachment: number): string {
    if (indexOfAttachment === 0) {
      return `Attachment${this.attachmentCount === 1 ? "" : "s"} (${this.attachmentCount})`;
    } else {
      return " ";
    }
  }

  isAttachmentDownloadable(attachment: AttachmentInfo): boolean {
    return !!attachment.downloadUrl && attachment.downloadUrl.length > 0;
  }

  getAttachmentDownload(attachment: AttachmentInfo): { fileName: string; link: string } | null {
    if (
      this.state.getFeatureFlag(FeatureFlagName.CANVAS_DOWNLOADS) !== FeatureFlagValue.ON &&
      this.itemDetails.workItemEntityType === WorkItemType.CANVAS
    ) {
      return null;
    }

    return this.isAttachmentDownloadable(attachment)
      ? {
          fileName: attachment.name,
          link: attachment.downloadUrl,
        }
      : null;
  }

  formatEventDate(date: moment.Moment): string {
    return date ? date.format("MMM D,") : null;
  }

  private createEvents(): any[] {
    const occurred = {
      label: WorkItemEventMap.occurred,
      workerId: null,
      workerName: null,
      date: formattedDateObject(this.itemDetails.occurrenceDate),
    };
    const created = {
      label: WorkItemEventMap.created,
      workerId: null,
      workerName: null,
      date: formattedDateObject(this.itemDetails.created),
    };

    const processingEvents = this.itemDetails.events.map((event) => ({
      label: WorkItemEventMap[event.event],
      workerId: event.workerId,
      workerName: event.workerName,
      date: formattedDateObject(event.date),
    }));

    // Check to see if there are events AFTER the complete event, if so, we can assume this is a reopened item.
    // and we'll use the information from the next event to create a reopened event.
    const completeEventIndex = processingEvents.findIndex((event) => event.label === WorkItemEventMap.complete);
    if (completeEventIndex !== -1 && completeEventIndex < processingEvents.length - 1) {
      const nextEvent = processingEvents[completeEventIndex + 1];
      const reopenEvent = {
        label: WorkItemEventMap.reopened,
        workerId: nextEvent.workerId,
        workerName: nextEvent.workerName,
        date: nextEvent.date,
      };
      processingEvents.splice(completeEventIndex + 1, 0, reopenEvent);
    }

    if (!this.incidentWasCreated) {
      return [occurred, created, ...processingEvents];
    } else {
      return [
        occurred,
        created,
        ...processingEvents,
        {
          label: WorkItemEventMap.createdIncident,
          workerId: null,
          workerName: null,
          date: {
            message: "Just now",
          },
        },
      ];
    }
  }

  private getDisplayableAttachments(): void {
    this.attachmentsToDisplay = this.showMoreAttachments ? this.attachments : this.attachments?.slice(0, 1);
  }

  private setExtensionItemSource(): void {
    this.extensionItemSource = "";
    const parsedItemData: ItemData = this.itemDetails.itemData ? JSON.parse(this.itemDetails.itemData) : null;
    if (parsedItemData && this.itemDetails.workItemSource === WorkItemSource.GOOGLE_CHROME_EXTENSION) {
      this.extensionItemSource = extensionItemSourceMap[parsedItemData.chromeExtensionData?.inputType];
    }
  }

  get itemIsFileMessageOrHangout(): boolean {
    return [WorkItemType.FILE, WorkItemType.MESSAGE, WorkItemType.HANGOUT].includes(this.itemDetails?.workItemEntityType);
  }

  get hasMoreSchoolInfo(): boolean {
    return !!(this.itemDetails?.studentTimezone || this.itemDetails?.workerName);
  }

  get hasMoreFileInfo(): boolean {
    return !!(this.itemDetails?.folder || this.itemDetails?.entityId || this.itemIsFileMessageOrHangout);
  }

  get hasPriorIncidents(): boolean {
    return this.priorIncidents?.incidents?.length > 0;
  }

  get hasMoreThanTwoPriorIncidents(): boolean {
    return this.hasPriorIncidents && this.priorIncidents.total > 2;
  }

  get flaggedUserHeader(): string {
    return this.hasMessageFields
      ? this.messageMetadata?.sender === this.itemDetails.userName
        ? "Flagged Sender"
        : "Flagged Recipient"
      : null;
  }

  formattedDate(timestamp: number): string {
    if (!timestamp) {
      return null;
    }
    return formattedDate(timestamp);
  }

  private incidentDate(incident: PriorIncident): moment.Moment {
    return moment.unix(incident.processedDate / 1000).tz(moment.tz.guess());
  }

  private isNewIncidentDate(incident: PriorIncident): boolean {
    const date = this.incidentDate(incident);
    const isNewDate = this.lastDateDisplayed ? !date.isSame(this.lastDateDisplayed, "day") : true;
    this.lastDateDisplayed = date;
    return isNewDate;
  }

  private getDisplayablePriorIncidents(): void {
    this.priorIncidentsCount = this.priorIncidents?.total ?? 0;
    if (!this.priorIncidents?.incidents?.length) {
      return;
    }
    const size = this.showMoreIncidentInfo ? 10 : 2;
    const maxToReturn = size <= this.priorIncidents.incidents.length ? size : this.priorIncidents.incidents.length;
    this.lastDateDisplayed = null;
    this.priorIncidentsToDisplay = this.priorIncidents.incidents

      /**
       * This will filter out any prior incidents that this work item is part of
       * so that the reps don't see the current incident in the list of prior incidents
       * when working an item that has been reinserted into the queue.
       */
      .filter((incident) => {
        if (this.hideCurrentIncident) {
          if (this.itemDetails.id === incident.workItemId) {
            this.priorIncidentsCount--;
          }
          return this.itemDetails.id !== incident.workItemId;
        } else {
          return true;
        }
      })
      .map((incident) => {
        const incidentDate = this.incidentDate(incident);
        const date = incidentDate.format("MMM D");
        const year = incidentDate.format(", y");
        const time = incidentDate.format("h:mmA");

        const displayWarningType = WARNING_DISPLAY_MAP[incident.warningType];
        const formattedReasons = formatActionReasonsFromPriorIncidents(incident);
        return {
          ...incident,
          date,
          year,
          time,
          displayWarningType,
          formattedReasons,
          isNewDate: this.isNewIncidentDate(incident),
        };
      })
      .slice(0, maxToReturn);
  }

  onClickShowFileInfo(): void {
    this.toggleSection.emit("showMoreFileInfo");
  }

  onClickShowAttachments(): void {
    this.toggleSection.emit("showMoreAttachments");
  }

  onClickShowIncidentInfo(): void {
    this.toggleSection.emit("showMoreIncidentInfo");
  }

  onClickShowSchoolInfo(): void {
    this.toggleSection.emit("showMoreSchoolInfo");
  }

  private generateIncidentSearchURL(): string {
    const queryParams: IncidentSearchParametersInputV2 = {
      [SearchFields.UserIdShort]: this.itemDetails?.userIdShort,
      pageNumber: 1,
      pageSize: 15,
      sort: {
        fieldName: IncidentSearchSortField.ProcessedDate,
        order: SortOrder.Descending,
      },
    };

    const criteria = objectToUrlEncodedString(queryParams, true);

    return `${window.location.origin}/search?searchType=${SearchType.Incident}&criteria=${criteria}`;
  }

  private generateUserLookupURL(): string {
    return this.itemDetails?.userIdShort
      ? `${this.state.gewiBaseUrl}/do/main#Main/Admin/Account:${this.itemDetails.userIdShort}`
      : this.state.gewiBaseUrl;
  }
}
