import { Component, Input, OnInit } from "@angular/core";
import { AttachmentInfo, MessageMetadata } from "src/app/models/work-item.model";
import { StateService } from "src/app/shared/services/state.service";
import moment from "moment-timezone";
import { ThemeService } from "src/app/shared/services/theme.service";
import { HighlightKeywordPipe } from "src/app/shared/pipes/highlightKeyword.pipe";
import { AppSyncService } from "src/app/shared/services/appsync.service";

const CHAT_MESSAGE_PADDING = 4; // how many chats before or after the flagged message will show
const DEFAULT_NUMBER_OF_CHATS_TO_DISPLAY = 5; // how many chats should display when page loads
const SHOW_ADDITIONAL_CHATS = 5; // how many additional chats should show when you press the view more buttons

@Component({
  selector: "app-message-preview-panel",
  templateUrl: "./message-preview-panel.component.html",
  styleUrls: ["./message-preview-panel.component.scss"],
  providers: [HighlightKeywordPipe],
})
export class MessagePreviewPanelComponent implements OnInit {
  @Input() keywords: string[];

  workItem;
  showChatContext: boolean;
  parsedChatContext = null;
  allParsedChatMessages = [];
  startingChatIndex = 0;
  endingChatIndex = null;
  chatParticipants = [];
  lastDisplayedDate = null;
  selectedChatParticipantEmail = null;
  showChatParticipants = false;

  htmlContent: string;
  private tempContent: string;
  loadingMessages = true;
  messageMetadata: MessageMetadata;
  messageAttachments = [];
  messageNotFound = false;
  contentScale: number;

  constructor(
    private state: StateService,
    private theme: ThemeService,
    private highlightKeyword: HighlightKeywordPipe,
    private appsync: AppSyncService,
  ) {}

  ngOnInit(): void {
    this.state.currentItemDetails$.subscribe((data) => {
      this.workItem = data;
    });
    this.state.messageMetadata$.subscribe((data) => {
      this.messageMetadata = data;
      this.processMessageMetadata();
    });

    this.state.chatContext$.subscribe((data) => {
      if (data) {
        this.mapChatContextToDisplayModel(data);
        this.showChatContext = true;
      } else {
        this.parsedChatContext = null;
        this.showChatContext = false;
      }
    });

    this.state.loadingMessageMetadata$.subscribe((data) => {
      this.loadingMessages = data;
      this.processMessageMetadata();
    });
    this.contentScale = 1.0;
  }

  get participantLabel(): string {
    return this.showChatParticipants ? "Hide Participants" : "Show All Participants";
  }

  get participantIcon(): string {
    return this.showChatParticipants ? "minus" : "plus";
  }

  get participantToggleBackground(): string {
    return this.theme.getVariable("--chat-background");
  }

  get participantToggleTextColor(): string {
    return this.theme.getVariable("--text-primary-color");
  }

  processMessageMetadata(): void {
    this.htmlContent = null;
    this.messageAttachments = [];
    if (this.messageMetadata) {
      if (this.messageMetadata.content) {
        this.messageNotFound = false;
        this.tempContent = this.highlightKeywords(this.messageMetadata.content[0]);
      }

      if (this.messageMetadata.attachments?.length > 0) {
        this.messageMetadata.attachments.filter((a) => !!a.contentId).forEach((a) => this.replaceAttachmentContentId(a));
        if (!this.itemHasBlockReasonCacheItem() && !this.itemHasBlockReasonVideoFrame()) {
          this.messageAttachments = this.messageMetadata.attachments.filter(
            (attachment) => attachment.downloadUrl && this.itemIsDisplayable(attachment.name),
          );
        }
      }
      this.removeSections();
      this.htmlContent = this.tempContent;
    } else if (this.messageMetadata === null && !this.loadingMessages) {
      this.messageNotFound = true;
    }
  }

  onClickZoomIn(): void {
    if (this.contentScale < 2.0) {
      const contentContainer = document.getElementById("message-content");
      if (contentContainer) {
        this.contentScale += 0.1;
        contentContainer.style.transform = `scale(${this.contentScale})`;
      }
    }
  }

  onClickZoomOut(): void {
    if (this.contentScale > 0.2) {
      const contentContainer = document.getElementById("message-content");
      if (contentContainer) {
        this.contentScale -= 0.1;
        contentContainer.style.transform = `scale(${this.contentScale})`;
      }
    }
  }

  onClickTranslate(): void {
    this.appsync.translateContent(this.messageMetadata.content, this.workItem.id);
  }

  openEmailInNewTab(): void {
    const win = window.open("", "Original Work Item Message", "scrollbars=yes,resizable=yes");
    win.document.body.innerHTML = this.htmlContent;
    win.document.title = "Original Work Item Message";
  }

  highlightChatsFrom(participantEmail: string): void {
    if (this.selectedChatParticipantEmail === participantEmail) {
      this.selectedChatParticipantEmail = null;
    } else {
      this.selectedChatParticipantEmail = participantEmail;
    }
  }

  toggleChatParticipants(): void {
    this.showChatParticipants = !this.showChatParticipants;
  }

  private itemIsDisplayable(name: string): boolean {
    const displayableExtensions = ["pdf", "jpg", "jpeg", "jpe", "jif", "jfif", "png", "apng", "bmp", "ico", "gif", "avif", "webp"];

    return displayableExtensions.some((extension) => name.toLowerCase().endsWith(`.${extension}`));
  }

  private itemHasBlockReasonCacheItem(): boolean {
    return this.workItem?.blockReasons?.some((br) => br?.cacheItem?.url);
  }

  private itemHasBlockReasonVideoFrame(): boolean {
    return this.workItem?.blockReasons?.some((br) => br?.videoItem?.frames?.some((fr) => fr?.url));
  }

  private highlightKeywords(text: string): string {
    if (!text?.length) {
      return "";
    }

    return this.keywords.reduce((accum, keyword) => this.highlightKeyword.transform(accum, keyword), text);
  }

  private replaceAttachmentContentId(attachment: AttachmentInfo): void {
    const regex = RegExp("cid[:]" + attachment.contentId, "gi");
    this.tempContent = this.tempContent.replace(regex, attachment.downloadUrl);
  }

  private removeSections(): void {
    const regex = RegExp("[<]section .*[>].*[<][/]section[>]", "gi");
    this.tempContent = this.tempContent.replace(regex, "");
  }

  private mapChatContextToDisplayModel(context: string): void {
    // Need to handle the case where the context value is escaped stringified JSON.
    // If it is, we just need to call JSON.parse() twice to get it converted successfully.
    let parsed;
    parsed = JSON.parse(context);
    if (typeof parsed === "string") {
      parsed = JSON.parse(parsed);
    }

    let relevantMessages = [...parsed.chatMessages];

    this.parsedChatContext = {
      source: parsed.metadata.sourceType,
      chatRoomName: parsed.chatRoom.name?.original || "N/A",
    };

    if (parsed.metadata.sourceType === "GOOGLE_CHAT") {
      // google chats have an "action" property that we can filter out noisy messages
      relevantMessages = parsed.chatMessages.filter(
        (message) => !this.irrelevantMessageActions.some((action) => message.action.includes(action)),
      );
    }

    const colorMap = this.getColorMap();

    const uniqueChatParticipants = Array.from(new Set(relevantMessages.map((message) => message.user?.email))).filter((v) => v);

    const participantColors = uniqueChatParticipants.map((participant, index) => {
      let colorMapIndex = index;
      if (index >= colorMap.length) {
        colorMapIndex = index % colorMap.length;
      }

      return {
        email: participant,
        color: colorMap[colorMapIndex],
      };
    });

    this.chatParticipants = parsed.chatParticipants.map((party) => {
      const matchingColor = participantColors.find((color) => color.email === party.email);
      if (matchingColor) {
        return {
          ...matchingColor,
          name: party.name,
        };
      } else {
        return {
          email: party.email,
          name: party.name,
          color: "transparent",
        };
      }
    });

    this.allParsedChatMessages = relevantMessages.map((message) => {
      const userEmail = message.user?.email;
      const participant = participantColors.find((p) => p.email === userEmail);
      const messageTimestamp = moment(message.timestamp);
      const date = messageTimestamp.format("MMM D, y");
      const time = messageTimestamp.format("h:mmA");

      const messageContent = message.message.content || "--";

      return {
        flagged: message.flagged,
        timestamp: time,
        date,
        isNewMessageDate: this.isNewMessageDate(messageTimestamp),
        sender: message.user?.name || "User Not Specified",
        senderEmail: message.user?.email || "Missing Email",
        content: message.flagged ? this.highlightKeywords(messageContent) : messageContent,
        colorCode: participant?.color || "transparent",
      };
    });

    if (this.allParsedChatMessages.length > DEFAULT_NUMBER_OF_CHATS_TO_DISPLAY) {
      const flaggedMessage = this.allParsedChatMessages.find((message) => message.flagged);
      const indexOfFlaggedChat = this.allParsedChatMessages.indexOf(flaggedMessage);

      this.startingChatIndex = indexOfFlaggedChat > CHAT_MESSAGE_PADDING ? indexOfFlaggedChat - CHAT_MESSAGE_PADDING : 0;
      if (indexOfFlaggedChat >= this.allParsedChatMessages.length - 1) {
        this.endingChatIndex = null;
      } else {
        this.endingChatIndex =
          indexOfFlaggedChat + CHAT_MESSAGE_PADDING + 1 >= this.allParsedChatMessages.length - 1
            ? null
            : indexOfFlaggedChat + CHAT_MESSAGE_PADDING + 1;
      }
    }
  }

  showEarlierChats(): void {
    this.startingChatIndex = this.startingChatIndex > SHOW_ADDITIONAL_CHATS ? this.startingChatIndex - SHOW_ADDITIONAL_CHATS : 0;
  }

  showLaterChats(): void {
    this.endingChatIndex =
      this.endingChatIndex + SHOW_ADDITIONAL_CHATS > this.allParsedChatMessages.length - 1
        ? null
        : this.endingChatIndex + SHOW_ADDITIONAL_CHATS;
  }

  get displayedChatMessages(): any[] {
    return this.endingChatIndex
      ? this.allParsedChatMessages.slice(this.startingChatIndex, this.endingChatIndex)
      : this.allParsedChatMessages.slice(this.startingChatIndex);
  }

  get shouldDisplayShowLaterChats(): boolean {
    return !!this.endingChatIndex;
  }

  get shouldDisplayShowEarlierChats(): boolean {
    return this.startingChatIndex > 0;
  }

  private getColorMap(): any[] {
    return this.baseColorMap
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  }

  private isNewMessageDate(timestamp: moment.Moment): boolean {
    const isNewDate = this.lastDisplayedDate ? !timestamp.isSame(this.lastDisplayedDate, "day") : true;
    this.lastDisplayedDate = timestamp;
    return isNewDate;
  }

  baseColorMap = [
    "#e0908c",
    "#ab90c2",
    "#90c7eb",
    "#7fb580",
    "#efe843",
    "#e2aa31",
    "#da6631",
    "#d23327",
    "#874196",
    "#45459b",
    "#345fab",
    "#4b8686",
    "#49823d",
    "#a0ba3a",
    "#655721",
    "#662e2d",
  ];

  irrelevantMessageActions = ["MEMBERSHIP STATE BECAME"];
}
