<div
    *ngIf="expand"
    class="ncmec-container">
    <ng-container *ngIf="allowAutomatedReport; else manualSafetyAuditMessage">
        <p class="instructions">
            Review and edit this information for accuracy. A report will be submitted to NCMEC at the appropriate time.
        </p>
        <div class="section-container">
            <p>School</p>
            <app-input
                name="school-name"
                label="School:"
                [(value)]="schoolOverrides.name"></app-input>
        </div>
        <div class="section-container">
            <p>District</p>
            <app-input
                name="district-name"
                label="District:"
                [(value)]="districtOverrides.name"></app-input>
            <app-input
                name="district-address"
                label="Address:"
                [(value)]="districtOverrides.address"></app-input>
            <app-input
                name="district-city"
                label="City:"
                [(value)]="districtOverrides.city"></app-input>
            <app-input
                name="district-state"
                label="State:"
                [(value)]="districtOverrides.state"></app-input>
            <app-input
                name="district-phone"
                label="Phone:"
                [(value)]="districtOverrides.phone"></app-input>
        </div>
        <div class="section-container">
            <p>Student Information</p>
            <app-input
                name="student-first-name"
                label="First Name:"
                [(value)]="studentOverrides.firstName"></app-input>
            <app-input
                name="student-last-name"
                label="Last Name:"
                [(value)]="studentOverrides.lastName"></app-input>
            <app-input
                name="student-email"
                label="Email:"
                [(value)]="studentOverrides.email"></app-input>
        </div>
        <div
            class="section-container"
            *ngIf="shouldDisplayAttachFiles">
            <p>Attachments</p>
            <div class="attachment-container">
                <p class="instructions">Select attachment(s) to be sent with the report.</p>
                <div
                    *ngFor="let attachment of attachableFiles"
                    class="attachment">
                    <app-checkbox
                        [label]="attachment.name"
                        (toggleCheckbox)="onClickAttachFile(attachment)"
                        [checked]="attachment.include"></app-checkbox>
                </div>
            </div>
        </div>
        <gaggle-button
            label="Send Report"
            (buttonClick)="sendReport()"
            [backgroundColor]="buttonBackgroundColor"
            [textColor]="buttonTextColor"
            buttonStyle="full-width"
            [disabled]="submitIsDisabled"></gaggle-button>
        <div
            *ngIf="disableSend"
            class="disabled-send">
            Sending NCMEC reports has been disabled for this view.
        </div>
    </ng-container>
    <ng-template #manualSafetyAuditMessage>
        <p class="manual-report">This work item is a safety audit, and requires this NCMEC report to be done manually.</p>
    </ng-template>
</div>
