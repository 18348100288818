import { Pipe, PipeTransform } from "@angular/core";
import { PhoneNumber } from "src/app/models/incident.model";
import { removeCharsFromPhoneNumber } from "src/app/shared/utils/format";

@Pipe({
  name: "formatPhoneNumber",
})
export class FormatPhoneNumberPipe implements PipeTransform {
  transform(phoneNumber: PhoneNumber): unknown {
    let formattedNumber = "";

    if (phoneNumber?.countryCode) {
      formattedNumber = `(${phoneNumber.areaCode}) ${phoneNumber.number.slice(0, 3)}-${phoneNumber.number.slice(3)}`;
    } else if (phoneNumber?.number) {
      formattedNumber = this.manuallyParsePhoneNumberForDisplay(phoneNumber.number);
    }

    return formattedNumber;
  }

  manuallyParsePhoneNumberForDisplay(phoneNumber: string): string {
    const EXTENSION_REGEX = new RegExp(/ x\d+$/);
    let formattedNumber = phoneNumber;

    //remove extension
    formattedNumber = formattedNumber.replace(EXTENSION_REGEX, "");

    // TODO: update when AUS is introduced to remove country code (+61)
    // remove country code if +1 or 1
    formattedNumber = formattedNumber.replace("+1", "");
    if (formattedNumber.startsWith("1")) {
      formattedNumber = formattedNumber.replace("1", "");
    }
    // remove any extra characters
    formattedNumber = removeCharsFromPhoneNumber(formattedNumber);
    // format to (###) ###-####
    return `(${formattedNumber.slice(0, 3)}) ${formattedNumber.slice(3, 6)}-${formattedNumber.slice(6)}`;
  }
}
