import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Incident } from "src/app/models/incident.model";

@Component({
  selector: "app-submit-incident",
  templateUrl: "./submit-incident.component.html",
  styleUrls: ["./submit-incident.component.scss"],
})
export class SubmitIncidentComponent {
  @Input() expand: boolean;
  @Input() incident: Incident;
  @Input() isReopenIncident: boolean;
  @Input() isOpenWorkItem: boolean;
  @Output() enableNextSection = new EventEmitter();

  constructor() {}

  enableNextIncidentSection(): void {
    this.enableNextSection.emit();
  }
}
