import { Component, OnInit } from "@angular/core";
import { StateService } from "src/app/shared/services/state.service";

@Component({
  selector: "app-district-notes",
  templateUrl: "./district-notes.component.html",
  styleUrls: ["./district-notes.component.scss"],
})
export class DistrictNotesComponent implements OnInit {
  itemDetails;

  constructor(private state: StateService) {}

  ngOnInit(): void {
    this.state.currentItemDetails$.subscribe((data) => {
      this.itemDetails = data;
    });
  }

  get hasDistrictNotes(): boolean {
    return this.itemDetails?.districtNotes;
  }
}
