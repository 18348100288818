/*
 * Base Classes
 */
import { SearchType } from "./search.state";
import { PageEvent } from "@angular/material/paginator";

export abstract class PerformSearch {
  static readonly base_type = "Perform Search";
  constructor(public readonly criteria: any, public readonly bypassSpinner = true) {}
}

export abstract class SetSearchSort {
  static readonly base_type = "Set Search Sort";
  constructor(public readonly sortFieldName: string, public readonly direction: string) {}
}

export abstract class SetSearchPage {
  static readonly base_type = "Set Search Page";
  constructor(public readonly page: PageEvent) {}
}

export abstract class SetSearchType {
  static readonly base_type = "Set Search Type";
  constructor(public readonly searchType: SearchType) {}
}

export abstract class SetCurrentRecordId {
  static readonly base_type = "Set Current Record Id";
  constructor(public readonly id: string) {}
}

export abstract class NavigateSearchRecord {
  static readonly base_type = "Navigate Search Record";
  constructor(public readonly direction: number) {}
}

export abstract class ClearSearch {
  static readonly base_type = "Clear Search";
}

/*
 * Scoped Classes
 */
export class PerformSearchFromSearchForm extends PerformSearch {
  static readonly type = `[Search Form] ${this.base_type}`;
}

export class PerformSearchFromSearchState extends PerformSearch {
  static readonly type = `[Search State] ${this.base_type}`;
}

export class SetSearchTypeFromSearchComponent extends SetSearchType {
  static readonly type = `[Search Component] ${this.base_type}`;
}

export class SetSearchSortFromSearchResultsComponent extends SetSearchSort {
  static readonly type = `[Search Results Component] ${this.base_type}`;
}

export class SetSearchPageNumberFromSearchResultsComponent extends SetSearchPage {
  static readonly type = `[Search Results Component] ${this.base_type}`;
}

export class SetSearchPageNumberFromSearchState extends SetSearchPage {
  static readonly type = `[Search State] ${this.base_type}`;
}

export class SetCurrentRecordIdFromSearchResultsComponent extends SetCurrentRecordId {
  static readonly type = `[Search Results Component] ${this.base_type}`;
}

export class SetCurrentRecordIdFromSearchState extends SetCurrentRecordId {
  static readonly type = `[Search State] ${this.base_type}`;
}

export class NavigateSearchRecordFromSearchNavigationComponent extends NavigateSearchRecord {
  static readonly type = `[Search Navigation Component] ${this.base_type}`;
}

export class ClearSearchFromSearchForm extends ClearSearch {
  static readonly type = `[Search Form] ${this.base_type}`;
}
