<app-expandable-panel
    [isExpanded]="true"
    *ngIf="blockReason?.cacheItem?.url || blockReason?.videoItem?.frames"
    data-testid="preview-panel">
    <ng-template appPanelHeader>
        <div class="new-row">
            <div class="label">Preview:</div>
        </div>
    </ng-template>
    <ng-template appPanelContent>
        <div
            *ngIf="blockReason?.cacheItem?.url"
            class="content-container">
            <app-image-modal
                [src]="imageUrl"
                imageAlt="Blocked Image Preview"></app-image-modal>
        </div>
        <div
            *ngIf="blockReason?.videoItem?.frames"
            class="content-container">
            <div class="frames-container">
                <div
                    *ngFor="let frame of blockReason.videoItem.frames"
                    class="frame">
                    <app-image-modal
                        [src]="frame.url"
                        imageAlt="Blocked Video Frame"></app-image-modal>
                </div>
            </div>
        </div>
    </ng-template>
</app-expandable-panel>
