<div
    class="content-category"
    *ngIf="expand"
    data-testid="content-category-component">
    <div class="choose-category">Choose the category that best describes the nature of the inappropriate content.</div>
    <div class="categories-container">
        <div
            *ngFor="let category of contentCategories"
            class="category">
            <div
                class="line-item"
                *ngIf="shouldDisplayCategory(category)">
                <span class="category-title">
                    <app-checkbox
                        attr.data-cy="{{ category.title }}-checkbox"
                        [label]="category.title"
                        [name]="category.title"
                        (toggleCheckbox)="onClickCategory(category)"
                        [checked]="category.isSelected"></app-checkbox>
                    <div class="hotkey">&nbsp;- {{ category.hotkey }}</div>
                </span>
                <ng-container *ngIf="category.isPssQcon">
                    <div class="qcon-pss-separator"></div>
                    <app-qcon-pss-switch
                        [category]="category"
                        [enabled]="category.isSelected"
                        (toggled)="onCategorySelection()"
                        [forcePSS]="shouldForcePSS(category)">
                    </app-qcon-pss-switch>
                </ng-container>
            </div>
            <div
                *ngFor="let description of category.descriptions"
                class="description">
                {{ description }}
            </div>
        </div>
    </div>

    <div
        *ngIf="showQuarantineToggle"
        class="quarantine-content">
        <gaggle-toggle
            label="Quarantine content"
            data-cy="quarantine_toggle"
            [isToggleOn]="quarantineContent"
            (status)="onToggleQuarantineContent()"></gaggle-toggle>
    </div>

    <div
        *ngIf="showContentType"
        class="content-type">
        <div [ngClass]="{ 'qcon-separator': isQcon, 'pss-separator': isPSS, 'viol-separator': isViolation }"></div>

        <ng-container *ngIf="isQcon">
            <gaggle-icon
                iconName="qcon"
                iconSize="4em"></gaggle-icon>
            <div class="main-text">Incident is a <span class="bold-font">QCON (questionable content).</span></div>
        </ng-container>

        <ng-container *ngIf="isPSS">
            <gaggle-icon
                iconName="pss"
                iconSize="4em"></gaggle-icon>
            <div class="main-text">Incident is a <span class="bold-font">PSS (possible student situation).</span></div>
        </ng-container>

        <ng-container *ngIf="isViolation">
            <gaggle-icon
                iconName="violation"
                iconSize="4em"></gaggle-icon>
            <div class="main-text">
                Incident is {{ violationDescriptor }} <span class="bold-font">{{ violationText }}</span>
            </div>
        </ng-container>

        <div class="subtext">
            Content will be <span class="bold-font">{{ quarantinedOrBlockedText }}</span
            >. <span *ngIf="quarantineContent">Make contact immediately! </span>
        </div>

        <div [ngClass]="{ 'qcon-separator': isQcon, 'pss-separator': isPSS, 'viol-separator': isViolation }"></div>
    </div>
</div>
