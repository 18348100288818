<app-expandable-panel
    [isExpanded]="true"
    data-testid="lms-info-panel"
    *ngIf="canvasData">
    <ng-template appPanelHeader>
        <div class="header">{{ canvasData.canvasType }}</div>
    </ng-template>
    <ng-template appPanelContent>
        <div class="contents">
            <div class="metadata">
                <div>
                    Name: <span class="metadata-content">{{ canvasData.name }}</span>
                </div>
                <div>
                    Author: <span class="metadata-content">{{ canvasData.author }}</span>
                </div>
                <div>
                    Occurred: <span class="metadata-content">{{ canvasData.date }}</span>
                </div>
            </div>
            <div
                class="content-preview"
                *ngIf="canvasData.textContents.length > 0">
                <div
                    *ngFor="let text of canvasData.textContents"
                    class="text-content">
                    <div class="descriptor">{{ text.name }}</div>
                    <div
                        class="content"
                        [innerHTML]="text.content | highlightKeywords: keywordList"></div>
                    <div
                        *ngIf="translatedText.length"
                        class="text-content-translated">
                        <div class="translate-header">Translation</div>
                        <ng-container *ngFor="let translation of translatedText">{{ translation }}</ng-container>
                    </div>
                    <gaggle-button
                        (buttonClick)="translateContent(text.content)"
                        label="Translate"
                        icon="translate-text"
                        buttonStyle="primary"
                        class="translate-button"
                        [disabled]="currentlyTranslating"
                        [showSpinner]="currentlyTranslating"
                        [backgroundColor]="overridePrimaryButtonColor"
                        [textColor]="overrideTextColor"
                        [iconColor]="overrideTextColor"></gaggle-button>
                </div>
            </div>
            <div
                class="content-preview"
                *ngIf="canvasData.fileContents.length > 0">
                <div
                    *ngFor="let file of canvasData.fileContents"
                    class="text-content">
                    <div class="descriptor">File Name</div>
                    <div class="content">{{ file.name | highlightKeywords: keywordList }}</div>
                    <div class="descriptor">File Type</div>
                    <div class="content">{{ file.type }}</div>
                </div>
            </div>
        </div>
    </ng-template>
</app-expandable-panel>
