import { Component, HostBinding, Input, OnInit } from "@angular/core";
import {
  AttachmentInfo,
  BlockReason,
  DownloadInfo,
  MessageMetadata,
  WorkItem,
  workItemToDisplayTypeMap,
  WorkItemType,
} from "src/app/models/work-item.model";
import { StateService } from "src/app/shared/services/state.service";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { downloadFile, isOversized } from "src/app/shared/utils/helpers";
import { ThemeService } from "src/app/shared/services/theme.service";
import { ClipboardService } from "ngx-clipboard";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UiAnalyticsService } from "src/app/shared/services/ui-analytics.service";
import { UiEventAction } from "src/generated/graphql";

// TODO: look into refactoring this because there's getters that seem to be related, duplicated if checks in nested components
export interface DisplayableBlockReason {
  blockReason: BlockReason;
  showExpanded: boolean;
}

@Component({
  selector: "app-item-viewer",
  templateUrl: "./item-viewer.component.html",
  styleUrls: ["./item-viewer.component.scss"],
})
export class ItemViewerComponent implements OnInit {
  bundleContent = false;
  showBundleToggle = false;
  itemDetails: WorkItem;
  showAvailableLanguages = false;
  keywords = new Set<string>();
  messageMetadata: MessageMetadata;
  fileDownloadInfo: DownloadInfo;
  blockReasons: DisplayableBlockReason[];
  dedupedBlockReasons = [];
  isAfterHours: boolean;
  showingAllImages = false;
  disableFileDownloadButton = false;
  hideAdditionalExcerpts = true;

  isImage: boolean;
  isPDF: boolean;
  isOversized: boolean;
  isFileComment: boolean;
  webFilterUrl: string;
  hasBlockReasonWithMultipleExcerpts = false;

  shouldShowMessagePreviewPanel = false;

  private messageTypes = [WorkItemType.MESSAGE, WorkItemType.HANGOUT, WorkItemType.SPEAKUP];

  @Input() hideBundleToggle = false;
  @Input() isReopenIncident = false;
  @Input() isOpenWorkItem = false;

  @HostBinding("style.--multi-image-columns") @Input() multiImageColumns = 10;

  private rDollarRegex = new RegExp(/.*[(][r][)][.][$].*/);

  get itemEntityType(): string {
    return workItemToDisplayTypeMap[this.itemDetails?.workItemEntityType];
  }

  get showDownload(): boolean {
    return !!this.messageMetadata?.download_info?.url;
  }

  get showFileDownload(): boolean {
    return !!this.fileDownloadInfo;
  }

  get hasPdfAvailable(): boolean {
    return !!this.fileDownloadInfo.pdfGenerated;
  }

  get isCalendar(): boolean {
    return this.itemDetails?.workItemEntityType === WorkItemType.CALENDAR;
  }

  get isSpeakUp(): boolean {
    return this.itemDetails?.workItemEntityType === WorkItemType.SPEAKUP;
  }

  get isHangout(): boolean {
    return this.itemDetails?.workItemEntityType === WorkItemType.HANGOUT;
  }

  get isLMS(): boolean {
    return this.itemDetails?.workItemEntityType === WorkItemType.CANVAS;
  }

  get fileDownloadName(): string {
    if (this.showFileDownload || this.showDownload) {
      switch (this.itemDetails.workItemEntityType) {
        case WorkItemType.FILE:
        case WorkItemType.FILE_COMMENT:
          return this.itemDetails.entityName;

        case WorkItemType.MESSAGE:
          // The first attachment is the message itself
          return this.messageMetadata.attachments[0].name;

        default:
          return this.itemDetails.id + ".eml";
      }
    } else {
      return null;
    }
  }

  get showFilePreview(): boolean {
    const blockReasonWithImage = this.itemDetails?.blockReasons?.find((b) => !!b.cacheItem?.url);
    return this.showFileDownload && !blockReasonWithImage;
  }

  get blockedImages(): AttachmentInfo[] {
    return this.messageMetadata?.attachments?.filter((info) => {
      return info.contentType?.startsWith("image");
    });
  }

  get keywordList(): string[] {
    return Array.from(this.keywords);
  }

  get pillTextColor(): string {
    return this.theme.getVariable("--pill-highlight-text-color");
  }

  get pillBackgroundColor(): string {
    return this.theme.getVariable(`--${this.itemDetails?.workItemEntityType?.toLowerCase()}-highlight`);
  }

  constructor(
    private clipboardService: ClipboardService,
    private state: StateService,
    private appSync: AppSyncService,
    private theme: ThemeService,
    private snackBar: MatSnackBar,
    private uiAnalyticsService: UiAnalyticsService,
  ) {}

  ngOnInit(): void {
    this.state.currentItemDetails$.subscribe((workItem: WorkItem) => {
      this.processCurrentItemDetails(workItem);
    });

    this.state.messageMetadata$.subscribe((data) => {
      this.messageMetadata = data;
    });
    this.state.downloadLink$.subscribe((data) => {
      this.fileDownloadInfo = data;
      this.disableFileDownloadButton = data?.file_size === 0;
    });
    this.state.itemIsInAfterHoursWindow$.subscribe((data) => (this.isAfterHours = data));
  }

  private processCurrentItemDetails(workItem: WorkItem): void {
    this.itemDetails = workItem;

    this.hideAdditionalExcerpts = false;
    this.blockReasons = [];
    this.dedupedBlockReasons = [];

    if (this.itemDetails) {
      this.hasBlockReasonWithMultipleExcerpts = this.itemDetails.blockReasons?.some((b) => b.excerpts?.length > 1);
      this.processBlockReasons();
      this.bundleContent = this.showBundleToggle = this.shouldShowBundleToggle();

      this.isFileComment = workItem?.workItemEntityType === WorkItemType.FILE_COMMENT;

      if (workItem?.workItemEntityType === WorkItemType.WEBFILTER && !!workItem?.objectId) {
        this.shouldShowMessagePreviewPanel = true;
      } else {
        this.shouldShowMessagePreviewPanel = this.messageTypes.includes(workItem?.workItemEntityType);
      }

      const isSVG = this.itemDetails.entityName?.toLowerCase().endsWith("svg");
      this.isImage = this.itemDetails.contentType?.startsWith("image/") && !isSVG;
      this.isPDF = this.itemDetails.contentType === "application/pdf";
      this.isOversized = isOversized(this.itemDetails);

      if (workItem.workItemEntityType === WorkItemType.WEBFILTER && !!workItem.itemData) {
        try {
          const itemData = JSON.parse(workItem.itemData);
          if ("chromeExtensionData" in itemData) {
            this.webFilterUrl = itemData?.chromeExtensionData.url;
          }
        } catch (e) {
          console.info("Error parsing webfilter item data", e);
        }
      }
    }
  }

  /* eslint-disable curly */
  private shouldShowBundleToggle(): boolean {
    const disableBundleTypes = [WorkItemType.WEBFILTER, WorkItemType.SPEAKUP, WorkItemType.HANGOUT];
    if (this.hideBundleToggle) return false;
    if (disableBundleTypes.includes(this.itemDetails.workItemEntityType)) return false;
    return this.state.userCanWarnOnItem;
  }
  /* eslint-enable curly */

  private showExpanded(blockReason: BlockReason): boolean {
    const stripped = blockReason.reason.replace(/\s+/g, "");
    return this.rDollarRegex.test(stripped);
  }

  private processBlockReasons(): void {
    if (this.itemDetails.blockReasons?.length > 0) {
      const byContentKey = {};

      this.itemDetails.blockReasons.forEach((b, index) => {
        if (b.reason) {
          this.blockReasons.push({
            blockReason: b,
            showExpanded: this.showExpanded(b),
          });
        }

        if (b.keyword?.trim().length > 0) {
          this.keywords.add(b.keyword.trim());
        }

        if (b.cacheItem?.url && b.cacheItem?.contentKey) {
          byContentKey[b.cacheItem?.contentKey] = b;
        } else {
          byContentKey[index] = b;
        }
      });

      this.dedupedBlockReasons = Object.values(byContentKey);
    }
  }

  onToggleBundleContent(): void {
    this.bundleContent = !this.bundleContent;
    this.state.isItemUnbundled = !this.bundleContent;
  }

  onClickDownload(type: "PDF" | "ORIGINAL" = "ORIGINAL"): void {
    this.uiAnalyticsService.sendAction(UiEventAction.DownloadContent);
    this.disableFileDownloadButton = true;
    this.appSync.setDownloadLink(this.fileDownloadInfo, true).then((newDownloadInfo) => {
      const url = type === "PDF" ? newDownloadInfo.pdfUrl : newDownloadInfo.url;
      const filename = this.fileDownloadName + (type === "PDF" ? ".pdf" : "");
      downloadFile(url, filename).then(() => {
        this.disableFileDownloadButton = false;
      });
    });
  }

  onClickShowImages(): void {
    this.showingAllImages = !this.showingAllImages;
  }

  onClickCopyImageFilename(filename: string): void {
    this.clipboardService.copy(filename);
    this.snackBar.open(filename + " copied to clipboard!", null, {
      duration: 1000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    });
  }

  onClickAdjustImageColumns(inc: number): void {
    this.multiImageColumns = this.multiImageColumns + inc;
  }
}
