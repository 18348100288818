import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject } from "rxjs";

export enum ThemeMode {
  DARK = "DARK",
  LIGHT = "LIGHT",
}

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private readonly THEME_KEY = "GAGGLE_UI_THEME";
  private readonly DARK_THEME_VALUE = ThemeMode.DARK;
  private readonly LIGHT_THEME_VALUE = ThemeMode.LIGHT;
  private readonly DARK_THEME_CLASS_NAME = "theme-dark";
  private readonly LIGHT_THEME_CLASS_NAME = "theme-light";

  private _themeMode$ = new BehaviorSubject<ThemeMode>(null);
  public themeMode$ = this._themeMode$.asObservable();

  private darkThemeOn = false;
  private bodyStyles;

  constructor(private snackBar: MatSnackBar) {
    this.bodyStyles = getComputedStyle(document.body);
  }

  public setThemeOnStart(): void {
    // if this is the first time user has used application, check if they have dark mode set in browser
    if (this.browserUsesDarkMode && !this.userHasSavedTheme()) {
      this.setDarkTheme();
      document.body.classList.add(this.DARK_THEME_CLASS_NAME);
      return;
    }

    if (this.isDarkThemeSelected()) {
      this.setDarkTheme();
      document.body.classList.add(this.DARK_THEME_CLASS_NAME);
    } else {
      this.setLightTheme();
      document.body.classList.add(this.LIGHT_THEME_CLASS_NAME);
    }

    setTimeout(() => {
      document.body.classList.add("animate-transition");
    }, 500);
  }

  public changeToTheme(theme: ThemeMode, message = null): void {
    let isChangingTheme = true;
    if (theme === ThemeMode.DARK) {
      if (this.darkThemeOn) {
        isChangingTheme = false;
      }
      this.setDarkTheme();
    } else {
      if (!this.darkThemeOn) {
        isChangingTheme = false;
      }
      this.setLightTheme();
    }

    if (message && isChangingTheme) {
      this.snackBar.open(message, "Okay", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }

  public toggleTheme(): void {
    if (this.darkThemeOn) {
      this.setLightTheme();
    } else {
      this.setDarkTheme();
    }
  }

  public getVariable(variableName: string): string {
    return this.bodyStyles.getPropertyValue(variableName);
  }

  get browserUsesDarkMode(): boolean {
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  }

  get useDarkMode(): boolean {
    return this.darkThemeOn;
  }

  private userHasSavedTheme(): boolean {
    return !!localStorage.getItem(this.THEME_KEY);
  }

  private isDarkThemeSelected(): boolean {
    const savedTheme = localStorage.getItem(this.THEME_KEY);
    this.darkThemeOn = savedTheme === this.DARK_THEME_VALUE;
    return this.darkThemeOn;
  }

  private setLightTheme(): void {
    document.body.classList.replace(this.DARK_THEME_CLASS_NAME, this.LIGHT_THEME_CLASS_NAME);
    localStorage.setItem(this.THEME_KEY, this.LIGHT_THEME_VALUE);
    this.darkThemeOn = false;
    this._themeMode$.next(ThemeMode.LIGHT);

    // Material Theme
    document.body.classList.remove("dark-theme");
    document.body.classList.add("light-theme");
  }

  private setDarkTheme(): void {
    document.body.classList.replace(this.LIGHT_THEME_CLASS_NAME, this.DARK_THEME_CLASS_NAME);
    localStorage.setItem(this.THEME_KEY, this.DARK_THEME_VALUE);
    this.darkThemeOn = true;
    this._themeMode$.next(ThemeMode.DARK);

    // Material Theme
    document.body.classList.add("dark-theme");
    document.body.classList.remove("light-theme");
  }
}
