import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputComponent,
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  // required to make input function correctly
  @Input() name: string; // should be unique per the current form
  @Input() label: string;
  @Input() value: string | number = "";

  @Output() valueChange = new EventEmitter<string | number>();
  @Input() inputType: "text" | "number" = "text";
  @Input() placeholder = "";
  @Input() disabled = false;
  @Input() required = false;
  @Input() readonly = false;

  touched = false;

  constructor() {}

  /* eslint-disable */ // This is all per the angular guide, so eslint can just be quiet already
  onChange = (value) => {};
  onTouched = () => {};
  /* eslint-enable*/

  setValue(value: any): void {
    this.value = value.target.value;
    this.valueChange.emit(this.value);
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
