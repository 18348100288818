<gaggle-menu-button
    [textColor]="textColor"
    [iconColor]="iconColor"
    [backgroundColor]="backgroundColor"
    [label]="label"
    [type]="type"
    [icon]="icon"
    (buttonClick)="performClick()"
    [disabled]="disabled"
    [ngClass]="{ disabled: disabled }"></gaggle-menu-button>
