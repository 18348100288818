<ng-container *ngIf="noAccess; else connectionTest">
    <div class="container" data-testid="no-access">
        <img src="assets/level2.png" class="logo" alt="No Access" />
        <h1 class="error">No Access</h1>
        <p>You have not been configured to use this application.</p>
        <br />
        <button mat-raised-button (click)="onClickReload()">Try Again</button>
    </div>
</ng-container>

<ng-template #connectionTest>
    <div class="container">
        <h1 *ngIf="success" class="success">Connected</h1>
        <h1 *ngIf="appSyncError.length && !success" class="error">Error</h1>
        <p *ngIf="statusCode">Status Code: {{ statusCode }}</p>
        <p *ngIf="appSyncError">{{ appSyncError }}</p>
    </div>
</ng-template>
