import { inject, Injectable, Injector } from "@angular/core";
import { ConfigService } from "./config.service";
import Amplify from "aws-amplify";
import { Auth } from "@aws-amplify/auth";
import { StateService } from "./state.service";
import { FeatureFlagName, FeatureFlagValue } from "src/app/models/feature-flags.model";
import { ApplicationPermission, GsmAppsyncApi } from "src/generated/graphql";
import { ApplicationUserExtended } from "@features/admin/state/admin-roles-and-permissions/admin-roles-and-permissions.state";

enum JWTPayloadProperty {
  L1UI_USERID = "custom:userId",
  WORKER_UUID = "custom:workerUUID",
  PSS_PERMISSION = "custom:hasPSSPermission",
  COGNITO_USERNAME = "cognito:username",
  ADMIN_PERMISSION = "custom:hasAdminPermission",
  EXPIRES = "exp",
  REOPEN_PERMISSION = "custom:hasReopenPermission",
  WORKER_FULL_NAME = "custom:workerFullName",
  // TODO: We need to update this to customer:userEmailAddress once that attribute is available in the cognito token
  USER_EMAIL_ADDRESS = "custom:workerEmailAddress",
  WORKER_PERMISSIONS = "custom:workerPermissions",
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private configService = inject(ConfigService);
  private stateService = inject(StateService);
  private injector = inject(Injector);
  private gsmAppsyncApi!: GsmAppsyncApi;
  private username: string = null;
  private gaggleUser = false;
  private identityProviderId: string;
  private userPoolClientId: string;
  private refreshTokenValue: string = null;
  private jwtExpiration: number;
  private userCanTakeReopenActions = false;
  private workerId: string = null;
  private workerFullName: string = null;
  private jwtToken: string = null;
  private jwtPayload: any;
  private applicationUser: ApplicationUserExtended;

  public async initialize(): Promise<void> {
    const config = this.configService.getConfig();

    this.stateService.environment = config.environment;
    this.userPoolClientId = config.userPoolWebClientId;
    this.identityProviderId = config.identityProviderId;

    Amplify.configure({
      Auth: {
        region: "us-west-2",
        userPoolId: config.userPoolId,
        userPoolWebClientId: config.userPoolWebClientId,
        mandatorySignIn: false,
      },
    });

    Auth.configure({
      oauth: {
        domain: config.userPoolDomain,
        scope: ["openid", "email", "profile"],
        redirectSignIn: config.redirectSignIn,
        redirectSignOut: config.redirectSignOut,
        responseType: "code",
      },
    });

    await Auth.currentAuthenticatedUser().catch((error) => {
      this.clearUserData();
      if (error === "The user is not authenticated") {
        void Auth.federatedSignIn({ customProvider: this.identityProviderId });
      } else {
        console.error(error);
      }
    });

    const session = await Auth.currentSession();
    await this.parseTokenData(session);
    await this.fetchApplicationUser(this.workerId);
    this.stateService.authenticated = true;
  }

  public signedIn(): boolean {
    return this.stateService.authenticated;
  }

  public signOut(): void {
    this.clearUserData();
    Auth.signOut();
  }

  public validGaggleUser(): boolean {
    return this.gaggleUser;
  }

  public getAuthToken(): string {
    return this.jwtToken;
  }

  public getWorkerFullName(): string {
    return this.workerFullName;
  }
  public getUsername(): string {
    return this.username;
  }

  public getWorkerId(): string {
    return this.workerId;
  }

  public getRefreshToken(): string {
    return this.refreshTokenValue;
  }

  public getUserPoolClientId(): string {
    return this.userPoolClientId;
  }

  public hasReopenPermission(): boolean {
    return this.userCanTakeReopenActions && this.reopenEnabled();
  }

  public get userEmailAddress(): string {
    return this.jwtPayload[JWTPayloadProperty.USER_EMAIL_ADDRESS];
  }

  private clearUserData(): void {
    this.username = null;
    this.gaggleUser = false;
    this.workerId = null;
    this.workerFullName = null;
    this.stateService.hasPSSPermission = false;
    this.stateService.authenticated = false;
    this.refreshTokenValue = null;
    this.jwtExpiration = null;
    this.userCanTakeReopenActions = false;
    this.jwtToken = null;
  }

  async fetchApplicationUser(userUUID: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.gsmAppsyncApi = this.injector.get(GsmAppsyncApi);
      this.gsmAppsyncApi.getApplicationUser({ userUUID }).subscribe({
        next: (result) => {
          this.applicationUser = result.data?.getApplicationUser as ApplicationUserExtended;

          // Make sure to give admin users all permissions
          if (this.applicationUser && this.applicationUser.isAdminUser) {
            this.applicationUser.effectivePermissions = Object.values(ApplicationPermission);
          }

          resolve();
        },
        error: (error) => {
          console.error("Failed to fetch application user", error);
          resolve();
        },
      });
    });
  }

  private async parseTokenData(session: any): Promise<void> {
    const sessionIdToken = session.getIdToken();
    this.jwtToken = sessionIdToken.getJwtToken();

    const jwtPayload = sessionIdToken.payload;
    this.jwtPayload = jwtPayload;
    this.jwtExpiration = Number(jwtPayload[JWTPayloadProperty.EXPIRES]);
    this.refreshTokenValue = session.getRefreshToken().getToken();
    this.username = null;
    this.workerId = null;
    this.gaggleUser = false;
    this.stateService.hasPSSPermission = this.extractPermission(jwtPayload, JWTPayloadProperty.PSS_PERMISSION);
    this.userCanTakeReopenActions = this.extractPermission(jwtPayload, JWTPayloadProperty.REOPEN_PERMISSION);
    this.workerFullName = jwtPayload[JWTPayloadProperty.WORKER_FULL_NAME];

    if (jwtPayload.identities !== undefined) {
      this.username = jwtPayload.identities[0].userId;
      this.gaggleUser = JWTPayloadProperty.WORKER_UUID in jwtPayload;
      if (this.gaggleUser) {
        this.workerId = jwtPayload[JWTPayloadProperty.WORKER_UUID];
      }
    } else {
      this.username = jwtPayload[JWTPayloadProperty.COGNITO_USERNAME];
      this.gaggleUser = true;
      this.workerId = jwtPayload[JWTPayloadProperty.L1UI_USERID];
    }
    this.stateService.authenticated = true;
  }

  async refreshSession(): Promise<any> {
    try {
      await Auth.currentSession().then((session) => {
        this.jwtToken = session.getIdToken().getJwtToken();
      });
    } catch (error) {
      console.error(`Failed to refresh session: ${error}`);
      this.signOut();
      if (this.identityProviderId) {
        void Auth.federatedSignIn({ customProvider: this.identityProviderId });
      }
    }
  }

  private extractPermission(jwtPayload: any, permission: JWTPayloadProperty): boolean {
    if (!(permission in jwtPayload)) {
      return false;
    }

    return parseInt(jwtPayload[permission]) > 0;
  }

  private reopenEnabled(): boolean {
    return this.stateService.getFeatureFlag(FeatureFlagName.REOPEN) === FeatureFlagValue.ON;
  }

  get isAdmin(): boolean {
    return this.applicationUser.isAdminUser;
  }

  /* eslint-disable curly */
  get userIsAllowed(): boolean {
    if (!this.applicationUser) return false;
    if (this.applicationUser.enabled === false) return false;
    return this.userPermissions.length > 0;
  }
  /* eslint-enable curly */

  get userPermissions(): ApplicationPermission[] {
    return this.applicationUser.effectivePermissions;
  }

  userHasPermission(permission: ApplicationPermission): boolean {
    return this.isAdmin || this.userPermissions.includes(permission);
  }
}
