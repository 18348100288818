<h1 mat-dialog-title>Send {{ data.smsType | titlecase }} SMS</h1>
<mat-dialog-content>
    <ng-container *ngIf="data.smsType === 'incident'">
        <mat-form-field
            appearance="outline"
            class="gaggle-form-field"
            floatLabel="always">
            <mat-label>Template</mat-label>
            <mat-select
                class="full-width-select"
                (selectionChange)="onTemplateSelected($event)">
                <mat-option
                    *ngFor="let template of smsTemplates"
                    [value]="template">
                    {{ template.dropdownOption }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>

    <div class="sms-content">
        <mat-form-field
            appearance="outline"
            class="gaggle-form-field"
            floatLabel="always">
            <mat-label>SMS Body</mat-label>
            <textarea
                matInput
                [cdkTextareaAutosize]="true"
                [(ngModel)]="smsBody"
                disabled></textarea>
        </mat-form-field>
    </div>

    <div class="title">Recipients</div>
    <div class="recipients">
        <div
            class="recipient"
            *ngFor="let recipient of data.recipients">
            <span class="name">{{ recipient.name }}</span>
            <span class="phone">({{ recipient.mobilePhone.areaCode }}) {{ recipient.mobilePhone.number }}</span>
            <span class="status">{{ recipient.smsStatus | titlecase }}</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="send-button-container">
        <button
            data-testid="send-sms-button"
            mat-raised-button
            color="primary"
            (click)="onClickSend()"
            [disabled]="messageSent || !!!smsBody">
            Send
        </button>
        <mat-progress-spinner
            *ngIf="sending"
            mode="indeterminate"
            diameter="24"></mat-progress-spinner>
    </div>

    <button
        mat-button
        mat-dialog-close>
        Close
    </button>
</mat-dialog-actions>
