<ng-container *ngIf="!hideToggle">
    <button #toggleBtn class="drawer-toggle" (click)="toggle()" [ngClass]="{ 'show-drawer': showDrawer }">
        <div class="icon-wrapper">
            <gaggle-icon [iconName]="getIconName()" iconSize="1.4em" [iconState]="getIconState()" [color]="overrideTextColor"></gaggle-icon>
            <div class="drawer-dot" [ngClass]="{ 'show-dot': showDot }"></div>
        </div>
    </button>
</ng-container>

<div #drawerDiv class="drawer mat-elevation-z5" [ngClass]="{ 'show-drawer': showDrawer }">
    <!-- Projection for drawer header -->
    <div *ngIf="slideDrawerHeader" class="drawer-header section">
        <ng-container [ngTemplateOutlet]="slideDrawerHeader?.templateRef"></ng-container>
    </div>

    <!-- Projection for drawer content -->
    <div class="drawer-content section">
        <!-- Use template directive -->
        <ng-container *ngIf="slideDrawerContent; else projectContent">
            <ng-container [ngTemplateOutlet]="slideDrawerContent?.templateRef"></ng-container>
        </ng-container>

        <!-- Using ng-content -->
        <ng-template #projectContent>
            <ng-content></ng-content>
        </ng-template>
    </div>

    <!-- Projection for drawer footer-->
    <div *ngIf="slideDrawerFooter" class="drawer-footer section">
        <ng-container [ngTemplateOutlet]="slideDrawerFooter?.templateRef"></ng-container>
    </div>
</div>
