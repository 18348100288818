import { Component, Input, OnChanges, OnInit, OnDestroy } from "@angular/core";
import { EventType, Incident } from "src/app/models/incident.model";
import { ActionReason, GsmEmailSetting, WorkItem, GroupAdministrator } from "src/app/models/work-item.model";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { StateService } from "src/app/shared/services/state.service";
import { IncidentSection, SectionTitles } from "src/app/models/incident-response.model";
import { FeatureFlagName, FeatureFlagValue } from "src/app/models/feature-flags.model";
import { contentCategories } from "src/app/models/content-category.model";
import { AcknowledgementService } from "src/app/shared/services/acknowledgement.service";
import { UiAnalyticsService } from "src/app/shared/services/ui-analytics.service";
import { IncidentEmailSentGQL, UiEventAction, WarningEventType } from "src/generated/graphql";
import { Subject, takeUntil, filter, Observable } from "rxjs";
import { EmergencyContactService, EmergencyContactExtended } from "src/app/shared/services/emergency-contact.service";
import { Select, Store } from "@ngxs/store";
import {
  NewIncidentFromIncidentResponseRightDrawer,
  ResetIncidentFromIncidentResponseRightDrawer,
} from "src/app/shared/state/incident/incident.actions";
import { v4 as uuid } from "uuid";
import { IncidentSelector } from "src/app/shared/state/incident/incident.selector";
import { Clipboard } from "@angular/cdk/clipboard";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-incident-response-right-drawer",
  templateUrl: "./incident-response-right-drawer.component.html",
  styleUrls: ["./incident-response-right-drawer.component.scss"],
  providers: [AcknowledgementService],
})
export class IncidentResponseRightDrawerComponent implements OnInit, OnChanges, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() ncmecOnly = false;
  @Input() ncmecEvent: EventType;
  @Input() isReopenIncident: boolean;
  @Input() isOpenWorkItem: boolean;

  @Select(IncidentSelector.incidentId) incidentId$: Observable<string>;

  ncmecReportSent = false;
  currentSection: IncidentSection;
  itemDetails: WorkItem;
  gsmEmailSetting: GsmEmailSetting;
  groupAdministrator: GroupAdministrator;
  newIncident: Incident = {};
  userMustEscalatePSS = false;
  shouldEnableNextForTextCategorySection = false;
  shouldEnableNextForContentCategorySection = false;
  shouldEnableNextForEmailEmergencyContactsSection = false;
  shouldEnableNextForTextEmergencyContactsSection = true;
  shouldEnableNextForCallEmergencyContactsSection = true;
  shouldEnableNextForSalesforceCaseSection = false;
  incidentIsEscalatePSS = false;
  isAfterHours = false;
  sectionTitles = SectionTitles;
  emailContacts: EmergencyContactExtended[];
  textContacts: EmergencyContactExtended[];
  callContacts: EmergencyContactExtended[];
  emailIndicator: string = null;
  emailIndicatorTooltip: string = null;

  drawerSections: IncidentSection[] = [
    {
      name: SectionTitles.ContentCategory,
      afterHoursName: SectionTitles.ContentCategory,
      timerName: "ContentCategory",
      enableSection: (): boolean => true,
      showNext: (): boolean => true,
      disableNext: (): boolean => !this.shouldEnableNextForContentCategorySection,
      showPrevious: (): boolean => false,
      disablePrevious: (): boolean => true,
      shouldEscalatePSSOnNext: (): boolean => this.userMustEscalatePSS,
    },
    {
      name: SectionTitles.SubmitIncident,
      afterHoursName: SectionTitles.SubmitIncident,
      timerName: "SubmitIncident",
      enableSection: (): boolean => !this.shouldSendEmail,
      showNext: (): boolean => true,
      disableNext: (): boolean => !this.shouldEnableNextForEmailEmergencyContactsSection,
      showPrevious: (): boolean => true,
      disablePrevious: (): boolean => this.shouldEnableNextForEmailEmergencyContactsSection,
      shouldEscalatePSSOnNext: (): boolean => false,
    },
    {
      name: SectionTitles.EmailEmergencyContacts,
      afterHoursName: SectionTitles.EmailEmergencyContacts,
      timerName: "EmailEmergencyContacts",
      indicatorIcon: (): string => this.emailIndicator,
      indicatorTooltip: (): string => this.emailIndicatorTooltip,
      enableSection: (): boolean => this.shouldSendEmail && !this.userMustEscalatePSS,
      showNext: (): boolean => true,
      disableNext: (): boolean => !this.shouldEnableNextForEmailEmergencyContactsSection,
      showPrevious: (): boolean => true,
      disablePrevious: (): boolean => this.shouldEnableNextForEmailEmergencyContactsSection,
      shouldEscalatePSSOnNext: (): boolean => false,
    },
    {
      name: SectionTitles.TextEmergencyContacts,
      afterHoursName: SectionTitles.AfterHoursTextEmergencyContacts,
      timerName: "TextEmergencyContacts",
      enableSection: (): boolean => this.isIncidentPss && !this.userMustEscalatePSS && this.textContacts.length > 0,
      showNext: (): boolean => true,
      disableNext: (): boolean => !this.shouldEnableNextForTextEmergencyContactsSection,
      showPrevious: (): boolean => false,
      disablePrevious: (): boolean => true,
      shouldEscalatePSSOnNext: (): boolean => false,
    },
    {
      name: SectionTitles.CallEmergencyContacts,
      afterHoursName: SectionTitles.AfterHoursCallEmergencyContacts,
      timerName: "CallEmergencyContacts",
      enableSection: (): boolean => this.isIncidentPss && !this.userMustEscalatePSS,
      showNext: (): boolean => true,
      disableNext: (): boolean => !this.shouldEnableNextForCallEmergencyContactsSection,
      showPrevious: (): boolean => false,
      disablePrevious: (): boolean => true,
      shouldEscalatePSSOnNext: (): boolean => false,
    },
    {
      name: SectionTitles.CreateSalesforceCase,
      afterHoursName: SectionTitles.CreateSalesforceCase,
      timerName: "CreateSalesforceCase",
      enableSection: (): boolean => this.isIncidentPss && !this.userMustEscalatePSS && !this.itemDetails.safetyAudit,
      showNext: (): boolean => true,
      disableNext: (): boolean => !this.shouldEnableNextForSalesforceCaseSection,
      showPrevious: (): boolean => true,
      disablePrevious: (): boolean => false,
      shouldEscalatePSSOnNext: (): boolean => false,
    },
    {
      name: SectionTitles.NCMEC,
      afterHoursName: SectionTitles.NCMEC,
      timerName: "NCMEC",
      enableSection: (): boolean => this.isNcmecEnabled && this.isNudityCategoryPssAndSelected,
      showNext: (): boolean => true,
      disableNext: (): boolean => !this.ncmecReportSent,
      showPrevious: (): boolean => true,
      disablePrevious: (): boolean => false,
      shouldEscalatePSSOnNext: (): boolean => false,
    },
  ];

  get isIncidentPss(): boolean {
    return this.newIncident.payload?.warningType === WarningEventType.PossibleStudentSituation;
  }

  get isCancelButtonDisabled(): boolean {
    return this.shouldEnableNextForEmailEmergencyContactsSection || this.ncmecReportSent;
  }

  get isNudityCategoryPssAndSelected(): boolean {
    return contentCategories.some((c) => {
      return c.actionReason === ActionReason.NUDITY && c.isSelected && c.warningType === WarningEventType.PossibleStudentSituation;
    });
  }

  get enabledSections(): IncidentSection[] {
    if (this.ncmecOnly) {
      return [this.drawerSections.find((section) => section.name === SectionTitles.NCMEC)];
    }

    return this.drawerSections.filter((section) => section.enableSection());
  }

  get shouldSendEmail(): boolean {
    const warningType = this.newIncident?.payload?.warningType;
    // Always send email if QCON/PSS or if there is no GSM violation email setting available.
    if (
      warningType === WarningEventType.QuestionableContent ||
      warningType === WarningEventType.PossibleStudentSituation ||
      !this.gsmEmailSetting
    ) {
      return true;
    } else if (this.gsmEmailSetting === GsmEmailSetting.NONE) {
      return false;
    } else if (this.gsmEmailSetting === GsmEmailSetting.ADMINISTRATOR && !this.groupAdministrator) {
      return false;
    }
    return true;
  }

  updateCurrentSection(section: IncidentSection): void {
    this.currentSection = section;
    if (section.name === SectionTitles.CallEmergencyContacts) {
      this.state.activeCall = false;
    }

    if (section.name === SectionTitles.NCMEC) {
      this.uiAnalyticsService.sendAction(UiEventAction.NcmecSectionOpened);
    } else if (section.name === SectionTitles.TextEmergencyContacts) {
      this.uiAnalyticsService.sendAction(UiEventAction.SmsSectionOpened);
    } else if (section.name === SectionTitles.CallEmergencyContacts) {
      this.uiAnalyticsService.sendAction(UiEventAction.CallSectionOpened);
    }
  }

  get isNcmecEnabled(): boolean {
    return this.state.getFeatureFlag(FeatureFlagName.NCMEC) === FeatureFlagValue.ON;
  }

  constructor(
    private state: StateService,
    private appsync: AppSyncService,
    private uiAnalyticsService: UiAnalyticsService,
    private emergencyContactService: EmergencyContactService,
    private store: Store,
    private clipboard: Clipboard,
    private matSnackbar: MatSnackBar,
    private incidentEmailSentGQL: IncidentEmailSentGQL,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(new ResetIncidentFromIncidentResponseRightDrawer());
  }

  ngOnInit(): void {
    this.appsync.getSMSTemplates();

    this.state.activeCall$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.shouldEnableNextForCallEmergencyContactsSection = !data;
    });

    this.emergencyContactService.filteredContacts$
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(filter((data) => !!data)) // Filter out empty values
      .subscribe((data) => {
        this.newIncident.unfilteredContacts = data.all;
        this.emailContacts = data.email;
        this.textContacts = data.sms;
        this.callContacts = data.call;
      });

    this.state.currentItemDetails$
      .pipe(filter((data) => !!data)) // Filter out empty values
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.itemDetails = data;
        this.gsmEmailSetting = data.gsmEmailSetting;
        this.groupAdministrator = data.groupAdministrator;
        this.incidentIsEscalatePSS = !!data?.events?.find((e) => e.event === "escalatepss");
      });

    this.state.shouldToggleEscalatePSS$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.userMustEscalatePSS = data;
    });

    this.state.isAnyCategorySelected$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.shouldEnableNextForContentCategorySection = data;
    });

    if (this.state && this.state.currentItemDetails) {
      const incidentId = uuid();

      this.store.dispatch(new NewIncidentFromIncidentResponseRightDrawer(incidentId));

      this.newIncident = {
        id: incidentId,
        workItemId: this.state.currentItemDetails.id,
        workerId: this.state.currentItemDetails.workerId,
        event: EventType.WARN,
        payload: {
          userId: this.state.currentItemDetails.blockedUserId
            ? this.state.currentItemDetails.blockedUserId
            : this.state.currentItemDetails.userId,
          itemID: this.state.currentItemDetails.id.replace(new RegExp("[-]", "g"), "_"),
          districtId: this.state.currentItemDetails.districtId,
          districtName: this.state.currentItemDetails.districtName,
          entityType: this.state.currentItemDetails.workItemEntityType,
          groupId: this.state.currentItemDetails.groupId,
          groupName: this.state.currentItemDetails.groupName,
          entityId: this.state.currentItemDetails.entityId,
          userEmail: this.state.currentItemDetails.userEmail,
          customerId: this.state.currentItemDetails.customerId,
          recipients: [],
          ccRecipients: [],
          actionReasons: [],
          attachments: [],
          remoteEntityId: this.state.currentItemDetails.remoteEntityId,
          fileOwnerEmail: this.state.currentItemDetails.fileOwnerEmail,
          entityOwnerId: this.state.currentItemDetails.remoteOwnerId,
          internalNotes: null,
          warningType: null,
          workerId: this.state.currentItemDetails.workerId,
          attributes: JSON.stringify({
            useMessageEnvoy: "true",
            timeZone: this.state.currentItemDetails.studentTimezone,
            eventId: incidentId,
            gaft: this.state.itemIsInAfterHoursWindow,
          }),
        },
        additionalRecipientsInput: "",
        ccRecipientsInput: "",
        unfilteredContacts: [],
        filteredEmailContacts: [],
        filteredTextContacts: [],
      };
    }

    this.isAfterHours = this.state.itemIsInAfterHoursWindow;

    this.determineCurrentSection();
  }

  ngOnChanges(): void {
    this.determineCurrentSection();
  }

  determineCurrentSection(): void {
    if (this.ncmecOnly) {
      this.uiAnalyticsService.sendAction(UiEventAction.NcmecSectionOpened);
      this.currentSection = this.drawerSections.find((section) => section.name === SectionTitles.NCMEC);
    } else {
      this.currentSection = this.drawerSections[0];
    }
  }

  onCopyIncidentId(incidentId: string): void {
    this.clipboard.copy(incidentId);
    this.matSnackbar.open("Incident ID copied", null, { duration: 2000 });
  }

  onClickCancel(): void {
    if (!this.isCancelButtonDisabled) {
      this.uiAnalyticsService.sendAction(UiEventAction.CancelIncidentCreation);
      this.state.showRightDrawer = false;
      this.state.isItemActionBarVisible = true;
    }
  }

  enableNextInEmailSection(): void {
    this.shouldEnableNextForEmailEmergencyContactsSection = true;
    this.emailIndicator = "send";
    this.emailIndicatorTooltip = "Email is pending";
    const incidentEmailSentSubscription = this.incidentEmailSentGQL
      .subscribe({ incidentId: this.store.selectSnapshot(IncidentSelector.incidentId) })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.emailIndicator = "mark_email_read";
        this.emailIndicatorTooltip = "Email has been sent";
        incidentEmailSentSubscription.unsubscribe();
      });
  }

  enableNextInTextSection(): void {
    this.shouldEnableNextForTextEmergencyContactsSection = true;
  }

  enableNextInSalesforceCaseSection(): void {
    this.shouldEnableNextForSalesforceCaseSection = true;
  }
}
