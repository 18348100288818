import { Component, Input, OnInit } from "@angular/core";
import { StateService } from "src/app/shared/services/state.service";
import { ExternalMetadata } from "src/app/models/work-item.model";
import { formattedCanvasDate } from "src/app/shared/utils/format";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { ThemeService } from "src/app/shared/services/theme.service";

@Component({
  selector: "app-lms-info",
  templateUrl: "./lms-info.component.html",
  styleUrls: ["./lms-info.component.scss"],
})
export class LmsInfoComponent implements OnInit {
  @Input() keywordList: string[];
  @Input() workItemId: string;
  metadata: ExternalMetadata;
  canvasData = null;
  currentlyTranslating = false;
  translatedText = [];

  constructor(private state: StateService, private appsync: AppSyncService, private theme: ThemeService) {}

  ngOnInit(): void {
    this.state.externalMetadata$.subscribe((data) => {
      this.metadata = data;
      this.processCanvasData();
    });
  }

  get overrideTextColor(): string {
    return "white";
  }

  get overridePrimaryButtonColor(): string {
    return this.theme.getVariable("--checkbox");
  }

  processCanvasData(): void {
    if (this.metadata) {
      const canvasType = this.metadata.content[0].entityType;
      const name = this.metadata.name;
      const author = this.metadata.author;
      const date = formattedCanvasDate(this.metadata.requestDate);

      let textContents = [];
      if (this.metadata.content[0].textContent) {
        textContents = this.metadata.content[0].textContent.map((c) => ({
          name: c.name,
          content: c.text,
        }));
      }
      let fileContents = [];
      if (this.metadata.content[0].fileContent.length > 0) {
        fileContents = this.metadata.content[0].fileContent.map((c) => ({
          name: c.name,
          type: c.contentType,
        }));
      }

      this.canvasData = {
        canvasType,
        name,
        author,
        date,
        textContents,
        fileContents,
      };
    }
  }

  async translateContent(textToTranslate: string): Promise<void> {
    this.currentlyTranslating = true;
    const response = await this.appsync.translateString([textToTranslate], this.workItemId);

    this.translatedText = response;
    this.currentlyTranslating = false;
  }
}
