<div class="current-and-completed-headers">
    <div *ngFor="let section of currentOrCompletedSections">
        <div class="separator"></div>
        <div class="section-header" (click)="navigateTo(section.name)">
            {{ sectionHeader(section) }}
            <ng-container *ngIf="indicatorIcon(section)">
                <mat-icon [matTooltip]="indicatorTooltip(section)">{{ indicatorIcon(section) }}</mat-icon>
            </ng-container>
        </div>
    </div>
</div>
<ng-content></ng-content>

<div class="navigation" *ngIf="!isAuditItem">
    <button
        class="previous"
        [disabled]="disablePreviousButton"
        [ngClass]="{ disabled: disablePreviousButton, hidden: hidePreviousButton }"
        (click)="goToPreviousSection()">
        Previous
    </button>
    <button
        class="next"
        [disabled]="disableNextButton"
        [ngClass]="{ disabled: disableNextButton, hidden: hideNextButton }"
        (click)="goToNextSection()">
        {{ nextLabelText }}
    </button>
</div>

<div class="separator"></div>

<div class="upcoming-headers">
    <div *ngFor="let section of upcomingSections">
        <div class="section-header" (click)="navigateTo(section.name)">
            {{ sectionHeader(section) }}
            <ng-container *ngIf="indicatorIcon(section)">
                <mat-icon [matTooltip]="indicatorTooltip(section)">{{ indicatorIcon(section) }}</mat-icon>
            </ng-container>
        </div>
        <div class="separator"></div>
    </div>
</div>
