<div
    class="call-emergency-contacts-container"
    data-testid="call-emergency-contacts-component"
    *ngIf="!modalDisplay">
    <div class="header">
        <button
            mat-icon-button
            [matMenuTriggerFor]="callOptionsMenu"
            *ngIf="expand"
            class="toggle-menu"
            data-testid="trigger-unavailable-menu">
            <gaggle-icon
                iconName="menu-ellipsis"
                iconSize="2em"></gaggle-icon>
        </button>
        <div
            *ngIf="expand && timer"
            class="timer">
            <span class="red-text">{{ callTimer | formatTimer }} sec</span> since calling
        </div>
    </div>

    <mat-menu #callOptionsMenu="matMenu">
        <button
            mat-menu-item
            class="show-all-contacts"
            (click)="toggleUnavailableContacts()">
            <span>Show Unavailable Contacts</span>
            <mat-icon *ngIf="showUnavailableContacts">check</mat-icon>
        </button>
    </mat-menu>

    <div
        *ngIf="expand"
        class="expandable-content"
        data-testid="call-contacts">
        <div class="contacts">
            <div
                *ngFor="let contact of filteredContacts"
                class="contact">
                <app-call-emergency-contact-row
                    *ngIf="!isAfterHours"
                    [contact]="contact"
                    (startSectionTimer)="startTimer()"></app-call-emergency-contact-row>
                <app-after-hours-call-emergency-contact-row
                    *ngIf="isAfterHours"
                    [contact]="contact"
                    (startSectionTimer)="startTimer()"></app-after-hours-call-emergency-contact-row>
            </div>
            <app-psap *ngIf="isAfterHours"></app-psap>
        </div>
    </div>
</div>

<div
    *ngIf="modalDisplay"
    class="modal-contact-container"
    data-testid="modal-contacts">
    <div class="header">
        <div>Call Emergency Contacts</div>
        <button
            mat-icon-button
            [matMenuTriggerFor]="callOptionsMenu"
            class="toggle-menu"
            data-testid="trigger-unavailable-menu">
            <gaggle-icon
                iconName="menu-ellipsis"
                iconSize="2em"></gaggle-icon>
        </button>
    </div>

    <mat-menu #callOptionsMenu="matMenu">
        <button
            mat-menu-item
            class="show-all-contacts"
            (click)="toggleUnavailableContacts()">
            <span>Show Unavailable Contacts</span>
            <mat-icon *ngIf="showUnavailableContacts">check</mat-icon>
        </button>
    </mat-menu>

    <div
        class="contacts"
        data-testid="call-contacts">
        <ng-container *ngFor="let contact of filteredContacts">
            <app-call-emergency-contact-row
                *ngIf="!isAfterHours"
                [contact]="contact"></app-call-emergency-contact-row>
            <app-after-hours-call-emergency-contact-row
                *ngIf="isAfterHours"
                [contact]="contact"></app-after-hours-call-emergency-contact-row>
        </ng-container>
        <app-psap *ngIf="isAfterHours"></app-psap>
    </div>
</div>
